import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import Notifications from "react-notification-system-redux";
import AddPayment from "./addPayment";
import SettleAmount from "./settleAmount";
import Table from "../common/tableWithBackEndPagination";
import { checkPrivileges, PaginationConfig } from "../../Helpers";
import ConfirmDialoag from "../common/ConfirmDialoag";
import Loader from "../../Layout/Loader";
import ExportImportMenu from "../../Components/common/ExportImportMenu";

import ImportFile from "./../common/ImportExcel";
import moment from "moment";
import TextField from "../common/TextField";
import Button from "./../common/Button";
import { Grid } from "@material-ui/core";


let source;
class paymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...PaginationConfig,
      // addOption: false,
      selectedDevice: "",
      selectedUsers: "",
      defaultDeviceList: true,
      selectedOption: false,
      isVisableDeviceBtn: false,
      duplicateIdentifier: false,
      onDeleteConfirmation: false,
      selectedUnitUserList: "",
      userId: "",
      
      importOption: false,
      labelSelected: "",
      categories: [],
      pagData: "",
      itemAttributes: {},
      selectedGroup: "",
      paymentData: "",
      searchContractText: "",
      value: "maintenance",
      openFilter: false,
      pageSize:20,
      page:1,
      accounts:[],
      searchAccount:"",
      loader1: false,

    };
  }

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
    this.props.dispatch(Notifications.removeAll());
  }

  getMorePayments = () => {
    let selectedId = this.state.userId || this.props.logInUser.id;
    let { page, pageSize, itemSearch } = this.state;
    source = axios.CancelToken.source();
    axios
      .get(
        `/api/payments/get?userId=${this.props.logInUser.id}&all=true&page=${page}&&limit=${pageSize}&&search=${itemSearch}`,
        {
          cancelToken: source.token,
        }
      )
      .then((response) => {
        let res = response.data;
        if (res.status === "success") {
          this.setState({
            pagData: response.data.data,
          });
        } else if (res?.statusCode === "440") {
          window.location.replace("/login");
        } else if (res.statusCode) {
          var err = res?.message.split(":");
          err[1] = err[1].replace(")", "");
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate(err[1]),
              autoDismiss: 5,
              location: this.props.location,
            })
          );
        }
      })
      .catch((e) => {
        //   this.props.dispatch(Notifications.error({
        //   message: 'somethingWentWrong',
        //   autoDismiss: 5
        // }))
      });
  };

  SearchItem = (searchValue) => {
    source.cancel();
    this.setState(
      {
        page: 1,
        itemSearch: searchValue,
      },
      () => this.getPaymentsData()
    );
  };
 
  
  componentWillMount() {
    this.props.dispatch(Notifications.removeAll());
    this.getPaymentsData();
  }
  searchContractText = (e) => {
    source.cancel();
    this.setState(
      {
        searchContractText: e.target.value,
      },
      () => {
        this.getPaymentsData();
      }
    );
  };
  

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ page:1,pageSize: rowsPerPage }, () => {
      this.getPaymentsData();
    });
  };

  handleChangePage = (value) => {
    this.setState({ page: value }, () => {
      this.getPaymentsData();
    });
  };

  setPagination = (items) => {
    let nItem = { ...items };
    let lastPage = nItem.total / this.state.pageSize;
    let IsFloate = this.checkFloteNumber(lastPage);
    delete nItem.data;
    nItem.pageSize = this.state.pageSize;
    nItem.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
    return nItem;
  };

  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  downloadSinglePayment = (item, type) => {
    let Accept = type
      ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      : "application/pdf";

    let api = this.props.leasing
      ? `   api/payments/export/${item[0].id}`
      : `  api/payments/export/${item[0].id}`;
    axios({
      method: "GET",
      url: api,
      headers: {
        Accept: Accept,
      },
      responseType: "blob",
    })
      .then((response) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        if (!type) {
          a.setAttribute("download", `Payment.pdf`);
        } else {
          a.setAttribute("download", `Payment.xlsx`);
        }
        document.body.appendChild(a);
        a.href = url;
        a.click();
      })
      .catch((error) => {
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate("somethingWentWrong"),
            autoDismiss: 5,
            location: this.props.location,
          })
        );
      });
  };

  handleChangeTabs = (e) => {
    let contractType;
    contractType =   e ==="maintenance" ? 1:e ==="leasing" ? 2:""
    this.setState({ value: e }, () => { 
      this.getPaymentsData();
      this.props.setContractType(contractType);
    });
  };
  getPaymentsData = () => {
    let cc = this.state.value === "maintenance" ? 1 : 2;
    let apiUrl = `/api/payments/contracts?page=${this.state.page}&limit=${this.state.pageSize}&contractType=${cc}&search=${this.state.searchContractText}`;
   
    source = axios.CancelToken.source();
    this.setState({
      loader1: true,
    },()=>{
    axios
      .get(apiUrl, {
        cancelToken: source.token,
      })
      .then((response) => {
        this.setState({
      loader1: false,

          paymentData: response?.data?.data || [],
        });
      })
      .catch((e) => {
        // console.log("e ===", e);
      });
    })
  };

  handleModale =  (n) => {
    if(n === "addOption"){
      this.setState({
        // pageSize:-1,
      },()=>   {
        if(!this.props.showPayments){
          this.getMoreAccounts()
        }
              this.props.setDefaultForm()
            }) 
    } else if(n === "settleAmountOption") {
      this.setState({ pageSize:1 }, () => { 
        if(!this.props.showPayments){
          this.getMoreAccounts()
        }
        this.props.setDefaultForm() 
      })
    } else { 
      this.setState({
        pageSize:20,
      },()=>    {this.props.setDefaultForm()
        if(!this.props.showPayments){
          this.getMoreAccounts()
        }} )
    }

  }

   getMoreAccounts = () => {
      let cc = this.state.value === "maintenance" ? 1 : this.state.value === "leasing" ? 2 :this.state.value === "cashCustomer" ? 3 :"";
      let {
        page,
        pageSize,
        searchAccount
      } = this.state;
      let apiUrl = `/api/accounts/get?userId=${this.props.logInUser.id}&contractType=${cc}&all=true&page=${page}&limit=-1&search=${searchAccount}`;
  
      source = axios.CancelToken.source();
      
         this.setState({
          accounts: [],
          // pageSize:newPageSize,
          // loader: true // set loader to true
        }, () => {
      axios
        .get(apiUrl, {
          cancelToken: source.token,
        })
        .then((response) => {
          if (response.status === 200) {
            // this.props.dispatch(getAccounts(response.data.data));
            this.setState({
              accounts: response?.data?.data.data || {},
              // loader: false 
            });
          }
        })
        .catch((e) => {
          // if (axios.isCancel(e)) {
          //   console.log('Request canceled = ', e);
          // }
        });
      })
  
    };


  render() {
    const { devices, group } = this.props;
    const { value } = this.state;
    let fileName = "units";

    return (
      <Grid>
        {this.props.addOption ? (
          <Fragment>
            <AddPayment
              {...this.state}
              {...this.props}
              title={this.props.translate("Add Payment")}
              data={this.props.data}
              users={this.props.users}
              selectedAccount={this.props.selectedAccount}
              group={group}
              formSubmit={this.props.handleSubmit}
              handleChangeLicenseExpDate={this.props.handleChangeLicenseExpDate}
              handleChange={this.props.handleChange}
              modalControle={this.props.modalControle}
              buttonText={this.props.translate("sharedCreate")}
              isVisableAddBtn={this.props.isVisableAddBtn}
              checkRequiredFields={this.props.checkRequiredFields}
              creatingUnit
            />
          </Fragment>
        ) : this.props.settleAmountOption ? (
          <Fragment>
            <SettleAmount
              {...this.state}
              {...this.props}
              title={this.props.translate("settleAmount")}
              data={this.props.data}
              formSubmit={this.props.handleSubmitSettleAmount}
              handleChange1={this.props.handleChange1}
              modalControle={this.props.modalControle}
              creditAmountForSettle={this.props.creditAmountForSettle}
              overallInvoiceBalance={this.props.overallInvoiceBalance}
              buttonText={this.props.translate("settle")}
              isVisableSettleBtn={this.props.isVisableSettleBtn}
            />
          </Fragment>
        ) : this.props.editOption ? (
          <>
            <AddPayment
              {...this.state}
              {...this.props}
              searchInvoices={this.searchInvoices}

              title="Payment Details"
              data={this.props.data}
              users={this.props.users}
              selectedAccount={this.props.selectedAccount}
              group={group}
              formSubmit={this.props.handleSubmit}
              handleChangeLicenseExpDate={this.props.handleChangeLicenseExpDate}
              handleChange={this.props.handleChange}
              modalControle={this.props.modalControle}
              buttonText={this.props.translate(" ")}
              isVisableAddBtn={false}
              checkRequiredFields={this.props.checkRequiredFields}
              creatingUnit
            />
          </>
        ) : this.props.showPayments || this.props.openFilter ? (
          <>
            <Grid
              container
              className="breadcrumb-row"
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid item xs={12} sm={2}>
                <h3 style={{ margin: 0 }}>
                  {this.props.translate("payments")}
                </h3>
              </Grid>

              <Grid
                item
                xs={12}
                sm={10}
              >
                <div style={{ width: "30%", marginRight: "10px" }}>
                  {/* <TextField
                    fullWidth
                    label={this.props.translate("search")}
                    value={this.props.searchPayment}
                    onChange={(e) => this.props.SearchItem(e)}
                  /> */}
                <TextField
                      fullWidth
                      label={this.props.translate("paymentNumber")}
                      value={this.props.paymentNumber}
                      onChange={(e) => this.props.SearchItem(e, "paymentNumber")}
                    />
                   
                  </div>
                  <div style={{ width: "30%", marginRight: 10 }}>
                  <TextField
                      fullWidth
                      label={this.props.translate("refNumber")}
                      value={this.props.refNumber}
                      onChange={(e) => this.props.SearchItem(e, "refNumber")}
                    />
                  </div>
                  <div style={{ width: "15%" }}>
                  <Button
                      aria-label="search"
                      size="small"
                      color="inherit"
                      onClick={this.props.getSearchedPayments}
                      id="search"
                    >
                      {this.props.translate("search")}
                    </Button> 
                  </div>
                <div style={{ textAlign: 'end', marginRight: '15px' }}>
                  <Button
                    size="small"
                    color="inherit"
                    onClick={()=>{this.props.settleAmount(); this.handleModale("settleAmountOption")}}
                    id="settleAmountButton"
                  >
                    {this.props.translate("settleAmount")}
                  </Button>
                </div>
                <div style={{ width: "15%", marginRight: '15px' }}>
                  <Button
                    aria-label="Create"
                    size="small"
                    color="inherit"
                    onClick={()=>{this.props.addDevice(); this.handleModale("addOption")}}
                    id="createButton"
                    disabled={!checkPrivileges("paymentCreate") }
                  >
                    {this.props.translate("create")}
                  </Button>
                </div>
                <div style={{ width: "15%" }}>
                  <Button

                    aria-label="back"
                    size="small"
                    color="inherit"
                    onClick={()=>{this.props.goBack();}}
                    id="backButton"
                  >
                    {this.props.translate("sharedBack")}
                  </Button>
                </div>
                <div style={{ width: "15%" }}>
                  <ExportImportMenu
                    fileName="payments"
                    checkPrivilege="serviceCreate"
                    isServices
                    // data={this.props.paymentList.data || []}
                    themecolors={this.props.themecolors}
                    categoryName={this.state?.selectedServiceTypeId || ""}
                    noPdf
                  />
                </div>
              </Grid>
            </Grid>
            <Grid>
          
              {this.props.loader2 ? <Loader/> :
               <Table
                //Payment Accounts List ...
                rows={this.props.paymentList?.data || []}
                pagination={
                  this.props.paymentList &&
                  this.props.paymentList.data &&
                  this.props.paymentList.data.length &&
                  this.props.setPagination(this.props.paymentList)
                }
                handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                handleChangePage={this.props.handleChangePage}
                loginAsUser={this.loginAsUser}
                filterDropdown
                downloadInvoice={this.downloadSinglePayment}
                // hasAccessOfDelete={checkPrivileges("paymentDelete")}
                // hasAccessOfUpdate={checkPrivileges("paymentUpdate")}
                isCursoPointer
                payment
                onEdit={this.props.paymentSelection}
                isEditable
                timezone={this.props.logInUser?.attributes?.timezone || "Asia/Riyadh" }
                SearchItem={this.SearchItem}
                rowDefinition={[
                  {
                    id: "paymentNumber",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("paymentNumber"),
                  },
                  {
                    id: "invoiceNumbers",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("invoiceNumbers"),
                  },
                  {
                    id: "refNum",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("refNum"),
                  },
                 
                  {
                    id: "paymentMode",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("paymentMode"),
                  },
                  {
                    // id: `${attributes.customerName}`,
                    id: "customerName",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("customerName"),
                  },
                  {
                    id: "amount",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("amount"),
                  },
                  {
                    id: "paymentDate",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("paymentDate"),
                  },

                  {
                    id: "adjustmentType",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("adjustmentType"),
                  },

                  {
                    id: "recipientDate",
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate("recipientDate"),
                    hide: false,
                  },
                  {
                    id: "paymentPdf",
                    label: this.props.translate("payment"),
                  },
                ]}
              />
  }
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} sm={12} style={{ display: "flex" }}>
              <Grid item xs={4} sm={4} style={{ display: "flex" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.handleChangeTabs("maintenance")}
                  style={{
                    width: "50%",
                    borderTopRightRadius: " 15px",
                    borderTopLeftRadius: "15px",
                    marginLeft: "5px",
                    backgroundColor:
                      value === "maintenance" ? "#00C179" : "#ffff",
                    color: value === "maintenance" ? "#ffff" : "#00C179",
                  }}
                >
                  {this.props.translate("maintenance")}
                </Button>

                <Button
                  variant="contained"
                  disabled={false}
                  onClick={() => this.handleChangeTabs("leasing")}
                  style={{
                    width: "50%",
                    borderTopRightRadius: " 15px",
                    borderTopLeftRadius: "15px",
                    marginLeft: "5px",
                    backgroundColor: value === "leasing" ? "#00C179" : "#ffff",
                    color: value === "leasing" ? "#ffff" : "#00C179",
                  }}
                >
                  {" "}
                  {this.props.translate("leasing")}
                </Button>
              </Grid>

              <Grid
                item
                xs={8}
                sm={8}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <div style={{ width: "15%", marginRight: 5, marginTop: 6 }}>
                  <Button
                    aria-label="filter"
                    size="small"
                    color="inherit"
                    onClick={()=>this.props.openFilters(this.state.value)}
                    style={{ marginRight: "10px" }}
                  >
                    {this.props.translate("allPayments")}
                  </Button>
                </div>
                <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                    fullWidth
                    label={this.props.translate("search")}
                    value={this.state.searchContractText}
                    onChange={(e) => this.searchContractText(e)}
                  />
                </div>

             
              </Grid>
            </Grid>
            {this.state.loader1 ? <Loader/> :  
            <Table
              rows={
                (this.state.paymentData && this.state.paymentData.data) || []
              }
              pagination={
                this.state.paymentData &&
                this.state.paymentData.data &&
                this.state.paymentData.data.length &&
                this.setPagination(this.state.paymentData)
              }
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              loginAsUser={this.loginAsUser}
              filterDropdown
              onClick={this.props.singlePaymentSelection}
              
              isCursoPointer
              SearchItem={this.SearchItem}
              rowDefinition={[
                {
                  id: "accountName",
                  label: this.props.translate("accountName"),
                },
                {
                  id: "contractName",
                  label: this.props.translate("contractName"),
                },
                {
                  id: "contractNumber",
                  label: this.props.translate("contractNumber"),
                },
                {
                  id: "creditLimit",
                  label: this.props.translate("creditLimit"),
                },
                {
                  id: "creditPeriod",
                  label: this.props.translate("creditPeriod"),
                },
                {
                  id: "totalInvoices",
                  label: this.props.translate("totalInvoices"),
                },
                {
                  id: "totalPaidInvoices",
                  label: this.props.translate("totalPaidInvoices"),
                },
                {
                  id: "partiallyPaid",
                  label: this.props.translate("partiallyPaid"),
                },
                {
                  id: "totalUnpaidInvoices",
                  label: this.props.translate("totalUnpaidInvoices"),
                },
             
              ]}
            />
          }
          </>
        )}

        {this.props.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.props.onCancel}
            onOk={this.props.deletePayment}
            title={this.props.translate("areYouWantToDelete")}
            children={this.props.selectedPayment.adjustmentType}
          />
        )}
        {this.state.importOption && (
          <ImportFile
            fileName={"payments"}
            Option={this.state.importOption}
            onClose={this.onCloseC_Modal}
          />
        )}
      </Grid>
    );
  }
}
const mapState = (state) => {
  const devices = {
    ...state.devices,
    data:
      state.devices.data &&
      state.devices.data.map((d) => {
        if (d.created) {
          if (state.logInUsers.twelveHourFormat) {
            d.created = moment(d.created).format("YYYY-MM-DD hh:mm A");
          } else {
            d.created = moment(d.created).format("YYYY-MM-DD HH:mm");
          }
          return d;
        } else {
          return d;
        }
      }),
  };

  return {
    devices: state.devices,
    logInUser: state.logInUsers,
    group: state.groups,
    themecolors: state.themecolors,
    ServerSetting: state.ServerSetting,
    parentUnitLimits: state.parentUnitLimits,
  };
};

const mapStateToProps = connect(mapState);
export const PaymentList = mapStateToProps(withLocalize(paymentModal));
