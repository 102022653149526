import React, { Component } from "react";
import { connect } from "react-redux";
import Layout from "../../wrapper";
import { logInUserInfo } from "../../Actions/Users";
import EmptyState from "../../Components/common/EmptyState";
import { withLocalize } from "react-localize-redux";
import Notifications from "react-notification-system-redux";
import { getPrivileges } from "../../Actions/Privileges";
import { checkPrivileges, isOwnerOrDealer , replaceSpecialCh} from "../../Helpers";
import Loader from "../../Layout/Loader";
import { Grid, InputAdornment } from "@material-ui/core";
import Table from "../common/tableWithBackEndPagination";
import ExportFile from "../../Components/common/ExportFile";
import Button from "../common/Button";
import TextField from "../common/TextField";
import ConfirmDialoag from "../common/ConfirmDialoag";
import ExportImportMenu from "../common/ExportImportMenu";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import SingleSelect from "../common/SingleSelect";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import AsyncSelect from "../common/asyncSelect";
import instance from "../../axios";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import MenuItem from "../common/MenuItem";
import parseHtml from "react-html-parser";


class CreateInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentWillMount() {
  }



render() {
    return (
      <Grid>
        
          <CashCustomerForm 
            {...this.state}
            {...this.props}
       />
        
      </Grid>
    );
  }
}


const CashCustomerForm = ({
  translate,
  form,
  handleChange,
  handleChangeLeasingItems,
  closeInvoiceForm
  
}) => {
  return (
    <div style={{ padding: 10 }}>
      <h4 className="page-title">{translate("cashInvoice")}</h4>
      <Grid container spacing={1}>
      <Grid item md={3} sm={6} xs={12}>
      {/* <SingleSelect
                // array={invoiceData && invoiceData.data || []}
                async
                selectName="cashAccount"
                isClearable
                label={translate("contractNumber")}
                // value={
                //   form &&
                //   form.contractNumber?.id
                //     ? {
                //         id: form.contractNumber.contractNumber,
                //         value:
                //         form.contractNumber.contractNumber,
                //         label: form.contractNumber.name || form.contractNumber.accountName,
                //       }
                //     : ""
                // }
                // value={this.props.form.contractNumbers || ""}
                // handleChange={handleChangeForAccount}
                canAssign={true}
              /> */}
                </Grid>
        <Grid item md={3} sm={6} xs={12}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  format="MM/yyyy"
                  required
                  // error={false}
                  margin="dense"
                  label={translate("from")}
                  variant="outlined"
                  fullWidth
                  views={["year", "month"]}
                  error={
                    !form?.from
                    // ||   moment(1, "DD").startOf("day").toISOString()
                  }
                  helperText={
                    !form?.from
                      ? translate("enterDate")
                      : ""
                  }
                  // value={this.props?.form?.from || ""}
                  value={
                    form && form.from
                      ? new Date(`${form.from}`)
                      : null
                  }
                  // allowEmpty={true}
                  onChange={(e) => handleChange("from", e)}
                  InputProps={{
                    classes: {
                      root: "theme-cssOutlinedInput",
                      input: "theme-input",
                      focused: "theme-cssFocused",
                      notchedOutline: "theme-notchedOutline",
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "theme-label",
                      focused: "theme-label-focused",
                      shrink: "theme-label-shrink",
                    },
                  }}
                />
            </MuiPickersUtilsProvider>
        </Grid>  
     
        
      </Grid>

      <div style={{ display: "flex", marginTop: 10 }}>
        <div style={{ marginLeft: "auto" }} />
        <Button
          size="small"
          style={{ marginRight: 15 }}
          className="button-white"
          onClick={closeInvoiceForm}
        >
          {translate("sharedCancel")}{" "}
        </Button>

        <Button
          size="small"
        //   onClick={submitForm}
        //   disabled={!isVisableSubmitBtn}
        >
          {/* {translate(mode === "update" ? "update" : "sharedCreate")} */}
        </Button>
      </div>
    </div>
  );
};






const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  themecolors: state.themecolors,
});

export default connect(mapStateToProps)(withLocalize(CreateInvoice));
