import React, { Component, Fragment } from "react";
import SimpleModalWrapped from "../common/Modal";
import { withStyles } from "@material-ui/core/styles";

import { FormControl, Grid, ListItemText, MenuItem, Select } from "@material-ui/core";
import { withLocalize } from "react-localize-redux";
import Button from "../common/Button";
import Checkbox from "../common/Checkbox";
import TextField from "../common/TextField";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import SingleSelect from "../common/SingleSelect";
import { InputLabel } from "@material-ui/core";
import AsyncSelect from "../common/asyncSelect";
import instance from "../../axios";
import axios from "axios";
import Scrollbar from "react-scrollbars-custom";
import { connect } from "react-redux";
import Loader from "../../Layout/Loader";

let cancelTokenSource;

let themecolors;
const mapStateToProps = (state, ownProps) => {
  themecolors = state.themecolors;
  return {
    themecolors: state.themecolors,
  };
};

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});

const IssuingParty = [
  {
    key: "National Transportation Solutions Company",
    name: "National Transportation Solutions Company",
  },
];

const Remarks = [
  {
    key: "Vehicle does not belong to Fleet",
    name: "Vehicle does not belong to Fleet",
  },
  {
    key: "Not Approved Items",
    name: "Not Approved Items",
  },
  {
    key: "Price Difference",
    name: "Price Difference",
  },
  
  {
    key: "Traffic Voilation",
    name: "Traffic Voilation",
  },
  {
    key: "Service Charges",
    name: "Service Charges",
  },
  {
    key: "Battery Charges",
    name: "Battery Charges",
  },
  {
    key: "Off Road Vehicles",
    name: "Off Road Vehicles",
  },
  {
    key: "Others",
    name: "Others",
  },
];




class AddCreditModal extends Component {
  constructor() {
    super();
    this.state = {
      loader:false,
      showMore:20,
      showItemList:false,
      totalAmount:0,
      totalAmount2:0,
      showLists:false
    };
  }


   searchInvoicesList = async (inputValue) => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel("New request");
    }
  
    cancelTokenSource = axios.CancelToken.source();
    let url;
    if (!inputValue) {
      return [];
    }
        if (this.props.value === "maintenance") {
          url = `/api/invoices/get?&contractNumber=${this.props.form?.creditDebitNote?.contractNumber}&invoicenumber=${inputValue}`;
        } else if (this.props.value === "leasing") {
          url = `/api/leaseinvoices/get?&contractNumber=${this.props.form?.creditDebitNote?.contractNumber}&invoicenumber=${inputValue}`;
        }
        // source = axios.CancelToken.source();
        return await instance({
          method: "GET",
          url: url,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          cancelToken: cancelTokenSource.token,
        })
          .then((response) => {
            if (response.data) {
              return response.data.map((item) => {
                return {
                  id: item.id,
                  value: item.invoiceNumber,
                  label: item.invoiceNumber,
                  uniqueId: item.invoiceNumber,
                  valueType: "string",
                };
              });
            }
          })
          .catch((error) => {
     });
  };

  handleChangeAmount = (e)=>{
    this.setState({
      totalAmount:e.target.value
    })
  }


  handleScroll = (event,data,ind)=>{
    const { scrollTop, scrollHeight, clientHeight } = event;
    const distanceFromBottom = scrollHeight - (scrollTop + clientHeight);
    const threshold = 10;
    if (distanceFromBottom <= threshold) {
       // If yes, load the next batch of items
      this.setState({showMore:this.state.showMore+10},
       ()=>{
          this.paginateData(data,ind)
       })
    }
  }

    paginateData = (data, ind)=>{
      let makeItems= []
   
      data&& data.map((item,i)=>{
        let lineItem = item
        if(i<this.state.showMore){
           makeItems.push(
              <Grid container spacing={2} key={i}>
                <Grid item md={11} sm={12} xs={12}>
                  <Grid
                    container
                    spacing={2}
                    key={i}
                  >
                    <Grid item md={2} sm={6} xs={12}>
                    <h5 style={{marginTop:"15px"}} >
                    { lineItem.itemName + "  " + "-  " + lineItem.itemCode || ""  }
                      </h5>
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                    <h5 style={{marginTop:"15px"}} >
                    { lineItem.deliveryNoteNumber  || ""  }
                      </h5>
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField
                        id="itemQty"
                        label={this.props.translate(
                          "itemQty"
                        )}
                        required
                        placeholder=""
                        value={lineItem.itemQty || ""}
                        onChange={(e) =>
                          this.props.handleChangeDNItems(
                            e,
                            "itemQty",
                            ind,
                            i
                          )
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        readOnly
                      
                      />
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField
                        id="saleItemPrice"
                        label={this.props.translate(
                          "itemPrice"
                        )}
                        required
                        placeholder=""
                        value={lineItem.saleItemPrice || 0}
                        onChange={(e) =>
                          this.props.handleChangeDNItems(
                            e,
                            "saleItemPrice",
                            ind,
                            i
                          )
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        type="number"
                      />
                    </Grid>
                    <Grid item md={1} sm={6} xs={12}>
                    <h5 style={{marginTop:"15px"}} >
                      {lineItem.totalAmount?.toFixed(2)}
                      </h5>
                      
                    </Grid>
                    <Grid item md={1} sm={2} xs={4}>
                    <h5 style={{marginTop:"15px"}} >
                    {lineItem.vatPercent?.toFixed(2) } 
                    </h5>
                    </Grid>
                  <Grid item md={1} sm={2} xs={4}>
                    <h5 style={{marginTop:"15px"}}>
                      {lineItem.netVAT.toFixed(2)}
                    </h5>
                    </Grid>
                    <Grid item md={1} sm={6} xs={12}>
                      <Button
                        size="small"
                        style={{ margin: 5 }}
                        onClick={() =>this.props.handleDeleteDNItem(ind,i)}
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
         }
         else{
          return;
         }
      })
         return makeItems
    }

  render() {
    const { form, classes , tranalate} = this.props;
    return (
      <Fragment>
        <SimpleModalWrapped
          {...this.state}
          {...this.props}
          visable
          isButtonVisable
          title={this.props.title}
          isNotShowAttributesBtn
          formSubmit={this.props.formSubmit}
          modalControle={this.props.modalControle}
          btnclosetext={this.props.translate("sharedBack")}
          isVisableBtn={this.props.isVisible}
          content={
            <>
                <Grid container spacing={2}>
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      disabled
                      id="type"
                      label={this.props.translate("type")}
                      placeholder=""
                      value={form?.creditDebitNote?.type || ""}
                      onChange={this.props.handleChange("type")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      // select
                      required
                    >
                    </TextField>
                  </Grid>
                 
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="issuingParty"
                      label={this.props.translate("issuingParty")}
                      required
                      select
                      placeholder=""
                      value={form?.creditDebitNote?.issuingParty || ""}
                      onChange={this.props.handleChange("issuingParty")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    >
                      {IssuingParty.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                          {this.props.translate(option.name)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}style={{zIndex:10}} >
                    <SingleSelect
                      array={
                        (this.props.recievingAccounts && this.props.recievingAccounts) || []
                      }
                      async
                      selectName="receivingParty"
                      label={this.props.translate("receivingParty")}
                      value={
                        form?.creditDebitNote?.receivingParty &&
                        form?.creditDebitNote?.receivingParty.id
                          ? {
                              id: form?.creditDebitNote?.receivingParty
                                .contractNumber,
                              value:
                                form?.creditDebitNote?.receivingParty
                                  .contractNumber,
                              label:
                                form?.creditDebitNote?.receivingParty
                                  .name ,
                              uniqueId:
                                form?.creditDebitNote?.receivingParty
                                  .contractNumber,
                            }
                          : "receivingParty"
                      }
                      handleChange={this.props.handleChangeAccount}
                      canAssign={true}
                    />
                 
                  </Grid>
                 
                  <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id="refNum"
                      label={this.props.translate("refNum")}
                      placeholder=""
                      value={form?.creditDebitNote?.refNum}
                      onChange={this.props.handleChange("refNum")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        format="dd/MM/yyyy"
                        margin="dense"
                        label={this.props.translate("date")}
                        variant="outlined"
                        fullWidth
                        minDate={new Date()} // for check not to select date before today
                        required
                        value={
                          form?.creditDebitNote?.creditDebitDate
                            ? new Date(
                                `${form?.creditDebitNote?.creditDebitDate}`
                              ).toString()
                            : null
                        }
                        onChange={this.props.handleChangeDate(
                          "creditDebitDate"
                        )}
                        InputProps={{
                          classes: {
                            root: "theme-cssOutlinedInput",
                            input: "theme-input",
                            focused: "theme-cssFocused",
                            notchedOutline: "theme-notchedOutline",
                          },
                        }}
                        InputLabelProps={{
                          classes: {
                            root: "theme-label",
                            focused: "theme-label-focused",
                            shrink: "theme-label-shrink",
                          },
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item md={4} sm={6} xs={12}>
                    <FormControl fullWidth variant="outlined" size="small"  margin="dense">
                      <InputLabel id="remarks-label">{this.props.translate("remarks *")}</InputLabel>
                      <Select
                        labelId="remarks-label"
                        id="remarks"
                        multiple
                        margin="dense"
                        size = "small"
                        required
                        value={form?.creditDebitNote?.remarks || []}
                        onChange={this.props.handleChange("remarks")}
                        renderValue={(selected) => (
                          <div>
                            {selected.map((value) => (
                              <div key={value}>{this.props.translate(value)}</div>
                            ))}
                          </div>
                        )}
                      >
                        {Remarks.map((option) => (
                          <MenuItem key={option.key} value={option.key}>
                            <Checkbox checked={form?.creditDebitNote?.remarks?.includes(option.key)} />
                            <ListItemText primary={this.props.translate(option.name)} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    
                  </Grid>

                  <Grid item md={4} sm={6} xs={12}>
                    <Checkbox 
                        checked={this.props.showItems}
                        label={this.props.translate('showItems')}
                        value='showItems'
                        color='primary'
                        // disabled
                        onChange={this.props.handleChange('showItems')}
                      /> 
                </Grid>
                
                  <Grid item md={4} sm={6} xs={12}>
                    <Checkbox 
                          checked={this.props.zeroVat}
                          label={this.props.translate('zeroVat')}
                          value='zeroVat'
                          color='primary'
                          // disabled
                          onChange={this.props.handleChange('zeroVat')}
                        /> 
                    </Grid>
                    
                 
                </Grid>                                                      

                     {this.props.selectedInvioceCopyShow &&
                      this.props.selectedInvioceCopyShow.map((data, ind) => (
                        <div style={{
                                  border: "1px dashed",
                                  padding: "10px",
                                  margin: "5px",
                                }}>
                         {this.props.loaderInvoice ? <div style={{height:"150px"}}> 
                         <Loader component="filter" fixHeight /> 
                         </div> : <>
                          <Grid container spacing={2}>
                            <Grid item md={4} sm={6} xs={12} style={{zIndex:10}}>
                              <AsyncSelect
                                  value={
                                    data && data.id
                                      ? {
                                          id: data.id,
                                          value: data.invoiceNumber,
                                          label: data.invoiceNumber ? data.invoiceNumber : "" ,
                                          name:  data.invoiceNumber ? data.invoiceNumber : "" ,
                                          uniqueId: data.invoiceNumber,
                                        }
                                      : "invoiceNumber"
                                  }
                                  placeholder={this.props.translate("invoiceNumber")}
                                  selectName="invoiceNumber"
                                  defaultOptions={this.props?.invoices || []}
                                  handleChange={this.props.handleChangeAccount}
                                  loadOptions={this.searchInvoicesList}
                                  isMulti={false}
                                  index={ind}
                                  invoiceNumber
                                  menuPlacement="auto"
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                  }}
                              />
                            </Grid>
                            <Grid item md={4} sm={6} xs={12}>
                              <Button
                                className={classes.button}
                                onClick={this.props.addNewInvoice}
                              >
                              { tranalate("Add More")}
                              </Button>
                              <Button
                                className={classes.button}
                                onClick={() => this.props.deleteInvoiceRow(ind)}
                              >
                               {tranalate("delete")}
                              </Button>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                          {this.props.showItems && ( <Grid item md={4} sm={6} xs={12}>
                                  <TextField
                                      id="amount"
                                      label={this.props.translate("FreeAmount")}
                                      placeholder=""
                                      value={this.props.freeAmount[ind]?.freeAmount || 0}
                                      onChange={(e)=>this.props.changeAmount(data,ind,"freeAmount",e)}
                                      variant="outlined"
                                      margin="dense"
                                      fullWidth
                                      type="number"
                                      required
                                    />
                              </Grid>)}
                              <Grid item md={4} sm={6} xs={12}>
                                    <Button
                                      className={classes.button}
                                      // disabled={!this.props.showItems}
                                      onClick={(e)=> this.props.getInvoiceData(ind)}
                                    >
                                      {tranalate("Show Invoice Data")}
                                    </Button>
                            
                              </Grid>
                          </Grid>          


                          {data?.invoiceNumber ? (
                            <>
                          <p style={{color:this.props.invoiceBalanceError ? "red" : ""}}>
                            {tranalate("Invoice Balance")} = {" "}
                            <b>
                             {data.invoiceBalance ? ` ${data.invoiceBalance?.toFixed(2)}` : 0}
                             </b>

                         </p>
                               {form?.creditDebitNote?.type==="credit" ? tranalate("Invoice Credit Amount") :  tranalate("Invoice Debit Amount")} =  {" "}
                                <b>{this.props.showItems ? data?.netVATSum || 0 :  parseFloat(data?.saleNetTotalAmount)?.toFixed(2) || '' }</b>
                              <br></br>
                              <br></br>
                                {this.props.AmountError ? <p style={{color:"red"}}>{this.props.AmountError}</p>  : " " }
                                <br></br>
                                </>
                          ):""}
                                
                          {data?.invoiceNumber ? (
                            <>
                              <div
                                style={{
                                  border: "1px dashed",
                                  padding: "10px",
                                  margin: "5px",
                                }}
                              >
                                <Grid container spacing={2} key={ind}>
                                  <Grid item md={4} sm={6} xs={12}>
                                    <h3>
                                      Selected Invoice ={" "}
                                      {data?.invoiceNumber || ""}
                                    </h3>
                                  </Grid>
                                </Grid>
                                {this.props.showItems ? 
                                  <>
                                  <Grid container spacing={2}>
                                      <Grid item md={11} sm={12} xs={12}>
                                          <Grid  container  spacing={2}  key={ind} >
                                                <Grid item md={2} sm={6} xs={12}><h4>{this.props.translate("itemName/itemCode")}</h4></Grid>
                                                <Grid item md={2} sm={6} xs={12}></Grid>
                                                <Grid item md={2} sm={6} xs={12}></Grid>
                                                <Grid item md={2} sm={6} xs={12}></Grid>
                                                <Grid item md={1} sm={6} xs={12}><h4>{this.props.translate("netAmount")}</h4></Grid>
                                                <Grid item md={1} sm={2} xs={4}><h4>{this.props.translate("VAT")}</h4></Grid>
                                                <Grid item md={1} sm={2} xs={4}><h4>{this.props.translate("Total Amount (with VAT)")}</h4></Grid>
                                              </Grid>
                                          </Grid>
                                      </Grid>
                                <Scrollbar
                                    disableTracksWidthCompensation={true}
                                    style={{
                                      height:
                                        (this.props.minHeight
                                          ? parseInt(this.props.minHeight)
                                          : 60) * (this.props.rows || 5),
                                      overflowX: 'hidden',
                                    }}
                                          onScroll={(event) => this.handleScroll(event, data.items, ind)}
                                          renderTrackHorizontal={(props) => (
                                            <div
                                              {...props}
                                              style={{
                                                display: 'none', // This will hide the horizontal scrollbar track
                                              }}
                                            />
                                          )}
                                        >
                                           {this.paginateData(data.items, ind, this.state.showMore)}
                                 </Scrollbar>
                                 </>
                                 :
                                 <>
                                  <Grid container spacing={2}>
                                      <Grid item md={11} sm={12} xs={12}>
                                          <Grid  container  spacing={2}  key={ind} >
                                                <Grid item md={2} sm={6} xs={12}>
                                                  </Grid>
                                                <Grid item md={2} sm={6} xs={12}>
                                                  </Grid>
                                                <Grid item md={2} sm={6} xs={12}></Grid>
                                                <Grid item md={2} sm={6} xs={12}></Grid>
                                                <Grid item md={2} sm={6} xs={12}><h4>{this.props.translate("netAmount")}</h4></Grid>
                                                <Grid item md={1} sm={2} xs={4}><h4>{this.props.translate("VAT")}</h4></Grid>
                                                <Grid item md={1} sm={6} xs={12}><h4>{this.props.translate("Total Amount (with VAT)")}</h4></Grid>
                                              </Grid>
                                          </Grid>
                                      </Grid>
       <Grid container spacing={2} key={1}>
                <Grid item md={11} sm={12} xs={12}>
                  <Grid
                    container
                    spacing={2}
                    // key={i}
                  >
                    <Grid item md={2} sm={6} xs={12}>
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                    </Grid>
                    <Grid item md={2} sm={6} xs={12}>
                    </Grid>
                    
                    <Grid item md={2} sm={6} xs={12}>
                      <TextField
                        id="saleTotalAmount"
                        label={this.props.translate(
                          "Invoice Credit Amount"
                        )}
                        required
                        placeholder=""
                        value={data.saleTotalAmount || 0}
                        onChange={(e) =>
                          this.props.handleChangeAccount(
                            "saleTotalAmount", e,
                            ind
                          )
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        type="number" 
                      /> 
                      
                    </Grid>
                    <Grid item md={1} sm={2} xs={4}>
                    <h5 style={{marginTop:"15px"}} >
                    { parseFloat(data.saleTotalAmountPercent)?.toFixed(2) }
                    </h5>
                    </Grid>
                    <Grid item md={1} sm={6} xs={12}>
                    <h5 style={{marginTop:"15px"}}>
                      { parseFloat(data.saleNetTotalAmount)?.toFixed(2)}
                    </h5>
                    </Grid>
                 
                  </Grid>
                </Grid>
              </Grid>
                                 </>
                                 
                                 }
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                          </>}
                        </div>
                      ))}
             </>
          }
        />
     
      </Fragment>
    );
  }
}
 
export default connect(mapStateToProps)(
  withLocalize(withStyles(styles)(AddCreditModal))
);
