  import React, { Component } from "react";
  import { connect } from "react-redux";
  import { withLocalize } from "react-localize-redux";
  import Notifications from "react-notification-system-redux";
  import { checkPrivileges , replaceSpecialCh} from "../../Helpers";
  import Loader from "../../Layout/Loader";
  import { Grid, InputAdornment } from "@material-ui/core";
  import Table from "../common/tableWithBackEndPagination";
  import Button from "../common/Button";
  import TextField from "../common/TextField";
  import ConfirmDialoag from "../common/ConfirmDialoag";
  import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
  import DateFnsUtils from "@date-io/date-fns";
  import SingleSelect from "../common/SingleSelect";
  import DeleteIcon from "@material-ui/icons/Delete";
  import axios from "axios";
  import AsyncSelect from "../common/asyncSelect";
  import instance from "../../axios";
  import MenuItem from "../common/MenuItem";
  import parseHtml from "react-html-parser";
  import CustomDialog from "../common/Dialog";
  import ReactJson from 'react-json-view';
 
  let source;

  let subTypes = [];
  let category = 100;

  const makeCashInvoiceData= (data, parentId) => ({
    ...data,
    id:data.id ||  '',
    contractNumber:data.contractNumber.contractNumber || data.contractNumber,
    // createdFor:data.createdFor.id || data.createdFor,
    invoiceDate : data.invoiceDate  || '',
    parentId:parentId || '',
    refNum:replaceSpecialCh(data.refNum || ''),
    saleNetAmount:data.saleNetAmount || '',
    saleTotalAmount:data.saleTotalAmount || '',
    saleVatAmount:data.saleVatAmount || '',
  });
  class InvoiceModal extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isVisable: false,
        loader: false,
        createSubType: false,
        createLeasing: false,
        mode: "create",
        isVisableSubmitBtn: false,
        onDeleteConfirmation: false,
        selectedCategory: {},
        allSubTypes: [],
        garages: [],
        invoiceForm: {},
        garageList: [],
        vehicleList: [],
        leasingItems: [],
        leaseForm: {},
        openResponseInvoice:false,
        singleInvoiceData:{},
        cashForm:{
          id:"", attributes: {},  invoiceDate:"" , saleNetAmount:"" , saleVatAmount:"" , saleTotalAmount :"" ,
          // createdFor: "", 
          refNum: "",parentId:this.props.logInUser.id, contractNumber:" "
        },
        value: "tab1",
        serviceExpenseCollection: [
          {
            itemQty: "1",
            itemPrice: "1",
            itemName: "",
            categoryName: "",
            selectedSubTypes: [],
          },
        ],
        cashAccount:""

      // cashCustomer:this.props.value ==="cashCustomer" ? true : false
      // cashCustomer: false

      };
    }

    componentWillMount() {
      this.setState({
        serviceExpenseCollection: [
          {
            itemQty: "1",
            itemPrice: "1",
            itemName: "",
            categoryName: "",
            selectedSubTypes: [],
          },
        ],
      });
      this.getVehicleList();
    }

    getVehicleList = () => {
      let id =
        this.props.selectedAccount && this.props.selectedAccount.userId
          ? this.props.selectedAccount.userId
          : this.props.logInUser && this.props.logInUser.id;
      if (this.props.logInUser && this.props.logInUser.id) {
         instance({
          url: `/api/vehicles/get?userId=${id}&all=true&limit=50`,
          // url: `/api/vehicles/get?userId=${id}`,
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            // let fetchGarageList = res.data.map((item) => {
            //   return {
            //     id: item.id,
            //     key: item.id,
            //     name: item.label,
            //     label: item.label,
            //     uniqueId: item.id,
            //     // vin: item.vin,
            //     odometer: item.odometer,
            //     plateNumber: item.vehicleLicensePlate,
            //     vehicleLisenceplateArabic: item.vehicleLisenceplateArabic,
            //     areaId: item.areaId,
            //     vehicleType: item.vehicleType,
            //     valueType: "string",
            //   };
            // });
            this.setState({ vehicleList: res.data });
          })
          .catch((error) => {
            // console.log("error ==", error)
          });
      }
    };

    handleChange = (name, value, index) => {
      if (name === "serviceDate") {
        this.setState(
          {
            invoiceForm: {
              ...this.state.invoiceForm,
              [name]: value.toISOString(),
            },
          },
          () => {
            this.checkRequiredFields();
          }
        );
      } else if (name === "vehicleId") {
        this.setState(
          {
            invoiceForm: {
              ...this.state.invoiceForm,
              // vin: value?.vin || "",
              odometerReading: value?.odometer || "",
              plateNumber: value?.vehicleLicensePlate || "",
              areaId: value?.areaId || "",
              [name]: value,
            },
          },
          () => {
            this.checkRequiredFields();
          }
        );
      } else {
        this.setState(
          {
            invoiceForm: {
              ...this.state.invoiceForm,
              [name]: value,
            },
          },
          () => {
            this.checkRequiredFields();
          }
        );
      }
    };

    handleChangeAttributes = (name, value, index) => {
      this.setState(
        {
          invoiceForm: {
            ...this.state.invoiceForm,
            attributes: {
              ...this.state.invoiceForm.attributes,
              [name]: value,
            },
          },
        },
        () => {
          this.checkRequiredFields();
        }
      );
    };

    handleChangeServices = (name, value, index) => {
      let rows = [...this.state.serviceExpenseCollection];
      if (name === "categoryName") {
        // this.getSubTypes(value&&value.id)
        let res = [];
        let api = `/api/expensetypes/subtype?expenseTypeId=${
            value && value.id
          }&search=`,
          source = axios.CancelToken.source();
        axios
          .get(api, {
            cancelToken: source.token,
          })

          .then((response) => {
            response &&
              response.data &&
              response.data.data &&
              response.data.data.map((item) => {
                res.push({
                  key: item.id,
                  id: item.id,
                  categoryName: item.categoryName,
                  itemCode: item.label,
                  name: item.label,
                  label: item.label,
                  unitPrice: item.unitPrice,
                });
              });
            rows[index].selectedSubTypes = res;
            rows[index].categoryName = value;
            this.setState({
              serviceExpenseCollection: rows,
              isVisableSubmitBtn: true,
            });
          });
      } else {
        if (name === "itemName") {
          rows[index]["itemPrice"] = value.unitPrice !== 0 ? value.unitPrice : 0;
        }
        if (name === "itemQty") {
          rows[index]["itemPrice"] =   parseFloat(
            value * rows[index].itemName.itemPrice
          ).toFixed(2);
        }
        let keys = [];
        rows[index][name] = value;
        rows.map((i) => {
          keys.push(i.x);
          return null;
        });
        let sorted_arr = keys.slice().sort();
        let results = [];
        for (let i = 0; i < sorted_arr.length - 1; i++) {
          if (sorted_arr[i + 1] === sorted_arr[i]) {
            results.push(sorted_arr[i]);
          }
        }
        let a = [];
        keys.map((item, index) => {
          results.map((w) => {
            if (item === w) {
              a.push(index);
            }
            return null;
          });
          return null;
        });
        a.map((val) => {
          rows[val] = { ...rows[val] };
          return null;
        });
        this.setState({
          serviceExpenseCollection: rows,
          error: a.length > 0 ? true : false,
          isVisableSubmitBtn: true,
        });
      }
    };


    onEdit = async (item) => {
             if (this.props.value === "maintenance") {
              let invoiceItem = item[0];
       
              let servicesAllData = [];
              if (item[0].garageId) {
                let garage = await this.fetchGarageEdit(
                  invoiceItem.garageId
                    ? invoiceItem.garageId && invoiceItem.garageId.id
                      ? invoiceItem.garageId.id
                      : invoiceItem.garageId
                    : invoiceItem.garageId
                );
                invoiceItem.garageId = garage;
              }
              if (item[0].serviceId) {
                let servic = await this.fetchServiceEdit(
                  invoiceItem.serviceId
                    ? invoiceItem.serviceId && invoiceItem.serviceId.id
                      ? invoiceItem.serviceId.id
                      : invoiceItem.serviceId
                    : invoiceItem.serviceId
                );
                invoiceItem.serviceId = servic;
              }
              if (item[0].vehicleId) {
                let veh = await this.fetchVehicleEdit(
                  invoiceItem.vehicleId
                    ? invoiceItem.vehicleId && invoiceItem.vehicleId.id
                      ? invoiceItem.vehicleId.id
                      : invoiceItem.vehicleId
                    : invoiceItem.vehicleId
                );
                invoiceItem.vehicleId = veh;
              }
      
              item[0] &&
                item[0].items.map((it, index) => {
                   let makeCategoryName = {
                    key: it.categoryName,
                    id: it.categoryName,
                    value: it.categoryName,
                    label: it.categoryName,
                    valid: it.valid,
                  };
                  let makeItemName = {
                    key: it.itemName,
                    id: it.itemName,
                    value: it.itemName,
                    label: it.itemName,
                    name: it.itemName,
                    validItem: it.validItem,
                    itemPrice:it.itemPrice
                  };
                  servicesAllData.push({
                    ...it,
                    categoryName: makeCategoryName,
                    itemName: makeItemName,
                  });
                });
       
              this.setState({
                invoiceForm: invoiceItem,
                serviceExpenseCollection: servicesAllData,
                createSubType: true,
                mode: "update",
              },()=>{ this.props.getGarageList(); this.props.getServicesList() ; this.props.fetchServices() });
            } 
            else if(this.props.value === "cashCustomer"){
              let option = await axios.get(`api/accounts/${item[0].contractNumber}`);
              const  obj = option.data.data; 
          
              const { contractNumber, name, nameArabic, userId, id , contractType } = obj;
              const user = { contractNumber, name, nameArabic, userId, id , contractType };
              
              this.setState({ 
                createSubType: true,
                   cashForm:{...item[0],
                         contractNumber :  user,
                      }, 
              mode: "update", });
            }
            else {
              // let data = item[0].items;
             
              // let data = item[0].items.map((item) => {
              //   const vehicle = this.state.vehicleList.find((v) => v.id === item.vehicleId);
              //   const vehicleId = vehicle ? Object.assign({}, vehicle) : null;
              //   return { vehicleId };
              // });
      
              let data = item[0].items.map((item) => {
                const vehicle = this.state.vehicleList.find((v) => v.id === item.vehicleId);
                return {
                  ...item,
                  vehicleId: vehicle || {},
                };
              });
              this.setState(
                {
                  leaseForm: item[0],
                  leasingItems: data,
                  mode: "update",
                  // vehicleList:""
                },
                () => {
                  this.setState({ createLeasing: true });
                }
              );
            }
          };
    deleteRow = (row, index, e) => {
      let rows = [...this.state.serviceExpenseCollection];
      rows.splice(index, 1);
      this.setState(
        {
          serviceExpenseCollection: rows,
          isVisible:
            rows[0].itemName && rows[0].itemPrice && rows[0].itemQty
              ? false
              : true,
        },
        () => {
          this.state.serviceExpenseCollection.map((row, index) => {
            this.handleChart2("itemQty", row["itemQty"], index);
            this.handleChart2("itemPrice", row["itemPrice"], index);
            this.handleChart2("itemName", row["itemName"], index);
            this.handleChart2("selectedSubTypes", row["selectedSubTypes"], index);
            return null;
          });
        }
      );
    };

    handleChart2 = (name, value, index) => {
      let rows = [...this.state.serviceExpenseCollection];
      let keys = [];
      rows[index][name] = value;
      rows.map((i) => {
        keys.push(i.x);
        return null;
      });
      let sorted_arr = keys.slice().sort();
      let results = [];
      for (let i = 0; i < sorted_arr.length - 1; i++) {
        if (sorted_arr[i + 1] === sorted_arr[i]) {
          results.push(sorted_arr[i]);
        }
      }
      let a = [];
      keys.map((item, index) => {
        results.map((w) => {
          if (item === w) {
            a.push(index);
          }
          return null;
        });
        return null;
      });
      rows.map((sal) => {
        sal.e = false;
        return null;
      });
      a.map((val) => {
        rows[val] = { ...rows[val], e: true };
        return null;
      });
      this.setState({
        serviceExpenseCollection: rows,
        error: a.length > 0 ? true : false,
      });
    };

    addMore = () => {
      let rows = [];
      rows = [...this.state.serviceExpenseCollection];

      if (rows.length) {
        let last = { ...rows[rows.length - 1] };
        rows.push({
          itemName: "",
          itemQty: "1",
          itemPrice: "1",
          categoryName: "",
          valid: true,
          validItem: true,
          selectedSubTypes: [],
        });
      } else {
        let last = { ...rows[rows.length - 1] };
        rows.push(last);
      }
      this.setState({ serviceExpenseCollection: rows });
    };

    addMoreLeaseItems = () => {
      let rows = [...this.state.leasingItems];
      if (rows.length) {
        let last = { ...rows[rows.length - 1] };
        rows.push({
          vehicleId: "",
          saleItemPrice: "",
          noOfDays: "1",
          brandingCost: "",
          telematicsCost: "",
          insuranceCost: "",
          bankInterest: "",
          zakatInterest: "",
          depreciationCost: "",
          maintenanceCost: "",
        });
      } else {
        let last = { ...rows[rows.length - 1] };
        rows.push(last);
      }
      this.setState({ leasingItems: rows });
    };

    getSubTypes = (id) => {
      let res = [];
      let api = `/api/expensetypes/subtype?categoryName=${id}&search=`;
      source = axios.CancelToken.source();
      axios
        .get(api, {
          cancelToken: source.token,
        })

        .then((response) => {
          response.data &&
            response.data &&
            response.data.data.map((item) => {
              res.push({
                key: item.id,
                id: item.id,
                categoryName: item.categoryName,
                name: item.label,
                label: item.label,
                parentId: item.parentId,
                unitPrice: item.unitPrice,
              });
            });
          subTypes = res;
          this.setState({
            allSubTypes: res,
          });
        })
        .catch((error) => {
          console.log("error ===", error);
        });
    };

    checkRequiredFields = () => {
      const { serviceDate } = this.state.invoiceForm;
      if (serviceDate && this.state.serviceExpenseCollection.length) {
        this.setState({ isVisableSubmitBtn: true });
      }
    };
    checkRequiredFields1 = () => {
      const {vehicleId, brandingCost, noOfDays,saleItemPrice,telematicsCost,insuranceCost,bankInterest,zakatInterest,depreciationCost,maintenanceCost } = this.state.leaseForm;

      // const { serviceDate } = this.state.invoiceForm;
      if (vehicleId && brandingCost || noOfDays||saleItemPrice||telematicsCost||insuranceCost||bankInterest||zakatInterest||depreciationCost||maintenanceCost) {
        this.setState({ isVisableSubmitBtn: true });
      }
    };


    submitForm = () => {
      let items = [];
      this.state.serviceExpenseCollection.map((item) => {
        let makePackage = {
          categoryName: item?.categoryName?.label || "",
          itemCode: item?.itemName?.label || "",
          itemName: item?.itemName?.label || "",
          itemPrice: item.itemPrice,
          itemQty: item.itemQty,
        };
        items.push(makePackage);
      });

      let obj = {
        ...this.state.invoiceForm,
        id: this.state.invoiceForm?.id || 0,
        // contractNumber: this.props?.selectedAccount?.contractNumber || "",
        contractNumber:  this.state.invoiceForm?.contractNumber || this.props?.selectedAccount?.contractNumber,
        garageId: this.state.invoiceForm?.garageId?.id || "",
        vehicleId: this.state.invoiceForm?.vehicleId?.id || "",
        serviceId: this.state.invoiceForm?.serviceId?.id || "",
        items: items,
      };
      // let url = this.state.mode ==='create' ? `/api/expensetypes/subtype/`: `/api/expensetypes/subtype/${this.state.serviceForm.id}`
      let url =
        this.state.mode !== "update"
          ? `/api/invoices`
          : `/api/invoices/${this.state.invoiceForm.id}`;
      fetch(url, {
        method: this.state.mode === "update" ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...obj,
        }),
      })
        .then((response) => {
          if (response.ok) {
            this.onInvoiceCreate();
            this.props.dispatch(
              Notifications.success({
                message:
                  this.state.mode === "update"
                    ? this.props.translate("invoiceUpdated")
                    : this.props.translate("invoiceCreated"),
                autoDismiss: 5,
                location: this.props.location,
              })
            );
          }
        })
        .catch((error) => {
          console.log("error ==", error);
        });
    };

    submitLeaseForm = () => {
      let allItems = [];
      this.state && this.state.leasingItems.map((item) => {
         let makeItems = {
          ...item,
          vehicleId: item.vehicleId.id,
        };
        allItems.push(makeItems);
      });
      let obj = {
        ...this.state.leaseForm,
        items: allItems,
      };
      let url =
        this.state.mode !== "update"
          ? `api/leaseinvoices`
          : `api/leaseinvoices/${this.state.leaseForm.id}`;
      fetch(url, {
        method: this.state.mode === "update" ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...obj,
        }),
      })
        .then((response) => {
          if (response.ok) {
            this.onInvoiceCreate();
            this.props.dispatch(
              Notifications.success({
                message:
                  this.state.mode === "update"
                    ? this.props.translate("invoiceUpdated")
                    : this.props.translate("invoiceCreated"),
                autoDismiss: 5,
                location: this.props.location,
              })
            );
          }
        })
        .catch((err) => {
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate(err),
              autoDismiss: 5,
              location: this.props.location,
            })
          );
          console.log("err====", err);
        });
    };

    closeEditForm = () => {
      this.setState({
        isVisableSubmitBtn:false,
        invoiceForm: {},
        createSubType: false,
        createLeasing: false,
        //  cashCustomer:false
        
      });
    };

    openCreateSubTypeFrom = () => {
      this.setState({
        mode: "create",
        createSubType: true,
        invoiceForm: {},
        cashForm:"",
        serviceExpenseCollection: [
          {
            itemQty: "1",
            itemPrice: "1",
            itemName: "",
            categoryName: "",
            selectedSubTypes: [],
          },
        ],
      });
    };

    async fetchGarageEdit(id) {
      return await instance({
        method: "GET",
        url: `/api/garages/${id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          let defaultOptions = {
            id: response.id,
            value: response.id,
            label: response.name,
            name: response.name,
            uniqueId: response.id,
          };

          return defaultOptions;
        })
        .catch((error) => {
          console.log("error garage ===", error);
        });
    }

    async fetchServiceEdit(id) {
      return await instance({
        method: "GET",
        url: `/api/services/${id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          let defaultOptions = {
            id: response.id,
            value: response.id,
            label: response.name,
            name: response.name,
            uniqueId: response.id,
          };
     

          return defaultOptions;
        })
        .catch((error) => {
          console.log("error service ==", error);
        });
    }

    async fetchVehicleEdit(id) {
      return await instance({
        method: "GET",
        url: `/api/vehicles/${id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          let defaultOptions = {
            id: response.id,
            value: response.id,
            label: response.label,
            name: response.label,
            uniqueId: response.id,
          };
          return defaultOptions;
        })
        .catch((error) => {
          console.log("error vehicles==", error);
        });
    }

  

    handleChangeLease = (name, value, index) => {
    };

    handleChangeLeasingItems = (name, value, index) => {
      let rows = [...this.state.leasingItems];
      rows[index][name] = value;
      this.setState({ leasingItems: rows }, () => {
        this.checkRequiredFields1();
      });
    };

    onDelete = (item) => {
      this.setState({
        onDeleteConfirmation: true,
        invoiceForm: item[0],
      });
    };

    onCancel = () => {
      this.setState({
        onDeleteConfirmation: false,
      });
    };

    removeSubType = () => {
    
      let url ;
      if(this.props.value === "cashCustomer"){
        url= `/api/cashinvoices/${this.state.invoiceForm.id}`
      }
      else{
        url= `/api/invoices/${this.state.invoiceForm.id}`
      }
      if (this.state.invoiceForm && this.state.invoiceForm.id) {
        fetch(url, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            if (response.ok) {
              this.setState({ onDeleteConfirmation: false },  () => {
                this.props.fetchInvoices(this.props.selectedAccount.contractNumber);
              });
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate("invoiceDeleted"),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            }
          })
          .catch((er) => {
            console.log("err ===", er);
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate("invoiceDeleted"),
                autoDismiss: 5,
                location: this.props.location,
              })
            );
          });
      }
    };

    onInvoiceCreate = () => {

      this.setState(
        {
          createSubType: false,
          invoiceForm: {},
          leaseForm: {},
          cashForm:{},
          leasingItems: [],
          createLeasing: false,
        },
        () => {
          this.props.fetchInvoices(this.props.selectedAccount.contractNumber);
        }
      );
    };

    downloadInvoice = (item, type) => {
      let Accept = type
        ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        : "application/pdf";
      let api = this.props.leasing
        ? `/api/leaseinvoices/export/${item[0].id}`
        : `/api/invoices/export/${item[0].id}`;
      axios({
        method: "GET",
        url: api,
        headers: {
          Accept: Accept,
        },
        responseType: "blob",
      })
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          if (!type) {
            a.setAttribute("download", `invoice.pdf`);
          } else {
            a.setAttribute("download", `invoice.xlsx`);
          }
          document.body.appendChild(a);
          a.href = url;
          a.click();
        })
        .catch((error) => {
          console.log("er =", error);
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate("somethingWentWrong"),
              autoDismiss: 5,
              location: this.props.location,
            })
          );
        });
    };

    regenerateInvoice = (item, type) => {
      
      let api;
      let invoiceType ;
      if(this.props.leasing){
        api = `api/leaseinvoices/${item[0].id}/recompute`
      }else{
        api = `/api/invoices/${item[0].id}/recompute`
      }
      // if(item[0].)
      // let api = ;
      axios({
        method: "GET",
        url: api,
      })
        .then((response) => {
          if (response?.data.status === "success"  ) {
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate("invoiceRegeneatedSuccessFul"),
                autoDismiss: 5,
                location: this.props.location,
              })
            );
          }
          else{
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(response?.data?.message),
                autoDismiss: 5,
                location: this.props.location,
              })
            );
          }
        })
        .catch((error) => {
          console.log("er =", error);
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate("somethingWentWrong"),
              autoDismiss: 5,
              location: this.props.location,
            })
          );
        });
    };

    forwardToZatca = (item, type) => {
      let api;
      let invoiceType ;
      if(this.props.leasing){
        api = `/api/zatca/forward/leaseinvoice/${item[0].id}`
      }else{
        api = `/api/zatca/forward/invoice/${item[0].id}`
      }
 
      axios({
        method: "GET",
        url: api,
      })
        .then((response) => {
          if (response?.data.status === "success"  ) {
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate("invoicesentToZatcaSuccessFul"),
                autoDismiss: 5,
                location: this.props.location,
              })
            );
          }
          else{
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(response?.data?.message),
                autoDismiss: 5,
                location: this.props.location,
              })
            );
          }
        })
        .catch((error) => {
          console.log("er =", error);
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate("somethingWentWrong"),
              autoDismiss: 5,
              location: this.props.location,
            })
          );
        });
    };

    sendToClient = (item, type) => {
      // console.log("item=====", item, type);
      let accountId = this.props.selectedAccount && Object.keys(this.props.selectedAccount).length > 0
      ? this.props.selectedAccount.id
      : this.props.logInUser.id;

      let api = `api/schema/emailmonthlydnsexcel/${accountId}`;
 
      axios({
        method: "GET",
        url: api,
      })
        .then((response) => {
          if (response?.data.status === "success"  ) {
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate("invoiceSentSuccessFully"),
                autoDismiss: 5,
                location: this.props.location,
              })
            );
          }
          else{
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(response?.data?.message),
                autoDismiss: 5,
                location: this.props.location,
              })
            );
          }
        })
        .catch((error) => {
          console.log("er =", error);
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate("somethingWentWrong"),
              autoDismiss: 5,
              location: this.props.location,
            })
          );
        });
    };
    downloadInvoiceExcel = (item) => {
      let Accept =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      let api = this.props.leasing
        ? `/api/leaseinvoices/export/${item[0].id}`
        : `/api/invoices/export/${item[0].id}`;
      axios({
        method: "GET",
        url: api,
        headers: {
          Accept: Accept,
        },
        responseType: "blob",
      })
        .then((response) => {
          const a = document.createElement("a");
          const url = window.URL.createObjectURL(new Blob([response.data]));
          a.setAttribute("download", `invoice.pdf`);
          document.body.appendChild(a);
          a.href = url;
          a.click();
        })
        .catch((error) => {
          console.log("er =", error);
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate("somethingWentWrong"),
              autoDismiss: 5,
              location: this.props.location,
            })
          );
        });
    };

    handleChangeTabs = (value) => {
      this.setState({ value });
    };


    handleChangeCashCustomer = (name, value, index) => {
      if (name === "invoiceDate") {
        this.setState(
          {
            cashForm: {
              ...this.state.cashForm,
              [name]: value,
            },
            
          },  ()=>this.checkRequiredFields2()
        );
      }  else {
        this.setState(
          {
            cashForm: {
              ...this.state.cashForm,
              // [name]: encodeURIComponent(value),
              [name]: value,
            },
          },  ()=>this.checkRequiredFields2()
        );
      }
    };

    submitCashCustomerForm = async (event) => {
      let result ;
      let { cashForm } = this.state;
      let parentId = this.props.logInUser.id
      const obj1 = makeCashInvoiceData(cashForm,parentId) 
      let  id = this.props.selectedAccount.contractNumber ? this.props.selectedAccount.contractNumber : this.props.logInUser.id
      
      const encodedRefNum = parseHtml(obj1.refNum);
       const obj = { ...obj1, refNum: encodedRefNum.toString() };
      // obj.refNum = encodeURIComponent(obj.refNum);
      // obj.refNum = obj.refNum.replace(/%26/g, '&');

        // let obj =  parseHtml(obj1);
       let headers = {
        "content-type": "application/json" // set the content-type header to application/json
      };
    
      if(this.state.mode !== "update") {
          result = await axios.post(`/api/cashinvoices`, obj, { headers: headers })}
        else{
          result = await axios.put(`api/cashinvoices/${obj.id}`, obj, { headers: headers });
        }
          if (result.data.status === "success") {
            this.setState(
              {
                createSubType: false,
                invoiceForm: {},
                leaseForm: {},
                cashForm:'',
                leasingItems: [],
                createLeasing: false,
                cashAccount:"",
                isVisableSubmitBtn:false
              }
              
            );
            this.props.fetchInvoices(id);
            // this.props.fetchInvoices(this.props.selectedAccount.contractNumber);
            this.props.dispatch(
              Notifications.success({
                message:
                  this.state.mode === "update"
                    ? this.props.translate("invoiceUpdated")
                    : this.props.translate("invoiceCreated"),
                autoDismiss: 5,
                location: this.props.location,
              })
            );
          } else {
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(result.data.message),
                autoDismiss: 5,
                location: this.props.location,
              })
            );
          }
    }

    checkRequiredFields2 = () => {
      const {refNum, contractNumber, invoiceDate, saleNetAmount,saleVatAmount, saleTotalAmount } = this.state.cashForm;
      if (refNum && contractNumber && invoiceDate&& saleNetAmount&&saleVatAmount&& saleTotalAmount ) {
        this.setState({ isVisableSubmitBtn: true });
      }else{
        this.setState({ isVisableSubmitBtn: false });
      }
    };
  handleChangeForAccount = async (name, event, link) => {
    this.setState({
      [name]: event,
      cashForm:{
        ...this.state.cashForm,
        contractNumber:event,
        // createdFor:event
      }, 
    }, ()=>this.checkRequiredFields2() 
    );
  };
  invoiceRespView = (item, n)=>{
  
    let id = n&&n[0]&&n[0].id
    this.setState({
      openResponseInvoice:true,
    },()=>{
       instance({
        method: "GET",
        url: this.props.leasing ? `/api/zatca/response/leaseinvoice/${id}` :`/api/zatca/response/invoice/${id}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if(response.zatcaResponse){
            let z = atob(response.zatcaResponse)
            const jsonData = JSON.parse(z);
            this.setState({singleInvoiceData:jsonData})
          }
          else{

          }
        })
        .catch(err=>{
          console.log('err zatcaResponse ==' , err)
        })
    })
  }
   render() {
      return (
        <Grid>

          {this.props.openInvoiceForm ? <>
            <CreateInvoiceForm
                {...this.state}
                {...this.props}
                getMoreAccounts={this.props.getMoreAccounts}
                isVisableSubmitBtn={this.props.isVisableSubmitBtn1}
                closeInvoiceForm={this.props.closeInvoiceForm}
                handleChange={this.props.handleChangeInvoiceCreate}
                submitForm={this.props.submitCreateInvoice}
            />
          
          </> :
          <>
          {this.state.createSubType && this.props.value === "cashCustomer"  ?
            <CashCustomerForm 
              {...this.state}
              {...this.props}
              isVisableSubmitBtn={this.state.isVisableSubmitBtn}
              form={this.state.cashForm}
              handleChange={this.handleChangeCashCustomer}
              // handleChangeLeasingItems={this.handleChangeLeasingItems}
              translate={this.props.translate}
              mode={this.state.mode}
              handleChangeForAccount={this.handleChangeForAccount}
              // vehicleList={this.state.vehicleList}
              // leasingItems={this.state.leasingItems}
              // addMoreLeaseItems={this.addMoreLeaseItems}
              closeEditForm={this.closeEditForm}
              submitForm={this.submitCashCustomerForm}
         />
          : 
          this.state.createSubType ? (
            <>
              <Grid>
                <EditSubForm
                  invoiceForm={this.state.invoiceForm}
                  handleChange={this.handleChange}
                  handleChangeAttributes={this.handleChangeAttributes}
                  translate={this.props.translate}
                  submitForm={this.submitForm}
                  closeEditForm={this.closeEditForm}
                  mode={this.state?.mode}
                  isVisableSubmitBtn={this.state.isVisableSubmitBtn}
                  checkPrivileges={checkPrivileges}
                  logInUser={this.props.logInUser}
                  expenseMainTypes={this.props.expenseMainTypes || []}
                  selectedCategory={this.state.selectedCategory}
                  allSubTypes={this.state.allSubTypes}
                  serviceExpenseCollection={this.state.serviceExpenseCollection}
                  handleChangeServices={this.handleChangeServices}
                  deleteRow={this.deleteRow}
                  addMore={this.addMore}
                  getGarageList={this.getGarageList}
                  garageList={this.props.garageList}
                  vehicleList={this.state.vehicleList}
                  servicesList={this.props.servicesList}
                  selectedAccount={this.props.selectedAccount}
                />
              </Grid>
            </>
          ) : this.state.createLeasing ? (
            <>
              <>
                <Grid>
                  <LeasingForm
                    isVisableSubmitBtn={this.state.isVisableSubmitBtn}
                    leaseForm={this.state.leaseForm}
                    handleChange={this.handleChangeLease}
                    handleChangeLeasingItems={this.handleChangeLeasingItems}
                    translate={this.props.translate}
                    mode={this.state.mode}
                    vehicleList={this.state.vehicleList}
                    leasingItems={this.state.leasingItems}
                    addMoreLeaseItems={this.addMoreLeaseItems}
                    closeEditForm={this.closeEditForm}
                    submitLeaseForm={this.submitLeaseForm}
                  />
                </Grid>
              </>
            </>
          ) : (
            <>
              <Grid
                container
                className="breadcrumb-row"
                style={{ paddingLeft: 10, paddingRight: 10 }}
              >
                <Grid item xs={12} sm={3}>
                  <h3 style={{ margin: 0 }}>
                    {this.props.translate("Invoices")}
                  </h3>
                </Grid>
                <Grid item xs={12} sm={9} style={{ justifyContent: "flex-end" }}>
                  <div style={{ width: "25%", marginRight: 20 }}>
                    <TextField
                      fullWidth
                      label={this.props.translate("search")}
                      value={this.props.searchInvoice}
                      onChange={(e) => this.props.searchInvoices(e)}
                    />
                  </div>
                  {/* || this.props.value === "leasing" */}
                 { this.props.value === "cashCustomer"  ? 
                  <Button
                      // disabled={
                      //   this.props.leasing
                      //     ? !checkPrivileges("leaseinvoiceCreate")
                      //     : !checkPrivileges("invoiceCreate")
                      // }
                      aria-label="Create"
                      size="small"
                      color="inherit"
                      onClick={this.openCreateSubTypeFrom}
                      id="createButton"
                    >
                      {this.props.translate("create")}
                    </Button> 
                   :""}

                  <Button
                    aria-label="Back"
                    size="small"
                    color="inherit"
                    onClick={this.props.goBack}
                    id="back"
                    style={{ margin: 10 }}
                  >
                    {this.props.translate("sharedBack")}
                  </Button>
                </Grid>
              </Grid>

              <Grid>
        {this.props.loader   ?  <Loader/> :
                <Table
                  rows={this.props && this.props.invoicesData &&  this.props.invoicesData?.data || []}
                  pagination={this.props.setPagination()}
                  handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
                  rowsPerPage={15}
                  handleChangePage={this.props.handleChangePage}
                  isEditable
                  canUpdate
                  canDelete
                  onEdit={this.onEdit}
                  onDelete={this.onDelete}
                  themecolors={this.props.themecolors}
                  serverTimezone={this.props.logInUser?.attributes?.timezone || "Asia/Riyadh" }
                  // hasAccessOfDelete={
                  //   this.props.value === "leasing"
                  //     ? checkPrivileges("leaseinvoiceDelete")
                  //     :
                  //     this.props.value === "maintenance"
                  //     ? checkPrivileges("invoiceDelete")
                  //     :checkPrivileges("cashinvoiceUpdate")
                  // }
                  timezone ={ this.props?.logInUser?.attributes?.timezone || 'Asia/Riyadh'}
                  hasAccessOfUpdate={
                    // this.props.leasing
                    //   ? checkPrivileges("leaseinvoiceUpdate")
                    //   : checkPrivileges("invoiceUpdate")

                      // this.props.value === "leasing"
                      // ? checkPrivileges("leaseinvoiceDelete")
                      // :
                      // this.props.value === "maintenance"
                      // ? checkPrivileges("invoiceDelete")
                      // :
                      this.props.value === "cashCustomer"
                      ?
                      checkPrivileges("cashinvoiceUpdate"):""
                  }
          //  InvoiceRegenerate= {checkPrivileges("invoiceBulkInvoiceRegenerate")}
                  contractNumber={
                    this.props?.selectedAccount?.contractNumber || ""
                  }
                  invoiceType= {this.props.value}
                  downloadInvoice={this.downloadInvoice}
                  regenerateInvoice={this.regenerateInvoice}
                  forwardToZatca={this.forwardToZatca}
                  sendToClient={this.sendToClient}
                  leasing={this.props.leasing}
                  invoiceRow
                  invoiceRespView
                  openinvoiceRespView={this.invoiceRespView}
                  rowDefinition={
                    this.props.value==="leasing"
                      ? [
                          {
                            id: "invoiceNumber",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("invoiceNumber"),
                          },
                          {
                            id: "invoiceDate",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("invoiceDate"),
                          },
                          {
                            id: "saleTotalAmount",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("Amount"),
                          },
                          {
                            id: "saleVATAmount",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("VAT Amount"),
                          },
                          {
                            id: "saleNetAmount",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("Total Amount"),
                          },
                          {
                            id: "zatcaStatus",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("zatcaStatus"),
                            hide: false,
                          },
                          {
                            id: "viewInvoice",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("invoice"),
                            hide: false,
                          },
                        ]
                      : this.props.value==="maintenance" ? [
                          {
                            id: "invoiceNumber",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("invoiceNumber"),
                          },
                          // {
                          //   id: "invoiceRefNumber",
                          //   numeric: false,
                          //   disablePadding: false,
                          //   label: this.props.translate("invoiceRefNum"),
                          // },
                          // {
                          //   id: "serviceId",
                          //   numeric: false,
                          //   disablePadding: false,
                          //   label: this.props.translate("bookingID"),
                          // },
                          // {
                          //   id: "serviceRefNumber",
                          //   numeric: false,
                          //   disablePadding: false,
                          //   label: this.props.translate("customerRefNum"),
                          // },
                          // {
                          //   id: "plateNumber",
                          //   numeric: false,
                          //   disablePadding: false,
                          //   label: this.props.translate("vehicleLicensePlate"),
                          // },
                          {
                            id: "serviceDate",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("serviceDate"),
                          },
                          {
                            id: "saleTotalAmount",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("Amount"),
                          },
                          {
                            id: "saleVATAmount",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("VAT Amount"),
                          },
                          {
                            id: "saleNetAmount",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("Total Amount"),
                          },
                          {
                            id: "valid",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("status"),
                            hide: checkPrivileges("invoiceInvalidView")
                              ? false
                              : true,
                          },
                          {
                            id: "zatcaStatus",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("zatcaStatus"),
                            hide: false,
                          },
                          {
                            id: "viewInvoice",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("invoice"),
                            hide: false,
                          },
                          


                          
                        ]
                        
                        : this.props.value==="cashCustomer"?
                        [
                          {
                            id: "refNum",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("refNum"),
                          },
                          {
                            id: "contractNumber",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("contractNumber"),
                          },
                          {
                            id: "invoiceDate",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("invoiceDate"),
                          },
                          {
                            id: "saleTotalAmount",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("amount"),
                          },
                          {
                            id: "saleVatAmount",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("vatAmount"),
                          },
                          {
                            id: "saleNetAmount",

                            // id: "saleTotalAmount",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("totalAmount"),
                          }
                        ]:[]
                  }
                />
                }
              </Grid>
            </>
          )}
       </>}

          {this.state.onDeleteConfirmation && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeSubType}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.value === "cashCustomer" ? this.state.invoiceForm?.contractNumber : this.state.invoiceForm?.name || ""}
            />
          )}
          {this.state.openResponseInvoice && (
          <CustomDialog
            title="Invoice Response"
            themecolors={this.props.themecolors}
            visable={true}
            onClose={(e)=>{this.setState({openResponseInvoice:false})}}
            bodyPadding={10}
            hideDragIcon
            fullWidth
            isVisableBtn
            noFullScreen
          >
            <div style={{height: "400px",   overflowY: 'scroll',}}>
               <ReactJson src={this.state.singleInvoiceData} displayDataTypes={false} />
            </div>
          </CustomDialog>
        )}
        </Grid>
      );
    }
  }

  

  const CreateInvoiceForm = ({
    translate,
    handleChange,
   invoiceData,
   accounts,
   closeInvoiceForm,
   selectedMonth,
   submitForm,
   isVisableSubmitBtn,
   selectedContractNumber,
   getMoreAccounts
  }) => {
    return (
      <div style={{ padding: 10 }}>
        <h4 className="page-title">{translate("createInvoice")}</h4>
        <Grid container spacing={1}>
        <Grid item md={3} sm={6} xs={12} style={{ position: 'relative', zIndex: 99 }}>
              <SingleSelect
                array={accounts && accounts || []}
                async
                selectName="contractNumber"
                isClearable
                // defaultOptions={defaultZones}
                // loadOptions={getMoreAccounts}
                label={translate("contractNumber")}
                value={
                  selectedContractNumber &&
                  selectedContractNumber
                    ? {
                        id: selectedContractNumber.contractNumber,
                        value: selectedContractNumber.contractNumber,
                        label: selectedContractNumber.name || selectedContractNumber.accountName,
                      }
                    : ""
                }
                handleChange={handleChange}
                canAssign={true}
                menuPlacement="auto"
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </Grid>

          <Grid item md={3} sm={6} xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  format="MM/yyyy"
                  required
                  // error={false}
                  margin="dense"
                  label={translate("from")}
                  variant="outlined"
                  fullWidth
                  views={["year", "month"]}
                  error={
                    !selectedMonth
                    // ||   moment(1, "DD").startOf("day").toISOString()
                  }
                  helperText={
                    !selectedMonth
                      ? translate("enterDate")
                      : ""
                  }
                  // value={this.props?.form?.from || ""}
                  value={
                    selectedMonth && selectedMonth
                      ? new Date(`${selectedMonth}`)
                      : null
                  }
                  // allowEmpty={true}
                  onChange={(e) => handleChange("selectedMonth", e)}
                  InputProps={{
                    classes: {
                      root: "theme-cssOutlinedInput",
                      input: "theme-input",
                      focused: "theme-cssFocused",
                      notchedOutline: "theme-notchedOutline",
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "theme-label",
                      focused: "theme-label-focused",
                      shrink: "theme-label-shrink",
                    },
                  }}
                />
            </MuiPickersUtilsProvider>
          </Grid>  
          
          
        </Grid>

        <div style={{ display: "flex", marginTop: 10 }}>
          <div style={{ marginLeft: "auto" }} />
          <Button
            size="small"
            style={{ marginRight: 15 }}
            className="button-white"
            onClick={closeInvoiceForm}
          >
            {translate("sharedCancel")}{" "}
          </Button>

          <Button
            size="small"
            onClick={submitForm}
            disabled={!isVisableSubmitBtn}
          >
            {translate("sharedCreate")}
          </Button>
        </div>
      </div>
    );
  };






  const EditSubForm = ({
    invoiceForm,
    handleChange,
    handleChangeAttributes,
    translate,
    submitForm,
    closeEditForm,
    mode,
    isVisableSubmitBtn,
    field,
    checkPrivileges,
    logInUser,
    expenseMainTypes,
    allSubTypes,
    serviceExpenseCollection,
    handleChangeServices,
    deleteRow,
    addMore,
    visible,
    getGarageList,
    garageList,
    vehicleList,
    servicesList,
    selectedAccount,
  }) => {
    const searchGarageList = async (inputValue) => {
      if (!inputValue) {
        return [];
      }
      let selecteGarageId =
        this.props.selectedAccount && this.props.selectedAccount.userId
          ? this.props.selectedAccount.userId
          : this.props.logInUser && this.props.logInUser.id;

      return await instance({
        method: "GET",
        url: `/api/garages/get?userId=${selecteGarageId}&page=1&limit=50&all=true&search=${inputValue}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.data) {
            return response.data.map((item) => {
              return {
                id: item.id,
                key: item.id,
                name: item.name,
                label: item.name,
                uniqueId: item.id,
                valueType: "string",
              };
            });
          }
        })
        .catch((error) => {
          // console.log("error ==", error)
        });
    };

    const searchVehicleList = async (inputValue) => {
      if (!inputValue) {
        return [];
      }
      let selecteVehicleId =
        this.props.selectedAccount && this.props.selectedAccount.userId
          ? this.props.selectedAccount.userId
          : this.props.logInUser && this.props.logInUser.id;

      return await instance({
        method: "GET",
        url: `/api/vehicles/get?userId=${selecteVehicleId}&page=1&limit=50&all=true&search=${inputValue}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.data) {
            return response.data.map((item) => {
              return {
                id: item.id,
                key: item.id,
                name: item.name,
                label: item.name,
                uniqueId: item.id,
                valueType: "string",
              };
            });
          }
        })
        .catch((error) => {
          // console.log("error ==", error)
        });
    };

    const searchServiceList = async (inputValue) => {
      if (!inputValue) {
        return [];
      }
      return await instance({
        method: "GET",
        url: `/api/services/due?page=1&limit=20&search=${inputValue}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.data) {
            return response.data.map((item) => {
              return {
                id: item.id,
                key: item.id,
                name: item.name,
                label: item.name,
                uniqueId: item.id,
                valueType: "string",
              };
            });
          }
        })
        .catch((error) => {
          // console.log("error ==", error)
        });
    };

    return (
      <div style={{ padding: 10 }}>
        <h4 className="page-title">{translate("Invoice")}</h4>
        <Grid container spacing={1}>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="name"
              // required
              margin="dense"
              label={translate("sharedName")}
              variant="outlined"
              fullWidth
              value={invoiceForm?.name || ""}
              onChange={(e) => handleChange("name", e.target.value)}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="invoiceNumber"
              // required
              margin="dense"
              label={translate("invoiceNumber")}
              variant="outlined"
              fullWidth
              value={invoiceForm?.invoiceNumber || ""}
              onChange={(e) => handleChange("invoiceNumber", e.target.value)}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="v_country"
              label={translate("country")}
              placeholder=""
              value={invoiceForm?.attributes?.v_country || ""}
              onChange={(e) =>
                handleChangeAttributes("v_country", e.target.value)
              }
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="v_makeYear"
              label={translate("makeYear")}
              placeholder=""
              value={invoiceForm?.attributes?.v_makeYear || ""}
              onChange={(e) =>
                handleChangeAttributes("v_makeYear", e.target.value)
              }
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="v_make"
              label={translate("model")}
              placeholder=""
              value={invoiceForm?.attributes?.v_make || ""}
              onChange={(e) => handleChangeAttributes("v_make", e.target.value)}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{ zIndex: 5 }}>
            <AsyncSelect
              value={
                invoiceForm.garageId && invoiceForm.garageId.id
                  ? {
                      id: invoiceForm.garageId.id,
                      value: invoiceForm.garageId.id,
                      label: invoiceForm.garageId.label,
                      name: invoiceForm.garageId.name,
                      uniqueId: invoiceForm.garageId.uniqueId,
                    }
                  : ""
              }
              placeholder={translate("garage")}
              selectName="garageId"
              defaultOptions={garageList}
              handleChange={handleChange}
              loadOptions={searchGarageList}
              isMulti={false}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12} style={{ zIndex: 5 }}>
         
            <AsyncSelect
              value={
                invoiceForm.vehicleId && invoiceForm.vehicleId.id
                  ? {
                      id: invoiceForm.vehicleId.id,
                      value: invoiceForm.vehicleId.id,
                      label: invoiceForm.vehicleId.label,
                      name: invoiceForm.vehicleId.name,
                      uniqueId: invoiceForm.vehicleId.uniqueId,
                    }
                  : ""
              }
              placeholder={translate("vehicle")}
              selectName="vehicleId"
              defaultOptions={vehicleList}
              handleChange={handleChange}
              loadOptions={searchVehicleList}
              isMulti={false}
            />
          </Grid>
 
          <Grid item md={3} sm={6} xs={12} style={{ zIndex: 99 }}>
            <AsyncSelect
              value={
                invoiceForm.serviceId && invoiceForm.serviceId.id
                  ? {
                      id: invoiceForm.serviceId.id,
                      value: invoiceForm.serviceId.id,
                      label: invoiceForm.serviceId.label,
                      name: invoiceForm.serviceId.name,
                      uniqueId: invoiceForm.serviceId.uniqueId,
                    }
                  : ""
              }
              placeholder={translate("serviceId")}
              selectName="serviceId"
              defaultOptions={servicesList}
              handleChange={handleChange}
              loadOptions={searchServiceList}
              isMulti={false}
            />
          </Grid>
          <Grid item sm={3} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                format="dd/MM/yyyy"
                margin="dense"
                label={translate("serviceDate")}
                variant="outlined"
                fullWidth
                // minDate={new Date()}
                value={
                  invoiceForm?.serviceDate
                    ? new Date(`${invoiceForm?.serviceDate}`).toString()
                    : null
                }
                required
                onChange={(e) => handleChange("serviceDate", e)}
                InputProps={{
                  classes: {
                    root: "theme-cssOutlinedInput",
                    input: "theme-input",
                    focused: "theme-cssFocused",
                    notchedOutline: "theme-notchedOutline",
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: "theme-label",
                    focused: "theme-label-focused",
                    shrink: "theme-label-shrink",
                  },
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <h4 style={{ margin: 0 }} className="with-border">
              {translate("customer")}
            </h4>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="phoneNumber"
              label={translate("phoneNumber")}
              placeholder=""
              value={invoiceForm?.phoneNumber || ""}
              onChange={(e) => handleChange("phoneNumber", e.target.value)}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="address"
              label={translate("address")}
              placeholder=""
              value={invoiceForm?.address || ""}
              onChange={(e) => handleChange("address", e.target.value)}
              variant="outlined"
              margin="dense"
              fullWidth
              // onChange={e => handleChange('stationName', e.target.value)}
              // variant='outlined'
              // margin='dense'
              // fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="stationCode"
              label={translate("stationCode")}
              placeholder=""
              value={invoiceForm?.stationCode || ""}
              onChange={(e) => handleChange("stationCode", e.target.value)}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="stationCity"
              label={translate("stationCity")}
              placeholder=""
              value={invoiceForm?.stationCity || ""}
              onChange={(e) => handleChange("stationCity", e.target.value)}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <h4 style={{ margin: 0 }} className="with-border">
              {translate("package")}
            </h4>
          </Grid>
 
          {serviceExpenseCollection.map((row, index) => (
            <Grid
              item
              md={12}
              xs={12}
              style={{
                //   background: themecolors.themeLightColor + '40',
                // padding: 16,
                maxHeight: 575,
                // overflow: 'auto',
                boxSizing: "border-box",
                borderRadius: 10,
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  md={3}
                  xs={3}
                  style={{
                    postion: "relative",
                    zIndex: category - index, 
                    //  backgroundColor:'red'
                    borderBottomWidth:
                      row && row.valid ? 0 : mode === "update" ? 1 : 0,
                    borderBottomColor: "red",
                    borderBottomStyle: "dashed",
                  }}
                >
                  <SingleSelect
                    array={expenseMainTypes || []}
                    id="categoryName"
                    selectName="categoryName"
                    label={translate("sharedType")}
                    // isDisabled={dueActive || completeMode}
                    value={
                      row.categoryName
                        ? {
                            key: row.categoryName.key,
                            id: row.categoryName.id,
                            value: row.categoryName.id,
                            label: row.categoryName.name
                              ? row.categoryName.name
                              : row.categoryName.label,
                          }
                        : ""
                    }
                    index={index}
                    handleChange={handleChangeServices}
                    canRemove={true}
                    canAssign={true}
                    // index={index}
                  />
                </Grid>
                <Grid
                  item
                  md={3}
                  xs={3}
                  style={{
                    postion: "relative",
                    zIndex: category - (index + 1),
                    borderBottomWidth:
                      row && row.validItem ? 0 : mode === "update" ? 1 : 0,
                    borderBottomColor: "red",
                    borderBottomStyle: "dashed",
                  }}
                >
                  {/*  zIndex: (category-(index+1)) */}
                  <SingleSelect
                    id="itemName"
                    array={
                      row.selectedSubTypes && row.selectedSubTypes.length
                        ? row.selectedSubTypes
                        : allSubTypes
                    }
                    selectName="itemName"
                    label={translate("subType")}
                    value={
                      row.itemName
                        ? {
                            key: row.itemName.key,
                            id: row.itemName.id,
                            value: row.itemName.id,
                            label: row.itemName.subType
                              ? row.itemName.subType
                              : row.itemName.label,
                          }
                        : ""
                    }
                    handleChange={handleChangeServices}
                    canRemove={true}
                    index={index}
                    canAssign={true}
                  />
                </Grid>

                <Grid item md={3} xs={3}>
                  <TextField
                    id="itemQty"
                    label={translate("Quantity")}
                    type="number"
                    placeholder=""
                    value={row.itemQty || ""}
                    onChange={(e) =>
                      handleChangeServices("itemQty", e.target.value, index)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={2} xs={2}>
                  <TextField
                    id="itemPrice"
                    label={translate("sellingPrice")}
                    type="number"
                    placeholder=""
                    value={row.itemPrice || 0}
                    onChange={(e) =>
                      handleChangeServices("itemPrice", e.target.value, index)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    InputProps={{
                      style: { padding: 0 },
                      endAdornment: (
                        <InputAdornment position="start" disableTypography>
                          {(logInUser.attributes &&
                            logInUser.attributes.currencyLabel) ||
                            "AED"}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  md={1}
                  xs={1}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {serviceExpenseCollection.length > 1 && category ? (
                    <DeleteIcon
                      onClick={(e) => deleteRow(row, index, e)}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <Grid item md={2} style={{ marginTop: 11 }}></Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item md={12} xs={12}>
            <div style={{ float: "right", marginTop: 10 }}>
              {
                <div>
                  <Grid item sm={2} xs={12}>
                    <Button
                      // href={emptyLink}
                      onClick={(e) => addMore(e)}
                      size="small"
                      color="inherit"
                      style={{ minWidth: "70px" }}
                    >
                      {translate("addMore")}
                    </Button>
                  </Grid>
                </div>
              }
            </div>
          </Grid>
        </Grid>

        <div style={{ display: "flex", marginTop: 10 }}>
          <div style={{ marginLeft: "auto" }} />
          <Button
            size="small"
            style={{ marginRight: 15 }}
            className="button-white"
            onClick={closeEditForm}
          >
            {translate("sharedCancel")}{" "}
          </Button>
          {checkPrivileges("expensetypeCreate") && (
            <Button
              size="small"
              onClick={submitForm}
              disabled={!isVisableSubmitBtn}
            >
              {translate(mode === "update" ? "update" : "sharedCreate")}
            </Button>
          )}
        </div>
      </div>
    );
  };

  
  const CashCustomerForm = ({
    translate,
    form,
    handleChange,
    handleChangeLeasingItems,
    mode,
    vehicleList,
    leasingItems,
    addMoreLeaseItems,
    closeEditForm,
    submitForm,
    isVisableSubmitBtn,
   invoiceData,
   handleChangeForAccount,
   cashAccount
  }) => {
    return (
      <div style={{ padding: 10 }}>
        <h4 className="page-title">{translate("cashInvoice")}</h4>
        <Grid container spacing={1}>
        <Grid item md={3} sm={6} xs={12}>
        <SingleSelect
                  array={invoiceData && invoiceData.data || []}
                  async
                  selectName="cashAccount"
                  isClearable
                  label={translate("contractNumber")}
                  value={
                    form &&
                    form.contractNumber?.id
                      ? {
                          id: form.contractNumber.contractNumber,
                          value:
                          form.contractNumber.contractNumber,
                          label: form.contractNumber.name || form.contractNumber.accountName,
                        }
                      : ""
                  }
                  // value={this.props.form.contractNumbers || ""}
                  handleChange={handleChangeForAccount}
                  canAssign={true}
                />
                  </Grid>
          <Grid item md={3} sm={6} xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  format="dd/MM/yyyy"
                  margin="dense"
                  label={translate("date")}
                  variant="outlined"
                  fullWidth
                  required
                  // minDate={new Date()}
                  value={
                    form?.invoiceDate
                      ? new Date(`${form?.invoiceDate}`).toString()
                      : null
                  }
                  // disabled
                  // readonly
                  onChange={(e) => handleChange("invoiceDate", e)}
                  InputProps={{
                    classes: {
                      root: "theme-cssOutlinedInput",
                      input: "theme-input",
                      focused: "theme-cssFocused",
                      notchedOutline: "theme-notchedOutline",
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "theme-label",
                      focused: "theme-label-focused",
                      shrink: "theme-label-shrink",
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
          </Grid>  
           <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="saleNetAmount"
              required
              margin="dense"
              label={translate("amount")}
              variant="outlined"
              fullWidth
              type="number"
              // value={form?.saleNetAmount || ""}
              // onChange={(e) => handleChange("saleNetAmount", e.target.value)}
              value={form?.saleTotalAmount || ""}
              onChange={(e) => handleChange("saleTotalAmount", e.target.value)}
              // readonly={mode === "update" ? true : false}
            />
          </Grid>   <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="saleVatAmount"
              required
              margin="dense"
              label={translate("saleVatAmount")}
              variant="outlined"
              fullWidth
              type="number"
              value={form?.saleVatAmount || ""}
              onChange={(e) => handleChange("saleVatAmount", e.target.value)}
              // readonly={mode === "update" ? true : false}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="refNum"
              required
              
              margin="dense"
              label={translate("refNum")}
              variant="outlined"
              fullWidth
              value={form?.refNum || ""}
              onChange={(e) => handleChange("refNum", e.target.value)}
              // readonly={mode === "update" ? true : false}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="saleTotalAmount"
              required
              type="number"
              margin="dense"
              label={translate("saleTotalAmount")}
              variant="outlined"
              fullWidth
              value={form?.saleNetAmount || ""}
              onChange={(e) => handleChange("saleNetAmount", e.target.value)}
              
              // readonly={mode === "update" ? true : false}
            />
          </Grid>
          
        </Grid>

        <div style={{ display: "flex", marginTop: 10 }}>
          <div style={{ marginLeft: "auto" }} />
          <Button
            size="small"
            style={{ marginRight: 15 }}
            className="button-white"
            onClick={closeEditForm}
          >
            {translate("sharedCancel")}{" "}
          </Button>

          <Button
            size="small"
            onClick={submitForm}
            disabled={!isVisableSubmitBtn}
          >
            {translate(mode === "update" ? "update" : "sharedCreate")}
          </Button>
        </div>
      </div>
    );
  };


  const LeasingForm = ({
    translate,
    leaseForm,
    handleChange,
    handleChangeLeasingItems,
    mode,
    vehicleList,
    leasingItems,
    addMoreLeaseItems,
    closeEditForm,
    submitLeaseForm,
    isVisableSubmitBtn
  }) => {
    return (
      <div style={{ padding: 10 }}>
        <h4 className="page-title">{translate("leaseinvoice")}</h4>
        <Grid container spacing={1}>
          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="invoiceNumber"
              // required
              margin="dense"
              label={translate("invoiceNumber")}
              variant="outlined"
              fullWidth
              value={leaseForm?.invoiceNumber || ""}
              onChange={(e) => handleChange("invoiceNumber", e.target.value)}
              readonly={mode === "update" ? true : false}
            />
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="paymentStatus"
              // required
              margin="dense"
              label={translate("paymentStatus")}
              variant="outlined"
              fullWidth
              value={
                leaseForm?.paymentStatus === 1
                  ? "Unpaid"
                  : leaseForm?.paymentStatus === 2
                  ? "Partial paid"
                  : "Paid"
              }
              onChange={(e) => handleChange("paymentStatus", e.target.value)}
              readonly={mode === "update" ? true : false}
            >
              <MenuItem value="1">Unpaid</MenuItem>
              <MenuItem value="2">Partial paid</MenuItem>
              <MenuItem value="3">Paid</MenuItem>
            </TextField>
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="valid"
              // required
              margin="dense"
              label={translate("valid")}
              variant="outlined"
              fullWidth
              value={leaseForm?.valid || ""}
              onChange={(e) => handleChange("valid", e.target.value)}
              readonly={mode === "update" ? true : false}
            >
              <MenuItem value="true">True</MenuItem>
              <MenuItem value="false">False</MenuItem>
            </TextField>
          </Grid>

          <Grid item md={3} sm={6} xs={12}>
            <TextField
              id="contractNumber"
              // required
              margin="dense"
              label={translate("contractNumber")}
              variant="outlined"
              fullWidth
              value={leaseForm?.contractNumber || ""}
              onChange={(e) => handleChange("contractNumber", e.target.value)}
              readonly={mode === "update" ? true : false}
            />
          </Grid>
          {mode === "update" ? (
            <Grid item sm={3} xs={12}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  format="dd/MM/yyyy"
                  margin="dense"
                  label={translate("created")}
                  variant="outlined"
                  fullWidth
                  // minDate={new Date()}
                  value={
                    leaseForm?.created
                      ? new Date(`${leaseForm?.created}`).toString()
                      : null
                  }
                  disabled
                  // readonly
                  // onChange={(e) => handleChange("createdTime", e)}
                  InputProps={{
                    classes: {
                      root: "theme-cssOutlinedInput",
                      input: "theme-input",
                      focused: "theme-cssFocused",
                      notchedOutline: "theme-notchedOutline",
                    },
                  }}
                  InputLabelProps={{
                    classes: {
                      root: "theme-label",
                      focused: "theme-label-focused",
                      shrink: "theme-label-shrink",
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <h4 style={{ margin: 0 }} className="with-border">
              {translate("items")}
            </h4>
          </Grid>
           {leasingItems &&
            leasingItems.map((row, index) => (
              <Grid
                item
                md={12}
                xs={12}
                style={{
                  //   background: themecolors.themeLightColor + '40',
                  // padding: 16,
                  maxHeight: 575,
                  marginTop: 5,
                  // overflow: 'auto',
                  boxSizing: "border-box",
                  borderRadius: 10,
                  borderColor: "#707070",
                  borderWidth: 1,
                  borderStyle: "dashed",
                }}
              >
                <Grid container spacing={1}>
                  <Grid
                    item
                    md={3}
                    xs={3}
                    style={{ postion: "relative", zIndex: category - index }}
                  >
                    <SingleSelect
                      array={vehicleList}
                      id="vehicleId"
                      selectName="vehicleId"
                      label={translate("vehicle")}
                      value={
                        row && row.vehicleId
                          ? {
                              key: row.vehicleId.id,
                              id: row.vehicleId.id,
                              value: row.vehicleId.label,
                              label: row.vehicleId.name
                                ? row.vehicleId.name
                                : row.vehicleId.label,
                            }
                          : ""
                      }
                      index={index}
                      handleChange={handleChangeLeasingItems}
                      canRemove={true}
                      canAssign={true}
                    />
                  </Grid>

                  <Grid item md={3} xs={3}>
                    <TextField
                      id="saleItemPrice"
                      label={translate("saleItemPrice")}
                      type="number"
                      placeholder=""
                      value={row.saleItemPrice || 0}
                      onChange={(e) =>
                        handleChangeLeasingItems(
                          "saleItemPrice",
                          e.target.value,
                          index
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <TextField
                      id="noOfDays"
                      label={translate("noOfDays")}
                      type="number"
                      placeholder=""
                      value={row.noOfDays || 0}
                      onChange={(e) =>
                        handleChangeLeasingItems(
                          "noOfDays",
                          e.target.value,
                          index
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <TextField
                      id="brandingCost"
                      label={translate("brandingCost")}
                      type="number"
                      placeholder=""
                      value={row.brandingCost || 0}
                      onChange={(e) =>
                        handleChangeLeasingItems(
                          "brandingCost",
                          e.target.value,
                          index
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <TextField
                      id="telematicsCost"
                      label={translate("telematicsCost")}
                      type="number"
                      placeholder=""
                      value={row.telematicsCost || 0}
                      onChange={(e) =>
                        handleChangeLeasingItems(
                          "telematicsCost",
                          e.target.value,
                          index
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <TextField
                      id="insuranceCost"
                      label={translate("insuranceCost")}
                      type="number"
                      placeholder=""
                      value={row.insuranceCost || 0}
                      onChange={(e) =>
                        handleChangeLeasingItems(
                          "insuranceCost",
                          e.target.value,
                          index
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <TextField
                      id="bankInterest"
                      label={translate("bankInterest")}
                      type="number"
                      placeholder=""
                      value={row.bankInterest ||0}
                      onChange={(e) =>
                        handleChangeLeasingItems(
                          "bankInterest",
                          e.target.value,
                          index
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <TextField
                      id="zakatInterest"
                      label={translate("zakatInterest")}
                      type="number"
                      placeholder=""
                      value={row.zakatInterest || 0}
                      onChange={(e) =>
                        handleChangeLeasingItems(
                          "zakatInterest",
                          e.target.value,
                          index
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <TextField
                      id="depreciationCost"
                      label={translate("depreciationCost")}
                      type="number"
                      placeholder=""
                      value={row.depreciationCost || 0}
                      onChange={(e) =>
                        handleChangeLeasingItems(
                          "depreciationCost",
                          e.target.value,
                          index
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <TextField
                      id="maintenanceCost"
                      label={translate("maintenanceCost")}
                      type="number"
                      placeholder=""
                      value={row.maintenanceCost || 0}
                      onChange={(e) =>
                        handleChangeLeasingItems(
                          "maintenanceCost",
                          e.target.value,
                          index
                        )
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  {/* <Grid item md={3} xs={3} style={{ postion: "relative",zIndex: (category-(index+1)),
                      borderBottomWidth:row && row.validItem ? 0: mode=== 'update' ? 1 : 0,
                      borderBottomColor:"red",
                      borderBottomStyle:"dashed"
                    }}>
                      <SingleSelect
                        id="itemName"
                        array={
                          row.selectedSubTypes && row.selectedSubTypes.length
                            ? row.selectedSubTypes
                            : subTypes
                        }
                        selectName="itemName"
                        label={translate("subType")}
                        value={
                          row.itemName
                            ? {
                                key: row.itemName.key,
                                id: row.itemName.id,
                                value: row.itemName.id,
                                label: row.itemName.subType
                                  ? row.itemName.subType
                                  : row.itemName.label,
                              }
                            : ""
                        }
                        handleChange={handleChangeServices}
                        canRemove={true}
                        index={index}
                        canAssign={true}
                      />
                    </Grid>

                    <Grid item md={3} xs={3}>
                      <TextField
                        id="itemQty"
                        label={translate("Quantity")}
                        type="number"
                        placeholder=""
                        value={row.itemQty || ""}
                        onChange={(e) =>
                          handleChangeServices("itemQty", e.target.value, index)
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={2} xs={2}>
                      <TextField
                        id="itemPrice"
                        label={translate("sellingPrice")}
                        type="number"
                        placeholder=""
                        value={row.itemPrice || 0}
                        onChange={(e) =>
                          handleChangeServices("itemPrice", e.target.value, index)
                        }
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        InputProps={{
                          style: { padding: 0 },
                          endAdornment: (
                            <InputAdornment position="start" disableTypography>
                              {(logInUser.attributes &&
                                logInUser.attributes.currencyLabel) ||
                                "AED"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={1}
                      xs={1}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {items.length > 1 && --category ? (
                        <DeleteIcon
                          onClick={(e) => deleteRow(row, index, e)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <Grid item md={2} style={{ marginTop: 11 }}></Grid>
                      )}
                    </Grid> */}
                </Grid>
              </Grid>
            ))}

          <Grid item md={12} xs={12}>
            <div style={{ float: "right", marginTop: 10 }}>
              {
                <div>
                  <Grid item sm={2} xs={12}>
                    <Button
                      // href={emptyLink}
                      onClick={(e) => addMoreLeaseItems(e)}
                      size="small"
                      color="inherit"
                      style={{ minWidth: "70px" }}
                    >
                      {translate("addMore")}
                    </Button>
                  </Grid>
                </div>
              }
            </div>
          </Grid>
        </Grid>

        <div style={{ display: "flex", marginTop: 10 }}>
          <div style={{ marginLeft: "auto" }} />
          <Button
            size="small"
            style={{ marginRight: 15 }}
            className="button-white"
            onClick={closeEditForm}
          >
            {translate("sharedCancel")}{" "}
          </Button>

          <Button
            size="small"
            onClick={submitLeaseForm}
            // disabled={!isVisableSubmitBtn}
          >
            {translate(mode === "update" ? "update" : "sharedCreate")}
          </Button>
        </div>
      </div>
    );
  };



  const mapStateToProps = (state) => ({
    ServerSetting: state.ServerSetting,
    logInUser: state.logInUsers,
    themecolors: state.themecolors,
  });

  export default connect(mapStateToProps)(withLocalize(InvoiceModal));
