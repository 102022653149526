import React, { Component, Fragment } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import CustomDialog from '../common/Dialog'
import Notifications from 'react-notification-system-redux'
import AddDevice from './addDevice'
import { parentUnitsLimit, getDevices } from '../../Actions/Devices'
import Table from '../common/tableWithBackEndPagination'
import isEqual from 'react-fast-compare'
import { checkPrivileges, PaginationConfig } from '../../Helpers'
import ConfirmDialoag from '../common/ConfirmDialoag'
import DevicePortList from './devicePortList'
import { ViewUserDetail } from './unitDetail/viewUnitDetail'
import Loader from '../../Layout/Loader'
import Style from 'style-it'
import ExportFile from './../common/ExportFile'
import ImportFile from './../common/ImportExcel'
import moment from 'moment'
import { id } from 'date-fns/locale'
import TextField from '../common/TextField'
import Button from './../common/Button'
import { Grid, Tooltip } from '@material-ui/core'
import SearchDevices from '../Devices/searchItems'

const advanceSettingsForm = {
  minimalNoDataDuration: 600,
  minimalParkingDuration: 300,
  minimalTripDuration: 60,
  minimalTripDistance: 0.1,
  speedThreshold: 0.01,
  processInvalidPositions: false,
  useIgnition: 2
}
const formDefaultState = {
  name: '',
  uniqueId: '',
  groupId: '',
  phone: '',
  model: '',
  contact: '',
  category: '',
  disabled: '',
  attributes: { ...advanceSettingsForm },
  parentId: ''
}

const deviceDataFormat = (data, attributes, parentId) => ({
  // id: id ? id : null,
  attributes: attributes ? attributes : {},
  name: data.name.trim(),
  uniqueId: (data.uniqueId && data.uniqueId) || '',
  status: data.status,
  disabled: data.disabled,
  lastUpdate: data.lastUpdate,
  positionId: data.positionId,
  groupId: data.groupId,
  phone: data.phone,
  model: data.model,
  contact: data.contact,
  category: data.category,
  geofenceIds: data.geofenceIds,
  expirationTime:data.expirationTime?data.expirationTime:'',
  parentId: data.parentId || 0
})
let source
class deviceModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ...PaginationConfig,
      addOption: false,
      selectedDevice: '',
      selectedUsers: '',
      defaultDeviceList: true,
      selectedOption: false,
      isVisableDeviceBtn: false,
      duplicateIdentifier: false,
      onDeleteConfirmation: false,
      selectedUnitUserList: '',
      userId: '',
      // all fields of form
      form: { ...formDefaultState },
      importOption: false,
      labelSelected: '',
      categories:[]
    }
    this.removeEnable = this.removeEnable.bind(this)
    this.deleteDevice = this.deleteDevice.bind(this)
    this.submitAdddevice = this.submitAdddevice.bind(this)
    this.changeAttribute2 = this.changeAttribute2.bind(this)
    this.handleChangeLicenseExpDate = this.handleChangeLicenseExpDate.bind(this)
    this.modalControle = this.modalControle.bind(this)
    this.devicesSelection = this.devicesSelection.bind(this)
    this.checkRequiredFields = this.checkRequiredFields.bind(this)
    this.addDevice = this.addDevice.bind(this)
    this.importDevice = this.importDevice.bind(this)
  }
  componentWillMount () {
    this.getMoreDevices()
    this.fetchCategories()
  }
  componentWillUnmount () {
    if (source) {
      source.cancel()
    }
    this.props.dispatch(Notifications.removeAll())
  }
  fetchCategories = ()=>{
    //(/api/devices/categories

    fetch(`/api/devices/categories`)
        .then(response => {
          if(response.ok){
            response.json().then(res=>{
              if(res.status ==='success'){
                    this.setState({categories:res.data.categories})
              } 
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }
            })
          }
        })
        .catch(e => {
           console.log('e =', e)
          })
  }

  getMoreDevices = () => {
    let selectedId = this.state.userId || this.props.logInUser.id
    let { page, pageSize, itemSearch } = this.state
    source = axios.CancelToken.source()
    axios
      .get(
        `/api/devices/get?userId=${selectedId}&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}`,
        {
          cancelToken: source.token
        }
      )
      // axios
      //   .get(
      //     `/api/devices/get?userId=${this.props.logInUser.id}&all=true&&page=${page}&&limit=${pageSize}&&search=${itemSearch}`,
      //     {
      //       cancelToken: source.token
      //     }
      //   )
      .then(response => {
        let res = response.data
        if (res.status === 'success') {
          this.props.dispatch(getDevices(response.data.data))
        }
        else if(res?.statusCode === '440'){
          window.location.replace('/login')
        }
        else if(res.statusCode){
          var err = res?.message.split(':')
          err[1] =err[1].replace(')', "")
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate(err[1]),
              autoDismiss: 5,
              location: this.props.location
            })
          )
        }
      })
      .catch(e => {
      //   this.props.dispatch(Notifications.error({
      //   message: 'somethingWentWrong',
      //   autoDismiss: 5
      // }))
    })
  }

  // Edit device
  removeEnable (selected) {
    if (selected[0]) {
      this.setState({
        selectedDevice: selected[0],
        onDeleteConfirmation: true,
        form: ''
      })
    } else {
      this.setState({
        onDeleteConfirmation: true,
        form: ''
      })
    }
  }
  submitAdddevice () {
    let { name, model, attributes, device, port } = this.state.form
    if (attributes && attributes['speedLimit']) {
      attributes['speedLimit'] = attributes['speedLimit'] / 1.852
    }
    attributes['minimalTripDistance'] = attributes['minimalTripDistance'] * 1000
    if (device && model) {
      attributes = {
        ...attributes,
        ['port']: parseInt(device),
        ['protocol']: model
      }
    }
    if (name.trim().length > 0) {
      const obj = deviceDataFormat(
        this.state.form,
        attributes,
        this.state.selectedParentId
      )
      fetch('/api/devices/', {
        method: 'POST',
        headers: {
          Accept: 'application/json; odata=verbose',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...obj
        })
      })
        .then(response => {
          response.json().then(res=>{
            if(res.status === 'success'){
              this.getMoreDevices()
              if (this.props.onCloseModal) {
                this.props.onCloseModal()
              }
              this.geUnitsLimits()
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate('trackerIsAdded'),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
              this.setState(
                {
                  addOption: false,
                  defaultDeviceList: true,
                  isVisableDeviceBtn: false,
                  form: ''
                },
                () => this.getMoreDevices()
              )
            }
            else if(res?.statusCode === '440'){
              window.location.replace('/login')
            }
            else if(res.statusCode){
              var err = res?.message.split(':')
              var err2 =err[1].split('&#')
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err2[0]),
                  autoDismiss: 5,
                  location: this.props.location
                })
              )
            }
          })
         
        })
        .catch(e => {
            Notifications.error({
              message: this.props.translate('somethingwentWrong'),
              autoDismiss: 5,
              location: this.props.location
            })
          // if (e && e.text) {
          //   e.text().then(err => {
          //     if (err.includes('Duplicate entry')) {
          //       this.setState({
          //         duplicateIdentifier: true
          //       })
          //       this.props.dispatch(
          //         Notifications.success({
          //           message: this.props.translate('uniqueIdIsAllreadyInUsed'),
          //           autoDismiss: 5,
          //           location: this.props.location
          //         })
          //       )
          //     } else {
          //       let a = err.split('- SecurityException').splice(0, 1)
          //       this.props.dispatch(
          //         Notifications.error({
          //           message: a[0],
          //           autoDismiss: 5
          //         })
          //       )
          //     }
          //   })
          // }
        })
    } else {
      this.setState({
        isVisableDeviceBtn: false
      })
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate('emptyField'),
          autoDismiss: 5,
          location: this.props.location
        })
      )
    }
  }
  // Delete device
  deleteDevice () {
    const { selectedDevice } = this.state
    fetch(`/api/devices/${selectedDevice.id}`, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        response.json().then(res=>{
          if(res.status === 'success'){
            this.geUnitsLimits()
            this.getMoreDevices()
            this.props.dispatch(
              Notifications.success({
                message: this.props.translate('trackersIsDeleted'),
                autoDismiss: 5,
                location: this.props.location
              })
            )
          }
          else if(res?.statusCode === '440'){
            window.location.replace('/login')
          }
          else if(res.statusCode){
            var err = res?.message.split(':')
            err[1] =err[1].replace(')', "")
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location
              })
            )
          }
        })
      

      })
      .catch(e => {
        this.props.dispatch(
          Notifications.error({
            message: 'Something went wrong',
            autoDismiss: 5,
            location: this.props.location
          })
        )
      })
    this.setState({
      selectedDevice: '',
      addOption: false,
      defaultDeviceList: true,
      onDeleteConfirmation: false
    })
  }

  //refreshing units limits
  geUnitsLimits = () => {
    if (this.props.logInUser.id) {
      //units limits
      if (checkPrivileges('device')) {
        fetch(`/api/devices/count?userId=${this.props.logInUser.id}`)
          .then(response => {
              response.json().then(unitsLimits => {
                if(unitsLimits.status === 'success'){
                  this.props.dispatch(parentUnitsLimit(unitsLimits.data))
                }
                else if(unitsLimits?.statusCode === '440'){
                  window.location.replace('/login')
                }
                else if(unitsLimits?.statusCode){
                  var err = unitsLimits?.message?.split(':')
                  err[1] =err[1].replace(')', "")
                  this.props.dispatch(
                    Notifications.error({
                      message: this.props.translate(err[1]),
                      autoDismiss: 5,
                      location: this.props.location
                    })
                  )
                }
                else {
                  throw response
                }
              })
           
          })
          .catch(e => {
            Notifications.error({
              message: this.props.translate('somethingwentWrong'),
              autoDismiss: 5,
              location: this.props.location
            })
          })
      }
    }
  }
  onChangedDevice = item => {
    if (item) {
      this.setState(
        {
          userId: item && item.id,
          selectedParentId: item.id
        },
        () => {
          this.getMoreDevices()
          this.checkRequiredFields()
        }
      )
    } else {
      this.setState(
        {
          userId: ''
        },
        () => {
          this.getMoreDevices()
          this.checkRequiredFields()
        }
      )
    }
  }
  modalControle () {
    this.setState({
      addOption: false,
      selectedDevice: '',
      defaultDeviceList: true,
      form: '',
      isVisableDeviceBtn: false,
      duplicateIdentifier: false
    })
  }
  addDevice () {
    this.setState({
      selectedDevice: '',
      addOption: true,
      defaultDeviceList: false,
      isVisableDeviceBtn: false,
      form: { ...formDefaultState }
    })
  }
  importDevice () {
    this.setState({
      importOption: true
    })
  }

  changeAttribute2 = (name, event) => {
    let value =
      event.target.type === 'checkbox'
        ? event.target.checked
        : event.target.value
    if (!value) {
      if (this.state.form.attributes[name]) {
        delete this.state.form.attributes[name]
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes
            }
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      if (name === 'speedLimit') {
        value = parseFloat(value)
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: value
            }
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }

  handleChangeLicenseExpDate = (name, event) => {
      if(name === 'expirationTime'){
        this.setState(
          {
            form: {
              ...this.state.form,
                [name]: event.toISOString()
              
            }
          },
          () => this.checkRequiredFields()
        )
      }
      else{
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
                [name]: event.toISOString()
              }
            }
          },
          () => this.checkRequiredFields()
        )
      }
    }

  handleChange = name => event => {
    const { target } = event
    const re = /^[a-zA-Z0-9_.-]*$/
    if (target.type === 'checkbox') {
      this.setState(
        preState => ({
          ...preState,
          form: {
            ...preState.form,
            [name]: target.checked
          }
        }),
        () => this.checkRequiredFields()
      )
    } else {
      let value = target.value
      if (value !== ' ' && (name === 'uniqueId' ? re.test(value) : true)) {
        this.setState(
          preState => ({
            ...preState,
            form: {
              ...preState.form,
              [name]: value
            }
          }),
          () => this.checkRequiredFields()
        )
      }
    }
  }
  handleChangeParent = (name, value) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: value.value
        },
        labelSelected: value.label
      },
      () => this.checkRequiredFields()
    )
  }
  timezone_handleChange = (name, value) => {
    if (!value || value.value === 'None') {
      if (this.state.form.attributes[name]) {
        delete this.state.form.attributes[name]
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes
            }
          }
        },
        () => this.checkRequiredFields()
      )
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: value.value
            }
          }
        },
        () => this.checkRequiredFields()
      )
    }
  }
  port_handleChange = (name, value) => {
    if (!value) {
      return; // Don't proceed if value is null or undefined
    }
    if (
      !('' + (this.state.form.uniqueId || '')).trim() &&
      value &&
      value.key === 'FX Tracker'
    ) {
      fetch(`/api/devices/uniqueid`)
        .then(response => {
          if (response.ok) {
            response.json().then(dataResp => {
              if (dataResp.status === 'success') {
                this.setState({
                  form: {
                    ...this.state.form,
                    ['uniqueId']: dataResp.data.uniqueId
                  }
                })
                this.setState(
                  preState => ({
                    ...preState,
                    form: {
                      ...preState.form,
                      [name]: value.key,
                      device: value.port
                    }
                  }),
                  () => this.checkRequiredFields()
                )
              }
              else if(dataResp?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(dataResp?.statusCode){
                var err = dataResp?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }
              else {
                throw response
              }
            })
          }
        })
        .catch(e => {
              this.props.dispatch(Notifications.error({
              message: 'somethingWentWrong',
              autoDismiss: 5
              }))
          })
    } else {
      this.setState(
        preState => ({
          ...preState,
          form: {
            ...preState.form,
            [name]: value.key,
            device: value.port
          }
        }),
        () => this.checkRequiredFields()
      )
    }
  }
  checkRequiredFields () {
    let { name, uniqueId, model } = this.state.form
    let valueOfPort = false
    if (uniqueId) {
      valueOfPort = true
    }
    if (model && model === 'FX Tracker') {
      valueOfPort = true
    }
    let value = !isEqual(this.state.form, this.state.selectedDevice)
    if (name && model && valueOfPort && value) {
      this.setState({
        isVisableDeviceBtn: true,
        duplicateIdentifier: false
      })
    } else {
      this.setState({
        isVisableDeviceBtn: false,
        duplicateIdentifier: false
      })
    }
  }
  devicesSelection (item) {
    if (item) {
      this.setState({
        defaultDeviceList: false,
        selectedDevice: item,
        selectedOption: true,
        form: { ...item }
      })
    }
  }

  showPortList = () => {
    this.setState({
      isVisablePortList: true
    })
  }

  onCloseModal = () => {
    this.setState({
      isVisablePortList: false,
      importOption: false
    })
  }

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false
    })
  }
  onCloseC_Modal = () => {
    this.setState(
      {
        selectedOption: false,
        importOption: false,
        defaultDeviceList: true
      },
      () => {
        this.getMoreDevices()
      }
    )
  }
  handleChangeRowsPerPage = value => {
    this.setState(
      {
        //tableData: [],
        page:1,

        pageSize: value
        //defaultUserList: false
      },
      () => this.getMoreDevices()
    )
  }
  handleChangePage = value => {
    this.setState(
      {
        //tableData: [],
        page: value
        //defaultUserList: false
      },
      () => this.getMoreDevices()
    )
  }
  SearchItem = searchValue => {
    source.cancel()
    this.setState(
      {
        page: 1,
        itemSearch: searchValue
      },
      () => this.getMoreDevices()
    )
  }
  checkFloteNumber (n) {
    return Number(n) === n && n % 1 !== 0
  }
  setPagination = items => {
    let nItem = { ...items }
    let lastPage = nItem.total / this.state.pageSize
    let IsFloate = this.checkFloteNumber(lastPage)
    delete nItem.data
    nItem.pageSize = this.state.pageSize
    nItem.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage
    return nItem
  }

  openFilter = () => {
    this.setState({
      openFilter: !this.state.openFilter
    })
  }

  render () {
    const { devices, group } = this.props
    let fileName = 'units'
    return (
      <div>
        <Style>{`
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors['600']};
            color: ${this.props.themecolors['themeInverse']};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected {
            background: ${this.props.themecolors['500']};
            color: ${this.props.themecolors['themeInverse']};
          }`}</Style>
        {this.state.addOption && (
          <Fragment>
            <AddDevice
              {...this.state}
              {...this.props}
              group={group}
              logInUser={this.props.logInUser}
              formSubmit={this.submitAdddevice}
              handleChange={this.handleChange}
              onChangedDevice={this.onChangedDevice}
              modalControle={this.modalControle}
              buttonText={this.props.translate('sharedCreate')}
              hasAccessOfUpdate={checkPrivileges('deviceUpdate')}
              hasAccessOfDelete={checkPrivileges('deviceDelete')}
              hasAccessOfCreate={checkPrivileges('deviceCreate')}
              showPortList={this.showPortList}
              showPort={true}
              editOption={false}
              serverIp={this?.props?.ServerSetting?.attributes?.serverIp
                  ||  null
              }
              port_handleChange={this.port_handleChange}
              changeAttribute2={this.changeAttribute2}
              handleChangeLicenseExpDate={this.handleChangeLicenseExpDate}
              handleChangeParent={this.handleChangeParent}
              timezone_handleChange={this.timezone_handleChange}
              creatingUnit
            />
            {this.state.isVisablePortList && (
              <DevicePortList
                themecolors={this.props.themecolors}
                onCloseModal={this.onCloseModal}
                translate={this.props.translate}
              />
            )}
          </Fragment>
        )}
        {this.state.selectedDevice && this.state.selectedOption && (
          <ViewUserDetail
            selectItemName={this.state.selectedDevice.name}
            selectItemId={this.state.selectedDevice.id}
            selectItemParentId={this.state.selectedDevice.parentId}
            onClose={this.onCloseC_Modal}
            categories={this.state.categories}
          />
        )}
        {this.state.defaultDeviceList ? (
          <>
            <Grid container className='breadcrumb-row'>
              <Grid item xs={12} sm={3}>
                <h3 style={{ margin: 0 }}>{this.props.translate('units')}</h3>
              </Grid>

              <Grid item xs={12} sm={9} style={{ justifyContent: 'flex-end' }}>
                <div style={{ marginRight: '20px', width: '25%' }}>
                  <TextField
                    fullWidth
                    label={this.props.translate('search')}
                    value={this.state.itemSearch}
                    onChange={e => this.SearchItem(e.target.value)}
                  />
                </div>
                {this.state.openFilter && (
                  <div style={{ marginRight: '20px', width: '25%' }}>
                    <SearchDevices
                      isMulti={false}
                      api='devices'
                      isClearable
                      onChange={this.onChangedDevice}
                      placeholder={this.props.translate('searchUsers')}
                    />
                  </div>
                )}
                {!this.state.openFilter && (
                  <Button
                    disabled={devices.data && devices.data.length === 0}
                    aria-label='filter'
                    size='small'
                    color='inherit'
                    onClick={this.openFilter}
                    style={{ marginRight: '20px' }}
                  >
                    {this.props.translate('filter')}
                  </Button>
                )}
                <ExportFile
                  title={this.props.translate('reportExport')}
                  downloadType='devices'
                  translate={this.props.translate}
                  dataLength={
                    this.props.devices &&
                    this.props.devices.data &&
                    this.props.devices.data.length
                      ? true
                      : false
                  }
                  closedModel={this.onCloseC_Modal}
                  dispatch={this.props.dispatch}
                />
                <Button
                  disabled={
                    devices && devices.data && devices.data.length === 0
                  }
                  aria-label='Export'
                  size='small'
                  color='inherit'
                  onClick={this.importDevice}
                  style={{ marginRight: '20px' }}
                >
                  {this.props.translate('import')}
                </Button>
                  <Button
                    disabled={
                      !(checkPrivileges('deviceCreate') &&
                      (this.props.parentUnitLimits?.remaningLimit > 0 ||
                        this.props.parentUnitLimits?.remaningLimit === -1)
                        ? true
                        : false)
                    }
                    aria-label='Create'
                    size='small'
                    color='inherit'
                    onClick={this.addDevice}
                    id='createButton'
                  >
                    {this.props.translate('create')}
                  </Button>
              </Grid>
            </Grid>
            <Table
              insideDialog={true}
              rows={devices.data || []}
              pagination={
                this.props.devices &&
                this.props.devices.data &&
                this.props.devices.data.length &&
                this.setPagination(devices)
              }
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              SearchItem={this.SearchItem}
              onClick={this.devicesSelection}
              onDelete={this.removeEnable}
              isEditable={true}
              btnName={this.props.translate('create')}
              onCreate={() => this.addDevice()}
              btnName1={'devices'}
              themecolors={this.props.themecolors}
              hasAccessOfDelete={checkPrivileges('deviceDelete')}
              isCursoPointer
              setSerialNo
              logInUser={this.props.logInUser}
              rowDefinition={[
                {
                  id: 'name',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('sharedName')
                },
                (this.props.logInUser.attributes.viewIMEI || this.props.logInUser.userType===-1) && {
                  id: 'uniqueId',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('driverUniqeId')
                },
                {
                  id: 'phone',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('sharedPhone')
                },
                (this.props.logInUser.attributes.viewModel || this.props.logInUser.userType===-1) &&{
                  id: 'model',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('model')
                },
                {
                  id: 'parentName',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('parentcreator')
                },
                {
                  id: 'created',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('createdAt')
                },
                {
                  id: 'status',
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate('status')
                }
              ]}
            />
          </>
        ) : (
          !this.state.addOption && !this.state.selectedOption && <Loader />
        )}
        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.deleteDevice}
            title={this.props.translate('areYouWantToDelete')}
            children={this.state.selectedDevice.name}
          />
        )}
        {this.state.importOption && (
          <ImportFile
            fileName={'units'}
            Option={this.state.importOption}
            onClose={this.onCloseC_Modal}
          />
        )}
      </div>
    )
  }
}
const mapState = state => {
  const devices = {
    ...state.devices,
    data:
      state.devices.data &&
      state.devices.data.map(d => {
        if (d.created) {
          if (state.logInUsers.twelveHourFormat) {
            d.created = moment(d.created).format('YYYY-MM-DD hh:mm A')
          } else {
            d.created = moment(d.created).format('YYYY-MM-DD HH:mm')
          }
          return d
        } else {
          return d
        }
      })
  }

  return {
    devices: state.devices,
    logInUser: state.logInUsers,
    group: state.groups,
    themecolors: state.themecolors,
    ServerSetting: state.ServerSetting,
    parentUnitLimits: state.parentUnitLimits
  }
}

const mapStateToProps = connect(mapState)
export const DeviceList = mapStateToProps(withLocalize(deviceModal))