import React, { Component } from 'react'
import './index.scss'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faColumns,
  faTh,
  faBell,
  faComments,
  faImage,
  faTerminal
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import 'moment-timezone'
import Tooltip from '@material-ui/core/Tooltip'

library.add([faColumns, faTh, faBell, faComments, faImage, faTerminal])

export default class Footer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      curTime:
        this.props.logInUser &&
        this.props.logInUser.attributes &&
        this.props.logInUser.attributes.timezone
          ? moment()
              .tz(this.props.logInUser.attributes.timezone)
              .format('HH:mm:ss a')
          : this.props.translate('noTimeZoneIsSet')
    }
    setInterval(() => {
      this.setState({
        curTime:
          this.props.logInUser &&
          this.props.logInUser.attributes &&
          this.props.logInUser.attributes.timezone
            ? moment()
                .tz(this.props.logInUser.attributes.timezone)
                .format('HH:mm:ss a')
            : this.props.translate('noTimeZoneIsSet')
      })
    }, 1000)
  }

  render () {
    const { attributes } = this.props.ServerSetting
    if (attributes && attributes.serviceTitle) {
      document.title = attributes.serviceTitle
    } else {
      document.title = ""
    }
    const emptyLink = '#'
    return (
      <footer className='fms-footer'>
        <table>
          <tbody>
            <tr>
              {/*<td className="toggleSidebar">
        				<a href={emptyLink} title="Unit Activation"><MaterialIcon color="currentColor" size="16" icon="add_to_queue" /></a>
        			</td>
        			<td className="toggleMiniMaps">
                <a href={emptyLink} title="units and Settings"><MaterialIcon color="currentColor" size="16" icon="settings" /></a>
              </td>
              <td className="toggleMiniMaps">
        				<a href={emptyLink} title="Help"><MaterialIcon color="currentColor" size="16" icon="help" /></a>
        			</td>*/}
              <td className='copyRightSection'>
                &copy;{' '}
                {(attributes && attributes.footerText) || (
                  <span>enroutech.com</span>
                )}
              </td>
              {/* <td className="activityCounterSection">
        				<Link to={'/notifications'} title="Alerts" ><FontAwesomeIcon icon={faBell} /><span className="badge">0</span></Link>
        				<a href={emptyLink} title="messages"><FontAwesomeIcon icon={faComments} /><span className="badge">0</span></a>
        				<a href={emptyLink}><FontAwesomeIcon icon={faImage} /><span className="badge">0</span></a>
        			</td>
              <td className="toggleConsole">
        				<a href={emptyLink}><FontAwesomeIcon icon={faTerminal} /></a>
        			</td> */}
              {this.state.curTime === 'Show Time' ? (
                <Tooltip title={this.props.translate('setTimeZoneMessage')}>
                  <td className='dateTimeSection'>{this.state.curTime}</td>
                </Tooltip>
              ) : (
                <td className='dateTimeSection'>{this.state.curTime}</td>
              )}
            </tr>
          </tbody>
        </table>
      </footer>
    )
  }
}
