import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Notifications from 'react-notification-system-redux'
import Layout from '../../wrapper'
import { logInUserInfo } from '../../Actions/Users'
import { UsersList } from '../../Components/B2C/usersModalforSuperAdmin'
import { checkPrivileges } from '../../Helpers'

class B2C extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isVisable: true,
      sessionCallTrack: true
    }
  }
  componentWillMount () {
    this.props.dispatch(Notifications.removeAll())
    //   if (
    //     localStorage.getItem('adminToken')
    //   ) {
    //     window.location.replace('/login')
    //   }
  }

  controleSession = () => {
    if (localStorage.getItem('userInfo')) {
      fetch('/api/session', {
        method: 'DELETE'
      }).then(res => {
        let response = res.data
        if (response.ok) {
          this.props.history.push('/logout')
        }else{
          throw response
        }
      }).catch(e => {})
    }
  }

  render () {
    if (this.props.logInUser) {
    if (checkPrivileges('user')) {
     
        return (
          <Layout {...this.props}>
            <UsersList history={this.props.history} />
          </Layout>
        )
     
    } else if (
      checkPrivileges('account') &&
      !checkPrivileges('device') &&
      !checkPrivileges('group')
    ) {
      this.controleSession()
      return null
    } else {
      return defaultPages.map(page => {
        if (checkPrivileges(page)) {
          if (page === 'device') {
            this.props.history.push(`/units`)
          } else {
            this.props.history.push(`/${page}s`)
          }
        }
      })
    }
  } 
    else {
      if (this.state.sessionCallTrack) {
        this.setState(
          {
            sessionCallTrack: false
          },
          () => {
            fetch('/api/session', {
              headers: { Accept: 'application/json; odata=verbose' }
            }).then(response => {
              if (response.ok) {
                response.json().then((res) => {
                  if (res.status === "success") {
                    // console.log("Stat====",res);
                    let userInfo = res.data;
                    this.props.setActiveLanguage(
                      userInfo?.attributes?.lang || "en"
                    );
                    this.props.dispatch(logInUserInfo(userInfo));
                  } else if (res?.statusCode === "440") {
                    window.location.replace("/login");
                  } else if (res.statusCode) {
                    var err = res?.message.split(":");
                    err[1] = err[1].replace(")", "");
                    this.props.dispatch(
                      Notifications.error({
                        message: this.props.translate(err[1]),
                        autoDismiss: 5,
                        location: this.props.location,
                      })
                    );
                    this.props.history.push("/login");
                  }
                });
              } else {
                this.props.history.push("/login");
                throw response;
              }
              })  .catch(e => {
                this.props.dispatch(Notifications.error({
                  message: 'somethingWentWrong',
                  autoDismiss: 5
                }))
              })
          }
        )
      }
      return null
    }
  }
}

const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers
})

export default connect(mapStateToProps)(withLocalize(B2C))

const defaultPages = ['account', 'device', 'group']
