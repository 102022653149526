import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import Notifications from "react-notification-system-redux";
import AddCreditModal from "./addCreditNote";
import AddDebitModal from "./addDebitNote";
import Table from "../common/tableWithBackEndPagination";
import { checkPrivileges } from "../../Helpers";
import ConfirmDialoag from "../common/ConfirmDialoag";
import Button from "../common/Button";
import { Grid } from "@material-ui/core";
import TextField from "../common/TextField";
import instance from "../../axios";
import Style from "style-it";
import CustomDialog from "../common/Dialog";

import moment from "moment";
import Loader from "../../Layout/Loader";
import ReactJson from 'react-json-view';
 

const formDefault = {
  creditDebitNote: {
    id: 0,
    issuingParty:   "National Transportation Solutions Company",
    receivingParty: "",
    type:"credit",
    contractNumber: "",
    refNum: "",
    parentId: 1,
    totalAmount: "",
    vatAmount: "",
    netAmount: "",
    // remarks:"",
    amount:0,
    remarks:[],
    created: new Date(),
    modified: "",
    creditDebitDate: "",
    attributes: {},
    creditDebitNoteIndex : "",
    creditDebitNoteNumber : "",
    // data: "",
  },
};

const creditDebitFormat = (data, form) => {
  const remarksString = form.creditDebitNote.remarks?.join(", ") || "";

  // Map over each item in the data array and apply parseFloat to saleItemPrice
  const formattedData = data.map(item => ({
    ...item,
    saleItemPrice: parseFloat(item.saleItemPrice) || 0, // Apply parseFloat to ensure saleItemPrice is treated as a float
  }));

  return {
    creditDebitNote: {
      ...form.creditDebitNote,
      remarks: remarksString,
      netAmount: parseFloat(form.netAmount) || 0,
    },
    creditDebitNoteItems: formattedData, // Use the formatted data array
  };
};


let source;
class creditDebitList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addOption: false,
      editOption: false,
      search: "",
      notesData: "",
      page: 1,
      pageSize: 10,
      searchContractText: "",
      form: { ...formDefault },
      accounts: "",
      invoices: "",
      selectedInvioce: [{ id: "", invoiceNumber: "", items: [] }],
      selectedInvioceCopy: [{ id: "", invoiceNumber: "", items: [] }],
      selectedInvioceCopyShow: [{ id: "", invoiceNumber: "", items: [] }],
      addNew: false,
      isVisible: false,
      onDeleteConfirmation: false,
      deleteSelectedItem: "",
      contractType: "",
      selectInvioce: false,
      selectedEditInvioce: [],
      invoiceData: "",
      invoiceNumber: "",
      del:false,
      editItems:false,
      totalItemsAmount:"",
      invoiceBalanceError:"",
      AmountError:"",
      changeOfAmount:false,
      freeAmount:[],
      showItems:false,
      zeroVat:false,
      loaderInvoice:false,
      debitAgainst:"open",
      filteredPayments : [],
      filteredCreditNotes : [],
      selectedPayment:{},
      creditNote:{},
      openResponseCD:false,
      singleCDData:{}
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.type !== this.props.type) {
      // Update the form type based on the new props type
      const newType = this.props.type === 2 ? "debit" : "credit";
      this.setState((prevState) => ({
        form: {
          ...prevState.form,
          creditDebitNote: {
            ...prevState.form.creditDebitNote,
            type: newType,
          },
        },
      }));
    }
  }

  checkRequiredFields = () => {

    const { type, issuingParty, receivingParty, refNum, creditDebitDate, remarks } = this.state.form.creditDebitNote;
    const { selectedInvioceCopyShow, invoiceNumber, debitAgainst, selectedPayment } = this.state;
    // const validRemarks = Array.isArray(remarks) && remarks.some(remark => remark.trim().length > 0);
    const validRemarks = Array.isArray(remarks) && (remarks.length > 0 && remarks.some(remark => remark.trim().length > 0));
    // console.log("remarks=====", remarks, validRemarks);
    if (type && issuingParty && receivingParty && refNum && creditDebitDate && validRemarks) {
        if (type === 'debit') {
            if (debitAgainst === 'open') {
                if (parseFloat(selectedPayment.netAmount) > 0) {
                    this.setState({ isVisible: true, debitAmountError: "" });
                } else {
                    this.setState({ isVisible: false });
                }
            } else {
                if (parseFloat(selectedPayment.netAmount) > 0 &&
                    (selectedPayment.paymentNumber || selectedPayment.creditDebitNoteNumber) &&
                    parseFloat(selectedPayment.netAmount) <= parseFloat(selectedPayment.taxableAmount)) {
                    this.setState({ isVisible: true, debitAmountError: "" });
                } else {
                    this.setState({ isVisible: false, debitAmountError: "Net Amount Should not exceed Total Balance" });
                }
            }
        } else {
            if (invoiceNumber) {
                for (let i = 0; i < selectedInvioceCopyShow.length; i++) {
                    const invoice = selectedInvioceCopyShow[i];
                    if (invoice.invoiceNumber !== '') {
                        if (this.state.showItems) {
                            if (type === "credit") {
                                if (parseFloat(invoice?.netVATSum) > invoice.invoiceBalance || invoice.invoiceBalance === 0) {
                                    this.setState({ isVisible: false, AmountError: "Invoice Credit Amount Should be less than Invoice Balance" });
                                    break;
                                } else {
                                    this.setState({ isVisible: true, AmountError: "" });
                                }
                            } else {
                                this.setState({ isVisible: true, AmountError: "" });
                            }
                        } else {
                            if (type === "credit") {
                                if (parseFloat(invoice.saleNetTotalAmount).toFixed(2) > invoice.invoiceBalance || invoice.invoiceBalance === 0) {
                                    this.setState({ isVisible: false, AmountError: "Invoice Credit Amount Should be less than Invoice Balance" });
                                    break;
                                } else {
                                    this.setState({ isVisible: true, AmountError: "" });
                                }
                            } else {
                                this.setState({ isVisible: true, AmountError: "" });
                            }
                        }
                    } else {
                        this.setState({ isVisible: false, AmountError: "" });
                    }
                }
            }
        }
    }else {
      this.setState({ isVisible: false });
    }
};


 
handleChange = (name) => (event) => {
 if (name === "debitAgainst") {
    const value = event.target.value;
    this.setState({
        selectedPayment: {},
        [name]: value
    }, () => {
        if (value !== "open") {
            this.getInvoices();
        }
        this.checkRequiredFields();
    });
} else if (name === "showItems" || name === "zeroVat") {
  // const newType = this.props.type === 2 ? "debit" : "credit";
    if (name === "zeroVat" && this.state.selectedPayment && this.props.type === 2) {
        let totalAmount, vatPercent, taxableAmount;
        if (event.target.checked) {
            totalAmount = this.state.selectedPayment.netAmount || 0;
            vatPercent = 0;
            taxableAmount = this.state.debitAgainst === "payment" ?
                parseFloat(this.state.selectedPayment.amountWithZeroVat) || 0 :
                this.state.selectedPayment.taxableAmount;
        } else {
            totalAmount = this.state.selectedPayment.netAmount ?
                (parseFloat(this.state.selectedPayment.netAmount) + (parseFloat(this.state.selectedPayment.netAmount) * 0.15)).toFixed(2) : 0;
            vatPercent = this.state.selectedPayment.netAmount ? (parseFloat(this.state.selectedPayment.netAmount) * 0.15).toFixed(2) : 0;
            taxableAmount = this.state.debitAgainst === "payment" ?
                parseFloat(this.state.selectedPayment.amountWithoutZeroVat) :
                this.state.selectedPayment.taxableAmount;
        }

        this.setState({
            [name]: event.target.checked,
            selectedPayment: {
                ...this.state.selectedPayment,
                totalAmount: parseFloat(totalAmount)?.toFixed(2),
                vatPercent: parseFloat(vatPercent)?.toFixed(2),
                taxableAmount: parseFloat(taxableAmount)?.toFixed(2)
            }
        }, () => this.checkRequiredFields());
    } 
    else if(name === "zeroVat" &&  this.props.type === 1){
      // console.log("Name, value======", name, event.target.value);
      const isZeroVat = event.target.checked;
      let  updatedInvoices;

      if(this.state.showItems){
        updatedInvoices = this.state.selectedInvioceCopyShow.map(invoice => {
          const updatedItems = invoice.items.map(item => ({
            ...item,
            vatPercent: isZeroVat ? 0 : item.totalAmount * 0.15 ,
            netVAT: isZeroVat ? item.totalAmount : item.totalAmount + (item.totalAmount * 0.15)
          }));
          let netVatSum = updatedItems.reduce((sum, item) => sum + item.netVAT, 0);

          return {
            ...invoice,
            netVATSum:netVatSum,
            items: updatedItems
          };
        });

      }else{
         updatedInvoices = this.state.selectedInvioceCopyShow.map(invoice => {
          // console.log("invoice, invoice======", invoice);
              let saleTotalAmount, saleTotalAmountPercent, saleNetTotalAmount;
    
              if (isZeroVat) {
                  saleTotalAmount = invoice.saleTotalAmount || 0;
                  saleTotalAmountPercent = 0;
                  saleNetTotalAmount = invoice.saleTotalAmount;
              } else {
                  saleTotalAmount = invoice.saleTotalAmount 
                  // ? (parseFloat(invoice.netAmount) + (parseFloat(invoice.netAmount) * 0.15)).toFixed(2) : 0;
                  saleTotalAmountPercent = invoice.saleTotalAmount ? (parseFloat(invoice.saleTotalAmount) * 0.15) : 0;
                  saleNetTotalAmount = parseFloat(invoice.saleTotalAmount) + (parseFloat(invoice.saleTotalAmount) * 0.15);
              }
    
              return {
                  ...invoice,
                  saleTotalAmount: parseFloat(saleTotalAmount)?.toFixed(2),
                  saleTotalAmountPercent: parseFloat(saleTotalAmountPercent)?.toFixed(2),
                  saleNetTotalAmount: parseFloat(saleNetTotalAmount)?.toFixed(2)
              };
          });
      }
     

      this.setState({
          [name]: isZeroVat,
          selectedInvioceCopyShow: updatedInvoices  // Assuming you have a state to hold the updated invoices
      }, () => this.checkRequiredFields());
    } 
    
    else {
        this.setState({
            [name]: event.target.checked
        }, () => this.checkRequiredFields());
    }
} else if (name === "netAmount") {  // To change not amount in Debit Note
    const netAmount = parseFloat(event.target.value) || 0;
    const totalAmount = this.state.zeroVat ?
        netAmount :
        (netAmount + (netAmount * 0.15)).toFixed(2);
    const vatPercent = this.state.zeroVat ? 0 : ((totalAmount - netAmount)).toFixed(2);

    this.setState({
        selectedPayment: {
            ...this.state.selectedPayment,
            totalAmount: parseFloat(totalAmount),
            vatPercent: parseFloat(vatPercent),
            netAmount: parseFloat(netAmount)
        }
    }, () => this.checkRequiredFields());
}
else {
      this.setState(
      {
        form: {
          ...this.state.form,
          creditDebitNote: {
            ...this.state.form.creditDebitNote,
            [name]: event.target.value,
          },
        },
      },
        () => { 
        if (name==="amount") {
          this.setState({changeOfAmount:false})
      }
      
      this.checkRequiredFields()
    }
    );
  }
};
 
  handleChangeDate = (name) => (event) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          creditDebitNote: {
            ...this.state.form.creditDebitNote,
            [name]: event.toISOString(),
          },
        },
      },
      () => this.checkRequiredFields()
    );
  };

  handleChangeDNItems = (e, name, mainIndex, index) => {
    let price = 0;
    let allItems = [...this.state.selectedInvioceCopyShow];
    let singleItem = allItems[mainIndex];
    
    const inputValue = e.target.value.trim();
    const value = inputValue === "" ? 0 : parseFloat(inputValue);
   
    if (name === 'saleItemPrice') {
      // update saleItemPrice, itemQty, totalAmount, and netVAT simultaneously
      const saleItemPrice = parseFloat(value);
      const itemQty = parseFloat(singleItem.items[index]['itemQty']);
      const totalAmount = saleItemPrice * itemQty;
      // const netVAT = totalAmount * 0.15;
      const vatPercent = this.state.zeroVat ? 0 : (saleItemPrice*itemQty) * 0.15
      const netVAT = this.state.zeroVat ? totalAmount : totalAmount + vatPercent;
      singleItem.items[index] = { ...singleItem.items[index],vatPercent, saleItemPrice, itemQty, totalAmount,
         netVAT   };
         
    } else if (name === 'itemQty') {
      // update itemQty, totalAmount, and netVAT simultaneously
      const itemQty = parseFloat(value);
      const saleItemPrice = parseFloat(singleItem.items[index]['saleItemPrice']);
      const totalAmount = saleItemPrice * itemQty;
      const vatPercent = this.state.zeroVat ? 0 : (saleItemPrice*itemQty) * 0.15
      const netVAT = this.state.zeroVat ? totalAmount : totalAmount + vatPercent;
      singleItem.items[index] = { ...singleItem.items[index],vatPercent, itemQty, totalAmount, netVAT };
    } else {
      // update other properties as usual
      singleItem.items[index][name] = value;
    }

      const netVATSum = singleItem?.items?.reduce((sum, subItem) => {
        return sum + subItem.netVAT;
      }, 0);


  const updatedVal = {
    ...singleItem,
    netVATSum: parseFloat(netVATSum?.toFixed(2)),
    // allItemsAmount:allItemsAmount
  };

  allItems[mainIndex] = updatedVal;
 
    this.setState({
      // totalItemsAmount:netVATSum,
      editItems: true,
      selectedInvioceCopyShow: allItems,
    }, () => {
      this.checkRequiredFields();
    });
  };

  handleChangeAccount = async (name, value, index,a) => {
    let netVATSum;
    if (name === "receivingParty") {
      const val = value;

      this.setState(
        {
          form: {
            ...this.state.form,
            creditDebitNote: {
              ...this.state.form.creditDebitNote,
              [name]: val,
              contractNumber: val.contractNumber,
            },
          },
          contractType: val.contractType,
        },
          () => {
            if( this.state.debitAgainst !== "open" || this.state.form.creditDebitNote.type === "credit"  ){
              this.getInvoices()
            }
            this.checkRequiredFields();
          }
        );
     }
     else if (name === 'selectedPayment' ||  name === 'creditNote'  ) {
        
      let netAmount = parseFloat(value.netAmount)?.toFixed(2);
      let vatPercent = parseFloat(value.vatPercent)?.toFixed(2);
      let totalAmount = parseFloat(value.totalAmount)?.toFixed(2);
      let taxableAmount = parseFloat(value.taxableAmount)?.toFixed(2);
  
      this.setState({
          [name]: { ...value, netAmount, vatPercent, totalAmount, taxableAmount },
          selectedPayment: { ...value, netAmount, vatPercent, totalAmount, taxableAmount },
      }, () => {
          this.checkRequiredFields();
      });

      }
      else if (name === 'saleTotalAmount') {
      // // Make a copy of your data array (assuming it's an array of objects)
      const updatedData = [...this.state.selectedInvioceCopyShow];
    
      updatedData[index].saleTotalAmount = parseFloat(value.target.value) || 0;
    
      // // Calculate saleTotalAmountPercent and saleNetTotalAmount based on the updated saleTotalAmount
      const saleTotalAmountPercent = this.state.zeroVat ? 0 : (updatedData[index].saleTotalAmount || 0) * 0.15;
      const saleNetTotalAmount = this.state.zeroVat ? (updatedData[index].saleTotalAmount || 0) :  saleTotalAmountPercent + (updatedData[index].saleTotalAmount || 0);
    
      // // Update the corresponding properties in the invoice object
      updatedData[index].saleTotalAmountPercent = saleTotalAmountPercent;
      updatedData[index].saleNetTotalAmount = saleNetTotalAmount;
          this.setState(
            {
              // totalItemsAmount:netVATSum,
              selectedInvioce: updatedData ,
              selectedInvioceCopyShow:updatedData ,
              selectInvioce: true,
              // invoiceNumber: val.invoiceNumber,
            }, ()=>{
              this.checkRequiredFields();
              
            });
      } 
      // else if (name === 'saleNetTotalAmount') {

      //   const updatedData = [...this.state.selectedInvioceCopyShow];
      
      //   // updatedData[index].saleNetTotalAmount = parseFloat(value.target.value) || 0;
      //   updatedData[index].saleTotalAmount = parseFloat(value.target.value) || 0;
      
      //   const saleTotalAmountPercent = (updatedData[index].saleTotalAmount || 0) * 0.15;
      //   const saleNetTotalAmount =  (updatedData[index].saleTotalAmount || 0) - saleTotalAmountPercent ;

      //   updatedData[index].saleTotalAmountPercent = saleTotalAmountPercent;
      //   updatedData[index].saleTotalAmount = saleNetTotalAmount;
    
      //       this.setState(
      //         {
      //           // totalItemsAmount:netVATSum,
      //           selectedInvioce: updatedData ,
      //           selectedInvioceCopyShow:updatedData ,
      //           selectInvioce: true,
      //           // invoiceNumber: val.invoiceNumber,
      //         }, ()=>{
      //           this.checkRequiredFields();
                
      //         });
      //   } 
     else {     //for invoice number 
      let url;
      if (this.props.value === "maintenance") {
        url = `/api/invoices/${value.id}`;
      } else if (this.props.value === "leasing") {
        url = `/api/leaseinvoices/${value.id}`;
      }
      return await this.setState({loaderInvoice :true },()=>{
      instance({
        method: "GET",
        url: url,
         headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          
        let vatPercent;
        let val = response ? response : "";
        let rows = [];
        rows = this.state.selectedInvioce
    
        let updatedItems  = [];
       // let allItems = val.items.map()
          if (this.state.form.creditDebitNote && this.state.form.creditDebitNote.contractNumber){
            if (this.state.form.creditDebitNote.contractNumber === "pfmc1670" || val.saleVATAmount === 0) {
              vatPercent = 0;
              // const netVAT = totalAmount + vatPercent;
              let calc =0
                updatedItems = val?.items?.map(item => ({
                // ...item,
                deliveryNoteNumber:item.deliveryNoteNumber,
                itemName:item?.itemName || "",
                itemCode:item?.itemCode || "",
                saleItemPrice:item.saleItemPrice || '',
                itemQty:item.itemQty || '',
                calc : calc + item.netVAT,
                vatPercent:vatPercent,
                netVAT: (item.saleItemPrice*item.itemQty)+ vatPercent,
                totalAmount:item.saleItemPrice*item.itemQty,
                invoiceId:item.invoiceId || item.leaseInvoiceId || "",
                invoiceItemId:item.id,
              }));
            }
          else{
            updatedItems = val?.items?.map(item => ({
            //  ...item,
              deliveryNoteNumber:item.deliveryNoteNumber,
              itemName:item?.itemName || "",
              itemCode:item?.itemCode || "",
              saleItemPrice:item.saleItemPrice || '',
              itemQty:item.itemQty || '',
              vatPercent:this.state.zeroVat ? 0 : item.saleVATAmount?(item.saleItemPrice*item.itemQty) * 0.15 :0,
              netVAT:this.state.zeroVat ? (item.saleItemPrice*item.itemQty) : (item.saleItemPrice*item.itemQty)+  (item.saleVATAmount?(item.saleItemPrice*item.itemQty) * 0.15:0) ,
              totalAmount:item.saleItemPrice*item.itemQty,
              invoiceId:item.invoiceId || item.leaseInvoiceId || "",
              invoiceItemId:item.id,
          }));
      
          }
        }
        netVATSum = parseFloat(updatedItems?.reduce((sum, item) => sum + item.netVAT, 0)) || 0;

        const updatedVal = {
          ...val,
          saleTotalAmountPercent:val.saleVATAmount === 0 ? 0 :  val.saleTotalAmount?(val.saleTotalAmount) * 0.15 :0,   // to use in case of not showing items
          saleNetTotalAmount:val.saleVATAmount === 0 ? val.saleTotalAmount:  val.saleTotalAmount?((val.saleTotalAmount) * 0.15) + (val.saleTotalAmount) : 0, // to use in case of not showing items
          calculateITemNet: parseFloat(netVATSum?.toFixed(2)),
          items: updatedItems,
          // allItemsAmount:allItemsAmount
        };
   
        rows[a] = updatedVal;
 
        this.setState(
          {
            // totalItemsAmount:netVATSum,
            selectedInvioce: rows,
            loaderInvoice :false,
            selectedInvioceCopy:rows,
            selectInvioce: true,
            invoiceNumber: val.invoiceNumber,
          }, ()=>{
            this.settingCopy(this.state.selectedInvioce,a)
          });

          })
          .catch((error) => {
          // console.log("error ==", error)
        });
        })
    }
  };
  getInvoices = async () => {
    let { contractType, form, search } = this.state;
    let url;

    if (this.state.debitAgainst === "creditNote" && this.state.form?.creditDebitNote?.type === 'debit') {
        url = `/api/creditdebitnotes/creditnotes?contractNumber=${form?.creditDebitNote?.contractNumber}`;
    } else if (this.state.debitAgainst === "payment" && this.state.form?.creditDebitNote?.type === 'debit') {
        url = `/api/payments/get?contractNumber=${form?.creditDebitNote?.contractNumber}`;
    } else {
        if (this.props.value === "maintenance") {
            url = `/api/invoices/list?contractNumber=${form?.creditDebitNote?.contractNumber}&invoiceNumber=${search}`;
        } else if (this.props.value === "leasing") {
            url = `/api/leaseinvoices/list?contractNumber=${form?.creditDebitNote?.contractNumber}&search=${search}`;
        }
    }

    return await instance({
        method: "GET",
        url: url,
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    })
    .then((response) => {
        if (response && response) {
            if (this.state.debitAgainst === "creditNote" && this.state.form?.creditDebitNote?.type === 'debit') {
                const filteredCreditNotes = response?.map(note => {

                    return {
                        id: note.id,
                        creditDebitNoteNumber: note.creditDebitNoteNumber,
                        netAmount: parseFloat(note.totalAmount),
                        label: note.creditDebitNoteNumber,
                        balance: note.balance,
                        taxableAmount: parseFloat(note.totalAmount),
                        vatPercent: this.state.zeroVat ? 0 : parseFloat((note.netAmount * 0.15).toFixed(2)),
                        totalAmount: parseFloat(note.netAmount),
                    };
                });

                this.setState({ filteredCreditNotes });
            } else if (this.state.debitAgainst === "payment" && this.state.form?.creditDebitNote?.type === 'debit') {
                const filteredPayments = response?.data?.map(payment => {
                    const taxableAmount = this.state.zeroVat ? payment.amount : (payment.amount / 1.15);
                    const vatPercent = payment.amount - parseFloat(taxableAmount);

                    return {
                        id: payment.id,
                        balance: payment.balance,
                        netAmount: parseFloat(taxableAmount).toFixed(2),
                        taxableAmount: parseFloat(taxableAmount).toFixed(2),
                        vatPercent: parseFloat(vatPercent),
                        totalAmount: parseFloat(payment.amount).toFixed(2),
                        label: payment.paymentNumber,
                        paymentNumber: payment.paymentNumber,
                        amountWithoutZeroVat: parseFloat((payment.amount / 1.15)).toFixed(2),
                        amountWithZeroVat: parseFloat(payment.amount).toFixed(2),
                    };
                });

                this.setState({ filteredPayments });
            } else {
                const filteredInvoices = response?.data?.filter((invoice) => parseFloat(invoice.invoiceBalance).toFixed(2) > 0);

                this.setState({ invoices: filteredInvoices || [] });
            }
        }
    })
    .catch((error) => {
        // Handle error
    });
};
  
  settingCopy = (allData,Mainindex)=>{
      let res = this.state.selectedInvioceCopyShow
      let pp = []
      if (res && Array.isArray(res)) {
        res.map((row, index) => {
          if(Mainindex === index){
            pp.push({ ...row, items: [] });
          }
          else{
             pp.push({ ...row});

          }
        });
      }
       this.setState({
        selectedInvioceCopyShow:pp

      })
  }
  handleDeleteDNItem = (mainIndex, index) => {
   
    let allItems = [...this.state.selectedInvioceCopyShow];
    let singleItem = allItems[mainIndex];
    singleItem.items.splice(index, 1);

    const netVATSum = singleItem?.items?.reduce((sum, subItem) => {
      return sum + subItem.netVAT;
    }, 0);
    
  const updatedVal = {
    ...singleItem,
    netVATSum:parseFloat(netVATSum?.toFixed(2)),
    // allItemsAmount:allItemsAmount
  };

  allItems[mainIndex] = updatedVal;

    this.setState({
      totalItemsAmount: parseFloat(netVATSum?.toFixed(2)),
      selectedInvioceCopyShow: allItems,
      del:true
    },()=>{
      this.checkRequiredFields()
    });
  };
  
  

  addNewInvoice = () => {
    
    let rows = [];
    rows = [...this.state.selectedInvioceCopyShow];

    if (rows.length) {
      let last = { ...rows[rows.length - 1] };
      rows.push({});
    } else {
      let last = { ...rows[rows.length - 1] };
      rows.push(last);
    }
    this.setState({ selectedInvioceCopyShow: rows },()=> this.checkRequiredFields());
  };

  deleteInvoiceRow = (index) => {
    
    let rows = [];
    rows = [...this.state.selectedInvioceCopyShow];
    if (this.state.selectedInvioceCopyShow.length > 1) {
      this.setState((state) => {
        const selectedInvioceCopyShow = state.selectedInvioceCopyShow.slice();
        selectedInvioceCopyShow.splice(index, 1);
        return { selectedInvioceCopyShow };
      });
    } else {
      // alert("We need to send Items of atleast one Invoice");
      this.setState({
        deleteInvoice: true,
      });
    }
  };


 

  handleSubmit = async () => {
    
    const { selectedInvioceCopyShow , showItems, selectedPayment, debitAgainst, zeroVat} = this.state;
    const { creditDebitNote } = this.state.form;
    let abc = creditDebitNote;
    let netAmount = 0 ;
    // if(calculateItemsNetVat < calculateInvoiceBalance){
        delete abc.amount
        if (this.state.form) {

          this.setState(
            {
              isVisible: false,
              form: {
                ...this.state.form,
                creditDebitNote: {
                  ...abc,
                  contractNumber:
                    creditDebitNote?.receivingParty?.contractNumber || "",
                  receivingParty: creditDebitNote?.receivingParty.name || "",
                  attributes : {...abc.attributes, zeroVat:zeroVat}
                  // netAmount: creditDebitNote.type  ==='debit'? parseFloat(this.state.selectedPayment.netAmount) : 0
                },
              },
            },
           async () => {
              let makeItems = [];
              let uniqueInvoiceItemIds = new Set();
              // if(this.props.value  ==="maintenance"){
                if(creditDebitNote.type  ==='debit' ){
                  netAmount =  parseFloat(this.state.selectedPayment.netAmount)
                  const newItem = {
                    // saleNetAmount : parseFloat(this.state.selectedPayment.netAmount) || 0,
                    invoiceId:  0,
                    invoiceItemId: 0,
                    invoiceNumber: '',
                    categoryName: "",
                    itemName:  "",
                    itemCode: "",
                    itemQty: 1,
                    id:  0,
                    saleItemPrice: parseFloat(this.state.selectedPayment.netAmount)?.toFixed(2) || 0,
                    saleVATAmount:  0,
                    attributes: { },
                    // Add other properties as needed
                  };

                  if (debitAgainst === "creditNote") {
                    newItem.creditNoteId  = selectedPayment.id || 0;
                  } else if (debitAgainst === "payment") {
                    newItem.paymentId = selectedPayment.id || 0;
                  }
                  makeItems.push(newItem);
                }else{
                  if (!showItems) {
                    // Iterate through selectedInvioceCopyShow and filter items
                      selectedInvioceCopyShow.forEach((data) => {
                      netAmount += parseFloat(data.saleTotalAmount);
                      data.items.forEach((item) => {
                    // Check if invoiceItemId is not in the unique set
                    if (
                      !uniqueInvoiceItemIds.has(item.invoiceId? item.invoiceId : item.leaseInvoiceId || 0)
                    ) {
                      uniqueInvoiceItemIds.add(item.invoiceId? item.invoiceId : item.leaseInvoiceId || 0);
                        makeItems.push({
                          creditDebitNoteId: item?.id || 0,
                          invoiceId: item?.invoiceId || item?.leaseInvoiceId || 0,
                          invoiceItemId: 0,
                          invoiceNumber: data?.invoiceNumber || 0,
                          categoryName: "",
                          itemName:  "",
                          itemCode: "",
                          itemQty: item?.itemQty || 0,
                          id: item?.id || 0,
                          saleItemPrice: parseFloat(data.saleTotalAmount)?.toFixed(2) || 0,
                          saleVATAmount:  0,
                          attributes: { ...item.attributes },
                        });
                      }
                      });
                    });
                  } else {
                    // If showItems is true, keep the old behavior
                    this.state.selectedInvioceCopyShow &&
                      this.state.selectedInvioceCopyShow.forEach((data, index) => {
                        data.items.forEach((item, index) => {
                         netAmount += parseFloat(item.saleItemPrice);
                          makeItems.push({
                            creditDebitNoteId: item?.id || 0,
                            invoiceId: item?.invoiceId || item?.leaseInvoiceId || 0,
                            invoiceItemId: item?.invoiceItemId || "",
                            invoiceNumber: data?.invoiceNumber || 0,
                            categoryName: item?.itemName || "",
                            itemName: item?.itemName || "",
                            itemCode: item?.itemCode || "",
                            itemQty: item?.itemQty || 0,
                            id: item?.id || 0,
                            saleItemPrice: parseFloat(item.saleItemPrice)?.toFixed(2) || 0,
                            saleVATAmount: item.saleVATAmount || 0,
                            attributes: { ...item.attributes },
                          });
                        });
                      });
                  }
                }

              const obj = creditDebitFormat(makeItems, {...this.state.form, netAmount : netAmount?.toFixed(2)});

               fetch(`api/creditdebitnotes`, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  ...obj,
                }),
              })
                .then((response) => {
                  response.json().then((res) => {
                    if (res.status === "success") {
                      this.props.dispatch(
                        Notifications.success({
                          message: this.props.translate("creditDebitCreated"),
                          autoDismiss: 5,
                          location: this.props.location,
                        })
                      );
                      this.setState(
                        {
                          
                          invoiceNumber:"",
                          isVisible: false,
                          del:false,
                          editItems:false,
                          addOption: false,
                          showItems:false,
                          freeAmount:[],
                          zeroVat:false,
                          form: {
                            ...formDefault,
                          },
                          selectedInvioce: [
                            { id: "", invoiceNumber: "", items: [] },
                          ],
                        } ,
                        () => {
                          this.props.setCDTabs();
                        }
                      );
                    }
                  });
                })
                .catch((err) => {
                  console.log("err ===", err);
                });
            }
          );
        }

  };


  onEdit = async (item1) => {
    
    let item = item1[0];
    let allItems = [];
    let selectedInvoice ;
    let invoiceApi; 

    //For recieving party 
    let option = await axios.get(`api/accounts/${item.contractNumber}`);
    const  obj = option.data.data; 

    const { contractNumber, name, nameArabic, userId, id , contractType } = obj;
    const user = { contractNumber, name, nameArabic, userId, id , contractType }; // recieving party 
    //Get all  Items of selected Credit-Debit-Note  
    await axios.get(`api/creditdebitnotes/${item.id}/items`).then((res) => {
      allItems = res?.data?.data || [];
    });

    //Get all Invoices of recieving party, 

    if(this.props.value  ==="maintenance"){
      invoiceApi = `/api/invoices/get?&contractNumber=${user?.contractNumber}&invoiceNumber=`;
    }
    else if(this.props.value  ==="leasing"){
      invoiceApi = `/api/leaseinvoices/get?&contractNumber=${user?.contractNumber}&search=`;
    }
    // await axios.get(`/api/leaseinvoices/get?&contractNumber=${user.contractNumber}&search=`).then((res) => {
      await axios.get(invoiceApi).then((res) => {
      selectedInvoice = res?.data?.data?.data || [];
    });
    
    const SelectedItems = allItems.map((obj) => {
      return { invoiceNumber: obj.invoiceNumber };
    });

    let newArray = [];
    let newItem = {};
    let singleInvoiceObject = {};
    SelectedItems.forEach((obj) => {
      let nameExists = newArray.find(
        (newObj) => newObj.invoiceNumber === obj.invoiceNumber
      );

      if (!nameExists) {
        singleInvoiceObject = { invoiceNumber: obj.invoiceNumber };
        newItem = {
          invoiceNumber: obj.invoiceNumber,
          items: allItems.filter(
            (item) => item.invoiceNumber === obj.invoiceNumber
          ),
        };
        newArray.push(newItem);
      }
    });
  
    if (item1) {
      this.setState(
        {
          selectedInvioce: newArray,
          data: item,
          editOption: true,
          addOption: false,
          invoices:selectedInvoice,
          invoiceNumber:singleInvoiceObject.invoiceNumber,
          form: {
            creditDebitNote: {
              ...item,
              receivingParty: user,
            },
          },
        },
        () => this.props.getMoreAccounts()
      );
    }
  };
 

  deleteRow = (item) => {
    this.setState({
      onDeleteConfirmation: true,
      deleteSelectedItem: item[0],
    });
  };

  deleteCreditDebit = (item) => {
   
    const { deleteSelectedItem } = this.state;
    if (deleteSelectedItem && deleteSelectedItem.id) {
      fetch(`/api/creditdebitnotes/${deleteSelectedItem.id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          response.json().then((res) => {
            if (res.status === "success") {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate("creditDebitNoteDeleted"),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
              this.setState(
                {
                  deleteSelectedItem: "",
                  onDeleteConfirmation: false,
                },
                () =>
                  this.props.getAllNotes(
                    this.props.to,
                    this.props.from,
                    this.props.selectedAccount.contractNumber
                  )
              );
            }else{
              const parts = res.message.split("(");
              if (parts.length > 1) {
                const hint = parts[1].slice(0, -1); // Remove trailing parenthesis
                this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(hint),
                  autoDismiss: 5,
                  location: this.props.location,
                })
                )
              }
              this.setState(
                {
                  deleteSelectedItem: "",
                  onDeleteConfirmation: false,
                },
              );
            }
          });
        })
        .catch((err) => {
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate(err),
              autoDismiss: 5,
              location: this.props.location,
            })
          );
        });
    }
  };
  modalControle = () => {
    const newType = this.props.type === 2 ? "debit" : "credit";
    this.setState(
      {
        addOption: false,
        editOption: false,
        isVisible:false,
        showItems:false,
        freeAmount:[],
        loaderInvoice:false,
        invoiceNumber: "",
        selectedPayment:{},
        del:false,
        zeroVat:false,
        form: {
          ...formDefault,
          creditDebitNote: {
            ...formDefault.creditDebitNote,
            type: newType,
          },
        },
        selectedInvioceCopyShow: [{ id: "", invoiceNumber: "", items: [] }],
      },
    );
  };

  openModale = () => {   
    this.props.getMoreAccounts();
    this.setState({
      addOption: true,
      editOption: false,
      invoiceNumber:"",
      showItems:false,
      freeAmount:[],
      loaderInvoice:false,
      debitAgainst:"open",
      selectedPayment:"",
      filteredPayments:[], 
      filteredCreditNotes:[], 
      creditNote:{},
      selectedInvioceCopyShow: [{ id: "", invoiceNumber: "", items: [] }],
    });
  };

  onCancel = () =>{
    this.setState({
      onDeleteConfirmation: false,
      deleteSelectedItem: "",
      deleteInvoice: false,
    });}

  downloadCreditDebit = (item, type , summary) => {
    let Accept = type
      ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      : "application/pdf";

    let api = summary
      ? `  api/creditdebitnotes/exportsummary/${item[0].id}`
      : `  api/creditdebitnotes/export/${item[0].id}`;
    axios({
      method: "GET",
      url: api,
      headers: {
        Accept: Accept,
      },
      responseType: "blob",
    })
      .then((response) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(new Blob([response.data]));
        let title  = this.props.type === 1 ? 'Credit Note' :  this.props.type === 2 ? 'Debit Note' : "Credit-Debit"
        if (!type) {
          a.setAttribute("download", `${title}.pdf`);
        } else {
          a.setAttribute("download", `${title}.xlsx`);
        }
        document.body.appendChild(a);
        a.href = url;
        a.click();
      })
      .catch((error) => {
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate("somethingWentWrong"),
            autoDismiss: 5,
            location: this.props.location,
          })
        );
      });
  };

  getInvoiceData =  (index)=>{
    let selectedItems2 = [];
    let z = []
    let price = 0
    let allItems = [...this.state.selectedInvioce];
    let newArray = [...this.state.selectedInvioceCopyShow]

    let amountArray = [...this.state.freeAmount]
    let singleFreeAmount  = amountArray[index]?.freeAmount || 0
    let selectedInvoice = allItems[index];
 
          let data = (selectedInvoice&&selectedInvoice.items) || []
          let nn = 0
          const selectedAmount = parseInt(singleFreeAmount)
            //  for (let i = 0; i < data.length; i++) {
              data&& data.map((item, i) =>{
                  price = price + item.netVAT
              
                  if (parseFloat(price) <= parseFloat(selectedAmount)) {
                      z[index]= price
                      selectedItems2.push(item)
                        nn++;
                  }
                  else if (i === (nn+1) || (i === nn)){
                      z[index]= price
                      selectedItems2.push(item)
                  }
                  else {
                    price =price - item.netVAT; // Subtract the last item added, as it exceeds the balance
                  }
              })
              let res = parseFloat(z[index]?.toFixed(2))
              
              let checkBalancetoItems = selectedInvoice&&selectedInvoice.invoiceBalance < res ? false : true
              const updatedVal = {
                ...selectedInvoice,
                items:selectedItems2,
                netVATSum:res? res?.toFixed(2): 0,
                checkBalancetoItems:checkBalancetoItems,
                // invoiceBalanceError:checkBalancetoItems ? "":"Credit Entered Should be less than Invoice Balance",
              };
              newArray[index] =updatedVal
              // allItems[index] = updatedVal;
                this.setState({
                  selectedInvioceCopyShow: newArray,
                  AmountError:checkBalancetoItems ? "":"Invoice Credit Amount Should be less than Invoice Balance",
                 }, ()=>{
                  this.checkRequiredFields()
                 })
  }
  
  changeAmount = (data,index, name,e)=>{
    let allItems = [...this.state.freeAmount];
    
    let singleItem = allItems[index];
    
    const updatedVal = {
      ...singleItem,
      freeAmount:e.target.value
    };

    allItems[index] = updatedVal;
      this.setState({
        freeAmount:allItems,
      })
  } 
 
  forwardToZatca = (item, type) => {

    let api = `/api/zatca/forward/creditdebitnote/${item[0].id}`
    instance({
      method: 'GET',
      url: api,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => {
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate("creditNotesentToZatcaSuccessFul"),
              autoDismiss: 5,
              location: this.props.location,
            })
          );

          this.props.getAllNotes(
            this.props.to,
            this.props.from,
            this.props.selectedAccount.contractNumber
          )
      })
      .catch((error) => {
        console.log("er =", error);
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate("somethingWentWrong"),
            autoDismiss: 5,
            location: this.props.location,
          })
        );
      });
  };

  cdRespView = (item, n)=>{
  
    let id = n&&n[0]&&n[0].id
    this.setState({
      openResponseCD:true,
    },()=>{
       instance({
        method: "GET",
        url:  `/api/zatca/response/creditdebitnote/${id}` ,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if(response.zatcaResponse){
            let z = atob(response.zatcaResponse)
            const jsonData = JSON.parse(z);
            this.setState({singleCDData:jsonData})
          }
          else{

          }
        })
        .catch(err=>{
          console.log('err zatcaResponse ==' , err)
        })
    })
  }
  render() {
    return (
      <div>
        <Style>{`
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors["600"]};
            color: ${this.props.themecolors["themeInverse"]};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected {
            background: ${this.props.themecolors["500"]};
            color: ${this.props.themecolors["themeInverse"]};
          }`}</Style>


{this.state.openResponseCD && (
          <CustomDialog
            title="Credit-Debit Response"
            themecolors={this.props.themecolors}
            visable={true}
            onClose={(e)=>{this.setState({openResponseCD:false})}}
            bodyPadding={10}
            hideDragIcon
            fullWidth
            isVisableBtn
            noFullScreen
          >
            <div style={{height: "400px",   overflowY: 'scroll',}}>
               <ReactJson src={this.state.singleCDData} displayDataTypes={false} />
            </div>
          </CustomDialog>
        )}
        {this.state.addOption ? (
          <Fragment>
            {this.props.type === 2 ?   //For  Debit-Note
            <AddDebitModal
            {...this.props}
            {...this.state}
            tranalate={this.props.translate}
            handleCheckboxChange={this.handleCheckboxChange}
            invoiceData={this.props.invoiceData}
            title={ this.props.translate("addDebitNote")}
            // title={this.props.translate("addCredit/DebitNotes")}
            handleChangeAccount={this.handleChangeAccount}
            getInvoices={this.getInvoices}
            addNewInvoice={this.addNewInvoice}
            deleteInvoiceRow={this.deleteInvoiceRow}
            formSubmit={this.handleSubmit}
            handleChangeDate={this.handleChangeDate}
            form={this.state.form}
            handleChange={this.handleChange}
            handleChangeDNItems={this.handleChangeDNItems}
            modalControle={this.modalControle}
            handleDeleteDNItem={this.handleDeleteDNItem}
            buttonText={this.props.translate("sharedCreate")}
            creatingUnit
            getInvoiceData={this.getInvoiceData}
            changeAmount={this.changeAmount}
          />:
            <AddCreditModal       //For  Credit-Note
              {...this.props}
              {...this.state}
              tranalate={this.props.translate}
              handleCheckboxChange={this.handleCheckboxChange}
              invoiceData={this.props.invoiceData}
              title={this.props.translate("addCreditNote") }
              // title={this.props.translate("addCredit/DebitNotes")}
              handleChangeAccount={this.handleChangeAccount}
              getInvoices={this.getInvoices}
              addNewInvoice={this.addNewInvoice}
              deleteInvoiceRow={this.deleteInvoiceRow}
              formSubmit={this.handleSubmit}
              handleChangeDate={this.handleChangeDate}
              form={this.state.form}
              handleChange={this.handleChange}
              handleChangeDNItems={this.handleChangeDNItems}
              modalControle={this.modalControle}
              handleDeleteDNItem={this.handleDeleteDNItem}
              buttonText={this.props.translate("sharedCreate")}
              creatingUnit
              getInvoiceData={this.getInvoiceData}
              changeAmount={this.changeAmount}
            />}
          </Fragment>
        ): (
          <>
            <Grid
              container
              className="breadcrumb-row"
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid item xs={4} sm={4} style={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => this.props.handleChangeNotesTabs(1)}
                    style={{
                      width: "50%",
                      borderTopRightRadius: " 15px",
                      borderTopLeftRadius: "15px",
                      marginLeft: "5px",
                      backgroundColor:
                      this.props.type === 1 ? "#00C179" : "#ffff",
                      color: this.props.type === 1 ? "#ffff" : "#00C179",
                    }}
                  >
                    {this.props.translate("creditNotes")}
                  </Button>

                  <Button
                    variant="contained"
                    disabled={false}
                    onClick={() => this.props.handleChangeNotesTabs(2)}
                    style={{
                      width: "50%",
                      borderTopRightRadius: " 15px",
                      borderTopLeftRadius: "15px",
                      marginLeft: "5px",
                      backgroundColor:
                         this.props.type === 2 ? "#00C179" : "#ffff",
                      color:  this.props.type === 2 ? "#ffff" : "#00C179",
                    }}
                  >
                    {" "}
                    {this.props.translate("debitNotes")}
                  </Button>
                </Grid>
              <Grid item xs={12} sm={8} style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                    fullWidth
                    label={this.props.translate("search")}
                    value={this.props.searchInvoiceText}
                    onChange={(e) => this.props.searchNote(e)}
                  />
                </div>
                  <Button
                    aria-label="Create"
                    disabled={!checkPrivileges("creditdebitnoteCreate")}
                    size="small"
                    color="inherit"
                    onClick={() => {
                      this.openModale();
                    }}
                    id="createButton"
                  >
                    {this.props.translate("create")}
                </Button>
                <Button
                  aria-label="Back"
                  size="small"
                  color="inherit"
                  onClick={this.props.goBack}
                  id="back"
                  style={{ margin: 10 }}
                >
                  {this.props.translate("sharedBack")}
                </Button>
              </Grid>
            </Grid>
            {this.props.loader2 ? <Loader/> : 
            <Table
              rows={this.props.notesData?.data || []}
              pagination={this.props.setPagination()}
              handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
              handleChangePage={this.props.handleChangePage}
              isCursoPointer
              downloadInvoice={this.downloadCreditDebit}
              isEditable
              openinvoiceRespView={this.cdRespView}

              hasAccessOfDelete={checkPrivileges('creditdebitnoteDelete')}
              onDelete={this.deleteRow}
              forwardToZatca={this.forwardToZatca}
              type={this.props.type === 1 ? "credit" : this.props.type === 2 ? "debit" :""}
              rowDefinition={[
                {
                  id: "creditDebitNoteNumber",
                  label: this.props.type === 1 ? this.props.translate("creditNoteNumber") : this.props.translate("debitNoteNumber") ,
                },
                {
                  id: "c_taxNumber",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("VATNumber"),
                },
                {
                  id: "creditDebitDate",
                  label: this.props.translate("date"),
                },
                {
                  id: "refNum",
                  label: this.props.translate("referenceNumber"),
                },
                {
                  id: "receivingParty",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("receivingParty"),
                },
                ...(this.props.type === 1
                  ? [
                      {
                          id: "invoiceNumbers",
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate("invoiceNumbers"),
                      },
                      
                  ]
                  : []),
                ...(this.props.type === 2
                  ? [
                      {
                          id: "creditNoteNumbers",
                          numeric: false,
                          disablePadding: false,
                          label: this.props.translate("creditNoteNumbers"),
                      },
                      {
                        id: "paymentNumbers",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("paymentNumbers"),
                    },
                  ]
                  : []),


           

                {
                  id: "totalAmount",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("taxableAmount"),
                },
                {
                  id: "vatAmount",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("taxAmount"),
                },
                {
                  id: "netAmount",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("totalAmount"),
                },

                ...(this.props.type === 1
                  ? [
                    {
                      id: "zatcaStatus",
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate("zatcaStatus"),
                      hide: false,
                    }
                  ]
                  : []),
              //   ...(this.props.type === 1
              //     && [
              //       {
              //         id: "zatcaStatus",
              //         numeric: false,
              //         disablePadding: false,
              //         label: this.props.translate("zatcaStatus"),
              //         hide: false,
              //       },]
              // ),
                {
                  id: this.props.type === 1 ? this.props.translate("viewCredit") : this.props.translate("viewDebit") ,
                  label: this.props.type === 1 ? this.props.translate("credit") : this.props.translate("debit") ,
                  numeric: false,
                  disablePadding: false,
                  hide: false,
                },
              ]}
            />
  }
          </>
        )}
        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.deleteCreditDebit}
            title={this.props.translate("areYouWantToDelete")}
            children={this.state.deleteSelectedItem?.creditDebitNoteNumber || ''}
          />
        )}
        {this.state.deleteInvoice && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.onCancel}
            title={this.props.translate("cantDeleteLastRow")}
          />
        )}
      </div>
    );
  }
}
const mapState = (state) => {
  const devices = {
    ...state.devices,
    data:
      state.devices.data &&
      state.devices.data.map((d) => {
        if (d.created) {
          if (state.logInUsers.twelveHourFormat) {
            d.created = moment(d.created).format("YYYY-MM-DD hh:mm A");
          } else {
            d.created = moment(d.created).format("YYYY-MM-DD HH:mm");
          }
          return d;
        } else {
          return d;
        }
      }),
  };

  return {
    devices: state.devices,
    logInUser: state.logInUsers,
    group: state.groups,
    themecolors: state.themecolors,
    ServerSetting: state.ServerSetting,
    parentUnitLimits: state.parentUnitLimits,
  };
};

const mapStateToProps = connect(mapState);
export const CreditDebitList = mapStateToProps(withLocalize(creditDebitList));
