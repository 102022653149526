import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import CustomDialog from "../common/Dialog";
import Notifications from "react-notification-system-redux";
// import AddWorkShopsModal from "./addWorkShops";
import { parentUnitsLimit, getDevices } from "../../Actions/Devices";
import Table from "../common/tableWithBackEndPagination";
import isEqual from "react-fast-compare";
import { checkPrivileges, PaginationConfig } from "../../Helpers";
import ConfirmDialoag from "../common/ConfirmDialoag";
// import DevicePortList from './devicePortList'
import Button from "../common/Button";
import Style from "style-it";
import { TextField, Grid } from "@material-ui/core";

import moment from "moment";

class reportsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  addOption:false,
    };
  }

  render() {
    const { devices, group } = this.props;
    let fileName = "units";
    return (
      <div>
        <Style>{`
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors["600"]};
            color: ${this.props.themecolors["themeInverse"]};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected {
            background: ${this.props.themecolors["500"]};
            color: ${this.props.themecolors["themeInverse"]};
          }`}</Style>

        <Fragment>
          {/* {this.props.addOption ? (
            <AddWorkShopsModal
              // {...this.state}
              {...this.props}
              title="Add Garages"
              data={this.props.data}
              //   users={this.props.users}
              //   group={group}
              formSubmit={this.props.handleSubmit}
              //   handleChangeLicenseExpDate={this.props.handleChangeLicenseExpDate}

              handleChange={this.props.handleChange}
              modalControle={this.props.modalControle}
              buttonText={this.props.translate("sharedCreate")}
              isVisableAddBtn={this.props.isVisableAddBtn}
              checkRequiredFields={this.props.checkRequiredFields}
              creatingUnit
            />
          ) : this.props.editOption ? (
            <AddWorkShopsModal
              // {...this.state}
              {...this.props}
              title="Update Garages"
              data={this.props.data}
              //   users={this.props.users}
              //   group={group}
              formSubmit={this.props.updateWorkshops}
              //   handleChangeLicenseExpDate={this.props.handleChangeLicenseExpDate}
              buttonText={"Update"}
              handleChange={this.props.handleChange}
              modalControle={this.props.modalControle}
              // buttonText={this.props.translate('sharedCreate')}
              isVisableAddBtn={this.props.isVisableAddBtn}
              checkRequiredFields={this.props.checkRequiredFields}
              creatingUnit
            />
          ) : (
            <> */}
          <Grid container className="breadcrumb-row">
            <Grid item xs={12} sm={3}>
              <h3 style={{ margin: 0 }}>{this.props.translate("reports")}</h3>
            </Grid>

            <Grid item xs={12} sm={9} style={{ justifyContent: "flex-end" }}>
              {/* <Button
                disabled={!(checkPrivileges("garageCreate") ? true : false)}
                aria-label="Create"
                size="small"
                color="inherit"
                // onClick={this.props.addGarages}
                id="createButton"
              >
                {this.props.translate("create")}
              </Button> */}
            </Grid>
          </Grid>

          {/* <Table
            // rows={this.props.garagesData?.data || []}

            // pagination={
            //   this.props.garagesData &&
            //   this.props.garagesData.data &&
            //   this.props.garagesData.data.length &&
            //   this.props.setPagination(this.props.garagesData)
            // }

            // handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
            // handleChangePage={this.props.handleChangePage}

            // filterDropdown
            // onClick={this.props.workShopSelection}
            isEditable={true}
            // onDelete={this.props.removeEnable}
            hasAccessOfDelete={checkPrivileges("garageDelete")}
            hasAccessOfUpdate={checkPrivileges("garageUpdate")}
            isCursoPointer
            // onEdit={this.props.workShopSelection}
            // setSerialNo
            // SearchItem={this.SearchItem}
            rowDefinition={[
              // {
              //   id: 'adjustmentType',

              //   label: this.props.translate('adjustmentType')
              // },
              {
                id: "name",
                label: this.props.translate("name"),
              },
              {
                id: "garageNumber",
                label: this.props.translate("garageNumber"),
              },
              {
                id: "description",
                label: this.props.translate("description"),
              },
              {
                id: "email",
                label: this.props.translate("email"),
              },
              {
                id: "garageManager",
                label: this.props.translate("garageManager"),
              },
              {
                id: "created",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("created"),
              },

              // {
              //   id: 'host',
              //   numeric: false,
              //   disablePadding: false,
              //   label: this.props.translate('host')
              // },
            ]}
          /> */}
          {/* </>
          )} */}

          {/* {this.props.onDeleteConfirmation && (
            <ConfirmDialoag
              onCancel={this.props.onCancel}
              onOk={this.props.deleteWorkShop}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.selectedWorkShop.name}
            />
          )} */}
        </Fragment>
      </div>
    );
  }
}
const mapState = (state) => {
  const devices = {
    ...state.devices,
    data:
      state.devices.data &&
      state.devices.data.map((d) => {
        if (d.created) {
          if (state.logInUsers.twelveHourFormat) {
            d.created = moment(d.created).format("YYYY-MM-DD hh:mm A");
          } else {
            d.created = moment(d.created).format("YYYY-MM-DD HH:mm");
          }
          return d;
        } else {
          return d;
        }
      }),
  };

  return {
    devices: state.devices,
    logInUser: state.logInUsers,
    group: state.groups,
    themecolors: state.themecolors,
    ServerSetting: state.ServerSetting,
    parentUnitLimits: state.parentUnitLimits,
  };
};

const mapStateToProps = connect(mapState);
export const ReportsList = mapStateToProps(withLocalize(reportsList));
