import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Account from "./Pages/Accounts";
import Users from "./Pages/Users";
import UserView from "./Pages/UserView";
import Login from "./Pages/Login";
import { LogOut } from "./Pages/LogOut";
import Devices from "./Pages/Devices";
import MaicIntegration from "./Pages/MaicIntegration"
import Groups from "./Pages/Groups";
import AccountManagements from "./Pages/AccountManagements";
import ServerSettings from "./Pages/ServerSettings";
import AccountSettings from "./Pages/AccountSettings";
import SystemLogs from "./Pages/SystemLogs";
import ActionLogs from "./Pages/ActionLogs";
import RoleManagement from "./Pages/Role";
import ContractManagment from "./Pages/ContractManagment";
import Invoice from "./Pages/Invoice";
import DeliveryNote from "./Pages/DeliveryNote";
import Plans from "./Pages/Plans";
// import Payment from './Pages/Payment'
import NewPayment from "./Pages/NewPayment";
import ActivationCode from "./Pages/ActivationCode";
import TemplateGenerator from "./Pages/TemplateGenerator";
import Trash from "./Pages/Trash";
import Coupon from "./Pages/Coupon";
import WebHooks from "./Pages/WebHooks";
import NotFoundPage from "./Pages/NotFound";
import { withLocalize } from "react-localize-redux";
import en from "./lang/en.json";
import fr from "./lang/fr.json";
import urdu from "./lang/urdu.json";
import arabic from "./lang/arabic.json";
import albanian from "./lang/albanian.json";
import burmese from "./lang/burmese.json";
import chinese from "./lang/chinese.json";
import cambodian from "./lang/cambodian.json";
import german from "./lang/german.json";
import gujarati from "./lang/gujarati.json";
import hebrew from "./lang/hebrew.json";
import hindi from "./lang/hindi.json";
import hungarian from "./lang/hungarian.json";
import indonesian from "./lang/indonesian.json";
import italian from "./lang/italian.json";
import japanese from "./lang/japanese.json";
import korean from "./lang/korean.json";
import mongolian from "./lang/mongolian.json";
import nepali from "./lang/nepali.json";
import persian from "./lang/persian.json";
import portuguese from "./lang/portuguese.json";
import russian from "./lang/russian.json";
import serbian from "./lang/serbian.json";
import spanish from "./lang/spanish.json";
import telugu from "./lang/telugu.json";
import thai from "./lang/thai.json";
import turkish from "./lang/turkish.json";
import amharic from "./lang/amharic.json";
import kurdish from "./lang/Kurdish.json";
import "./assets/main.scss";
import InvoiceDetail from "./Pages/Invoice/Invoice";
import MaintenanceInvoiceDetail from "./Pages/Invoice/maintenanceInvoice";
import DeliveryDetail from "./Pages/DeliveryNote/DeliveryNote";
import Notifications from "react-notification-system-redux";
import CreditDebit from "./Pages/CreditDebit";
import Commutator from "./Pages/Commutator";
import Workshops from "./Pages/Workshops";
import Reports from "./Pages/Reports";
import Quotations from "./Pages/Quotation";
import Vehicle from "./Pages/Vehicle";
import MaintenanceDashboard from "./Pages/MaintenanceDashboard";
import ExDashboard from "./Pages/ExDashboard";
import Payment from "./Pages/NewPayment/payment";
import MissingItems from "./Pages/MissingItems";
import MissedOpportunities from "./Pages/MissedOpportunities";
import Offers from "./Pages/Offers";
import B2C from "./Pages/B2C";
import Simah from "./Pages/Simah";
import ScheduleReportsAndNotifications from './Pages/ScheduleReportsNotifications'
import Triggers from "./Pages/Triggers";

const onMissingTranslation = ({ translationId }) => translationId;
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whiteLabling: "",
      callResponse: false,
      isDown: false,
      brandingCallInProcess: false,
    };
    this.props.initialize({
      languages: [
        { name: "English", code: "en" },
        { name: "French", code: "fr" },
        { name: "Urdu", code: "ur" },
        { name: "Arabic", code: "ar" },
        { name: "Albanian", code: "sq" },
        { name: "Burmese", code: "my" },
        { name: "Chinese", code: "zh" },
        { name: "Central Khmer", code: "km" },
        { name: "German", code: "de" },
        { name: "Gujarati", code: "gu" },
        { name: "Hebrew", code: "he" },
        { name: "Hindi", code: "hi" },
        { name: "Hungarian", code: "hu" },
        { name: "Indonesian", code: "id" },
        { name: "Italian", code: "it" },
        { name: "Japanese", code: "ja" },
        { name: "Korean", code: "ko" },
        { name: "Mongolian", code: "mn" },
        { name: "Nepali", code: "ne" },
        { name: "Persian", code: "fa" },
        { name: "Portuguese", code: "pt" },
        { name: "Russian", code: "ru" },
        { name: "Serbian", code: "sr" },
        { name: "Spanish", code: "es" },
        { name: "Telugu", code: "te" },
        { name: "Thai", code: "th" },
        { name: "Turkish", code: "tr" },
        { name: "Amharic", code: "am" },
        { name: "Kurdish", code: "ku" },
      ],
      //translation: en,
      options: {
        renderToStaticMarkup: false,
        defaultLanguage: "en",
        onMissingTranslation,
      },
    });

    this.props.addTranslationForLanguage(en, "en");
    this.props.addTranslationForLanguage(fr, "fr");
    this.props.addTranslationForLanguage(urdu, "ur");
    this.props.addTranslationForLanguage(arabic, "ar");
    this.props.addTranslationForLanguage(albanian, "sq");
    this.props.addTranslationForLanguage(burmese, "my");
    this.props.addTranslationForLanguage(chinese, "zh");
    this.props.addTranslationForLanguage(cambodian, "km");
    this.props.addTranslationForLanguage(german, "de");
    this.props.addTranslationForLanguage(gujarati, "gu");
    this.props.addTranslationForLanguage(hebrew, "he");
    this.props.addTranslationForLanguage(hindi, "hi");
    this.props.addTranslationForLanguage(hungarian, "hu");
    this.props.addTranslationForLanguage(indonesian, "id");
    this.props.addTranslationForLanguage(italian, "it");
    this.props.addTranslationForLanguage(japanese, "ja");
    this.props.addTranslationForLanguage(korean, "ko");
    this.props.addTranslationForLanguage(mongolian, "mn");
    this.props.addTranslationForLanguage(nepali, "ne");
    this.props.addTranslationForLanguage(persian, "fa");
    this.props.addTranslationForLanguage(portuguese, "pt");
    this.props.addTranslationForLanguage(russian, "ru");
    this.props.addTranslationForLanguage(serbian, "sr");
    this.props.addTranslationForLanguage(spanish, "es");
    this.props.addTranslationForLanguage(telugu, "te");
    this.props.addTranslationForLanguage(thai, "th");
    this.props.addTranslationForLanguage(turkish, "tr");
    this.props.addTranslationForLanguage(amharic, "am");
    this.props.addTranslationForLanguage(kurdish, "ku");
  }
  componentWillMount() {
    this.fetchBranding();
  }

  fetchBranding = () => {
    fetch(`/api/accounts/labeling?hostUrl=${window.location.host}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
              this.setState({
                whiteLabling: res.data,
                callResponse: true,
                brandingCallInProcess: false,
              });
              document.title = res.data.serviceTitle;
              let domElements = document.getElementById("favicon");
              if (res.data && res.data.favIcon && domElements) {
                domElements.setAttribute("href", res.data.favIcon);
              }
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.setState({
                callResponse: true,
              });
            } else {
              this.setState({
                callResponse: true,
                brandingCallInProcess: false,
              });
            }
          });
        } else {
          //alert('Server is Down')
          this.setState({
            callResponse: true,
          });
          console.log("Server is Down");
          throw response;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  render() {
    if (
      window.location.pathname === "/logout" &&
      this.state.brandingCallInProcess
    ) {
      this.setState(
        {
          brandingCallInProcess: true,
        },
        () => this.fetchBranding()
      );
    }
    if (this.state.callResponse) {
      return (
        <div className="fms">
          <Switch>
            <Route
              exact
              path="/"
              render={(routeProps) => (
                <Redirect
                  from="/"
                  to="/login"
                /> /*<Users {...routeProps} {...this.state} />*/
              )}
            />
            {/* <Route
              exact
              path='/dashboard'
              render={routeProps => (
                <Dashboard {...routeProps} {...this.state} />
              )}
            /> */}

             <Route
              exact
              path="/triggers"
              render={(routeProps) => (
                <Triggers {...routeProps} {...this.state} />
              )}
            />

            <Route
              exact
              path="/schedule-Reports-And-Notifications"
              render={(routeProps) => (
                <ScheduleReportsAndNotifications {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/accounts"
              render={(routeProps) => (
                <Account {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/maintenance_dashboard"
              render={(routeProps) => (
                <MaintenanceDashboard {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/ex_dashboard"
              render={(routeProps) => (
                <ExDashboard {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/offers"
              render={(routeProps) => (
                <Offers {...routeProps} {...this.state} />
              )}
            />
            
            <Route
              exact
              path="/accountSettings"
              render={(routeProps) => (
                <AccountSettings {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/users"
              render={(routeProps) => <Users {...routeProps} {...this.state} />}
            />
              <Route
              exact
              path="/b2c"
              render={(routeProps) => <B2C {...routeProps} {...this.state} />}
            />

            {/* <Route
              exact
              path='/payment'
              render={routeProps => <Payment {...routeProps} {...this.state} />}
            /> */}
            <Route
              exact
              path="/new_payment"
              render={(routeProps) => (
                <NewPayment {...routeProps} {...this.state} />
              )}
            />
              <Route
              exact
              path="/payment-detail"
              render={(routeProps) => (
                <Payment {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/new_payment/:id"
              render={(routeProps) => (
                <NewPayment {...routeProps} {...this.state} />
              )}
            />

            <Route
              exact
              path="/vehicle"
              render={(routeProps) => (
                <Vehicle {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/users/view"
              render={(routeProps) => (
                <UserView {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/users/:id"
              render={(routeProps) => <Users {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path="/users/edit/:userId"
              render={(routeProps) => (
                <Users {...routeProps} {...this.state} addUserScreen={true} />
              )}
            />
            <Route
              exact
              path="/units"
              render={(routeProps) => (
                <Devices {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/units/:id"
              render={(routeProps) => (
                <Devices {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/maic-integration"
              render={(routeProps) => (
                <MaicIntegration {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/units/create/:userId"
              render={(routeProps) => (
                <Devices {...routeProps} {...this.state} addUnitScreen={true} />
              )}
            />
             <Route
              exact
              path="/missing-items"
              render={(routeProps) => (
                <MissingItems {...routeProps} {...this.state} addUnitScreen={true} />
              )}
            />
            <Route
              exact
              path="/missed-opportunities"
              render={(routeProps) => (
                <MissedOpportunities {...routeProps} {...this.state} addUnitScreen={true} />
              )}
            />
            {/* <Route
              exact
              path="/groups"
              render={(routeProps) => (
                <Groups {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/groups/:id"
              render={(routeProps) => (
                <Groups {...routeProps} {...this.state} />
              )}
            /> */}
            <Route
              exact
              path="/accountManagements"
              render={(routeProps) => (
                <AccountManagements {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/serverSettings"
              render={(routeProps) => (
                <ServerSettings {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/login"
              render={(routeProps) => <Login {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path="/logout"
              render={(routeProps) => (
                <LogOut {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/systemLogs"
              render={(routeProps) => (
                <SystemLogs {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/actionLogs"
              render={(routeProps) => (
                <ActionLogs {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/roleManagement"
              render={(routeProps) => (
                <RoleManagement {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/roleManagement/:id"
              render={(routeProps) => (
                <RoleManagement {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/contractManagement"
              render={(routeProps) => (
                <ContractManagment {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/contractManagement/:id"
              render={(routeProps) => (
                <ContractManagment {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/invoice"
              render={(routeProps) => (
                <Invoice {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/invoice/:id"
              render={(routeProps) => (
                <Invoice {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/invoiceDetail"
              render={(routeProps) => (
                <InvoiceDetail
                  {...this.props}
                  {...routeProps}
                  {...this.state}
                />
              )}
            />
             <Route
              exact
              path="/maintenance-invoice-detail"
              render={(routeProps) => (
                <MaintenanceInvoiceDetail
                  {...this.props}
                  {...routeProps}
                  {...this.state}
                />
              )}
            />
            <Route
              exact
              path="/delivery-note"
              render={(routeProps) => (
                <DeliveryNote {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/delivery-note/:id"
              render={(routeProps) => (
                <DeliveryNote {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/delivery-detail"
              render={(routeProps) => (
                <DeliveryDetail
                  {...this.props}
                  {...routeProps}
                  {...this.state}
                />
              )}
            />
            <Route
              exact
              path="/plans"
              render={(routeProps) => <Plans {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path="/activationCode"
              render={(routeProps) => (
                <ActivationCode {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/templateGenerator"
              render={(routeProps) => (
                <TemplateGenerator {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/trash"
              render={(routeProps) => <Trash {...routeProps} {...this.state} />}
            />
            <Route
              exact
              path="/coupon"
              render={(routeProps) => (
                <Coupon {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/commutator"
              render={(routeProps) => (
                <Commutator {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/credit_debit_notes"
              render={(routeProps) => (
                <CreditDebit {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/workshops"
              render={(routeProps) => (
                <Workshops {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/reports"
              render={(routeProps) => (
                <Reports {...routeProps} {...this.state} />
              )}
            />
             <Route
              exact
              path="/simah"
              render={(routeProps) => (
                <Simah {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/quotation"
              render={(routeProps) => (
                <Quotations {...routeProps} {...this.state} />
              )}
            />
            <Route
              exact
              path="/webHooks"
              render={(routeProps) => (
                <WebHooks {...routeProps} {...this.state} />
              )}
            />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </div>
      );
    } else {
      if (this.state.isDown) {
        return (
          <h2 style={{ textAlign: "center" }}>
            Oops Something Went Wrong Try Again
          </h2>
        );
      } else {
        return null;
      }
    }
  }
}
export default withLocalize(App);
