import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Loader from "../../Layout/Loader";
import Scrollbar from "react-scrollbars-custom";
import Button from "../common/Button";
import TextField from "../common/TextField";
import MenuItem from "../common/MenuItem";
import { GetApp } from "@material-ui/icons";

let contractTypes = [
  { id: "Y", name: "Yes" },
  { id: "N", name: "No" },
];

export default class simahFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: "",
      to: "",
      isSubmitBtn: false,
      isVisible:false,
      financialstorage:"N",
      contractNumber:"",
      accType:""
    };
  }

 
  

  handleChange = (name, e) => {
      this.setState({
        [name]:e.target.value,
      });
  };


  checkRequiredFields = () => {
    const { to,from,financialstorage,accType } =this.state;   
    if ( to && from && financialstorage && accType) {
        this.setState({ isVisible: true });
      }
      else {
        this.setState({ isVisible: false });
      }
  };

  render() {
    const viewScreen = (
      <form style={{ padding: "16px 0 16px 10px" }}>
        {
          <>
            <Grid container className="form-group sidebar-bg-layer">

              <Grid item xs={12} style={{ padding: 10 }}>
                  <h4 style={{ margin: 10 }}>
                  {this.props.translate("Member ID")}: {"PETR"}
                  </h4>
              </Grid>
              <Grid item xs={12} style={{ padding: 10 }}>
                <TextField
                id="financialstorage"
                select
                label={this.props.translate("financialstorage")}
                value={this.state.financialstorage || ""}
                onChange={(e) => this.handleChange("financialstorage", e)}
                SelectProps={{
                  MenuProps: {
                    // className: classes.menu,
                  },
                }}
                margin="dense"
                fullWidth
                // helperText={this.props.roleTypes.length ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
                required
              >
                  {contractTypes.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {this.props.translate(option.name)}
                    </MenuItem>
                  ))}
              </TextField>
              </Grid>
              <Grid item xs={12} style={{display:"flex", justifyContent:"end",  padding:"0px 50px"}}>
                    {/* <Button
                      onClick={(e) => this.props.downloadExcel(this.state.financialstorage, "excel")}
                    >
                   {this.props.translate("SIMAH Excel")}
                    </Button> */}
                    <Button
                      // disabled={this.state.isSubmitBtn ? "" : "disabled"}
                      variant="contained"
                      color="primary"
                      // downloadInvoices={this.props.downloadInvoices}
                      onClick={(e) => this.props.downloadExcel(this.state.financialstorage, "excel")}
                      style={{
                        margin: 5,
                        height: "30px",
                        // width: "60px",
                      }}
                    >
                      <GetApp />
                      {this.props.translate("SIMAH Excel")}
                    </Button>
              </Grid>
            </Grid>
          </>
        }
      </form>
    );

    return (
      <div className="geofence-sidebar-list">
        {!this.props.loader ? (
          <Scrollbar>{viewScreen}</Scrollbar>
        ) : (
          <Loader component="filter" />
        )}
      </div>
    );
  }
}
