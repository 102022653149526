import React, { PureComponent } from "react";
import Menu from "./CommonMenu";
import MenuItem from "./MenuItem";
import { withLocalize } from "react-localize-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { connect } from "react-redux";
import { Tooltip } from "@material-ui/core";
import Style from "style-it";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Button from "./Button";
import ExportExcel from "./ExportFile";
import ImportFile from "./ImportExcel";
import { checkPrivileges } from "../../Helpers";

class ExportImportMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      enableImportServicesPopup: false,
      importOption: false,
      vehicleStatusUpload: false

    };
    this.importDevice = this.importDevice.bind(this);
  }
  importDevice(name, e) {
    this.setState({
      importOption: true,
      vehicleStatusUpload: name === "vehicleStatus" ? true : false
    });
  }
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onCloseServiceModel = () => {
    this.setState({
      enableImportServicesPopup: false,
      importOption: false,
    });
  };
  onOpenModal = (name, e) => {
    this.setState({
      enableImportServicesPopup: true,
    });
  };

  render() {
    const { anchorEl } = this.state;
    const empty = null;
    const { data, fileName, checkPrivilege } = this.props;
    return (
      <Style>
        {`
      .main-menu-list-wrapper {
        background-color: ${this.props.themecolors.themeLightColor};
      }
      a {
        color: inherit
      }
      `}
        <div>
          <a
            href={empty}
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            disabled={this.props.disabled}
            onClick={this.props.disabled ? "" : this.handleClick}
          >
            {this.props.isServices ? (
              <Button
                style={{
                  marginLeft: 10,
                  paddingTop: 0.5,
                  paddingBottom: 0.5,
                  paddingRight: 0.5,
                  minheight: 28,
                }}
                disabled={this.props.disabled}
              >
                {this.props.translate("select")}
                <KeyboardArrowDownIcon
                  style={{
                    backgroundColor: "rgba(4, 82, 102,0.4)",
                    marginLeft: 6,
                    width: 20,
                    height: 26,
                    borderRadius: 4,
                  }}
                  disabled={this.props.disabled}
                />
              </Button>
            ) : (
              <MoreVertIcon
                style={{
                  fontSize: 26,
                  marginRight: -8,
                  verticalAlign: "middle",
                }}
              />
            )}
          </a>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            themecolors={this.props.themecolors}
          >
            {!this.props.category &&
              fileName !== "vehicles" &&
              fileName !== "invoices" &&
              fileName !== "deliverynotes" &&
              fileName !== "payments" && (
                <ExportExcel
                  type="Sample"
                  fileName={fileName}
                  data={data}
                  title={this.props.translate("downloadSample")}
                  sample={true}
                  dispatch={this.props.dispatch}
                  translate={this.props.translate}
                  downloadType={"expensetypes/subtypes"}
                  services
                />
              )}

           
            {fileName === "deliverynotes" ? (
              <ExportExcel
                type="Sample"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadSample")}
                sample={true}
                dispatch={this.props.dispatch}
                translate={this.props.translate}
                downloadType={"deliverynotes"}
                services
              />
            ) : null}
            {fileName === "vehicles"  || fileName === "category" ? (
              <ExportExcel
                type="Sample"
                fileName={fileName}
                data={data}
                title={ fileName === "vehicles" ?
                        this.props.translate("downloadSample") :
                        fileName === "category" ? 
                        this.props.translate("downloadSamplev2") : 
                        null}
                sample={true}
                dispatch={this.props.dispatch}
                translate={this.props.translate}
                downloadType={fileName === "vehicles" ? "vehicles" :
                              fileName === "category" ? "reportV2" : null }
                logInUser={this.props.logInUser}
                services
              />
            ) : null}
            {fileName === "vehicles" ? (
              <>
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadExcel")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"vehicles"}
                expensetypeId={this.props.expensetypeId}
                services
              />
               <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("Download FuelTypes")}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"vehicles/fueltypes"}
                expensetypeId={this.props.expensetypeId}
                services
              />
                <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadVehicleEntitleMent")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                reportType="entitlement"
                downloadType={"entitlement"}
                expensetypeId={this.props.expensetypeId}
                services
              />
               <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("Download Categories")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"vehicles/categories"}
                expensetypeId={this.props.expensetypeId}
                categoriesOnly={this.state.categoriesOnly}
                services
              />
                <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("Download MakeModel")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"vehicles/makemodel"}
                expensetypeId={this.props.expensetypeId}
                categoriesOnly={this.state.categoriesOnly}
                services
              />
                 <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadCarbonFootprint")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                reportType="carbonfootprint"
                downloadType={"carbonfootprint"}
                expensetypeId={this.props.expensetypeId}
                services
              />
                <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadVehicleUtilization")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                reportType="vehicle-utilization"
                downloadType={"utilization"}
                expensetypeId={this.props.expensetypeId}
                services
              />
               <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data ? data : []}
                title="downloadStatus"
                sample={false}
                dispatch={this.props.dispatch}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"vehicles/status"}
                // expensetypeId={this.props.expensetypeId}
                services
              />
              </>
            ) : null}
            {/* {fileName === "vehicles" ? (
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("Download FuelTypes")}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"vehicles/fueltypes"}
                expensetypeId={this.props.expensetypeId}
                services
              />) : null} */}
                  {/* {fileName === "vehicles" ? (
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadVehicleEntitleMent")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                reportType="entitlement"
                downloadType={"entitlement"}
                expensetypeId={this.props.expensetypeId}
                services
              />
            ) : null} */}
             
            {/* {fileName === "vehicles"? (
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("Download Categories")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"vehicles/categories"}
                expensetypeId={this.props.expensetypeId}
                categoriesOnly={this.state.categoriesOnly}
                services
              />
            ) : null} */}
            {/* {fileName === "vehicles"? (
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("Download MakeModel")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"vehicles/makemodel"}
                expensetypeId={this.props.expensetypeId}
                categoriesOnly={this.state.categoriesOnly}
                services
              />
            ) : null} */}
                
            {fileName === "expensetypes/subtypes" ? (
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadExcel")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"expensetypes/subtypes"}
                expensetypeId={this.props.expensetypeId}
                services
              />
            ) : null}

            {fileName === "invoices" ? (
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadExcel")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"invoices"}
                expensetypeId={this.props.expensetypeId}
                services
              />
            ) : null}
           
            {fileName === "category" ? (
              <ExportExcel
                type="Sample"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadSample")}
                sample={true}
                dispatch={this.props.dispatch}
                translate={this.props.translate}
                downloadType={"expensetypes"}
                services
              />
            ) : null}

            {fileName === "payments" ? (
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadExcel")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"payments"}
                expensetypeId={this.props.expensetypeId}
                services
              />
            ) : null}

            {fileName === "vehicles" && !this.props.noPdf ? (
              <ExportExcel
                type="PDF"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadPDF")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"vehicles"}
                expensetypeId={this.props.expensetypeId}
                services
              />
            ) : null}

            {fileName === "invoices" && !this.props.noPdf ? (
              <ExportExcel
                type="PDF"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadPDF")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"invoices"}
                expensetypeId={this.props.expensetypeId}
                services
              />
            ) : null}
            {fileName === "deliverynotes" && !this.props.noPdf ? (
              <ExportExcel
                type="PDF"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadPDF")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                downloadType={"deliverynotes"}
                expensetypeId={this.props.expensetypeId}
                services
              />
            ) : null}

             {fileName === "deliverynotes"  &&  checkPrivileges('deliverynoteCreate') ? (
              <Tooltip title={this.props.translate("uploadExcel")}>
                <MenuItem
                  style={{ minWidth: "inherit" }}
                  component="label"
                  onClick={this.importDevice}
                >
                  {this.props.translate("uploadExcel")}
                </MenuItem>
              </Tooltip>
            ) : null}

           {fileName === "vehicles" ? (
              <Tooltip title={this.props.translate("uploadExcelStatus")}>
                <MenuItem
                  style={{ minWidth: "inherit" }}
                  component="label"
                  onClick={(e)=>this.importDevice("vehicleStatus",e)}
                >
                  {this.props.translate("uploadExcelStatus")}
                </MenuItem>
              </Tooltip>
            ) : null}

            {fileName === "vehicles" || fileName === "category"  ? (
              <Tooltip title={fileName === "vehicles" ? 
                              this.props.translate("uploadExcel"): 
                              fileName === "category"  ?
                              this.props.translate("uploadExcelv2") : 
                              null
                              }>
                <MenuItem
                  style={{ minWidth: "inherit" }}
                  component="label"
                  onClick={this.importDevice}
                >
                  {fileName === "vehicles" ? 
                              this.props.translate("uploadExcel"): 
                              fileName === "category"  ?
                              this.props.translate("uploadExcelv2") : 
                              null
                              }
                </MenuItem>
              </Tooltip>
            ) : null}
            {/* {fileName === "vehicles" ? (
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadCarbonFootprint")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                reportType="carbonfootprint"
                downloadType={"carbonfootprint"}
                expensetypeId={this.props.expensetypeId}
                services
              />
            ) : null} */}
            {/* {fileName === "vehicles" ? (
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("downloadVehicleUtilization")}
                sample={false}
                dispatch={this.props.dispatch}
                isMaintenance={this.props.isMaintenance}
                ServerSetting={this.props.ServerSetting}
                logInUser={this.props.logInUser}
                translate={this.props.translate}
                reportType="vehicle-utilization"
                downloadType={"utilization"}
                expensetypeId={this.props.expensetypeId}
                services
              />
            ) : null} */}
            
            {fileName !== "users" &&
            checkPrivileges(checkPrivilege || "") &&
            fileName !== "invoices" &&
            fileName !== "deliverynotes" &&
            fileName !== "vehicles" &&
            fileName !== "payments" ? (
              <Tooltip title={this.props.translate("uploadExcel")}>
                <MenuItem
                  style={{ minWidth: "inherit" }}
                  component="label"
                  onClick={this.onOpenModal}
                >
                  {this.props.translate("uploadExcel")}
                </MenuItem>
              </Tooltip>
            ) : null}
          </Menu>

          {this.state.importOption && (
            <ImportFile
              fileName={this.props.fileName}
              onClose={this.onCloseServiceModel}
              Option={this.state.importOption}
              openImportTable={this.props.openImportTable}
              v2Report={fileName === "category" ? true : false }
              vehicleStatusUpload={this.state.vehicleStatusUpload}
              // expensetypeId={this.props.expensetypeId}
              // service
              // selectedAccount={this.props?.selectedAccount || ''}
              // fetchAccountServices={this.props.fetchAccountServices}
            />
          )}

          {this.state.enableImportServicesPopup && (
            <ImportFile
              fileName={this.props.category ? "category" : "services"}
              onClose={this.onCloseServiceModel}
              expensetypeId={this.props.expensetypeId}
              service
              openImportTable={this.props.openImportTable}
              selectedAccount={this.props?.selectedAccount || ""}
              fetchAccountServices={this.props.fetchAccountServices}
            />
          )}
        </div>
      </Style>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)(withLocalize(ExportImportMenu));
