import React, { Component } from "react";
import axios from "axios";
import Tooltip from "@material-ui/core/Tooltip";
// import MenuItem from '../common/MenuItem'
import Checkbox from "../common/Checkbox";
import { errorHandler } from "../../Helpers";
// import CustomDialog from './Dialog';
import Stepper from "../Invoice/Stepper";
import moment from "moment";
import DateRangePicker from "../Invoice/DateRangePicker";
// import TextField from './TextField';
import { Grid, FormControlLabel } from "@material-ui/core";
import Loader from "../../Layout/Loader";
// import Button from './Button';
import VehicleSelector from "../Invoice/VehicleSelector";
import AccountSelector from "../Invoice/AccountSelector";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// // // import Menu from '../Menu/CommonMenu'
import AreaSelector from "../Invoice/AreaSelector";
import Scrollbar from "react-scrollbars-custom";
import Button from "../common/Button";
import { GetApp } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "../common/TextField";
import MenuItem from "../common/MenuItem";
import SingleSelect from "../common/SingleSelect";

let contractTypes = [
  { id: "packages", name: "Packages" },
  { id: "services", name: "Services" },
  { id: "items", name: "Items" },
];
let accountTypes=[
  {id:"maintenance",name:"Maintenance"},
  {id:"cash",name:"Cash"},
]
export default class missingItemsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: "",
      to: "",
      isSubmitBtn: false,
      isVisible:false,
      expenseType:"",
      contractNumber:"",
      accType:""
    };
  }

 
  showDates = (e, date) => {
    // console.log("e, data=====", e, date);
  
    // Adjust the end time to 23:59:59.999
    const adjustedEndDate = date.endDate.clone().set({ hour: 23, minute: 59, second: 59, millisecond: 999 });
  
    // Calculate duration if needed
    const duration = moment.duration(date.startDate.diff(adjustedEndDate));
    const hours = duration.asHours();
  
    // Determine the appropriate time zone
    let timezone = "Asia/Riyadh";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);
  
    // Formatted date objects
    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: adjustedEndDate.toISOString(),
    };
  
    // Determine time format
    let timeFormat = "HH:mm";
    if (this.props.logInUser && this.props.logInUser.twelveHourFormat) {
      timeFormat = "hh:mm A";
    }
  
    // Update state with selected date range and formatted dates
    this.setState({
      selectedDate:
        moment(date.startDate).format("YYYY-MM-DD " + timeFormat) +
        " - " +
        adjustedEndDate.format("YYYY-MM-DD " + timeFormat),
      ...formatedDate,
    });
  };

  handleChange = (name, e) => {
  
    if(name==="expenseType" || name ==="accType"){
      this.setState({
        [name]:e.target.value,
      });
    }else if(name==="clearFilter"){
        this.setState({
          from: "",
          to: "",
          selectedDate:"",
          expenseType:"",
          accType:""
         },()=>{this.validatedForm();
        })    
    }
    else{
      this.setState({
           contractNumber : e
      })
    }
  
  };

  validatedForm = () => {
   
    this.props.getMissingItems(this.state.contractNumber.contractNumber, this.state.to, this.state.from, this.state.expenseType,this.state.accType)
   
  };

  checkRequiredFields = () => {
    const { to,from,expenseType,accType } =this.state;   
    if ( to && from && expenseType && accType) {
        this.setState({ isVisible: true });
      }
      else {
        this.setState({ isVisible: false });
      }
  };
  // handleChange = (e)=>{
  //   // this.setState({ contractNumber: false });

  // }

  render() {
    const viewScreen = (
      <form style={{ padding: "16px 0 16px 10px" }}>
        {
          <>
            <Grid container className="form-group sidebar-bg-layer">

              <Grid item xs={12} style={{ padding: 10 }}>
                  <h4 style={{ margin: 10 }}>
                  {this.props.translate("Out of Contract Items")}: {this.props.selectedAccount.accountName}
                  </h4>

           {/* <Grid item xs={12} style={{ padding: 10,marginBottom:"10px" }}>
              <TextField
              id="accType"
              select
              label={this.props.translate("Account Type")}
              value={this.state.accType || ""}
              onChange={(e) => this.handleChange("accType", e)}
              SelectProps={{
                MenuProps: {
                  // className: classes.menu,
                },
              }}
              margin="dense"
              fullWidth
              // helperText={this.props.roleTypes.length ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
              required
            >
              {accountTypes.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {this.props.translate(option.name)}
                </MenuItem>
              ))}
            </TextField>
              </Grid> */}


                <DateRangePicker
                  fill
                  error={this.props.timeError || false}
                  selectedDate={this.state.selectedDate}
                  label={
                    this.props.translate("reportFrom") +
                    " " +
                    this.props.translate("reportTo")
                  }
                  onEvent={this.showDates}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: 10 }}>
              <TextField
              id="expenseType"
              select
              label={this.props.translate("category")}
              value={this.state.expenseType || ""}
              onChange={(e) => this.handleChange("expenseType", e)}
              SelectProps={{
                MenuProps: {
                  // className: classes.menu,
                },
              }}
              margin="dense"
              fullWidth
              // helperText={this.props.roleTypes.length ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
              required
            >
              {contractTypes.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {this.props.translate(option.name)}
                </MenuItem>
              ))}
            </TextField>
              </Grid>
              {/* <Grid item xs={12} style={{ padding: 10 }}>
              { this.props && this.props.accounts?.data?.length >=0 ? 
                  <SingleSelect
                    array={this.props && this.props.accounts?.data || []}
                    async
                    selectName="contractNumber"
                    isClearable
                    label={this.props.translate("selectedAccount")}
                    value={
                      this.state.contractNumber &&
                      this.state.contractNumber.id
                        ? {
                            id: this.state.contractNumber,
                            value:
                              this.state.contractNumber.contractNumber,
                            label:this.state.contractNumber.label,
                          }
                        : ""
                    }
                    // value={this.props.form.contractNumbers || ""}
                    handleChange={this.handleChange}
                    canAssign={true}
                  />
                  : <Loader component="filter" />
             }
              </Grid> */}

              <Grid item xs={12}>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: 20,
                    }}
                  >
                    <Button
                      onClick={(e) => this.validatedForm("", "", "")}
                      style={{
                        // margin: 5,
                        height: "40px",
                        width: "110px",
                      }}
                      // disabled={!this.state.isVisible }
                    >
                   {this.props.translate("submit")}
                    </Button>
                    <Button
                       style={{
                      //  margin: 5,
                        height: "40px",
                        width: "110px",
                     }}
                      id="clearFilter"
                      onClick={(e) => this.handleChange("clearFilter", e)}
                    >
                   {this.props.translate("Clear Filters")}
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </>
        }
      </form>
    );

    return (
      <div className="geofence-sidebar-list">
        {/* <Scrollbar>{viewScreen}</Scrollbar> */}
        {!this.props.loader ? (
          <Scrollbar>{viewScreen}</Scrollbar>
        ) : (
          <Loader component="filter" />
        )}
      </div>
    );
  }
}
