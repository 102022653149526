import React, { Component, Fragment } from "react";
import SimpleModalWrapped from "../common/Modal";
import { withStyles } from "@material-ui/core/styles";

import { Grid, Typography } from "@material-ui/core";
import Checkbox from './../common/Checkbox'

import { withLocalize } from "react-localize-redux";
import TextField from "../common/TextField";
import AccountSelector from "../Invoice/NewAccountSelector";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});
const SendOptions = [
  // {
  //   key: "whatsapp",
  //   name: "WhatsApp",
  // },
  // {
  //   key: "firebase",
  //   name: "Petromin Mobile App Notifications",
  // },
  {
    key: "mail",
    name: "email",
  },
];

let contractTypes = [
  { id: 4, name: "All" },
  { id: 1, name: "Maintenance" },
  { id: 2, name: "Leasing" },
  { id: 3, name: "Cash" },
];
class addCommutatorModal extends Component {
  constructor() {
    super();
    this.state = {
      contractNumber: [],
      selectedaccountId: [],
      accounts: "",
      newUser: [],
    };
  }
  handleChange = (name, e, check) => {
  this.setState({
    contractNumber: name,
    // newUser: e,
  });
  this.setState({ contractNumber: name }, () => {
    let filter = [];
    Object.entries(this.state.contractNumber).map(([key, value]) => {
      if (value) {
        filter.push(value);
        this.setState({});
      }
    });
    this.setState({
      selectedaccountId: filter,
    });
  });

}
  render() {
    // const {form}=this.props.data;

    // const defaultZones = []
    // timeZonesTypes.map((item, i) => {
    //   if (i <= 9) {
    //     defaultZones.push({
    //       id: item.key,
    //       key: item.key,
    //       label: item.key,
    //       value: item.key
    //     })
    //   }
    // })
   
    return (
      <Fragment>
        <SimpleModalWrapped
          {...this.state}
          {...this.props}
          visable
          isButtonVisable
          title={this.props.title}
          isNotShowAttributesBtn
          formSubmit={this.props.formSubmit}
          // addAttributes={this.addAttributes}
          modalControle={this.props.modalControle}
          btnclosetext={this.props.translate("sharedBack")}
          buttonText={this.props.buttonText}
          // isVisableBtn={true}
          // isVisableBtn={true}
          isVisableBtn={this.props.isVisableAddBtn}

          notVisableBackbtn={true}
          // showPortList={this.props.showPortList}
          // showPort={this.props.showPort}
          content={
            <>
              <Grid container spacing={1}>
              <Grid item xs={12} sm={8} md={5} lg={5}>

              <Grid item xs={12} md={12} sm={12}>
                    <TextField
                      id="title"
                      required
                      margin="dense"
                      label={this.props.translate("title")}
                      variant="outlined"
                      fullWidth
                      value={this.props.data?.title || ""}
                      onChange={this.props.handleChange("title")}
                    />
                  </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    id="message"
                    required
                    margin="dense"
                    label={this.props.translate("msg")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.message || ""}
                    onChange={this.props.handleChange("message")}
                    multiline={true}
                    maxRows={30}
                    minRows={5}
                  />
                </Grid>
                </Grid>
                <Grid item xs={12} sm={8} md={3} lg={3}>
                <Grid item md={12} sm={12} xs={12}>
             {/* <TextField
              id="contractType"
              select
              label={this.props.translate("contractType")}
              value={this.props.contractType || ""}
              onChange={this.props.handleChange("contractType")}
              SelectProps={{
                MenuProps: {
                  // className: classes.menu,
                },
              }}
              margin="dense"
              fullWidth
              // helperText={this.props.roleTypes.length ? <span onClick={this.toggleRoles} style={{cursor: 'pointer'}}><b>Select Custom Role</b></span> : ''}
              required
            >
              {contractTypes.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {this.props.translate(option.name)}
                </MenuItem>
              ))}
            </TextField>  */}
            </Grid>

                <AccountSelector
                  // contractType={this.props.contractType}
                  comapigns
                  // api="accounts"
                  // newUser={this.props.newUser}
                  fill
                  hideIcons
                  isClearable
                  placeholder={this.props.translate("searchAccount")}
                  onChange={this.props.handleChangeAccount}
                  // value={this.state?.contractNumber || []}
                  // contractType = {1}
                />
                </Grid>

                <Grid item xs={12} sm={8} md={3} lg={3}>
                <Typography variant="h6" gutterBottom 
                      style={{ fontSize: "16px", fontWeight: 700 , marginLeft:20}}>
                        {this.props.translate("sendMessageVia")}
                        </Typography>
                <ul className="selector-list">
                {SendOptions.map((row) => (
                  <li key={row.key}>
                      <span className="selector-check">
                                  <Checkbox
                                    // checked={this.state.vehicles[row.id]}
                                    value={row.key}
                                    checked={this.props?.data?.notificators?.includes(row.key) || ""}
                                    onChange={this.props.handleChangeMode}
                                    
                                    // onClick={() => this.props.selecteItem(this.props.data)}
                                  />
                                </span>
                                <span className="selector-label">
                                  {this.props.translate(row.name)}
                                </span>
                  </li>
                ))}
              </ul>

                {/* <ul className="selector-list">
                        {SendOptions &&
                          SendOptions.map((row) => (
                            <li key={row.key}>
                              <label
                                className={
                                  SendOptions[row.key] ? "active" : null
                                }
                              >
                                <span className="selector-check">
                                  <Checkbox
                                    // checked={this.state.vehicles[row.id]}
                                    value={row}
                                    onChange={this.props.handleChangeMode}
                                    // onClick={() => this.props.selecteItem(this.props.data)}
                                  />
                                </span>
                                <span className="selector-label">
                                  {row.name}
                                </span>
                              </label>
                            </li>
                          ))}
                      </ul> */}
                   </Grid>

               
              
              </Grid>
            </>
          }
        />
      </Fragment>
    );
  }
}

const AddCommutatorModal = withStyles(styles);
export default AddCommutatorModal(withLocalize(addCommutatorModal));
