import React, { Component } from 'react'
import Notifications from 'react-notification-system-redux'
import TextField from '../../../common/TextField'
import { withStyles } from '@material-ui/core/styles'
import Button from '../../../common/Button'
import Grid from '@material-ui/core/Grid'

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  dense: {
    marginTop: 19
  },
  menu: {
    width: 200
  },
  button: {
    margin: theme.spacing(1)
  }
})

class termsAndConditions extends Component {
  constructor () {
    super()
    this.state = {
      errorMgs: false,
      form: {
        termsEnglishMaintenance: '',
        termsArabicMaintenance: '',
        termsEnglishLeasing: '',
        termsArabicLeasing: '',
        bankNameEnglish: '',
        bankHolderEnglish: '',
        accountNumberEnglish: '',
        ibanEnglish: '',
        bankNameArabic: '',
        bankHolderArabic: '',
        accountNumberArabic: '',
        ibanArabic: '',
      }
    }
    this.handleChange = this.handleChange.bind(this)
    this.formSubmit = this.formSubmit.bind(this)

    this.checkRequiredFields = this.checkRequiredFields.bind(this)
  }
  componentWillMount(){
    this.setState({
      form: {
        id:this.props.termsobj?.data?.id || 0,
        termsEnglishMaintenance: this.props.termsobj?.data?.attributes?.termsEnglishMaintenance || '',
        termsArabicMaintenance: this.props.termsobj?.data?.attributes?.termsArabicMaintenance|| '',
        termsEnglishLeasing: this.props.termsobj?.data?.attributes?.termsEnglishLeasing|| '',
        termsArabicLeasing: this.props.termsobj?.data?.attributes?.termsArabicLeasing|| '',
        bankNameEnglish: this.props.termsobj?.data?.attributes?.bankNameEnglish|| '',
        bankHolderEnglish: this.props.termsobj?.data?.attributes?.bankHolderEnglish|| '',
        accountNumberEnglish: this.props.termsobj?.data?.attributes?.accountNumberEnglish|| '',
        ibanEnglish: this.props.termsobj?.data?.attributes?.ibanEnglish|| '',
        bankNameArabic: this.props.termsobj?.data?.attributes?.bankNameArabic|| '',
        bankHolderArabic: this.props.termsobj?.data?.attributes?.bankHolderArabic|| '',
        accountNumberArabic: this.props.termsobj?.data?.attributes?.accountNumberArabic|| '',
        ibanArabic: this.props.termsobj?.data?.attributes?.ibanArabic|| ''
      },
    });
  }

  formSubmit () {
    const { selectItemId } = this.props
    let { termsEnglish, termsArabic } = this.state.form
    // if (termsEnglish.trim().length > 0 && termsArabic.trim().length > 0) {
  
    //     this.setState({
    //       errorMgs: false
    //     })
       
    // } else {
    //   this.setState({
    //     errorMgs: true
    //   })
    // }
    // if (termsEnglish.trim().length > 0 && termsArabic.trim().length > 0) {
    //   let obj = {};
    //   if (this.props.termsobj.data != null) {
    //     obj = this.props.termsobj.data;
    //     obj.attributes.termsEnglish = this.state.form.termsEnglish;
    //     obj.attributes.termsArabic = this.state.form.termsArabic;
    //   }
      const payload = {
        id: this.props.termsobj.data&&this.props.termsobj.data.id ? this.props.termsobj.data.id : 0,
        property: this.props.termsobj.data&&this.props.termsobj.data.property ? this.props.termsobj.data.property : "terms",
        entityId: this.props.termsobj.data&&this.props.termsobj.data.entityId ? this.props.termsobj.data.entityId : selectItemId.accountId,
        description: this.props.termsobj.data&&this.props.termsobj.data.description ? this.props.termsobj.data.description : "description",
        ...this.props.termsobj.data,
        attributes: { ...this.state.form },
      };
      fetch(`api/accounts/${selectItemId.accountId}/terms`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...payload,
        }),
        })
        .then((response) => {
          if (response.ok) {
            response.json().then((res) => {
              //console.log("res:",res);
              if (res.status === "success") {
                this.props.dispatch(
                  Notifications.success({
                    // message: this.props.translate('Changed'),,
                    message: "Changed",
                    autoDismiss: 5,
                    location: this.props.location,
                  })
                );
                this.setState({
                  // form: {
                  //   termsEnglish: res.data.attributes.termsEnglish?res.attributes.termsEnglish:"",
                  //   termsArabic:  res.data.attributes.termsArabic?res.attributes.termsArabic:"",
                  // },
                  errorMgs: false,
                });
              } else if (res?.statusCode === "440") {
                window.location.replace("/login");
              } else if (res.statusCode) {
                var err = res?.message.split(":");
                err[1] = err[1].replace(")", "");
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location,
                  })
                );
              }
            });
          } else {
            throw response;
          }
        })
        .catch((e) => {
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate("somethingWentWrong"),
              autoDismiss: 5,
              location: this.props.location,
            })
          );
        });
    // }
    //  else {
    //   this.props.dispatch(
    //     Notifications.error({
    //       message: this.props.translate("emptyField"),
    //       autoDismiss: 5,
    //       location: this.props.location,
    //     })
    //   );
    // }
  }

  handleChange = name => event => {
    const { target } = event

    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: target.value
        }
      }
    )
  }

  checkRequiredFields () {
    let { termsEnglish, termsArabic } = this.state.form
    if (termsEnglish.trim().length > 0 && termsArabic.trim().length > 0) {
     
        this.setState({
          errorMgs: false
        })
    
    } else {
      this.setState({
        errorMgs: true
      })
    }
  }

  render () {
    return (
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} sm={6}>
            <TextField
              id='english'
              label={this.props.translate('termsEnglishMaintenance')}
              type='text'
              placeholder=''
              error={this.state.nerrorMgs}
              value={this.state.form.termsEnglishMaintenance || ''}
              onChange={this.handleChange('termsEnglishMaintenance')}
              variant='outlined'
              margin='dense'
              fullWidth
              multiline={true}
              maxRows={30}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <TextField
              id='termsArabicMaintenance'
              error={this.state.errorMgs}
              label={this.props.translate('termsArabicMaintenance')}
              type='text'
              
              placeholder=''
              value={this.state.form.termsArabicMaintenance || ''}
              onChange={this.handleChange('termsArabicMaintenance')}
              variant='outlined'
              margin='dense'
              fullWidth
              multiline={true}
              maxRows={30}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <TextField
              id='termsEnglishLeasing'
              error={this.state.errorMgs}
              label={this.props.translate('termsEnglishLeasing')}
              type='text'
              
              placeholder=''
              value={this.state.form.termsEnglishLeasing || ''}
              onChange={this.handleChange('termsEnglishLeasing')}
              variant='outlined'
              margin='dense'
              fullWidth
              multiline={true}
              maxRows={30}
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <TextField
              id='termsArabicLeasing'
              error={this.state.errorMgs}
              label={this.props.translate('termsArabicLeasing')}
              type='text'
              
              placeholder=''
              value={this.state.form.termsArabicLeasing || ''}
              onChange={this.handleChange('termsArabicLeasing')}
              variant='outlined'
              margin='dense'
              fullWidth
              multiline={true}
              maxRows={30}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4} style={{marginTop:10}}>
          <Grid item xs={12} md={6} sm={6}>
            <TextField
              id='bankNameEnglish'
              label={this.props.translate('bankNameEnglish')}
              type='text'
              placeholder=''
              error={this.state.nerrorMgs}
              value={this.state.form.bankNameEnglish || ''}
              onChange={this.handleChange('bankNameEnglish')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <TextField
              id='bankHolderEnglish'
              error={this.state.errorMgs}
              label={this.props.translate('bankHolderEnglish')}
              type='text'
              
              placeholder=''
              value={this.state.form.bankHolderEnglish || ''}
              onChange={this.handleChange('bankHolderEnglish')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <TextField
              id='accountNumberEnglish'
              error={this.state.errorMgs}
              label={this.props.translate('accountNumberEnglish')}
              type='text'
              
              placeholder=''
              value={this.state.form.accountNumberEnglish || ''}
              onChange={this.handleChange('accountNumberEnglish')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <TextField
              id='ibanEnglish'
              error={this.state.errorMgs}
              label={this.props.translate('ibanEnglish')}
              type='text'
              
              placeholder=''
              value={this.state.form.ibanEnglish || ''}
              onChange={this.handleChange('ibanEnglish')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <TextField
              id='bankNameArabic'
              error={this.state.errorMgs}
              label={this.props.translate('bankNameArabic')}
              type='text'
              
              placeholder=''
              value={this.state.form.bankNameArabic || ''}
              onChange={this.handleChange('bankNameArabic')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <TextField
              id='bankHolderArabic'
              error={this.state.errorMgs}
              label={this.props.translate('bankHolderArabic')}
              type='text'
              
              placeholder=''
              value={this.state.form.bankHolderArabic || ''}
              onChange={this.handleChange('bankHolderArabic')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <TextField
              id='accountNumberArabic'
              error={this.state.errorMgs}
              label={this.props.translate('accountNumberArabic')}
              type='text'
              
              placeholder=''
              value={this.state.form.accountNumberArabic || ''}
              onChange={this.handleChange('accountNumberArabic')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} sm={6}>
            <TextField
              id='ibanArabic'
              error={this.state.errorMgs}
              label={this.props.translate('ibanArabic')}
              type='text'
              
              placeholder=''
              value={this.state.form.ibanArabic || ''}
              onChange={this.handleChange('ibanArabic')}
              variant='outlined'
              margin='dense'
              fullWidth
            />
          </Grid>
        </Grid>

        <br />
 
        <Button
          variant='contained'
          style={{ marginLeft: 8 }}
          // disabled={!this.state.isVisableBtn}
          onClick={() => this.formSubmit()}
        >
          {this.props.translate('sharedSave')}
        </Button>
      </div>
    )
  }
}
const TermsAndConditions = withStyles(styles)
export default TermsAndConditions(termsAndConditions)
