import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import Style from "style-it";
import { Grid } from "@material-ui/core";

class reportsList extends Component {

  render() {
    return (
      <div>
        <Style>{`
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors["600"]};
            color: ${this.props.themecolors["themeInverse"]};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected {
            background: ${this.props.themecolors["500"]};
            color: ${this.props.themecolors["themeInverse"]};
          }`}</Style>

        <Fragment>
          <Grid container className="breadcrumb-row">
            <Grid item xs={12} sm={3}>
              <h3 style={{ margin: 0 }}>{this.props.translate("monthlyInvoices&Dn")}</h3>
            </Grid>
          </Grid>
        </Fragment>
      </div>
    );
  }
}
const mapState = (state) => {
  return {
    devices: state.devices,
    logInUser: state.logInUsers,
    group: state.groups,
    themecolors: state.themecolors,
    ServerSetting: state.ServerSetting,
    parentUnitLimits: state.parentUnitLimits,
  };
};

const mapStateToProps = connect(mapState);
export const ReportsList = mapStateToProps(withLocalize(reportsList));
