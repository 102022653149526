import React, { Component, Fragment } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import CustomDialog from "../common/Dialog";
import Notifications from "react-notification-system-redux";
import { parentUnitsLimit, getDevices } from "../../Actions/Devices";
import Table from "../common/tableWithBackEndPagination";
import isEqual from "react-fast-compare";
import { checkPrivileges, PaginationConfig } from "../../Helpers";
import ConfirmDialoag from "../common/ConfirmDialoag";
// import DevicePortList from './devicePortList'
import Button from "../common/Button";
import Style from "style-it";
import {  Grid } from "@material-ui/core";
import TextField from "../common/TextField";

import moment from "moment";
import Loader from "../../Layout/Loader";

class maicintegrationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  addOption:false,
      accountstatus : '',
      vehiclestatus : ''
    };
  }
  // modalControle =()=>{
  //   this.setState({
  //   addOption:false
  // })
  // }

  // addGarages=()=>{
  //   this.setState({
  //     addOption:true
  //   })
  // }
handleVehicleIntegration = () => {
    fetch('/api/schema/vehicleintegration')
      .then(response => response.json())
      .then(data => {
        if (data && data.status) {
            const vehiclestatus = data.status;
            console.log('Status:', vehiclestatus);
            this.setState({ vehiclestatus });
        } else {
          console.error('API response is missing the "status" key.');
        }
      })
      .catch(error => {
        console.error('Error fetching data from the API:', error);
    });
}
handleAccountIntegration = () => {
    fetch('/api/schema/accountintegration')
      .then(response => response.json())
      .then(data => {
        if (data && data.status) {
            const accountstatus = data.status;
            console.log('Status:', accountstatus);
            this.setState({ accountstatus });
        } else {
          console.error('API response is missing the "status" key.');
        }
      })
      .catch(error => {
        console.error('Error fetching data from the API:', error);
      });
  }
  render() {
    // const { devices, group } = this.props;
    // let fileName = "units";
    return (
      <div>
        <Style>{`
          .accountstatusbtn {
            color: #00C179;
            background: unset;
            border: 2px solid #00C179;
          }
          .accountstatusbtn:hover {
            color: #fff;
          }
          .vehicletatusbtn {
            margin-left: 8px;
          }
          `}</Style>

        <Fragment>
         
              <Grid container className="breadcrumb-row">
                <Grid item xs={12} sm={12}>
                  <h3 style={{ margin: 0 }}>
                    {this.props.translate("MAIC Integration")}
                  </h3>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button onClick={this.handleAccountIntegration}
                    className="accountstatusbtn" variant="outlined" size='large'>
                        Send Account Status to MAIC
                    </Button>
                    <Button onClick={this.handleVehicleIntegration} className="vehicletatusbtn" variant="contained" size='large' color='inherit'>
                      Send Vehicles Status to MAIC
                    </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  style={{ justifyContent: "flex-end" }}
                >
             
              {/* <div div style={{ width: "25%", marginRight: 20 }}>
                    <TextField
                      fullWidth
                      label={this.props.translate("garageCity")}
                    //   value={this.props.garageCity}
                    //   onChange={(e) => this.props.searchContractText(e, "garageCity")}
                    />
                   
                  </div>
                  <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                      fullWidth
                      label={this.props.translate("garageType")}
                      value={this.props.garageType}
                    //   onChange={(e) => this.props.searchContractText(e, "garageType")}
                    />
                  </div>
                  <div style={{ width: "15%", marginRight: 20 }}>
                  <Button
                      aria-label="search"
                      size="small"
                      color="inherit"
                    //   disabled = {!this.props.isVisableFilterBtn}
                    //   onClick={this.props.getGarages}
                      id="search"
                    >
                      {this.props.translate("search")}
                    </Button> 
                    </div>
                    <div style={{ width: "15%", marginRight: 20 }}>
                  <Button
                      aria-label="search"
                      size="small"
                      color="inherit"
                    //   disabled = {!this.props.enableReset}
                    //   onClick={this.props.resetGarages}
                      id="search"
                    >
                      {this.props.translate("reset")}
                    </Button> 
                    </div>




               <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                    fullWidth
                    // label={this.props.translate("search")}
                    // value={this.props.searchGarage}
                    // onChange={(e) => this.props.searchContractText(e, "search")}
                  />
                </div>
                  <Button
                    disabled={!(checkPrivileges("garageCreate") ? true : false)}
                    aria-label="Create"
                    size="small"
                    color="inherit"
                    // onClick={this.props.addGarages}
                    // id="createButton"
                  >
                    {this.props.translate("create")}
                  </Button> */}
                </Grid>
              </Grid>

          

          {/* {this.props.onDeleteConfirmation && (
            <ConfirmDialoag
              onCancel={this.props.onCancel}
              onOk={this.props.deleteWorkShop}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.selectedWorkShop.name}
            />
          )} */}
        </Fragment>
      </div>
    );
  }
}
const mapState = (state) => {
  const devices = {
    ...state.devices,
    data:
      state.devices.data &&
      state.devices.data.map((d) => {
        if (d.created) {
          if (state.logInUsers.twelveHourFormat) {
            d.created = moment(d.created).format("YYYY-MM-DD hh:mm A");
          } else {
            d.created = moment(d.created).format("YYYY-MM-DD HH:mm");
          }
          return d;
        } else {
          return d;
        }
      }),
  };

  return {
    devices: state.devices,
    logInUser: state.logInUsers,
    group: state.groups,
    themecolors: state.themecolors,
    ServerSetting: state.ServerSetting,
    parentUnitLimits: state.parentUnitLimits,
  };
};

const mapStateToProps = connect(mapState);
export const Maiclist = mapStateToProps(withLocalize(maicintegrationList));
