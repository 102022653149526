import React, { Component, Fragment } from "react";
import SimpleModalWrapped from "../common/Modal";
import TextField from "../common/TextField";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// import { unitsList } from './unitsPortList'
import MenuItem from "@material-ui/core/MenuItem";
import { Grid, Button } from "@material-ui/core";
import SingleSelect from "../common/SingleSelect";
import { withLocalize } from "react-localize-redux";
import { timeZonesTypes } from "../Attributes/timezone";
import { parentUnitsLimit, getDevices } from "../../Actions/Devices";
import { checkPrivileges } from "../../Helpers";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import Table from "../../Components/common/TableServices";
import Notifications from "react-notification-system-redux";
import Checkbox from "../common/Checkbox";
import Loader from "../../Layout/Loader";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});

const PaymentModes = [
  {
    key: "cash",
    name: "Cash",
  },
  {
    key: "cheque",
    name: "Cheque",
  },
  {
    key: "NTSC AlRajhi Bank",
    name: "NTSC AlRajhi Bank",
  },
  {
    key: "PE SNB",
    name: "PE SNB",
  },
  {
    key: "NTSC Riyadh Bank",
    name: "NTSC Riyadh Bank",
  },
  {
    key:"PE AlRajhi Bank",
    name:"PE Al Rajhi Bank"
  },
  {
    key:"Petrolube",
    name:"Petrolube"
  },
  {
    key: "others",
    name: "Others",
  },
];

const AdjustmentTypes = [
  {
    key: "fifo",
    name: "FIFO",
  },
  {
    key: "manual",
    name: "MANUAL",
  },
];

class addPayment extends Component {
  constructor() {
    super();
    this.state = {
      usersData: "",
      parentId: "",
      resultMessage: "",
      defaultOption: [],
      value: [],
      isVisableAddBtn: true,
      rowsPerPage:5
    };
    this.searchFromZoneList = this.searchFromZoneList.bind(this);
    this.searchFromList = this.searchFromList.bind(this);
  }

  onChange = (name, value) => {
    this.setState({ value: value });
  };
  searchList = async (inputValue) => {
    if (!inputValue) {
      return [];
    }

    let response = await fetch(
      `/api/users/list?userId=${this.props.logInUser.id}&all=true&search=${inputValue}&limit=100`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    let json = await response.json();
    let result =
      (await json.data) &&
      json.data.data &&
      json.data.data.map((item) => ({
        ...item,
        label: item.label || item.name,
        value: item.id,
      }));
    if (result.length === 0) {
      this.setState({
        resultMessage: "No option found",
      });
    } else {
      this.setState({
        resultMessage: "",
      });
    }

    if (json.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: "Search for more.",
      });
    }
    return result;
  };
  async searchFromList(inputValue) {
    if (!inputValue) {
      return [];
    }

    const result = [];

    return await axios({
      method: "GET",
      url: `/api/devices/models?search=${inputValue}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) => {
      let response = res.data;
      if (response.status === "success") {
        if (response && response.data.data && response.data.data) {
          return response.data.data.map((item) => {
            return {
              id: item.name,
              name: item.name,
              key: item.name,
              port: item.port,
              protocol: item.protocol,
              label: item.name,
              value: item.name,
            };
          });
        }
      } else if (response?.statusCode === "440") {
        window.location.replace("/login");
      } else if (response?.statusCode) {
        var err = response?.message.split(":");
        err[1] = err[1].replace(")", "");
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate(err[1]),
            autoDismiss: 5,
            location: this.props.location,
          })
        );
      }
    });
  }

  async searchFromZoneList(inputValue) {
    if (!inputValue) {
      return [];
    }

    let result = timeZonesTypes.filter((i) =>
      i.key.toLowerCase().includes(inputValue.toLowerCase())
    );
    return await result.map((item) => {
      return { id: item.key, key: item.key, label: item.key, value: item.key };
    });
  }

  unitToUserpermission(event, item) {}
  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }
  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });


  };
  handleChangePage = (value) => {};
  SearchItem = (searchValue) => {};

  allUnitsId = (event) => {};

  selectAllUser = (event) => {};
  getMoreUnits = () => {};

  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <SimpleModalWrapped
          {...this.state}
          {...this.props}
          visable
          isButtonVisable
          title={this.props.title}
          isNotShowAttributesBtn
          formSubmit={this.props.formSubmit}
          // addAttributes={this.addAttributes}
          modalControle={this.props.modalControle}
          btnclosetext={this.props.translate("sharedBack")}
          buttonText={this.props.buttonText}
          isVisableBtn={this.props.isVisableAddBtn}
          content={
            <>
           { this.props.addOption  ? 
              <Grid container spacing={2}>
                <Grid item md={3} sm={6} xs={12}>
                  {this.props.showPayments ? 
                  <SingleSelect
                  isDisabled
                    // isDisabled={this.props.showPayments ? "isDisabled" : ""}
                    array={
                      (this.props &&
                        this.props.accounts) ||
                      []
                    }
                    id="paymentAccount"
                    defaultValue={this.props.selectedAccount || ""}
                    selectName="paymentAccount"
                    label={this.props.translate("contractNumber")}
                    value={ 
                      // this.props.showPayments ?
                        this.props.selectedAccount &&
                          this.props.selectedAccount.id
                          ? {
                              key: this.props.selectedAccount.id,
                              id: this.props.selectedAccount.id,
                              label: this.props.selectedAccount.accountName,
                            }
                          : ""
                    }
                    handleChange={this.props.handleChangeForAccount}
                    canRemove={true}
                    canAssign={true}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                  :
                  <SingleSelect
                  array={this.props && this.props.accounts || []}
                  async
                  selectName="paymentAccount"
                  isClearable
                  label={this.props.translate("contractNumber")}
                  value={
                    this.props?.paymentAccount &&
                    this.props.paymentAccount.id
                      ? {
                          id: this.props.paymentAccount,
                          value:
                            this.props.paymentAccount.contractNumber,
                          label: this.props?.paymentAccount.label,
                        }
                      : ""
                  }
                  // value={this.props.form.contractNumbers || ""}
                  handleChange={this.props.handleChangeForAccount}
                  canAssign={true}
                  menuPlacement="auto"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
                   }
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                  <TextField
                    id="paymentMode"
                    select
                    required
                    label={this.props.translate("paymentMode")}
                    value={this.props.data.paymentMode || ""}
                    onChange={this.props.handleChange("paymentMode")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="dense"
                    fullWidth
                  >
                 
                    {PaymentModes.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {this.props.translate(option.name)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="amount"
                    label={this.props.translate("amount")}
                    // label="Amount"
                    type="number"
                    placeholder=""
                    value={this.props.data.amount || ""}
                    // name="amount"
                    onChange={this.props.handleChange("amount")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    required
                  />
                </Grid>

                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="refNum"
                    label={this.props.translate("referenceNumber")}
                    placeholder=""
                    required
                    value={this.props.data.refNum || ""}
                    name="refNum"
                    onChange={this.props.handleChange("refNum")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={3} sm={6}>
                  <TextField
                    id="adjustmentType"
                    select
                    label={this.props.translate("adjustmentType")}
                    value={this.props.data.adjustmentType || ""}
                    onChange={this.props.handleChange("adjustmentType")}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    required
                    margin="dense"
                    fullWidth
                  >
                    {AdjustmentTypes.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {this.props.translate(option.name)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item md={3} sm={6} xs={12}>
                  <TextField
                    id="remarks"
                    label={this.props.translate("remarks")}
                    placeholder=""
                    // required
                    value={this.props.data.remarks || ""}
                    onChange={this.props.handleChange("remarks")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>

                <Grid item md={3} sm={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      margin="dense"
                      label={this.props.translate("paymentDate")}
                      variant="outlined"
                      fullWidth
                      required
                      maxDate={new Date()}
                      value={
                        this.props.data.paymentDate
                          ? new Date(
                              `${this.props.data.paymentDate}`
                            ).toString()
                          : null
                      }
                      onChange={(e) =>
                        this.props.handleChangeLicenseExpDate("paymentDate", e)
                      }
                      InputProps={{
                        classes: {
                          root: "theme-cssOutlinedInput",
                          input: "theme-input",
                          focused: "theme-cssFocused",
                          notchedOutline: "theme-notchedOutline",
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: "theme-label",
                          focused: "theme-label-focused",
                          shrink: "theme-label-shrink",
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      required
                      format="dd/MM/yyyy"
                      margin="dense"
                      label={this.props.translate("recipientDate")}
                      variant="outlined"
                      fullWidth
                      maxDate={new Date()}
                      value={
                        this.props.data.recipientDate
                          ? new Date(
                              `${this.props.data.recipientDate}`
                            ).toString()
                          : null
                      }
                      onChange={(e) =>
                        this.props.handleChangeLicenseExpDate(
                          "recipientDate",
                          e
                        )
                      }
                      InputProps={{
                        classes: {
                          root: "theme-cssOutlinedInput",
                          input: "theme-input",
                          focused: "theme-cssFocused",
                          notchedOutline: "theme-notchedOutline",
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root: "theme-label",
                          focused: "theme-label-focused",
                          shrink: "theme-label-shrink",
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                  {/* {console.log("chk====", this.props.data)} */}
                  {this.props.data.contractNumber && ( 
                    <Checkbox 
                        checked={this.props.data.useCredit}
                        label={this.props.translate('useCredit')}
                        value='useCredit'
                        color='primary'
                        // disabled
                        onChange={this.props.handleChange('useCredit')}
                      />
                  )}
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <h3>
                  {this.props.translate("totalInvoiceBalance")}  = 
                    {this.props && this.props.totalInvoiceBalance }
                  </h3>

                 {  this.props.data && this.props.data.useCredit ? 
                 ( <h3>
                   {this.props.translate("creditAmount")}  = 
                    {this.props && this.props.creditAmount && this.props.creditAmount.toFixed(2)}
                  </h3>):""
                }

                  {this.props.balanceError ?  <p style={{color:"red"}}>{this.props.balanceError}</p> :" "}

                </Grid>
              </Grid> : 
              <>
              <Grid container spacing={2}>
                <Grid item md={4} sm={6} xs={12}>
                <h3>{this.props.translate("customerName")} :
                 <span style={{fontWeight:500}}>{this.props?.data?.attributes?.customerName || ""}</span> </h3>

                </Grid>
                <Grid item xs={12} md={4} sm={6}>
                  <h3>{this.props.translate("paymentMode")} :  <span style={{fontWeight:500}}>
                    {this.props.translate(`${this.props?.data?.paymentMode}`)}</span> </h3>
                
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <h3>{this.props.translate("amount")} : <span style={{fontWeight:500}}>
                    {this.props?.data?.amount || ""} </span> </h3>
              
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                  <h3>{this.props.translate("referenceNumber")} :  <span style={{fontWeight:500}}>
                    {this.props?.data?.refNum || ""}</span></h3>
            
                </Grid>
                <Grid item xs={12} md={4} sm={6}>
                  <h3>{this.props.translate("adjustmentType")} : <span style={{fontWeight:500}}>
                  {this.props.translate(`${this.props?.data?.adjustmentType}`)} </span> </h3>
                 
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                  <h3>{this.props.translate("remarks")} : <span style={{fontWeight:500}}>
                    {this.props?.data?.remarks || ""}</span></h3>
            
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                  <h3>{this.props.translate("paymentDate")} : <span style={{fontWeight:500}}>
                      {this.props?.data?.paymentDate
                        ? new Date(this.props?.data?.paymentDate).toLocaleDateString("en-US", {
                            month: "short",
                            day: "2-digit",
                            year: "numeric",
                          })
                        : null } 
                      </span>
                      </h3>
                 
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <h3>{this.props.translate("recipientDate")} :  <span style={{fontWeight:500}}>
                    {this.props?.data?.recipientDate
                        ? new Date(this.props?.data?.recipientDate).toLocaleDateString("en-US", {
                            month: "short",
                            day: "2-digit",
                            year: "numeric",
                          })
                        : null }</span>  </h3>
                  
                </Grid>
                <Grid item md={12} sm={6} xs={12}>
                {/* <Checkbox 
                    checked={this.props?.data?.useCredit}
                    label={this.props.translate('useCredit')}
                    value='useCredit'
                    color='primary'
                    onChange={this.props.handleChange('useCredit')}

                  /> */}
                    {/* <h3>{this.props.translate('useCredit')} =
                         {this.props?.data?.useCredit ? "YES" : "No"}
                   </h3> */}
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  {/* {console.log("data====,", this.props.data)} */}
                  {/* {console.log("amount====,", this.props.totalInvoiceBalance)} */}
                  <h3>
                  {this.props.translate("totalInvoiceBalance")}  = 
                    {this.props && this.props?.data?.balance || 0 }
                  </h3>
{/* 
                 {  this.props.data && this.props.data.useCredit ? 
                 ( <h3>
                   {this.props.translate("creditAmount")}  = 
                    {this.props && this.props.creditAmount && this.props.creditAmount.toFixed(2)}
                  </h3>):""
                } */}

                </Grid>
              </Grid>
              </>
              }

{/* {  this.props.invoiceLoader ?  */}

                {this.props.addOption ? <>
              {this.props.selectedAccount &&
              this.props.data.adjustmentType === "manual" ? (
                <>
              {  !this.props.invoiceLoader ? 
                  <Paper
                    {...this.props}
                    style={{
                      boxShadow: "none",
                      padding: this.props.padding ? 0 : 16,
                      // color: "rgba(0,0,0,0.54)",
                      position: "relative",
                      border: "1px dashed",
                      // marginTop: 10,
                    }}
                  >

                    <Grid container spacing={2} >
                    <Grid item xs={12} >

                    <div style={{  display:"flex", justifyContent:"right"}}>
                  <TextField
                    fullWidth
                    label={this.props.translate("search")}
                    value={this.props.invoiceSearch}
                    onChange={(e) => this.props.searchInvoices(e)}
                  />
                </div>
                    </Grid>

                      <Table
                        {...this.props}
                        rows={
                          (this.props.manualPayments &&
                            this.props.manualPayments) ||
                          []
                        }
                        disableHead
                        // allUsersId={this.props.allUsersId}
                        showCheckbox
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        // handleChangePage={this.handleChangePage}
                        isChecked={this.props.isChecked}
                        rowsPerPage={this.state.rowsPerPage}
                        isEditable={false}
                        // ServerSetting={this.props.ServerSetting}
                        themecolors={this.props.themecolors}
                        // checkHandleChange={this.unitToUserpermission}
                        canAssign
                        canRemove
                        payment
                        rowDefinition={[
                          {
                            id: "invoiceNumber",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("InvoiceNo"),
                          },
                          {
                            id: "contractNumber",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("contractNumber"),
                          },
                          {
                            id: "invoicebalance",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("remainingBalance"),
                          },
                          {
                            id: "salenetamount",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("totalAmount"),
                          },
                          // {
                          //   id: "remainingBalance",
                          //   numeric: false,
                          //   disablePadding: false,
                          //   label: this.props.translate("remainingBalance"),
                          // },

                          
                        ]}
                      />
                    </Grid>
                  </Paper>
                  : 
                  <Loader component="filter" /> }
                  </>
              ) : null}
              </>:
              <>
              {this.props.selectedAccount &&
              this.props.data.adjustmentType === "manual" ? (
                <>
                  {/* <Paper
                    {...this.props}
                    style={{
                      boxShadow: this.props.notVisableEnternalAddBtn && "none",
                      padding: this.props.padding ? 0 : 16,
                      color: "rgba(0,0,0,0.54)",
                      position: "relative",
                      marginTop: 20,
                    }}
                  >
                    <Grid container spacing={2} style={{ marginTop: 20 }}>
                      <Table
                        {...this.props}
                        rows={
                          (this.props.invoiceResponse &&
                            this.props.invoiceResponse) ||
                          []
                        }
                        // allUsersId={this.props.allUsersId}
                        // showLineCheckbox
                        // showCheckbox
                        // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        // handleChangePage={this.handleChangePage}
                        // isChecked={this.props.isChecked}
                        rowsPerPage={5}
                        isEditable={false}
                        // showCheckbox2={true}
                        // ServerSetting={this.props.ServerSetting}
                        themecolors={this.props.themecolors}
                        // checkHandleChange={this.unitToUserpermission}
                        // canAssign
                        // canRemove
                        payment
                        rowDefinition={[
                          {
                            id: "invoiceNumber",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("InvoiceNo"),
                          },
                          {
                            id: "contractNumber",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("contractNumber"),
                          },
                          {
                            id: "invoiceBalance",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("remainingBalance"),
                          },
                          {
                            id: "saleNetAmount",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("totalAmount"),
                          },
                          // {
                          //   id: "remainingBalance",
                          //   numeric: false,
                          //   disablePadding: false,
                          //   label: this.props.translate("remainingBalance"),
                          // },

                          
                        ]}
                      />
                    </Grid>
                  </Paper> */}
                </>
              ) : null}
              </>
              }
            </>
          }
        />
      </Fragment>
    );
  }
}

const AddPayment = withStyles(styles);
export default AddPayment(withLocalize(addPayment));
