import { combineReducers } from 'redux'
import { accounts } from './Accounts'
import { users, searchUser, unitBaseUser, parentUserLimits } from './Users'
import { devices, searchRecently, parentUnitLimits } from './Devices'
import { groups } from './Groups'
import { logInUsers } from './logInUser'
import { ServerSetting } from './ServerSetting'
import { reports } from './Reports'
import { roles, searchRole } from './Roles'
import { privileges } from './Privileges'
import { templates } from './Templates'
import { themecolors } from './Theme'
import { reducer as notificationSystem } from 'react-notification-system-redux'
import { trash } from './Trash'
import { coupon } from './Coupon'
import { sideBar } from './SideBar'
import { tab1,tab2,tab3,tab4,tab5,tab6,tab7,tab8,tab9,tab10,tab11,tab12,tab13,tab14,tab15, tab18, tab17, tab19} from './MaintenanceDashboard'

export default combineReducers({
  accounts,
  users,
  searchUser,
  unitBaseUser,
  devices,
  parentUnitLimits,
  parentUserLimits,
  searchRecently,
  groups,
  logInUsers,
  ServerSetting,
  reports,
  themecolors,
  roles,
  searchRole,
  privileges,
  templates,
  notificationSystem,
  trash,
  coupon,
  sideBar,
  tab1,
  tab2,
  tab3,
  tab4,
  tab5,
  tab6,
  tab7,
  tab8,
  tab9,
  tab10,
  tab11,
  tab12,
  tab12,
  tab13,
  tab14,
  tab15,
  tab18,
  tab17,
  tab19
})
