import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import Layout from "../../wrapper";
import { logInUserInfo } from "../../Actions/Users";
import Notifications from "react-notification-system-redux";
import axios from "axios";
import moment from "moment";
import { ReportsList } from "../../Components/Triggers/index";
import instance from "../../axios";

let source;
let serverTimeZoneName = "Asia/Dubai";
// let serverTimeZone = "Asia/Dubai";

const formDefault = {
  from: "",
  to: "",
  vehicleIds: [],
  contractNumbers: [],
  detail: false,
  mail: false,
};
class Triggers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      loaderR: false,
      userInfo: {},
      userFetch: false,
      form: { ...formDefault },
      from:'',
      moduleType: "",
      searchInvoiceText: "",
      accounts: "",
      page: "",
      pageSize: -1,
      makingAccounts: {},
      makingUsers: {},
      makingVehicles: {},
      filename: "",
      valid: false,
      validTo: false,
      validFrom: false,
      accounts1:[],
      accounts2:[],
      combinedAccounts:[],
      reportKey:"",
      garageVerticals:[],
      area:'',
      selectedOperation:"",
      selectedAccount:{},
      enableSubmit : false,
      refNum:"",
      repost:false
    };
  }

  checkRequiredFields= () => {
    if (this.state.from && this.state.selectedAccount.contractNumber && this.state.selectedOperation) {
      this.setState({
        enableSubmit: true,
      });
    }else{
      this.setState({
        enableSubmit: false,
      });
    }
  };

  handleSubmit = (item, type) => {
    const {refNum ,  repost} = this.state
    let accountId = this.state.selectedAccount ? this.state.selectedAccount?.contractNumber : this.props.logInUser.id;
    let date = this.state.from
    // let accountId = this.props.logInUser.id;
     
    let api = this.state.selectedOperation.id === 1 ?
    `api/trigger/missingdn?date=${date}&contractnumber=${accountId}&refnum=${refNum}&repost=${repost}`
    :`api/trigger/emailmonthlydnspdf1?date=${date}&contractnumber=${accountId}`;

    // axios({
    //   method: "GET",
    //   url: api,
    // })
    instance({
      url: api,
      method: 'GET',
    })
      .then((response) => {
        if (response) {
          let message = this.state.selectedOperation.id === 1 ? response : "invoice&DNSentSuccessFully";
          this.props.dispatch(
            Notifications.success({
              message: this.props.translate(message),
              autoDismiss: 5,
              location: this.props.location,
            })
          );
        }
        // }
        // else{
        //   this.props.dispatch(
        //     Notifications.error({
        //       message: this.props.translate(response?.data?.message),
        //       autoDismiss: 5,
        //       location: this.props.location,
        //     })
        //   );
        // }
        // this.setState({
        //   enableSubmit: false,
        //   from:'',
        //   selectedOperation:'',
        //   selectedAccount:{},
        //   refNum:"", 
        //   respost:false
        // });
      })
      .catch((error) => {
        console.log("er =", error);
      });
  };
 

  handleChange =  (name, value) => {
    let timezone = ''
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone
    }
    moment.tz.setDefault(timezone)

    if (name === "moduleType" || name === "refNum") {
      this.setState(
        {
          [name] : value.target.value,
        }
      );
    }else if(name === "repost") {
      this.setState(
        {
          [name] : value.target.checked,
        }
      );
    }
    else if (name === "selectedOperation") {

        this.setState({
          form: {
            ...this.state.form,
            to:"",
            from:""
          },
          [name]: value,
          filename: value.name,
          // accounts:this.state.combinedAccounts,
          reportKey:value.key
        }
        ,()=>{this.checkRequiredFields(); this.getContractsData()}
        
        );
      
      } else if (name === "from") {

        let val = moment(value).startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";

        this.setState(
          {
            [name]: val.toString(),
            validFrom: true,
          },
          () => this.checkRequiredFields()
        );

        
      } else if (name === "to") {
        let val ;

        if (this.state.selectedOperation ===102  || 
           this.state.selectedOperation.key  ===102  ){
            val = moment(value).endOf("day").utc().format("YYYY-MM-DDTHH:mm:59.59") + "Z";
            let fromVal = "2020-12-31T21:00:00.000Z";
            this.setState(
              {
                form: {
                  ...this.state.form,
                  [name]: val.toString(),
                  from:fromVal.toString()
                },
                validTo: true,
              },
              () => this.checkDates()
            );
           }else{
            //  val = moment(value).startOf("month").startOf("day").utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
            val = moment(value).endOf("day").utc().format("YYYY-MM-DDTHH:mm:59.59") + "Z";
            
            // val = moment(value).endOf("month").endOf("day").utc().format("YYYY-MM-DDTHH:mm:59.59") + "Z";
             this.setState(
              {
                form: {
                  ...this.state.form,
                  [name]: val.toString(),
                },
                validTo: true,
              },
              () => this.checkDates()
            );
           }

   
       
      } else if (name === 'selectedAccount'){
      this.setState({
        [name]: value,
      },
      () => this.checkRequiredFields());
      }
    
      else {
      this.setState({
        form: {
          ...this.state.form,
          [name]: value,
        },
      });
    }
  };

  
  getContractsData = () => {
    let apiUrl;
    let apiUrl1;
    let list = {};
    let usersId = {};
        apiUrl = `/api/accounts/list?all=true&contractType=1&userId=${this.props.logInUser.id}&limit=-1&search=${this.state.searchInvoiceText}`;

    source = axios.CancelToken.source();
            this.setState({
        accounts: [],
        loaderR:false,
      }, () => {
        instance({
          url: apiUrl,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
        })
      .then((response) => {
        let dataRes = response?.data || [];
        let allAccounts = dataRes.map((item) => ({
          ...item,
          label: `${item.label} - ${item.contractNumber}`
      }));
        dataRes && 
          dataRes.map(
            (d) => ((list[d.contractNumber] = true), (usersId[d.userId] = true))
          );
        this.setState({
          accounts: allAccounts || {},
          makingAccounts: list,
          makingUsers: usersId,
          loader: false,
        loaderR:true,

        });
      })
      .catch((e) => {
        console.log("e ===", e);
      });
    })
  };


  searchItem = (text) => {
     source.cancel()
 
     this.setState({
       searchInvoiceText: text.target.value
       }, 
       () => {
        setTimeout(() => {
          this.getContractsData();
        }, 500);
          // this.getContractsData();
     }
     );
 }


  render() {
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone;
    }
    if (this.props.logInUser) {
      return (
        <Layout
          getContractsData={this.getContractsData}
          searchItem={this.searchItem}
          user={this.props.logInUser}
          {...this.state}
          {...this.props}
          form={this.state.form}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
          downloadInvoices={this.downloadInvoices}
          // childFunction={this.myFunction}
        >
          <ReportsList />
        </Layout>
      );
    } else {
      fetch("/api/session", {
        headers: { Accept: "application/json; odata=verbose" },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){  
                let userInfo = res.data
                this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
                this.props.dispatch(logInUserInfo(userInfo))
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
                this.props.history.push('/login')
              }
            })
          } else {
            this.props.history.push('/login')
            throw response
          }
        })
        .catch((e) => {
          this.props.dispatch(
            Notifications.error({
              message: "somethingWentWrong",
              autoDismiss: 5,
            })
          );
        });
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)(withLocalize(Triggers));
