import React, { Component } from "react";
import TextField from "../../../common/TextField";
import { withStyles } from "@material-ui/core/styles";
import Button from "../../../common/Button";
import Grid from "@material-ui/core/Grid";
import Notifications from "react-notification-system-redux";
import { updateAccount } from "../../../../Actions/Accounts";
import { ServerInfo } from "../../../../Actions/serverSetting";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});

class monthlyTargets extends Component {
  constructor() {
    super();
    this.state = {
      errorMgs: false,
      monthlyRevenueTargets: {
        Jan: 0,
        Feb: 0,
        Mar: 0,
        Apr: 0,
        May: 0,
        Jun: 0,
        Jul: 0,
        Aug: 0,
        Sep: 0,
        Oct: 0,
        Nov: 0,
        Dec: 0,
      },
    };
  }
  componentWillMount() {
    // console.log("this=====",this.props?.selectedAccount?.attributes);
    this.setState({
      monthlyRevenueTargets:
        this.props?.selectedAccount?.monthlyRevenueTargets || {},
    });
  }

  formSubmit() {
    const payload = {
      ...this.props.selectedAccount,
      monthlyRevenueTargets:this.state.monthlyRevenueTargets || {}
      // attributes: {
      //   ...this.props.selectedAccount.attributes,
      //   c_garageTarget: this.state.c_garageTarget,
      // },
    };

    fetch(`api/accounts/${this.props.selectAccountId}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...payload,
      }),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate("monthlyTargetUpdatedSuccessfully"),
                  // message: "garagesUpdatedSuccessfully",
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
              this.setState({
                errorMgs: false,
              });
              this.props.dispatch(updateAccount(res.data));

              if (this.props.selectedAccount.id === 1) {
                this.props.dispatch(ServerInfo(res.data));
              }
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            }
          });
        } else {
          throw response;
        }
      })
      .catch((e) => {
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate("somethingWentWrong"),
            autoDismiss: 5,
            location: this.props.location,
          })
        );
      });
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const value = parseFloat(target.value);
    this.setState({
      monthlyRevenueTargets: {
        ...this.state.monthlyRevenueTargets,
        [name]: value,
      },
    });
  };

  render() {
    return (
      <Grid container spacing={4}>
        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id="january"
            error={this.state.errorMgs}
            value={this.state.monthlyRevenueTargets?.Jan || ""}
            onChange={this.handleChange("Jan")}
            label={this.props.translate("january")}
            type="text"
            placeholder=""
            variant="outlined"
            fullWidth
            margin="dense"
          />
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id="febuary"
            error={this.state.errorMgs}
            value={this.state.monthlyRevenueTargets?.Feb || ""}
              onChange={this.handleChange("Feb")}
            label={this.props.translate("febuary")}
            type="text"
            placeholder=""
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id="march"
            error={this.state.errorMgs}
            value={this.state.monthlyRevenueTargets?.Mar || ""}
              onChange={this.handleChange("Mar")}
            label={this.props.translate("march")}
            type="text"
            placeholder=""
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id="april"
            error={this.state.errorMgs}
            value={this.state.monthlyRevenueTargets?.Apr || ""}
              onChange={this.handleChange("Apr")}
            label={this.props.translate("april")}
            type="text"
            placeholder=""
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id="may"
            error={this.state.errorMgs}
            value={this.state.monthlyRevenueTargets?.May || ""}
              onChange={this.handleChange("May")}
            label={this.props.translate("may")}
            type="text"
            placeholder=""
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id="june"
            label={this.props.translate("june")}
            value={this.state.monthlyRevenueTargets?.Jun || ""}
              onChange={this.handleChange("Jun")}
            type="text"
            placeholder=""
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id="july"
            error={this.state.errorMgs}
            value={this.state.monthlyRevenueTargets?.Jul || ""}
              onChange={this.handleChange("Jul")}
            label={this.props.translate("july")}
            type="text"
            placeholder=""
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id="august"
            error={this.state.errorMgs}
            value={this.state.monthlyRevenueTargets?.Aug || ""}
              onChange={this.handleChange("Aug")}
            label={this.props.translate("august")}
            type="text"
            placeholder=""
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id="september"
            error={this.state.errorMgs}
            value={this.state.monthlyRevenueTargets?.Sep || ""}
              onChange={this.handleChange("Sep")}
            label={this.props.translate("september")}
            type="text"
            placeholder=""
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id="october"
            error={this.state.errorMgs}
            value={this.state.monthlyRevenueTargets?.Oct || ""}
              onChange={this.handleChange("Oct")}
            label={this.props.translate("october")}
            type="text"
            placeholder=""
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id="november"
            error={this.state.errorMgs}
            value={this.state.monthlyRevenueTargets?.Nov || ""}
              onChange={this.handleChange("Nov")}
            label={this.props.translate("november")}
            type="text"
            placeholder=""
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>

        <Grid item xs={12} md={3} sm={6}>
          <TextField
            id="december"
            error={this.state.errorMgs}
            value={this.state.monthlyRevenueTargets?.Dec || ""}
              onChange={this.handleChange("Dec")}
            label={this.props.translate("december")}
            type="text"
            placeholder=""
            variant="outlined"
            margin="dense"
            fullWidth
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sm={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            variant="contained"
            style={{ marginLeft: 8 }}
            // disabled={!this.state.isVisableBtn}
              onClick={() => this.formSubmit()}
          >
            {this.props.translate("sharedSave")}
          </Button>
        </Grid>
      </Grid>
    );
  }
}
const MonthlyTargets = withStyles(styles);
export default MonthlyTargets(monthlyTargets);
