import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import Layout from '../../wrapper'
import { logInUserInfo } from '../../Actions/Users'
import Notifications from 'react-notification-system-redux'
import Dashboard from './Dashboard';

 
const ExDashboard = (props) => {
    const [userInfo, setUserInfo] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.logInUser) {
          // Render the dashboard if the user is logged in
          return;
        }
    
        fetch("/api/session", {
          headers: { Accept: "application/json; odata=verbose" },
        })
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                if (res.status === 'success') {
                  let userInfo = res.data;
                  props.setActiveLanguage(userInfo?.attributes?.lang || 'en');
                  dispatch(logInUserInfo(userInfo));
                } else if (res?.statusCode === '440') {
                  window.location.replace('/login');
                } else if (res.statusCode) {
                  var err = res?.message.split(':');
                  err[1] = err[1].replace(')', '');
                  dispatch(
                    Notifications.error({
                      message: props.translate(err[1]),
                      autoDismiss: 5,
                      location: props.location,
                    })
                  );
                  props.history.push('/login');
                }
              });
            } else {
              props.history.push('/login');
              throw response;
            }
          })
          .catch((e) => {
            dispatch(
              Notifications.error({
                message: "somethingWentWrong",
                autoDismiss: 5,
              })
            );
          });
      }, [props.logInUser, props, dispatch]);

        if (props.logInUser) {
            return (
            <Layout {...props} MaintenanceDashboard>
                    <Dashboard />
             </Layout>
            );
        } else {
            return null;
         }
    };
const mapStateToProps = (state) => ({
    ServerSetting: state.ServerSetting,
    logInUser: state.logInUsers,
    });

export default connect(mapStateToProps)(withLocalize(ExDashboard))