import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { ViewUserDetail } from "./userDetail/viewUserDetail";
import { ViewStatistics } from "./statistics";
import { validEmailPattern } from "./../../Helpers";
import Notifications from "react-notification-system-redux";
import AddUserModal from "./addUserModal";
import ChangeContractModal from './changeContractModal'
import MarkerMap from "../common/Maps";
import CustomDialog from "../common/Dialog";
import Moment from "moment";
import {
  parentUsersLimit,
  getUsers,
} from "../../Actions/Users";
import { parentUnitsLimit } from "../../Actions/Devices";
import Table from "../common/tableWithBackEndPagination";
import Button from "../common/Button";
import { withLocalize } from "react-localize-redux";
import "./usersModal.scss";
import {
  checkPrivileges,
  checkUserRoles,
  checkUserAcount,
  prepareUserForLogin,
  PaginationConfig,
  prepareUsers,
} from "../../Helpers";
import ConfirmDialoag from "../common/ConfirmDialoag";
import LoginAsUser from "../../Helpers/loginAsUser";
import Loader from "../../Layout/Loader";
import Style from "style-it";
import ExportFile from "./../common/ExportFile";
import { checkUnitsWithStandardType } from "./userDataTypes";
import TextField from "../common/TextField";
import { Grid, Tooltip } from "@material-ui/core";
import Menu from "../common/CommonMenu";
import {
  FormControlLabel,
} from "@material-ui/core";
import Checkbox from "../common/Checkbox";
import MenuItem from "../common/MenuItem";
import instance from "../../axios";

const userDataFormat = (data, extAttriubtes, itemId) => {
  if (data.roleId === "defaultRole") {
    data.roleId = "";
  }
  let formattedData = {
    city: data.city ? data.city : "",
    administrator: data.userType === 1 ? false : true,
    attributes: extAttriubtes ? extAttriubtes : {},
    coordinateFormat: data.coordinateFormat ? data.coordinateFormat : "",
    deviceLimit: data.deviceLimit ? data.deviceLimit : "",
    deviceReadonly:
      data.deviceReadonly === "on" || data.deviceReadonly === true
        ? true
        : false,
    disabled: data.disabled === "on" || data.disabled === true ? true : false,
    email: data.email.trim(),
    expirationTime: data.expirationTime ? data.expirationTime : null,
    id: itemId ? itemId : 0,
    latitude: data.latitude ? data.latitude : 0,
    limitCommands:
      data.limitCommands === "on" || data.limitCommands === true ? true : false,
    login: data.login,
    longitude: data.longitude ? data.longitude : 0,
    map: data.map,
    name: data.name.trim(),
    password: data.password,
    phone: data.phone,
    poiLayer: data.poiLayer,
    readonly: data.readonly === "on" || data.readonly === true ? true : false,
    token: data.token,
    twelveHourFormat:
      data.twelveHourFormat === "on" || data.twelveHourFormat === true
        ? true
        : false,
    userLimit: data.userLimit ? data.userLimit : "",
    vehicleLimit: data.vehicleLimit ? data.vehicleLimit : "",
    zoom: data.zoom ? data.zoom : 0,
    roleId: data.roleId ? data.roleId : data.userType === 1 ? 2 : 3,
    // roleId: data.roleId ? data.roleId : 2,
    userType: data.userType,
    accountId: data.accountId || 0,
    parentId: data.parentId || 0,
    // pacContractId:data.pacContractId || ''
  };
  if (data.customerType) {
    formattedData.customerType = parseInt(data.customerType);
  }
  return formattedData;
};
const attributesDefaultValue = {
  distanceUnit: "km",
  speedUnit: "kmh",
  volumeUnit: "ltr",
  measurementStandard: "metric",
  currencies: "USD",
};
const formDefaultState = {
  name: "",
  accountName: "",
  nameArabic: "",
  email: "",
  accountEmail: "",
  password: "",
  confirmPassword: "",
  attributes: { ...attributesDefaultValue },
  phone: "",
  map: "",
  latitude: "",
  longitude: "",
  zoom: "",
  twelveHourFormat: "",
  coordinateFormat: "",
  poiLayer: "",
  disabled: "",
  admin: "",
  readonly: "",
  deviceReadonly: "",
  limitCommands: "",
  deviceLimit: 0,
  userLimit: 0,
  vehicleLimit: 0,
  token: "",
  expirationTime: null,
  roleId: "",
  userType: "",
  administrator: false,
  attributes: {},
  accountId: 0,
  parentId: 0,
  monthlyRevenueTarget:"",
  yearlyRevenueTarget:"",
  status:"",
  vatPercentage:15,
  newField:"",
  pacContractId:""
  
};
let source;
class usersModal extends Component {
  constructor() {
    super();
    this.state = {
      ...PaginationConfig,
      selectedUser: "",
      addOption: false,
      changeContractOption: false,
      defaultUserList: false,
      userDetailOption: false,
      isSelectedUser: false,
      localTime: new Date(),
      isVisableUserBtn: false,
      usesId: "",
      isMapModal: false,
      duplicateEntry: false,
      isVisableAddlocBtn: false,
      localPostionObj: "",
      selectedUsers: "",
      isVisableRoleAssiging: false,
      onDeleteConfirmation: false,
      emailPattern: false,
      tableData: [],
      defaultAccountsList: [],
      defaultUsersList: [],
      accountSelectedData: "",
      userSelectedData: "",
      pagData: "",
      isUserRecivedList: false,
      deviceLimitError: false,
      userLimitError: false,
      vehicleLimitError: false,
      unitsLimitsMeaasge: "",
      usersLimitsMeaasge: "",
      parentUserLimits: "",
      parentUnitLimits: "",
      tableHeight: false,
      countChecked: 0,
      anchorEl: null,
      topRow: true,
      loadingUser: false,
      onChangeStatusConfirmation: false,
      isSubmit: false,
      loader: false,
      accountsform:{c_taxNumber:"",
      c_idIssuer:"MC", 
      c_productType:"IMV",
      c_productStatus:"A",
      c_defaultStatus:"",
      c_goodsServices:"ALOTH",
      c_schemeId:'TIN'
    },
      selecteditemid: '',
      selecteditemname: '',
      parentItemId: '', 
      childItemId: '',
      userStatus: [],
      userStatusLabel: '',
      userActivate: false,
      loader1: false,
      userMigrateLabel: '',
      userMigrate: false,
      accountApiResponse: null,
      nextPage: 1,
      UserType: [
        { id: "1", label: "user" },
        { id: "2", label: "admin" },
        { id: "3", label: "Service Provider" },
        { id: "4", label: "Reseller" },
        { id: "5", label: "Distributor" },
      ],
      userTypeValue: '',
      userDetailsNameValue: '',
      userDetailsEmailValue: '',
      statusFilter: [],
      vehicleLimit: 0,
      // userStatus: [
      //   { id: '1', label: 'Online' },
      //   { id: '2', label: 'Offline' },
      //   { id: '3', label: 'Sleep' }
      // ],
      // userTime: [
      //   { id: '1', label: 'created time' },
      //   { id: '2', label: 'Active time' },
      //   { id: '3', label: 'Deleted time' },
      //   { id: '4', label: 'Edited time' }
      // ],
      // all fields of form
      form: {
        ...formDefaultState,
      },
      spCreated:false,
      aaCreated:false,
      unlimitedLimit:false,
      emailInvalidError:'',
      accountEmailInvalidError:'',
      defaultGarageOptions:[]
    };
    this.au_submit = this.au_submit.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.generateToken = this.generateToken.bind(this);
    this.modalControle = this.modalControle.bind(this);
    this.changeAttribute2 = this.changeAttribute2.bind(this);
    this.timeDateOnchange = this.timeDateOnchange.bind(this);
    this.userSelection = this.userSelection.bind(this);
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
  }
  componentWillMount() {
    this.getMoreUsers();
  }
  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
    this.props.dispatch(Notifications.removeAll());
  }
  replaceHtmlEntities = (obj) => {
    if (typeof obj === 'string') {
      obj = obj.replace(/amp;/, ' ').replace(/amp;/g, '');
      const tempElement = document.createElement('div');
      tempElement.innerHTML = obj;
      return tempElement.innerText;
    } else if (Array.isArray(obj)) {
      return obj.map((item) => this.replaceHtmlEntities(item));
    } else if (typeof obj === 'object' && obj !== null) {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [key, this.replaceHtmlEntities(value)])
      );
    } else {
      return obj;
    }
  };
  getMoreUsers = () => {
    let { page, pageSize, itemSearch, userTypes, userStatuses, userTimes } =
      this.state;
    if (this.props.logInUser.id) {
      let apiCall = `/api/users/get?userId=${
        this.props.logInUser.id
      }&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}${
        userTypes || ""
      }${userStatuses || ""}${userTimes || ""}`;
      if(this.props.logInUser.userType === 4) {
        apiCall = `/api/users/get?userId=${
          this.props.logInUser.id
        }&all=true&page=${page}&limit=${pageSize}&search=${itemSearch}${
          userTypes || ""
        }${userStatuses || ""}${userTimes || ""}&userType=3`;
      }
      // if (this.props.logInUser.userType === 6) {
      //   apiCall = `/api/users/get?userId=${this.props.logInUser.id}&all=true&page=${page}&&limit=${pageSize}&&search=${itemSearch}${this.state.filterType ||
      //     ''}`
      // }
      // instance({
      //   url: apiCall,
      //   method: 'GET',
      // })
      // .then(response => {
      //     // response.data && response.data.length &&
      //     // dispatch(getUsers(response.data))
      //   // }
      // })
      // .catch(error => {errorHandler(error, this.props.dispatch)})
      source = axios.CancelToken.source();
      axios
        .get(apiCall, {
          cancelToken: source.token,
        })
        .then((response) => {
          let res = response.data;
          if (res.status === "success") {
            if (res.data.data.length === 0) {
              this.setState({
                noRecord: true,
              });
            } else {
              this.setState({
                noRecord: false,
              });
            }
            if (res.data.data) {
              let udata = prepareUserForLogin(
                checkUserRoles(prepareUsers(res.data.data), this.props.roles)
              );
              this.props.dispatch(getUsers(udata));

              let users = udata.map((item) => {
                let u = this.replaceHtmlEntities(item)
                if (u.created) {
                  return {
                    ...u,
                    created: this.props.logInUser.twelveHourFormat
                      ? Moment(u.created).format("YYYY-MM-DD hh:mm A")
                      : Moment(u.created).format("YYYY-MM-DD HH:mm"),
                  };
                } else {
                  return { ...u };
                }
              });
              const getuserStatus = users?.map((useritem) => useritem.disabled)
              this.setState({ userStatus: getuserStatus });
              this.setState({
                tableData: users,
                defaultUserList: true,
                loader: false,
                pagData: response.data.data,
              });
            } else {
              this.setState({
                tableData: [],
                defaultUserList: true,
                pagData: "",
              });
            }
          } else if (res?.statusCode === "440") {
            window.location.replace("/login");
          } else if (res.statusCode) {
            var err = res?.message.split(":");
            err[1] = err[1].replace(")", "");
            this.props.dispatch(
              Notifications.error({
                message: this.props.translate(err[1]),
                autoDismiss: 5,
                location: this.props.location,
              })
            );
          }
        })
        .catch((e) => {
          //   this.props.dispatch(Notifications.error({
          //   message: 'somethingWentWrong',
          //   autoDismiss: 5
          // }))
        });
    }
  };
  changeContract = (item, event) => {
    event.stopPropagation()

    if (this.props.logInUser.userType === -1) {
      this.fetchAccountsList(this.props.logInUser.accountId);
    }
    this.setState({
      selectedUser: "",
      changeContractOption: true,
      defaultUserList: false,
      noRecord: false,
      topRow: false,
      selecteditemid: item.id,
      selecteditemname: item
    })
  }
  loadNextPage = (nextPageValue) => {
    this.setState({ nextPage: nextPageValue });
    if (this.props.logInUser.userType === -1) {
      this.fetchAccountsList(this.props.logInUser.accountId);
    }
  }
 


  fetchAccountsList = (id, search) => {
 
    let searchVal = search ? search : ""
    let fetchApi = `/api/accounts/all?accountId=${id}&page=${this.state.nextPage}&limit=100&search=${searchVal}`;
    if (this.props.logInUser.userType === -1) {
      fetchApi = `/api/accounts/all?accountId=${id}&all=true&page=${this.state.nextPage}&limit=100&search=${searchVal}`;
    }
    fetch(fetchApi, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
              let userlist = res.data;
              this.setState({ accountApiResponse: userlist });
              let result =
                userlist.data &&
                userlist.data.map((item) => ({
                  ...item,
                  label: item.label || item.name,
                  name: item.label || item.name,
                  value: item.id,
                }));
              result.unshift({
                id: this.props.ServerSetting.id,
                label: this.props.ServerSetting.name,
                value: this.props.ServerSetting.id,
              });
              this.setState((prevState) => ({
                defaultAccountsList: [...prevState.defaultAccountsList, ...result],
              }));
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            }
          });
        }
      })
      .catch((e) => {
        console.log("e =", e);
        //   this.props.dispatch(Notifications.error({
        //   message: 'somethingWentWrong',
        //   autoDismiss: 5
        // }))
      });
  };
  fetchUsersList = (id) => {
    let fetchApi = `/api/users/list?accountId=${id}&limit=100`;
    // if (this.props.logInUser.userType === -1) {
    //   fetchApi = `/api/users/list?${onBase}=${id}&all=true&limit=100`
    // }
    fetch(fetchApi, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
              let userlist = res.data;
              let result = [];
              userlist.data &&
                userlist.data.map((item) => {
                  if (item.userType !== 1) {
                    result.push({
                      label: item.label || item.name,
                      value: item.id,
                    });
                  }
                });
              if (parseInt(id) === parseInt(this.props.ServerSetting.id)) {
                result.unshift({
                  id: this.props.logInUser.id,
                  label: this.props.logInUser.name,
                  name: this.props.logInUser.name,
                  value: this.props.logInUser.id,
                });
              }
              if (result.length === 0) {
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(
                      "selectedAccountHaveNoUserWhichIsAbleToCreateNewUser"
                    ),
                    autoDismiss: 5,
                    location: this.props.location,
                  })
                );
              }
              this.setState({
                defaultUsersList: result,
                isUserRecivedList: true,
                loadingUser: false,
              });
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            }
          });
        }
      })
      .catch((e) => {
        console.log("e =", e);
        // this.props.dispatch(Notifications.error({
        // message: 'somethingWentWrong',
        // autoDismiss: 5
        // }))
      });
  };
  openUserStatusDialog = (event, item) => {
    let user = item[0];
    this.setState({
      userStatusLabel : user.disabled ? "Are You Sure, You Want to Activate" :"Are You Sure, You Want to DeActivate",
      selectedUser: item[0],
      userActivate: true,
    });
    event.stopPropagation()
  };
  closeUserStatusDialog = () => {
    this.setState({
      userActivate: false,
    });
  };
  handleUserStatus = (event,item) => {

    const active = this.state.selectedUser.disabled ? true:false

    let fetchApi ; 
    if (active){
      fetchApi = `/api/users/active/${this.state.selectedUser.id}`
    }else{
      fetchApi = `/api/users/inactive/${this.state.selectedUser.id}`
    }

    this.setState({
      loader1: true,},()=>{
    fetch(fetchApi, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
                this.setState({
                  userActivate: false,
                  loader1: false,
                },()=>this.getMoreUsers());
                this.props.dispatch(
                  Notifications.success({
                    message: "User " + (active ? "Activated" : "Deactivated"),
                    autoDismiss: 5,
                    location: this.props.location,
                  })
                );
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            }
          });
        }
      })
      .catch((e) => {
        console.log("e =", e);
      });
    })
   }
  checkAttributes = (data) => {
    let form = {
      coordinateFormat: data.coordinateFormat,
      poiLayer: data.poiLayer,
      latitude: 25.2048,
      longitude: 55.2708,
      zoom: 16,
      map: data.map,
      attributes: {
        ["web.liveRouteLength"]:
          data.attributes && data.attributes["web.liveRouteLength"],
        ["web.maxZoom"]: data.attributes && data.attributes["web.maxZoom"],
        ["web.selectZoom"]:
          data.attributes && data.attributes["web.selectZoom"],
        ["timezone"]: data.attributes && data.attributes["timezone"],
        ["distanceUnit"]: data.attributes && data.attributes["distanceUnit"],
        ["speedUnit"]: data.attributes && data.attributes["speedUnit"],
        ["volumeUnit"]: data.attributes && data.attributes["volumeUnit"],
        ["measurementStandard"]:
          data.attributes && data.attributes["measurementStandard"],
      },
    };
    return form;
  };

  removeUser(selected) {
    if (selected && selected.length) {
      this.setState({
        onDeleteConfirmation: true,
        selectedUser: selected[0],
        form: "",
      });
    } else {
      this.setState({
        onDeleteConfirmation: true,
        directDelete: false,
        selectedUser: selected,
        form: "",
      });
    }
  }
  handleChangeAttributes = (name) => (event) => {
    const c_schemeIdList = [
      { key: 1, name: "TIN" },
      { key: 2, name: "CRN"},
      { key: 3, name: "MOM"},
      { key: 4, name: "MLS"},
      { key: 5, name: "700"},
      { key: 6, name: "SAG"},
      { key: 7, name: "NAT" },
      { key: 8, name: "GCC"},
      { key: 9, name: "IQA"},
      { key: 10, name: "OTH"},
    ];
    
    const value = name === "agreedAdvancePayment" ?
                      parseFloat(event.target.value) :
                      event.target.value;
    
    if (name === 'c_schemeId') {

      const selectedScheme = c_schemeIdList.find(scheme => scheme.name === value);
      if (selectedScheme) {

        const newFieldName = `c_${selectedScheme.name.toLowerCase()}Number`;

        this.setState({
          accountsform: {
            ...this.state.accountsform,
            [name]: value,
            [newFieldName]: '', // Initialize the value of the new field
          },
        });
      }
    } 
    else if(name==='number'){
      this.setState({
        newField:value,
        accountsform: {
          ...this.state.accountsform,
          [`c_${this.state?.accountsform?.c_schemeId?.toLowerCase()}Number`]: value,
        },
      });
    }
    else {
      // For other fields, update the state as usual
      this.setState(
        {
          accountsform: {
            ...this.state.accountsform,
            [name]: value,
          },
        },
        () => {
          this.checkRequiredFields();
        }
      );
    }
  };



  deleteUser = () => {
    let { selectedUser } = this.state;
    if (selectedUser.id) {
      fetch(`/api/users/${selectedUser.id}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...selectedUser,
        }),
      })
        .then((response) => {
          response.json().then((res) => {
            if (res.status === "success") {
              this.getMoreUsers();
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate("userIsRemoved"),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            } else {
              throw response;
            }
          });
        })

        .catch((e) => {
          // if(e && e.text){
          // e.text().then(err => {
          // var array = err.split("-")
          // if(array.length > 1){
          //       this.props.dispatch(
          //         Notifications.error({
          //           message: this.props.translate(array[0]),
          //           autoDismiss: 5,
          //           location: this.props.location
          //         })
          //       )
          // }
          // else {
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate("somthingWentWrongMessage"),
              autoDismiss: 5,
              location: this.props.location,
            })
          );
          // }
          //   })
          // }
        });
      this.setState({
        selectedUser: "",
        onDeleteConfirmation: false,
        userDetailOption: false,
        defaultUserList: true,
      });
    }
  };

  //refreshing users and units limits
  getUsersLimits = () => {
    if (this.props.logInUser.id) {
      //units limits
      fetch(`/api/devices/count?userId=${this.props.logInUser.id}`)
        .then((response) => {
          if (response.ok) {
            response.json().then((unitsLimits) => {
              if (unitsLimits.status === "success") {
                this.props.dispatch(parentUnitsLimit(unitsLimits));
              } else if (unitsLimits?.statusCode === "440") {
                window.location.replace("/login");
              } else if (unitsLimits.statusCode) {
                var err = unitsLimits?.message.split(":");
                err[1] = err[1].replace(")", "");
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location,
                  })
                );
              }
            });
          } else {
            throw response;
          }
        })
        .catch((e) => {
          this.props.dispatch(
            Notifications.error({
              message: "somethingWentWrong",
              autoDismiss: 5,
            })
          );
        });
      //users limits
      fetch(`/api/users/count?userId=${this.props.logInUser.id}`)
        .then((response) => {
          if (response.ok) {
            response.json().then((usersLimits) => {
              if (usersLimits.status === "success") {
                this.props.dispatch(parentUsersLimit(usersLimits));
              } else if (usersLimits?.statusCode === "440") {
                window.location.replace("/login");
              } else if (usersLimits.statusCode) {
                var err = usersLimits?.message.split(":");
                err[1] = err[1].replace(")", "");
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location,
                  })
                );
              }
            });
          } else {
            throw response;
          }
        })
        .catch((e) => {
          this.props.dispatch(
            Notifications.error({
              message: "somethingWentWrong",
              autoDismiss: 5,
            })
          );
        });
    }
  };
  changeAttribute2 = (name, event) => {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    if (name === "measurementStandard") {
      let data = checkUnitsWithStandardType(value);
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: event.target.type === "number" ? parseInt(value) : value,
              distanceUnit: data[0].distanceUnit.key,
              speedUnit: data[0].speedUnit.key,
              volumeUnit: data[0].volumeUnit.key,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: value,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  handleChangeContractform = (name, value) => {
    this.setState(
      {
        isVisableUserBtn: true,
      },
    );
    this.setState({parentItemId: this.state.selecteditemid});
    this.setState({childItemId: value});
  }
  openUserMigrateDialog = () => {
    this.setState({
      userMigrateLabel : "Are You Sure, You Want to Migrate?",
      userMigrate: true,
    });
  };
  closeUserMigrateDialog = () => {
    this.setState({
      userMigrate: false,
    });
  };
  cc_submit = () => {
    this.openUserMigrateDialog();
  }
  handleUserMigrate = () => {
    const apiUrl = '/api/users/migrate';

    const payload = {
      newParentId: this.state.childItemId.id,
      userId: [this.state.parentItemId],
    };

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((res) => {
            if (res.status === "success") {
              this.setState({ 
                userMigrate: false,
                changeContractOption: false,
                defaultUserList: true,
                noRecord: true,
                topRow: true,
              },()=> this.getMoreUsers());
              this.props.dispatch(
                Notifications.success({
                  message: "User Migrated successfully",
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
                this.setState({ userMigrate: false, });
                var err = res?.message.split(":");
                err[1] = err[1].replace(")", "");
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location,
                  })
                );
              }
          })
        } else {
          this.props.history.push("/login");
          throw response;
        }
        
      })
  }
  timezone_handleChange = (name, value) => {
    if (name === "timezone") {
      if (!value || value.value === "None") {
        delete this.state.form.attributes[name];
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
                [name]: value.value,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      }
    } else {
      if (name === "accountId") {
        this.setState(
          {
            loadingUser: true,
            form: {
              ...this.state.form,
              [name]: value.value,
              userId: "",
              deviceLimit: 0,
              userLimit: 0,
            },
            accountSelectedData: value,
            // userSelectedData: "",
            isUserRecivedList: false,
            deviceLimitError: false,
            userLimitError: false,
            vehicleLimitError: false,
            usersLimitsMeaasge: "",
            vehicleLimitMeaasge: "",
          },
          () => {
            this.fetchUsersList(value.value);
            this.checkRequiredFields();
          }
        );
      } else if (name === "parentId") {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value.value,
              deviceLimit: 0,
              userLimit: 0,
            },
            userSelectedData: value,
            deviceLimitError: false,
            userLimitError: false,
            vehicleLimitError: false,
            usersLimitsMeaasge: "",
            vehicleLimitMeaasge: "",
          },
          () => {
            if (value.value) {
              this.checkLimitsForCreatingUser(value.value);
            }
            this.checkRequiredFields();
          }
        );
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value.value,
            },
          },
          () => this.checkRequiredFields()
        );
      }
    }
  };
  modalControle() {
    this.setState({
      addOption: false,
      changeContractOption: false,
      duplicateEntry: false,
      topRow: true,
      userDetailOption: false,
      passwordOption: false,
      isSelectedUser: false,
      selectedUser: "",
      form: "",
      isVisableUserBtn: false,
      isVisableRoleAssiging: false,
      spCreated:false,
      aaCreated:false,


    });
    if (this.props.onCloseModal) {
      this.props.onCloseModal();
    }
    if (this.state.noRecord) {
      this.setState({
        defaultUserList: false,
        topRow: true,
      });
    } else {
      this.setState({
        defaultUserList: true,
        topRow: true,
      });
    }
  }


  generateToken() {
    let symbols =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let i,
      newToken = "";
    for (i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length));
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          token: newToken,
        },
      },
      () => this.checkRequiredFields()
    );
  }
  timeDateOnchange(date) {
    this.setState((preState) => ({
      ...preState,
      form: {
        ...preState.form,
        timeDate: date,
      },
    }));
  }
  setNoToken = (e) => {
    e.stopPropagation();
    this.setState(
      {
        form: {
          ...this.state.form,
          token: null,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  handleChange = (name) => (event) => {
    const { target } = event;
    if (name === "userType") {
      this.setState({
        userTypeValue: target.value
      })
    }
    if (name === "accountName") {
      if(this.state.userTypeValue === 4) {
        this.setState({
          userDetailsNameValue: target.value
        })
      }
    }
    if (name === "accountEmail") {
      if(this.state.userTypeValue === 4) {
        this.setState({
          userDetailsEmailValue: target.value
        })
      }
    }
    if (target && target.type === "checkbox") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.checked,
          },
        },
        () => this.checkRequiredFields()
      );
    }
    else if (
      name === "expirationTime" ||
      name === "contractStartDate" ||
      name === "defaultSatisfactionDate" ||
      name === "settleDate" ||
      name === "contractEndDate"
    ) {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event.toISOString(),
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      let value = target.value;
      if (value) {
        if (target.type === "number") {
          value = parseFloat(value);
        }
        if (name === "deviceLimit") {
          const { parentUnitsLimit } = this.state;
          if (
            parseInt(value) === -1 &&
            parseInt(parentUnitsLimit && parentUnitsLimit.remaningLimit) === -1
          ) {
            this.setState(
              {
                deviceLimitError: false,
                unitsLimitsMeaasge: "",
              },
              () => this.checkRequiredFields()
            );
          } else {
            if (parseInt(value) > -1) {
              if (
                parseInt(parentUnitsLimit && parentUnitsLimit.remaningLimit) ===
                -1
              ) {
                this.setState(
                  {
                    deviceLimitError: false,
                    unitsLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              } else if (
                parseInt(value) >
                parseInt(
                  parentUnitsLimit.remaningLimit - parentUnitsLimit.totalCount
                )
              ) {
                this.setState(
                  {
                    deviceLimitError: true,
                    unitsLimitsMeaasge: "deviceLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    deviceLimitError: false,
                    unitsLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            } else {
              if (parseInt(value) <= -1) {
                this.setState(
                  {
                    deviceLimitError: true,
                    unitsLimitsMeaasge: "deviceLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    deviceLimitError: false,
                    unitsLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            }
          }
        }
        if (name === "userLimit") {
          const { parentUsersLimit } = this.state;
          if (
            parseInt(value) === -1 &&
            parseInt(parentUsersLimit.remaningLimit) === -1
          ) {
            this.setState(
              {
                userLimitError: false,
                usersLimitsMeaasge: "",
              },
              () => this.checkRequiredFields()
            );
          } else {
            if (parseInt(value) > -1) {
              if (parseInt(parentUsersLimit.remaningLimit) === -1) {
                this.setState(
                  {
                    userLimitError: false,
                    usersLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              } else if (
                parseInt(value) >
                parseInt(
                  parentUsersLimit.remaningLimit - parentUsersLimit.totalCount
                )
              ) {
                this.setState(
                  {
                    userLimitError: true,
                    usersLimitsMeaasge: "userLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    userLimitError: false,
                    usersLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            } else {
              if (parseInt(value) <= -1) {
                this.setState(
                  {
                    userLimitError: true,
                    usersLimitsMeaasge: "userLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    userLimitError: false,
                    usersLimitsMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            }
          }
        }
        if (name === "vehicleLimit") {
          const { vehicleLimit } = this.state;
          if (
            parseInt(value) === -1 &&
            parseInt(vehicleLimit.remaningLimit) === -1
          ) {
            this.setState(
              {
                vehicleLimitError: false,
                vehicleLimitMeaasge: "",
              },
              () => this.checkRequiredFields()
            );
          } else {
            if (parseInt(value) > -1) {
              if (parseInt(vehicleLimit.remaningLimit) === -1) {
                this.setState(
                  {
                    vehicleLimitError: false,
                    vehicleLimitMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              } else if (
                parseInt(value) >
                parseInt(vehicleLimit.remaningLimit - vehicleLimit.totalCount)
              ) {
                this.setState(
                  {
                    vehicleLimitError: true,
                    vehicleLimitMeaasge: "vehicleLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    vehicleLimitError: false,
                    vehicleLimitMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            } else {
              if (parseInt(value) <= -1) {
                this.setState(
                  {
                    vehicleLimitError: true,
                    vehicleLimitMeaasge: "userLimitErrorParent1",
                  },
                  () => this.checkRequiredFields()
                );
              } else {
                this.setState(
                  {
                    vehicleLimitError: false,
                    vehicleLimitMeaasge: "",
                  },
                  () => this.checkRequiredFields()
                );
              }
            }
          }
        }
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value,
            },
          },
          () => this.checkRequiredFields()
        );
      } else if (this.state.form[name]) {
        if (target.type === "number") {
          value = parseFloat(value);
        }
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value,
            },
          },
          () => this.checkRequiredFields()
        );
      }
    }
  };
  checkLimitsForCreatingUser = (id) => {
    this.setState(
      {
        isUserRecivedList: false,
      },
      () => {
        fetch(`/api/devices/count?userId=${id}`)
          .then((response) => {
            if (response.ok) {
              response.json().then((unitsLimits) => {
                if (unitsLimits.status === "success") {
                  this.setState({
                    parentUnitsLimit: unitsLimits.data,
                  });
                } else if (unitsLimits?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (unitsLimits.statusCode) {
                  var err = unitsLimits?.message.split(":");
                  err[1] = err[1].replace(")", "");
                  this.props.dispatch(
                    Notifications.error({
                      message: this.props.translate(err[1]),
                      autoDismiss: 5,
                      location: this.props.location,
                    })
                  );
                }
              });
            } else {
              throw response;
            }
          })
          .catch((e) => {
            this.props.dispatch(
              Notifications.error({
                message: "somethingWentWrong",
                autoDismiss: 5,
              })
            );
          });
        //users limits
        fetch(`/api/users/count?userId=${id}`)
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                if (res.status === "success") {
                  let usersLimits = res.data;
                  this.setState({
                    parentUsersLimit: usersLimits,
                    isUserRecivedList: true,
                  });
                  if (usersLimits.remaningLimit === 0) {
                    this.setState({
                      userLimitError: true,
                      usersLimitsMeaasge: "userLimitErrorParent1",
                    });
                    this.props.dispatch(
                      Notifications.error({
                        message: this.props.translate("userLimitErrorParent2"),
                        autoDismiss: 10,
                        location: this.props.location,
                      })
                    );
                  }
                } else if (res?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (res.statusCode) {
                  var err = res?.message.split(":");
                  err[1] = err[1].replace(")", "");
                  this.props.dispatch(
                    Notifications.error({
                      message: this.props.translate(err[1]),
                      autoDismiss: 5,
                      location: this.props.location,
                    })
                  );
                }
              });
            } else {
              throw response;
            }
          })
          .catch((e) => {
            this.props.dispatch(
              Notifications.error({
                message: "somethingWentWrong",
                autoDismiss: 5,
              })
            );
          });
        //vehicles limits
        fetch(`/api/vehicles/count?userId=${id}`)
          .then((response) => {
            if (response.ok) {
              response.json().then((res) => {
                if (res.status === "success") {
                  let vehicleLimit = res.data;
                  this.setState({
                    vehicleLimit: vehicleLimit,
                    isUserRecivedList: true,
                  });
                  if (vehicleLimit.remaningLimit === 0) {
                    this.setState({
                      vehicleLimitError: true,
                      vehicleLimitMeaasge: "userLimitErrorParent1",
                    });
                    this.props.dispatch(
                      Notifications.error({
                        message: this.props.translate("userLimitErrorParent2"),
                        autoDismiss: 10,
                        location: this.props.location,
                      })
                    );
                  }
                } else if (res?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (res.statusCode) {
                  var err = res?.message.split(":");
                  err[1] = err[1].replace(")", "");
                  this.props.dispatch(
                    Notifications.error({
                      message: this.props.translate(err[1]),
                      autoDismiss: 5,
                      location: this.props.location,
                    })
                  );
                }
              });
            } else {
              throw response;
            }
          })
          .catch((e) => {
            this.props.dispatch(
              Notifications.error({
                message: "somethingWentWrong",
                autoDismiss: 5,
              })
            );
          });
      }
    );
  };
  setExpirationTime = (e) => {
    e.stopPropagation();
    if (this.state.form.expirationTime) {
      this.setState(
        {
          form: {
            ...this.state.form,
            expirationTime: null,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  checkRequiredFields() {
    let valid = true;
    let {
      name,
      email,
      password,
      confirmPassword,
      userType,
      accountId,
      parentId,
      zoom,
      contractNumber,
      vatPercentage,
      nameArabic,
      contractName,
      creditPeriod,
      accountEmail,
      contractStartDate, 
      contractEndDate
    } = this.state.form;
    let{c_taxNumber, c_streetName, c_buildingNo,c_schemeId, c_city, c_postalCode, c_country, c_district}=this.state.accountsform


    // Check if email is not valid
    if (email && !validEmailPattern.test(email)) {
      this.setState({
        emailInvalidError: "Please enter a valid email address."
      });
    } else {
      this.setState({
        emailInvalidError: ""
      });
    }

    if (accountEmail && !validEmailPattern.test(accountEmail)) {
      this.setState({
        accountEmailInvalidError: "Please enter a valid email address."
      });
    } else {
      this.setState({
        accountEmailInvalidError: ""
      });
    }

    if (userType > 2) {
      if(userType === 4){
        if (
          // creditPeriod &&
          password &&
          confirmPassword &&
          contractNumber &&
          vatPercentage && 
          vatPercentage >= 0 && vatPercentage <= 100&&
          nameArabic &&
          accountEmail &&
          contractName &&
          password === confirmPassword
        ) {
          this.setState({
            isVisableUserBtn: true,
            duplicateEntry: false,
          });
     
        } else {
          this.setState({
            isVisableUserBtn: false,
            duplicateEntry: false,
          });
        }
      }
      else if(userType === 3){
        if (
          password &&
          confirmPassword &&
          contractNumber &&
          nameArabic &&
          vatPercentage && 
          c_postalCode &&
          vatPercentage >= 0 && vatPercentage <= 100&&
          accountEmail &&
          contractName &&
          password === confirmPassword &&
          c_schemeId 
          
        ) {
          this.setState({
            isVisableUserBtn: true,
            duplicateEntry: false,
          });
     
        } else {
          this.setState({
            isVisableUserBtn: false,
            duplicateEntry: false,
          });
        }
      }
     else {
      if (
        creditPeriod &&
        password &&
        confirmPassword &&
        contractNumber &&
        vatPercentage && 
        vatPercentage >= 0 && vatPercentage <= 100&&
        nameArabic &&
        accountEmail &&
        contractName &&
        c_taxNumber&&
        c_streetName&& c_buildingNo&& c_city&& c_postalCode&& c_country&& c_district&&

        password === confirmPassword
      ) {

        this.setState({
          isVisableUserBtn: true,
          duplicateEntry: false,
        });
      
      } else {
        this.setState({
          isVisableUserBtn: false,
          duplicateEntry: false,
        });
      }
    }
    } else {
      if (
        name &&
        email &&
        password &&
        confirmPassword &&
        // vatPercentage && 
        // vatPercentage >= 0 && vatPercentage <= 100&&
        password === confirmPassword
      ) {

        this.setState({
          isVisableUserBtn: true,
          duplicateEntry: false,
        });
      
      } else {
        this.setState({
          isVisableUserBtn: false,
          duplicateEntry: false,
        });
      }
    }
    // if(vatPercentage){
    //   if(vatPercentage >= 0 && vatPercentage <= 100){
    //     this.setState({
    //       isVisableUserBtn: true,
    //         vatPercentageError: "",
    //     });
        
    //   } else {
    //     this.setState({
    //       isVisableUserBtn: false,
    //       vatPercentageError:"Enter Value between 0 and 100"
    //     });
    //   }
    // }
    if (contractStartDate && contractEndDate) {
      const startDate = new Date(contractStartDate);
      const endDate = new Date(contractEndDate);
    
      if (startDate > endDate) {
        this.setState({
          isVisableUserBtn: false,
            contractEndDateError: "Contract end date must be after contract start date",
        });
      } else {
        this.setState({
          isVisableUserBtn: true,
          contractEndDateError:""
        });
      }
    }
  }
  handleClickShowPostion = () => {
    this.setState({
      isMapModal: true,
      isVisableAddlocBtn: false,
    });
  };
  getPostion = (latlng, zoom) => {
    if (latlng.lat && latlng.lng && zoom) {
      this.setState({
        localPostionObj: { latlng, zoom },
        isVisableAddlocBtn: true,
      });
    }
  };
  setSelectedLocation = () => {
    const { localPostionObj } = this.state;
    if (localPostionObj) {
      this.setState(
        {
          form: {
            ...this.state.form,
            latitude: localPostionObj.latlng.lat,
            longitude: localPostionObj.latlng.lng,
            zoom: localPostionObj.zoom,
          },
          isMapModal: false,
        },
        () => this.checkRequiredFields()
      );
    }
  };

  onCloseC_Modal = () => {
    this.setState({
      isVisableRoleAssiging: false,
      userDetailOption: false,
      showStatisticsModel: false,
      defaultUserList: true,
      topRow: true,
    });
  };
  onCloseModal = () => {
    this.setState({
      isMapModal: false,
      onChangeStatusConfirmation: false,
      statusMode: false,
      isSubmit: false,
    });
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };

  loginAsUser = (data) => {
    if (data[0].logInAsUserState) {
      this.setState(
        {
          userDataForLogin: data[0],
          isUserLogin: false,
        },
        () => {
          this.setState(
            {
              isUserLogin: true,
              userDetailOption: false,
            },
            () => this.getMoreUsers()
          );
        }
      );
    }
  };
  showStatistics = (data) => {
    if (data[0]) {
      this.setState({
        defaultUserList: false,
        selectedUser: data[0],
        Loader: false,
        topRow: false,
        showStatisticsModel: true,
      });
    }
  };
  prepareUserDataForTable = () => {
    let apiCall = `/api/accounts/list?userId=${this.props.logInUser.id}&limit=-1&all=true`;
    if (this.props.logInUser.userType === 6) {
      apiCall = `/api/accounts/list?userId=${this.props.logInUser.id}&limit=-1`;
    }
    fetch(apiCall)
      .then((response) => {
        if (response.ok) {
          response.json().then((accounts) => {
            if (accounts.status === "success") {
              let prData = prepareUserForLogin(
                checkUserAcount(
                  checkUserRoles(this.props.users.data, this.props.roles),
                  accounts.data
                )
              );
              this.setState({
                tableData: prData,
              });
            } else if (accounts?.statusCode === "440") {
              window.location.replace("/login");
            } else if (accounts.statusCode) {
              var err = accounts?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            }
          });
        } else {
          throw response;
        }
      })
      .catch((e) => {
        this.props.dispatch(
          Notifications.error({
            message: "somethingWentWrong",
            autoDismiss: 5,
          })
        );
      });
  };
  handleChangeRowsPerPage = (value) => {
    this.setState(
      {
        //tableData: [],
        page:1,
        pageSize: value,
        //defaultUserList: false
      },
      () => this.getMoreUsers()
    );
  };
  handleChangePage = (value) => {
    this.setState(
      {
        //tableData: [],
        page: value,
        //defaultUserList: false
      },
      () => this.getMoreUsers()
    );
  };
  SearchItem = (searchValue) => {
    source.cancel();
    this.setState(
      {
        page: 1,
        itemSearch: searchValue,
        loader: true,
        defaultUserList: false,
      },
      () => this.getMoreUsers()
    );
  };

  handleUsersChange = (name, e) => {
    if (e.length) {
      this.setState({
        tableHeight: true,
      });
    } else {
      this.setState({
        tableHeight: false,
      });
    }
    if (name === "type") {
      let users = "";
      e.map((i) => {
        users += "&userType=" + i.value;
      });
      this.setState(
        {
          page: 1,
          userTypes: users,
        },
        () => this.getMoreUsers()
      );
    } else if (name === "status") {
      let usersStatus = "";
      e.map((i) => {
        usersStatus += "&userStatus=" + i.value;
      });
      this.setState(
        {
          page: 1,
          userStatuses: usersStatus,
        },
        () => this.getMoreUsers()
      );
    } else if (name === "time") {
      let usersTime = "";
      e.map((i) => {
        usersTime += "&usersTime=" + i.value;
      });
      this.setState(
        {
          page: 1,
          userTimes: usersTime,
        },
        () => this.getMoreUsers()
      );
    }
  };
  callUserFunc = () => {
    if (this.state.itemSearch) {
      this.getMoreUsers();
    } else {
      return null;
    }
  };
  loginAsUserhandleChange = (id, value) => {
    let { tableData } = this.state;
    tableData.map((item) => {
      if (item.id === id) {
        item.logInAsUserState = value;
      }
    });
    this.setState({
      tableData,
    });
  };
  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }
  setPagination = (user) => {
    if (this.state.tableData.length) {
      let nUser = { ...user };
      let lastPage = nUser.total / this.state.pageSize;
      let IsFloate = this.checkFloteNumber(lastPage);
      delete nUser.data;
      nUser.pageSize = this.state.pageSize;
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nUser;
    }
    return {};
  };
  openFilter = () => {
    this.setState({
      openFilter: !this.state.openFilter,
    });
  };
  handleClickFilter = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleCloseFilter = () => {
    this.setState({ anchorEl: null });
  };
  handleChangeFilter = (name, event) => {
    let users = "";
    this.setState(
      {
        userTypes: [],
        statusFilter: {
          ...this.state.statusFilter,
          [name]: event.target.checked,
        },
        countChecked: 0,
      },
      () => {
        Object.entries(this.state.statusFilter).map(([key, value]) => {
          if (value) {
            users += "&userType=" + key;
            this.setState({
              countChecked: ++this.state.countChecked,
            });
          }
        });
        this.setState(
          {
            page: 1,
            userTypes: users,
          },
          () => {
            this.getMoreUsers(this.state.page, this.state.pagesize);
          }
        );
      }
    );
  };
  onBinaryView = () => {
    this.props.history.push("/users/view");
  };

  ChangeStatusSubmit = () => {
    if (this.state.selectedUser.disabled) {
      let msg = this.state.statusMode
        ? "allChildEnabledSuccess"
        : "userEnabled";
      fetch(
        `api/users/enable?userId=${this.state.selectedUser.id}&enableAllChild=${
          this.state?.statusMode ? true : false
        }`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          // if(response.status === 200){
          response.json().then((res) => {
            if (res.status === "success") {
              this.setState(
                {
                  onChangeStatusConfirmation: false,
                  statusMode: false,
                  isSubmit: false,
                  selectedUser: {
                    ...this.state.selectedUser,
                    disabled: false,
                  },
                },
                () => {
                  this.getMoreUsers();
                }
              );
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate(msg),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            }
          });
          // }
        })
        .catch((e) => {
          console.log("e =", e);
          // this.props.dispatch(Notifications.error({
          //   message: 'somethingWentWrong',
          //   autoDismiss: 5
          // }))
        });
    } else {
      let msg = this.state.statusMode
        ? "allChildDisabledSuccess"
        : "userDisabled";
      fetch(
        `api/users/disable?userId=${
          this.state.selectedUser.id
        }&disableAllChild=${this.state?.statusMode ? true : false}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          // if(response.status === 200){
          response.json().then((res) => {
            if (res.status === "success") {
              this.setState(
                {
                  onChangeStatusConfirmation: false,
                  statusMode: false,
                  isSubmit: false,
                  selectedUser: {
                    ...this.state.selectedUser,
                    disabled: true,
                  },
                },
                () => {
                  this.getMoreUsers();
                }
              );
              this.props.dispatch(
                Notifications.success({
                  message: this.props.translate(msg),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            } else if (res?.statusCode === "440") {
              window.location.replace("/login");
            } else if (res.statusCode) {
              var err = res?.message.split(":");
              err[1] = err[1].replace(")", "");
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate(err[1]),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
            }
          });
          // }
        })
        .catch((e) => {
          console.log("e =", e);
          // this.props.dispatch(Notifications.error({
          // message: 'somethingWentWrong',
          // autoDismiss: 5
          // }))
        });
    }

    this.setState({
      statusDialog: false,
    });
  };

  onChangeStatus = (selected) => {
    this.setState({
      onChangeStatusConfirmation: true,
      selectedUser: selected[0],
      isSubmit: true,
    });
  };

  changeStatusMode = () => {
    this.setState({ statusMode: !this.state.statusMode });
  };

  changeLimit =(name, event)=>{
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    // this.setState({
    //   [name]: value,
    // });
    this.setState(
      {
        [name]: value,
        form: {
          ...this.state.form,
          deviceLimit:value ?  -1 : 0,
          vehicleLimit:value ?  -1 : 0,
          userLimit: value ? -1 : 0,
        },
      },
      () => this.checkRequiredFields()
    );
  }


  addUser = () => {
    this.checkLimitsForCreatingUser(this.props.logInUser.id);
    let limitsOptionData = formDefaultState;
    let defaulfFeildsSetting = this.checkAttributes(this.props.logInUser);
    // limitsOptionData.deviceLimit = this.props.parentUnitLimits.remaningLimit
    // limitsOptionData.userLimit = this.props.parentUserLimits.remaningLimit
    // if (limitsOptionData.userLimit > 0) {
    //   limitsOptionData.userLimit = limitsOptionData.userLimit - 1
    // }
    if (this.props.logInUser.userType === -1) {
      // its just for owner user enable when other user implements
      this.fetchAccountsList(this.props.logInUser.accountId);
      this.fetchUsersList(this.props.logInUser.accountId);
    }
    this.setState({
      selectedUser: "",
      addOption: true,
      defaultUserList: false,
      noRecord: false,
      topRow: false,
      form: {
        ...limitsOptionData,
        accountId: this.props.logInUser.accountId,
        parentId: this.props.logInUser.id,
        ...defaulfFeildsSetting,
      },
      userTypeValue: "",
      userDetailsNameValue: "",
      userDetailsEmailValue: ""
      // accountSelectedData: {
      //   id: this.props.ServerSetting.id,
      //   label: this.props.ServerSetting.name,
      //   value: this.props.ServerSetting.id
      // },
      // userSelectedData: {
      //   id: this.props.logInUser.id,
      //   label: this.props.logInUser.name,
      //   value: this.props.logInUser.id
      // }
    },()=>{
      // if (this.props.logInUser.userType === 4)
      // {
        this.getGarageList()
      // }
    });
  };


  searchGarageList = async (inputValue) => {
    if (!inputValue) {
      return []
    }
    if (source) {
      source.cancel('Operation canceled due to new request=======.');
    }
    source = axios.CancelToken.source();

    return await instance({
      method: 'GET',
      url: `/api/garages/get?userId=${this.props.logInUser&&this.props.logInUser.id}&page=1&limit=50&all=true&search=${inputValue}`,
      cancelToken: source.token,

      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(response.data){
        return response.data.map(item => {
          return {
            id:item.id,
            key:item.id,
            name:item.name +" - "+ item.garageNumber,
            label:item.name +" - "+ item.garageNumber,
            uniqueId:item.id,
            valueType:'string',
            garageNumber:item.garageNumber

          }
        })
      }
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }
  getGarageList = async (inputValue) => {
    await instance({
      method: 'GET',
      url: `/api/garages/get?userId=${this.props.logInUser&&this.props.logInUser.id}&page=1&limit=50&all=true`,
      cancelToken: source.token,

      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if(response.data){
        const garages =  response.data.map(item => {
          return {
            id:item.id,
            key:item.id,
            name:item.name +" - "+ item.garageNumber,
            label:item.name +" - "+ item.garageNumber,
            uniqueId:item.id,
            valueType:'string',
            garageNumber:item.garageNumber

          }
        })
        this.setState({
          defaultGarageOptions : garages || []
        })
      }
    }).catch(error => {
      // errorHandler(error, this.props.dispatch)
    })
  }

  handleChangeExpense = (name, value) => {
    // console.log("name======", name, value);

    if (name === "garage") {
      this.setState(
        {
          form: {
            ...this.state.form,
            garage: value,
          },
        },
        () => {
          this.checkRequiredFields();
        }
      );
    }
  }
  userSelection(item) {
    console.log("item=====", item);
    if (item) {
      this.setState({
        defaultUserList: false,
        selectedUser: item[0],
        userDetailOption: true,
        topRow: false,
        isSelectedUser: true,
        addOption: false,
        changeContractOption: false,
        form: { ...item[0] },
        spCreated:false,
        aaCreated:false,
      },()=>{
          this.getGarageList()
      });
    }
  }

  au_submit() {
    let obj = {};
    let url = `/api/accounts`;
    let { attributes } = this.state.form;
    let {
      name,
      email,
      password,
      parentId,
      accountEmail,
      accountName,
      accountDescription,
      accountContact,
      customerNumber,
      contractName,
      contractNumber,
      vatPercentage,
      creditLimit,
      creditPeriod,
      contractStartDate,
      contractEndDate,
      settleDate,
      defaultSatisfactionDate,
      contractType,
      nameArabic,
      monthlyRevenueTarget,
      yearlyRevenueTarget,
      status,
      garage,
      pacContractId
    } = this.state.form;
    if (
      name.trim().length > 0 &&
      email.trim().length > 0 &&
      password.trim().length > 0
    ) {
      this.setState({
        selectedUser: "",
      });
      if (parentId) {
        attributes = { ...attributes, parentId: parentId };
      }
      const obj1 = userDataFormat(this.state.form, attributes);
      if (this.state.form.userType > 2) {
        obj = {
          account: {
            name: accountName,
            email: accountEmail,
            nameArabic: nameArabic,
            monthlyRevenueTarget :monthlyRevenueTarget,
            yearlyRevenueTarget : yearlyRevenueTarget, 
            description: accountDescription ? accountDescription : "",
            contact: accountContact ? accountContact : "",
            customerNumber: customerNumber ? customerNumber : "",
            contractName: contractName ? contractName : "",
            contractNumber: contractNumber ? contractNumber : "",
            vatPercentage: vatPercentage ? vatPercentage : 0,
            
            creditLimit: creditLimit ? creditLimit : "",
            creditPeriod: creditPeriod ? creditPeriod : "",
            contractStartDate: contractStartDate ? contractStartDate : "",
            contractEndDate: contractEndDate ? contractEndDate : "",
            settleDate : settleDate ? settleDate : " ",
            defaultSatisfactionDate : defaultSatisfactionDate ? defaultSatisfactionDate : " ",
            contractType: contractType ? contractType : 1,
            status:status ? status :'',
            garageNumber:garage?.garageNumber ? garage.garageNumber : "",
            pacContractId: pacContractId || "",

            attributes: { ...this.state.accountsform },
          },
          user: {
            ...obj1,
          },
        };
      } else {
        obj = { ...obj1 };
        url = `/api/users/`;
      }

          fetch(url, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ...obj,
            }),
          })
            .then((response) => {
              // if (response.ok) {
              response.json().then((user) => {
                if (user.status === "success") {
                  this.getUsersLimits()
                  if(user?.data?.user?.userType && (user?.data?.user?.userType === 4 || user?.data?.user?.userType === 3)){
                      this.setState({
                        defaultUserList: false,
                        selectedUser: user.data.user,
                        userDetailOption: true,
                        topRow: false,
                        isSelectedUser: true,
                        addOption: false,
                        changeContractOption: false,
                        // form: { ...user.data.user },
                        form: user.data.user,
                        spCreated:user?.data?.user?.userType === 4 ? true : false,
                        aaCreated:user?.data?.user?.userType === 3 ? true : false,

                      });

                    }else {
                      this.getMoreUsers();
                      if (this.props.onCloseModal) {
                        this.props.onCloseModal();
                      }
                      this.setState({
                        addOption: false,
                        selectedUser: "",
                        isVisableUserBtn: false,
                        defaultUserList: true,
                        topRow: true,
                        spCreated:false,
                        aaCreated:false,

                        })
                    }
                  
                  this.props.dispatch(
                    Notifications.success({
                      message: this.props.translate("userIsCreated"),
                      autoDismiss: 5,
                      location: this.props.location,
                    })
                  );
                } else if (user?.statusCode === "440") {
                  window.location.replace("/login");
                } else if (user.statusCode) {
                  var err = user?.message.split(":");
                  var err2 = err[1].split("&#");
                  this.props.dispatch(
                    Notifications.error({
                      message: this.props.translate(err2[0]),
                      autoDismiss: 5,
                      location: this.props.location,
                    })
                  );
                } else {
                  throw response;
                }
              });
              // }
              // else {
              //   throw response
              // }
            })
            .catch((e) => {
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate("somthingWentWrongMessage"),
                  autoDismiss: 5,
                  location: this.props.location,
                })
              );
              // if(e && e.text){
              //   e.text().then(err => {
              //     if (err.includes('Duplicate entry')) {
              //       this.setState({
              //         duplicateEntry: true,
              //         isVisableUserBtn: false,
              //         topRow:true,

              //       })
              //       this.props.dispatch(
              //         Notifications.error({
              //           message: this.props.translate('userEmailIsAllreadyInUsed'),
              //           autoDismiss: 5,
              //           location: this.props.location
              //         })
              //       )
              //     } else {
              //       let a = err.split('- SecurityException').splice(0, 1)
              //       this.props.dispatch(
              //         Notifications.error({
              //           message: a[0],
              //           autoDismiss: 5
              //         })
              //       )
              //     }
              //   })
              // } else {
              //   this.props.dispatch(
              //     Notifications.error({
              //       message: this.props.translate('somthingWentWrongMessage'),
              //       autoDismiss: 5,
              //       location: this.props.location
              //     })
              //   )
              // }
            });
    } else {
      this.setState({
        isVisableUserBtn: false,
      });
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate("emptyFields"),
          autoDismiss: 5,
          location: this.props.location,
        })
      );
    }
  }
  render() {
    const { logInUser, users, roles } = this.props;
    return (
      <div style={{ position: "relative" }}>
        <Style>{`
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors["600"]};
            color: ${this.props.themecolors["themeInverse"]};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected {
            background: ${this.props.themecolors["500"]};
            color: ${this.props.themecolors["themeInverse"]};
          }`}</Style>
        {this.state.addOption && logInUser && (
          <>
            {/* {this.state.isUserRecivedList ? ( */}
            <AddUserModal
              {...this.state}
              
              roleTypes={
                this.props.roles &&
                this.props.roles.filter((item) => item.id > 7)
              }
              searchGarageList={this.searchGarageList}
              handleChangeExpense={this.handleChangeExpense}
              changeLimit={this.changeLimit}
              logInUser={logInUser}
              formSubmit={this.au_submit}
              timeDateOnchange={this.timeDateOnchange}
              generateToken={this.generateToken}
              modalControle={this.modalControle}
              changeAttribute2={this.changeAttribute2}
              handleChange={this.handleChange}
              buttonText={this.props.translate("sharedCreate")}
              handleClickShowPostion={this.handleClickShowPostion}
              userType={logInUser.userType}
              setExpirationTime={this.setExpirationTime}
              timezone_handleChange={this.timezone_handleChange}
              setNoToken={this.setNoToken}
              userDetailsNameValue={this.state.userDetailsNameValue}
              userDetailsEmailValue={this.state.userDetailsEmailValue}
              userTypeValue={this.state.userTypeValue}
              usersLimit={
                this.props.parentUserLimits.remaningLimit -
                this.props.parentUserLimits.totalCount
              }
              vehicleLimit={
                this.props.parentUserLimits.remaningLimit -
                this.props.parentUserLimits.totalCount
              }
              deviceLimit={
                this.props.parentUnitLimits.remaningLimit -
                this.props.parentUnitLimits.totalCount
              }
              hasAccessOfUpdate={checkPrivileges("userUpdate")}
              hasAccessOfDelete={checkPrivileges("userDelete")}
              hasAccessOfCreate={checkPrivileges("userCreate")}
              handleChangeAttributes={this.handleChangeAttributes}
            />
            {/* // ) : ( */}
            {/* //   <Loader /> */}
            {/* // )} */}
          </>
        )}
        {this.state.changeContractOption && logInUser && (
          <>
            <ChangeContractModal 
              {...this.state}
              roleTypes={
                this.props.roles &&
                this.props.roles.filter((item) => item.id > 7)
              }
              // logInUser = {this.props.logInUser.accountId}
              fetchAccountsList={this.fetchAccountsList}
              logInUser={logInUser}
              formSubmit={this.cc_submit}
              modalControle={this.modalControle}
              buttonText={this.props.translate("migrate")}
              userType={logInUser.userType}
              handleChangeContractform={this.handleChangeContractform}
              isVisableUserBtn={this.state.isVisableUserBtn}
              openUserMigrateDialog={this.openUserMigrateDialog}
              closeUserMigrateDialog={this.closeUserMigrateDialog}
              handleUserMigrate={this.handleUserMigrate}
              loadNextPage={this.loadNextPage}
            />
          </>
        )}
        {this.state.selectedUser && this.state.userDetailOption && (
          <ViewUserDetail
           defaultGarageOptions={this.state.defaultGarageOptions}
            spCreated={this.state.spCreated}
            aaCreated={this.state.aaCreated}
            tableData={this.state.tableData}
            pagination={this.state.pagData}
            getMoreUsers={this.getMoreUsers}
            setPagination={this.setPagination}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            selectItemName={this.state.selectedUser.name}
            selectItemId={this.state.selectedUser}
            onClose={this.onCloseC_Modal}
            usersLimit={this.state.selectedUser.userLimit}
            deviceLimit={this.state.selectedUser.deviceLimit}
            replaceHtmlEntities={this.replaceHtmlEntities}
            searchGarageList={this.searchGarageList}

          />
        )}
        {this.state.selectedUser && this.state.showStatisticsModel && (
          <ViewStatistics
            selectItem={this.state.selectedUser}
            onClose={this.onCloseC_Modal}
            title="Users"
            showUnlimited={true}
          />
        )}

        {this.state.topRow && !this.state.addOption && (
          <>
            <Grid container className="breadcrumb-row">
              <Grid item xs={12} sm={3}>
                <h3 style={{ margin: 0 }}>{this.props.translate("users")}</h3>
              </Grid>
              <Grid item xs={12} sm={9} style={{ justifyContent: "flex-end" }}>
                <div style={{ width: "25%", marginRight: 20 }}>
                  <TextField
                    fullWidth
                    label={this.props.translate("search")}
                    value={this.state.itemSearch}
                    onChange={(e) => this.SearchItem(e.target.value)}
                  />
                </div>
                {this.props.logInUser.userType !== 4 ? (
                  <>
                    {this.state.openFilter && (
                      <div style={{ width: "20%", marginRight: 20 }}>
                        <Filter
                          countChecked={this.state.countChecked}
                          anchorEl={this.state.anchorEl}
                          handleChangeFilter={this.handleChangeFilter}
                          handleClickFilter={this.handleClickFilter}
                          handleCloseFilter={this.handleCloseFilter}
                          statusFilter={this.state.statusFilter}
                          userType={this.props.logInUser.userType}
                        />
                      </div>
                    )}
                    {!this.state.openFilter && (
                      <Button
                        disabled={
                          this.state.tableData && this.state.tableData.length === 0
                        }
                        aria-label="filter"
                        size="small"
                        color="inherit"
                        onClick={this.openFilter}
                        style={{ marginRight: "20px" }}
                      >
                        {this.props.translate("filter")}
                      </Button>
                    )}
                  </>
                ) : null}
                <ExportFile
                  title={this.props.translate("export")}
                  downloadType="users"
                  translate={this.props.translate}
                  dataLength={
                    this.props.users && this.props.users.length ? true : false
                  }
                  closedModel={this.onCloseC_Modal}
                  dispatch={this.props.dispatch}
                />
                
                <Button
                  // disabled={
                  //   !(checkPrivileges("userCreate") &&
                  //   (this.props.parentUserLimits.remaningLimit > 0 ||
                  //     this.props.parentUserLimits.remaningLimit === -1)
                  //     ? true
                  //     : false)
                  // }
                  disabled={!checkPrivileges("userCreate")}
                  aria-label="Create"
                  size="small"
                  color="inherit"
                  onClick={this.addUser}
                  id="createButton"
                >
                  {this.props.translate("create")}
                </Button>
              </Grid>
            </Grid>
          </>
        )}

        {this.state.defaultUserList ? (
          <>
            <Table
              rows={this.state.tableData || []}
              pagination={this.setPagination(this.state.pagData)}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              SearchItem={this.SearchItem}
              onEdit={this.userSelection}
              onDelete={this.removeUser}
              loginAsUser={this.loginAsUser}
              showStatistics={this.showStatistics}
              loginAsUserhandleChange={this.loginAsUserhandleChange}
              userDataArray1={this.state.UserType}
              userStatus={this.state.userStatus}
              userDataArrayChange1={(e) => this.handleUsersChange("type", e)}
              ServerSetting={this.props.ServerSetting}
              changeContract={this.changeContract}
              btnName={this.props.translate("create")}
              onCreate={() => this.addUser()}
              btnName1="users"
              onBinaryView={() => this.onBinaryView()}
              logInUser={this.props.logInUser}
              openUserStatusDialog={this.openUserStatusDialog}
              btnView={
                this.props.logInUser.userType === -1
                  ? this.props.translate("view")
                  : ""
              }
              viewButton={this.props.logInUser.userType === -1 ? true : false}
              isEditable={checkPrivileges("accountUpdate")}
              // onClick={checkPrivileges("accountUpdate") ? this.accountSelection : ''}
              // onDelete={checkPrivileges("accountDelete") ? this.removeAccount : ''}
              themecolors={this.props.themecolors}
              isRowIcons={true}
              isCursoPointer
              filterDropdown
              // setSerialNo
              hasAccessOfChangeStatus={
                this.props.logInUser.userType === -1 ? true : false
              }
              onChangeStatus={this.onChangeStatus}
              hasAccessOfUpdate={checkPrivileges("userUpdate")}
              hasAccessOfDelete={checkPrivileges("userDelete")}
              rowDefinition={rowDefinitionR(this.props.translate)}
            />
          </>
        ) : (
          !this.state.addOption &&
          !this.state.changeContractOption &&
          !this.state.showStatisticsModel &&
          !this.state.userDetailOption &&
          !this.state.noRecord && <Loader />
        )}
        {/* // ) : (
        //   (!this.state.addOption && !this.state.showStatisticsModel && !this.state.userDetailOption && !this.state.noRecord) 
        //   ? <Loader /> 
        //   : this.state.noRecord
        //   ? <h1>No records found</h1>
        //   : ''
        // ) */}
        {this.state.loader && <Loader />}
        {this.state.isUserLogin && (
          <LoginAsUser selectedUser={this.state.userDataForLogin} />
        )}
        {this.state.isMapModal && (
          <CustomDialog
            title={this.props.translate("setLocation")}
            themeColors={this.props.themeColors}
            visable={true}
            onClose={this.onCloseModal}
            isButtonVisable={true}
            draggable={true}
            CancelText={this.props.translate("sharedBack")}
            fullWidth
            headerActions={
              <Button
                size="small"
                variant="contained"
                color="secondary"
                disabled={!this.state.isVisableAddlocBtn}
                onClick={() => this.setSelectedLocation()}
              >
                {" "}
                {this.props.translate("sharedAdd")}
              </Button>
            }
            bodyPadding={0}
          >
            <MarkerMap
              getPostion={this.getPostion}
              minHeight={500}
              zoom={this.state.selectedUser.zoom || 0}
              lat={this.state.selectedUser.latitude || 0}
              lng={this.state.selectedUser.longitude || 0}
              height={"100%"}
            />
          </CustomDialog>
        )}
        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.deleteUser}
            title={this.props.translate("areYouWantToDelete")}
            children={this.state.selectedUser.name}
          />
        )}
        {this.state.onChangeStatusConfirmation && (
          <CustomDialog
            title="Change Status"
            themecolors={this.props.themecolors}
            visable={true}
            onClose={this.onCloseModal}
            bodyPadding={10}
            hideDragIcon
            fullWidth
            headerActions={
              <Button
                onClick={() => this.ChangeStatusSubmit()}
                disabled={!this.state.isSubmit}
              >
                {this.props.translate("Submit")}
              </Button>
            }
            isVisableBtn
            noFullScreen
          >
            {this.state.selectedUser.disabled ? (
              <>
                <Checkbox
                  name="DocCheckbox"
                  color="primary"
                  checked={this.state?.statusMode || ""}
                  value={true}
                  onChange={(e) => this.changeStatusMode(e)}
                  label={this.props.translate("allChildEnable")}
                />
              </>
            ) : (
              <>
                <Checkbox
                  name="DocCheckbox"
                  color="primary"
                  checked={this.state?.statusMode || ""}
                  value={true}
                  onChange={(e) => this.changeStatusMode(e)}
                  label={this.props.translate("allChildDisable")}
                />
              </>
            )}
          </CustomDialog>
        )}
        {this.state.userActivate && (
          <ConfirmDialoag
            onCancel={this.closeUserStatusDialog}
            onOk={this.handleUserStatus}
            title={this.state.userStatusLabel}
            children={<span>
              <span style={{ fontWeight: 'bold', }}>User Name : </span>
              {this.state.selectedUser.name}
            </span>}
          />
        )}
      </div>
    );
  }
}

{
  /* <RadioGroup
              aria-label='selectedDate'
              name='selectedDate'
              value={this.state.statusMode}
              onChange={e => this.changeStatusMode(e)}
              row
            >
              
              {this.state.selectedUser.disabled ? (
                <FormControlLabel
                  classes={{
                    label: 'form-contol-label'
                  }}
                  value={'enable'}
                  control={<Radio size='small' />}
                  label={this.props.translate('allChildEnable')}
                  labelPlacement='end'
                />
                ):(<FormControlLabel
                  classes={{
                    label: 'form-contol-label'
                  }}
                  value={'disable'}
                  control={<Radio size='small' />}
                  label={this.props.translate('allChildDisable')}
                  labelPlacement='end'
                />)}
            </RadioGroup> */
}

const mapState = (state) => {
  return {
    users: state.users,
    logInUser: state.logInUsers,
    roles: state.roles,
    themecolors: state.themecolors,
    ServerSetting: state.ServerSetting,
    devices: state.devices,
    groups: state.groups,
    accounts: state.accounts,
    parentUserLimits: state.parentUserLimits,
    parentUnitLimits: state.parentUnitLimits,
  };
};
const mapStateToProps = connect(mapState);
export const UsersList = mapStateToProps(withLocalize(usersModal));

const Filter = (props) => {
  const html = (
    <div>
      <a
        className="v-filter-field"
        href={null}
        aria-owns={props.anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={props.handleClickFilter}
      >
        {props.countChecked} Selected
      </a>
      <Menu
        id="simple-menu"
        anchorEl={props.anchorEl}
        open={Boolean(props.anchorEl)}
        onClose={props.handleCloseFilter}
      >
        <MenuItem
          component={FormControlLabel}
          style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
          control={
            <Checkbox
              name="user"
              checked={props.statusFilter[1]}
              onChange={(e) => props.handleChangeFilter("1", e)}
              canAssign
              canRemove
            />
          }
          label="User"
        />
        <MenuItem
          component={FormControlLabel}
          style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
          control={
            <Checkbox
              name="admin"
              checked={props.statusFilter[2]}
              onChange={(e) => props.handleChangeFilter("2", e)}
              canAssign
              canRemove
            />
          }
          label="Admin"
        />
        {(props.userType > 3 || props?.userType === -1) && (
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
            control={
              <Checkbox
                name="adminAccount"
                checked={props.statusFilter[3]}
                onChange={(e) => props.handleChangeFilter("3", e)}
                canAssign
                canRemove
              />
            }
            label="Admin Account"
          />
        )}
        {(props.userType > 4 || props?.userType === -1) && (
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
            control={
              <Checkbox
                name="ServiceProvider"
                checked={props.statusFilter[4]}
                onChange={(e) => props.handleChangeFilter("4", e)}
                canAssign
                canRemove
              />
            }
            label="Service Provider"
          />
        )}
        {(props.userType > 5 || props?.userType === -1) && (
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
            control={
              <Checkbox
                name="Reseller"
                checked={props.statusFilter[5]}
                onChange={(e) => props.handleChangeFilter("5", e)}
                canAssign
                canRemove
              />
            }
            label="Reseller"
          />
        )}
        {props.userType === -1 && (
          <MenuItem
            component={FormControlLabel}
            style={{ margin: 0, paddingTop: 0, paddingBottom: 0 }}
            control={
              <Checkbox
                name="Distributor"
                checked={props.statusFilter[6]}
                onChange={(e) => props.handleChangeFilter("6", e)}
                canAssign
                canRemove
              />
            }
            label="Distributor"
          />
        )}
      </Menu>
    </div>
  );
  return <div>{html}</div>;
};

const rowDefinitionR = (translate) => {
  if (localStorage.getItem("adminToken")) {
    const commonFields =  [
      {
        id: "userType",
        type: "symbol",
        numeric: false,
        disablePadding: false,
        label: translate("symbol"),
      },
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: translate("sharedName"),
      },
      {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: translate("userEmail"),
      },
      {
        id: "roleName",
        numeric: false,
        disablePadding: false,
        label: translate("roleName"),
      },
      {
        id: "parentName",
        numeric: false,
        disablePadding: false,
        label: translate("parentName"),
      },
      {
        id: "accountName",
        numeric: false,
        disablePadding: false,
        label: translate("accountName"),
      },
      {
        id: "created",
        numeric: false,
        disablePadding: false,
        label: translate("createdAt"),
      },
      {
        id: "changeContract",
        numeric: false,
        disablePadding: false,
        label: translate("changeContract"),
      },
      // {
      //   id: "userStatus",
      //   numeric: false,
      //   disablePadding: false,
      //   label: translate("accountStatus"),
      // }
    ];
    if (checkPrivileges("userStatusViewOnly")) {
      commonFields.push({
        id: "userStatus",
        numeric: false,
        disablePadding: false,
        label: translate("accountStatus"),
      });
    }

    return commonFields;

  } else {
    const commonFields = [
      {
        id: "userType",
        type: "symbol",
        numeric: false,
        disablePadding: false,
        label: translate("symbol"),
      },
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: translate("sharedName"),
      },
      {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: translate("userEmail"),
      },
      {
        id: "roleName",
        numeric: false,
        disablePadding: false,
        label: translate("roleName"),
      },
      {
        id: "parentName",
        numeric: false,
        disablePadding: false,
        label: translate("parentName"),
      },
      {
        id: "accountName",
        numeric: false,
        disablePadding: false,
        label: translate("accountName"),
      },
      {
        id: "loginAsUser",
        numeric: false,
        disablePadding: false,
        label: translate("loginAsUser"),
      },
      {
        id: "created",
        numeric: false,
        disablePadding: false,
        label: translate("createdAt"),
      },
      {
        id: "changeContract",
        numeric: false,
        disablePadding: false,
        label: translate("changeContract"),
      },
      // {
      //   id: "userStatus",
      //   numeric: false,
      //   disablePadding: false,
      //   label: translate("accountStatus"),
      // }
    ];
    if (checkPrivileges("userStatusViewOnly")) {
      commonFields.push({
        id: "userStatus",
        numeric: false,
        disablePadding: false,
        label: translate("accountStatus"),
      });
    }
    return commonFields;
  }
};
