import React, { Component } from 'react'
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Paper from '@material-ui/core/Paper'
import Preferences from './perferences'
import Default from './default'
import Branding from './branding'
import EmailGatway from './emailGatway'
import SmsGateway from './smsGateway'
import Wasl from './wasl'
import Account from './account'
import Style from 'style-it'
import { withLocalize } from 'react-localize-redux'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { withStyles } from '@material-ui/core/styles'
import Breadcrumb from '../common/Breadcrumb'
import Button from '../common/Button'
import Notifications from 'react-notification-system-redux'
import Loader from '../../Layout/Loader'

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    position: 'relative',
    padding: theme.spacing(1) * 2,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1) * 2
  },
  nested: {
    paddingLeft: theme.spacing(1) * 4
  }
})

class serverSettings extends Component {
  constructor () {
    super()
    this.state = {
      value: 0,
      serverSetting: '',
      serverPrefernceSetting: '',
      AccountSettings:'',
      loader:true,
    }
  }
  componentWillMount () {
    if(!this.props.updateOtherServerSetting){
      this.setState({value:'settings'})
    }
    if (this.props.logInUser.userType === -1) {
      // server call
      fetch(`/api/server/`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          if (response.ok) {
            response.json().then(server => {
              if(server.status ==='success'){
                this.setState({
                  serverPrefernceSetting: server.data
                })
              }
              else if(server?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(server.statusCode){
                var err = server?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
              }
            })
          } else {
            throw response
          }
        })
        .catch(e => {
          console.log('e =',e)
          // this.props.dispatch(Notifications.error({
          //   message: 'somethingWentWrong',
          //   autoDismiss: 5
          // }))
        })
    }
    this.setAccountSettings(this.props.ServerSetting)
  }

  componentWillReceiveProps (n){
    if(n?.ServerSetting?.id !== this.props?.ServerSetting?.id){
        this.setAccountSettings(n.ServerSetting)
      }
  }

  setAccountSettings = (item)=>{
    if(item){
      this.setState({AccountSettings:item, loader:false})
    }

  }

  handleChange = (event, value) => {
    this.setState({ value })
  }
  handleChangeServerSettings =(item)=>{
    this.setState({serverPrefernceSetting:item})
  }
  handleChangeAccounts =(item)=>{
    this.setState({AccountSettings:item, loader:false})
  }
  render () {
    const { classes, logInUser } = this.props
    const { value, serverSetting, serverPrefernceSetting } = this.state
    const orientation = window.innerWidth <= 959 ? 'horizontal' : 'vertical'
    let tabName = 'Edit'
    // if(value ===0){tabName='Account Settings'}
    // else if(value===1){tabName='Branding'}
    // else if(value===2){tabName='Preferences'}
    // else if(value===3){tabName='Default'}
    // else if(value===4){tabName='Email Gateway'}
    // else if(value===5){tabName='Sms Gateway'}

    if(value ===0){tabName=this.props.translate('branding')}
    else if(value===1){tabName=this.props.translate('emailGateway')}
    else if(value===2){tabName=this.props.translate('smsGateway')}

    if (this.props.ServerSetting && !this.state.loader) {
      if (this.props.updateOtherServerSetting) {
        return (
          <div className={classes.root}>
            <Breadcrumb
              tabName={tabName}
              onClose={this.props.onClose}
              moduleName={this.props.translate('serverSettings')}
            />
           <Style>{`
          .tab-vertial-wrapper > .custom-tabs {
            background: ${this.props.themecolors.backgroundColor};
          }
          .custom-tab-button2 .MuiTab-wrapper {
            align-items: normal;
            flex-direction: row;
            justify-content: unset;

          }
          .custom-tab-button2 {
            color: ${this.props.themecolors.textColor};
            text-align: left;
            text-transform: inherit;
            border-bottom: 1px solid rgba(0,0,0,0.05)
          }
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors['600']};
            color: ${this.props.themecolors['themeInverse']};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected2 {
            color: ${this.props.themecolors['500']};
          }`}</Style>
          <div className="v-tabs">
           <AppBar elevation={0} position="static" style={{background: 'transparent'}} className="tab-vertial-wrapper">
              <Tabs
                value={value}
                onChange={this.handleChange}
                orientation={orientation}
                classes={{ root: 'custom-tabs' }}
                TabIndicatorProps={{
                  style: { background: 'transparent' }
                }}
              >
                <Tab
                  classes={{
                    root: 'custom-tab-button2',
                    selected: 'custom-tab-button-selected2'
                  }}
                  label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('branding')}</>}
                />
                <Tab
                  classes={{
                    root: 'custom-tab-button2',
                    selected: 'custom-tab-button-selected2'
                  }}
                  label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('emailGateway')}</>}
                />
                <Tab
                classes={{
                  root: 'custom-tab-button2',
                  selected: 'custom-tab-button-selected2'
                }}
                label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('smsGateway')}</>}
              />
              </Tabs>
            
            <Paper style={{ padding: 16, minHeight: 270, flex: 1 }} square={true}>
              {value === 0 && (
                <div>
                  <Branding
                    {...this.props}
                    ServerSetting={
                      this.props.updateOtherServerSetting
                        ? this.props.otherServerSetting
                        : this.props.ServerSetting
                    }
                  />
                </div>
              )}
              {value === 1 && (
                <div>
                  <EmailGatway
                    {...this.props}
                    translate={this.props.translate}
                    ServerSetting={
                      this.props.updateOtherServerSetting
                        ? this.props.otherServerSetting
                        : this.props.ServerSetting
                    }
                  />
                </div>
              )}
              {value === 2 && (
                <div>
                  <SmsGateway
                    {...this.props}
                    translate={this.props.translate}
                    ServerSetting={
                      this.props.updateOtherServerSetting
                        ? this.props.otherServerSetting
                        : this.props.ServerSetting
                    }
                  />
                </div>
              )}
            </Paper>
            </AppBar>
          </div>
          </div>
        )
      } else {
        if (this.props.logInUser.userType === -1) {
          if(value ==='settings'){tabName=this.props.translate('accountSettings')}
          else if(value==='branding'){tabName=this.props.translate('branding')}
          else if(value==='preferences'){tabName=this.props.translate('preferences')}
          else if(value==='default'){tabName=this.props.translate('default')}
          else if(value==='emailGateway'){tabName=this.props.translate('emailGateway')}
          else if(value==='smsGateway'){tabName=this.props.translate('smsGateway')}
          else if(value==='wasl'){tabName=this.props.translate('Wasl')}
          return (
            <div className={classes.root}>
               <Breadcrumb
                  tabName={tabName}
                  onClose={this.props.onClose}
                  moduleName={this.props.translate("serverSettings")}
                />
           <Style>{`
          .tab-vertial-wrapper > .custom-tabs {
            background: ${this.props.themecolors.backgroundColor};
          }
          .custom-tab-button2 .MuiTab-wrapper {
            align-items: normal;
            flex-direction: row;
            justify-content: unset;

          }
          .custom-tab-button2 {
            color: ${this.props.themecolors.textColor};
            text-align: left;
            text-transform: inherit;
            border-bottom: 1px solid rgba(0,0,0,0.05)
          }
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors['600']};
            color: ${this.props.themecolors['themeInverse']};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected2 {
            color: ${this.props.themecolors['500']};
          }`}</Style>
            <div className="v-tabs">
            <AppBar elevation={0} position="static" style={{background: 'transparent'}} className="tab-vertial-wrapper">
                <Tabs
                  value={value}
                  onChange={this.handleChange}
                  orientation={orientation}
                  classes={{ root: 'custom-tabs' }}
                  TabIndicatorProps={{
                    style: { background: 'transparent' }
                  }}
                >
                  <Tab
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                    value="settings"
                    label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('accountSetting')}</>}
                  />
                  <Tab
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                    value="branding"
                    label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('branding')}</>}
                  />
                  <Tab
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                    value="preferences"
                    label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('preferences')}</>}
                  />
                  <Tab
                    classes={{
                        root: 'custom-tab-button2',
                        selected: 'custom-tab-button-selected2'
                      }}
                      value="default"
                    label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('default')}</>}
                  />
                  <Tab
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                    value="emailGateway"
                    label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('emailGateway')}</>}
                  />
                  <Tab
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                    value="smsGateway"
                    label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('smsGateway')}</>}
                  />
                  <Tab
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                    value="wasl"
                    label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('wasl')}</>}
                  />
                </Tabs>
              {(
                <Paper style={{ padding: value === 'settings' ? 0 : 16, minHeight: 270, flex: 1 }} square={true}>
                  {value === 'settings' && (
                    <div>
                      <Account 
                        {...this.props}
                        handleChangeAccounts={this.handleChangeAccounts}
                        ServerSetting={this.state.AccountSettings}/>
                    </div>
                  )}
                  {value === 'branding' && (
                    <div>
                      <Branding {...this.props} />
                    </div>
                  )}
                  {value === 'preferences' && (
                    <div>
                      <Preferences
                        {...this.props}
                        ServerSetting={serverPrefernceSetting}
                        handleChangeServerSettings={this.handleChangeServerSettings}
                      />
                    </div>
                  )}
                  {value === 'default' && (
                    <div>
                      <Default 
                        {...this.props} 
                        ServerSetting={serverPrefernceSetting}
                        handleChangeServerSettings={this.handleChangeServerSettings}
                      />
                    </div>
                  )}
                  {value === 'emailGateway' && (
                    <div>
                      <EmailGatway
                        {...this.props}
                        translate={this.props.translate}
                        handleChangeServerSettings={this.handleChangeServerSettings}
                      />
                    </div>
                  )}
                  {value === 'smsGateway' && (
                    <div>
                      <SmsGateway
                        {...this.props}
                        translate={this.props.translate} />
                    </div>
                  )}
                  {value === 'wasl' && (
                    <div>
                      <Wasl
                        ServerSetting={
                          this.props.updateOtherServerSetting
                            ? this.props.otherServerSetting
                            : this.props.ServerSetting
                        }
                      />
                    </div>
                  )}
                </Paper>
              )}
               </AppBar>
            </div>
            </div>
          )
        } else {
          return (
            <div className={classes.root}>
               <Breadcrumb
                  tabName={tabName}
                  onClose={this.props.onClose}
                  moduleName={this.props.translate("serverSettings")}
              />
            <Style>{`
           .tab-vertial-wrapper > .custom-tabs {
             background: ${this.props.themecolors.backgroundColor};
           }
           .custom-tab-button2 .MuiTab-wrapper {
             align-items: normal;
             flex-direction: row;
             justify-content: unset;
 
           }
           .custom-tab-button2 {
             color: ${this.props.themecolors.textColor};
             text-align: left;
             text-transform: inherit;
             border-bottom: 1px solid rgba(0,0,0,0.05)
           }
           .custom-tab-button {
             min-height: 29px;
             border-radius: 6px 6px 0 0;
             margin: 0 2px;
             background: ${this.props.themecolors['600']};
             color: ${this.props.themecolors['themeInverse']};
           }
           .custom-tabs {
             min-height: 30px;
             margin: 10px 6px 0;
           }
 
           .custom-tab-button-selected2 {
             color: ${this.props.themecolors['500']};
           }`}</Style>
             <div className="v-tabs">
             <AppBar elevation={0} position="static" style={{background: 'transparent'}} className="tab-vertial-wrapper">
                 <Tabs
                   value={value}
                   onChange={this.handleChange}
                   orientation={orientation}
                   classes={{ root: 'custom-tabs' }}
                   TabIndicatorProps={{
                     style: { background: 'transparent' }
                   }}
                 >
                  <Tab
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                    label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('Account Setting')}</>}
                  />
                  <Tab
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                    label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('Branding')}</>}
                  />
                  <Tab
                    classes={{
                      root: 'custom-tab-button2',
                      selected: 'custom-tab-button-selected2'
                    }}
                    label={<><KeyboardArrowRightIcon color='currentColor' />{this.props.translate('emailGateway')}</>}
                  />
                </Tabs>
              
              <Paper style={{ padding: 16, minHeight: 270 , flex:1}} square={true}>
                {value === 0 && (
                  <div>
                    <Account {...this.props} readOnly={true} />
                  </div>
                )}
                {value === 1 && (
                  <div>
                    <Branding {...this.props} />
                  </div>
                )}
                {value === 2 && (
                  <div>
                    <EmailGatway
                      {...this.props}
                      translate={this.props.translate}
                    />
                  </div>
                )}
              </Paper>
              </AppBar>
            </div>
            </div>
          )
        }
      }
    } else {
      return <Loader defaultStyle/>
    }
  }
}


const mapState = state => {
  return {
    logInUser: state.logInUsers,
    themecolors: state.themecolors,
    users: state.users,
    accounts: state.accounts,
    roles: state.roles,
    ServerSetting: state.ServerSetting
  }
}

const mapStateToProps = connect(mapState)
export const Serversettings = mapStateToProps(
  withLocalize(withStyles(styles)(serverSettings))
)