import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import Style from "style-it";
import {  Grid } from "@material-ui/core";

import moment from "moment";

class SimahList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //  addOption:false,
    };
  }

  render() {
    const { devices, group } = this.props;
    let fileName = "units";
    return (
      <div>
        <Style>{`
          .custom-tab-button {
            min-height: 29px;
            border-radius: 6px 6px 0 0;
            margin: 0 2px;
            background: ${this.props.themecolors["600"]};
            color: ${this.props.themecolors["themeInverse"]};
          }
          .custom-tabs {
            min-height: 30px;
            margin: 10px 6px 0;
          }

          .custom-tab-button-selected {
            background: ${this.props.themecolors["500"]};
            color: ${this.props.themecolors["themeInverse"]};
          }`}</Style>

        <Fragment>
          <Grid container className="breadcrumb-row">
            <Grid item xs={12} sm={3}>
              <h3 style={{ margin: 0 }}>{this.props.translate("creditBureau")}</h3>
            </Grid>
          </Grid>

          {/* <Table
            // rows={this.props.garagesData?.data || []}

            // pagination={
            //   this.props.garagesData &&
            //   this.props.garagesData.data &&
            //   this.props.garagesData.data.length &&
            //   this.props.setPagination(this.props.garagesData)
            // }

            // handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
            // handleChangePage={this.props.handleChangePage}

            // filterDropdown
            // onClick={this.props.workShopSelection}
            isEditable={true}
            // onDelete={this.props.removeEnable}
            hasAccessOfDelete={checkPrivileges("garageDelete")}
            hasAccessOfUpdate={checkPrivileges("garageUpdate")}
            isCursoPointer
            // onEdit={this.props.workShopSelection}
            // setSerialNo
            // SearchItem={this.SearchItem}
            rowDefinition={[
              // {
              //   id: 'adjustmentType',

              //   label: this.props.translate('adjustmentType')
              // },
              {
                id: "name",
                label: this.props.translate("name"),
              },
              {
                id: "garageNumber",
                label: this.props.translate("garageNumber"),
              },
              {
                id: "description",
                label: this.props.translate("description"),
              },
              {
                id: "email",
                label: this.props.translate("email"),
              },
              {
                id: "garageManager",
                label: this.props.translate("garageManager"),
              },
              {
                id: "created",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("created"),
              },

              // {
              //   id: 'host',
              //   numeric: false,
              //   disablePadding: false,
              //   label: this.props.translate('host')
              // },
            ]}
          /> */}
          {/* </>
          )} */}

          {/* {this.props.onDeleteConfirmation && (
            <ConfirmDialoag
              onCancel={this.props.onCancel}
              onOk={this.props.deleteWorkShop}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.selectedWorkShop.name}
            />
          )} */}
        </Fragment>
      </div>
    );
  }
}
const mapState = (state) => {
  const devices = {
    ...state.devices,
    data:
      state.devices.data &&
      state.devices.data.map((d) => {
        if (d.created) {
          if (state.logInUsers.twelveHourFormat) {
            d.created = moment(d.created).format("YYYY-MM-DD hh:mm A");
          } else {
            d.created = moment(d.created).format("YYYY-MM-DD HH:mm");
          }
          return d;
        } else {
          return d;
        }
      }),
  };

  return {
    devices: state.devices,
    logInUser: state.logInUsers,
    group: state.groups,
    themecolors: state.themecolors,
    ServerSetting: state.ServerSetting,
    parentUnitLimits: state.parentUnitLimits,
  };
};

const mapStateToProps = connect(mapState);
export const ReportsList = mapStateToProps(withLocalize(SimahList));
