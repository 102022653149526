import React, { Component, Fragment } from "react";
import SimpleModalWrapped from "../common/Modal";
import { withStyles } from "@material-ui/core/styles";

import { Grid, MenuItem } from "@material-ui/core";
import { withLocalize } from "react-localize-redux";
import { timeZonesTypes } from "../Attributes/timezone";
import Button from "../common/Button";
import Checkbox from "../common/Checkbox";
import TextField from "../common/TextField";
import { checkPrivileges } from "../../Helpers";


let cancelTokenSource;

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});




class editItems extends Component {
  constructor() {
    super();
    this.state = {
    };
  }

  replaceAmpersands(obj) {
    for (const prop in obj) {
      if (typeof obj[prop] === 'string') {
        obj[prop] = obj[prop].replace(/amp;/g, '');
      } else if (typeof obj[prop] === 'object') {
        this.replaceAmpersands(obj[prop]);
      }
    }
  }
  render() {
    let price = 0;
    const { form, classes } = this.props;
    this.replaceAmpersands(form);
    return (
      <Fragment>
        <SimpleModalWrapped
          {...this.state}
          {...this.props}
          visable
          isButtonVisable
          title={form?.categoryName}
          isNotShowAttributesBtn
          formSubmit={this.props.formSubmit}
          // addAttributes={this.addAttributes}
          modalControle={this.props.modalControle}
          btnclosetext={this.props.translate("sharedBack")}
            buttonText={"sharedCreate"}
          isVisableBtn={this.props.isVisible}
          // isVisableBtn={true}
          // notVisableBackbtn={f}
          // showPortList={this.props.showPortList}
          // showPort={this.props.showPort}
          content={
            <>
              <form autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4} lg={4} style={{display:"flex", }} >
                     <h3>
                      { form?.categoryName ==="items" ? this.props.translate("itemName") :
                        form?.categoryName ==="services" ? this.props.translate("serviceName") :
                        form?.categoryName ==="packages" ? this.props.translate("packageName") : null}
                      {" "}
                       : {" "}
                   
                     <span style={{fontWeight:400}}>
                      {this.props.translate(`${form?.itemName}`)} </span> </h3>
                   </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} style={{display:"flex", }} >
                        <h3>
                          {/* {this.props.translate("number")}  */}
                        { form?.categoryName ==="items" ? this.props.translate("itemCode") :
                        form?.categoryName ==="services" ? this.props.translate("serviceCode") : 
                        form?.categoryName ==="packages" ? this.props.translate("packageCode") : null}
                      {" "}
                        : <span style={{fontWeight:400}}>
                           {this.props.translate(`${form?.itemCode}`)} </span> </h3>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={4} style={{display:"flex", }} >
                        <h3>{this.props.translate("accountName")} : <span style={{fontWeight:400}}>
                           {this.props.translate(`${form?.accountName}`)} </span> </h3>
                    </Grid>
                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                  </Grid> */}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        id="subTypeGroup"
                        margin="dense"
                        label={this.props.translate("group")}
                        variant="outlined"
                        fullWidth
                        disabled={this.props.itemAlredyExists ? true : false}


                        value={form?.subTypeGroup || ""}
                        onChange={this.props.handleChange("subTypeGroup")}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="itemPrice"
                      label={this.props.translate("itemPrice")}
                      placeholder=""
                      value={form?.itemPrice}
                      onChange={this.props.handleChange("itemPrice")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      type="number"
                      required
                      disabled={this.props.itemAlredyExists ? true : false}

                      // error={this.props.AmountError ? true : false}
                      // helperText={this.props.AmountError || ""}
                    />
                  </Grid>
                  {checkPrivileges("invoicePurchasePriceView") && (
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="purchasePrice"
                      margin="dense"
                      label={this.props.translate("purchasePrice")}
                      variant="outlined"
                      fullWidth
                      disabled={this.props.itemAlredyExists ? true : false}
                      type="number"
                      value={form?.purchasePrice || ""}
                      onChange={this.props.handleChange("purchasePrice")}
                    />
                    </Grid>
                 )}

                <Grid item xs={12} sm={6} md={4} lg={3}>
                </Grid>

                    {this.props.itemAlredyExists && (
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <h2  style={{color:"red"}} >{this.props.translate("itemAlreadyExistsCann'tAddAgain")}</h2>
                        </Grid>
                    )}
                <Grid item xs={12} sm={6} md={4} lg={3} style={{margin:"0px 0px 5px 0px"}}>
                 <Button
                          size="small"
                          // style={{ marginRight: 15 }}
                          className="button-white"
                          onClick={this.props.handleQuntityPriceScale}
                          // disabled={ this.props.quantityError ? "disabled" : ""}
                      disabled={this.props.itemAlredyExists ? true : false}

                        >
                          {this.props.translate("addQty/Price")}
                        </Button>
                </Grid>
                     
                </Grid>
                    {this.props.showGrid && (
                      <Grid container spacing={1}>
                        {form?.attributes?.quantityPriceScale?.map((item, index) => (
                        <Grid container spacing={1} 
                        key={index}
                        >
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                        id={`quantity-${index}`}
                        required
                        margin="dense"
                        label={this.props.translate("quantity")}
                        variant="outlined"
                        fullWidth
                        type="number"
                        value={item.quantity || 0}
                        onChange={(e) => this.props.handleChange1(index, 'quantity', e.target.value)}
                        error={index === (form.attributes.quantityPriceScale.length - 1) && this.props.quantityError !== ''}
                        helperText={index === (form.attributes.quantityPriceScale.length - 1) ? this.props.quantityHelperText : ''}
                      />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <TextField
                            margin="dense"
                            label="Price"
                            type="number"
                            variant="outlined"
                            fullWidth
                            value={item.price || 0}
                            onChange={(e) => this.props.handleChange1(index, 'price', e.target.value)}
                          /> 
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                          <div style={{ display: 'flex', marginTop: 10 }}>
                            <Button
                              size="small"
                              style={{ marginRight: 15 }}
                              className="button-white"
                              onClick={this.props.handleAddMore}
                              disabled={ this.props.quantityError ? "disabled" : ""}
                            >
                              Add More
                            </Button>
                            <Button size="small"
                              onClick={() => this.props.handleDelete(index)}
                              >
                              Delete
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                      ))}
                      </Grid>
                   )}
              </form>
            </>
          }
        />
     
      </Fragment>
    );
  }
}

const EditItems = withStyles(styles);
export default EditItems(withLocalize(editItems));
