import React, { Component } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { checkPrivileges, setCookie } from "../../Helpers";
import Style from "style-it";
import { NavLink } from "react-router-dom";
import { Tooltip, Icon } from "@material-ui/core";
import { withLocalize } from "react-localize-redux";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { ReactComponent as accountsIcon } from "./../../assets/nav/accounts.svg";
import { ReactComponent as DashboardIcon } from "./../../assets/nav/dashboard.svg";
import { ReactComponent as usersIcon } from "./../../assets/nav/users.svg";
import { ReactComponent as unitsIcon } from "./../../assets/nav/units.svg";
import { ReactComponent as groupsIcon } from "./../../assets/nav/groups.svg";
import { ReactComponent as serverSettingsIcon } from "./../../assets/nav/server-settings.svg";
import { ReactComponent as sysLogsIcon } from "./../../assets/nav/sys-logs.svg";
import { ReactComponent as roleManageIcon } from "./../../assets/nav/role-manage.svg";
import { ReactComponent as contractMgmtIcon } from "./../../assets/nav/contractMgmt.svg";
import { ReactComponent as invoiceIcon } from "./../../assets/nav/invoice.svg";
import { ReactComponent as DeliveryIcon } from "./../../assets/nav/sticky-note-01.svg";
import { ReactComponent as OutOfContract } from "./../../assets/nav/out-of-contracat-01.svg";
import { ReactComponent as MissedOpportunity } from "./../../assets/nav/mised-opportunity-01.svg";
import { ReactComponent as tempGenIcon } from "./../../assets/nav/tem-gen.svg";
import { ReactComponent as trashIcon } from "./../../assets/nav/trash.svg";
import { ReactComponent as couponIcon } from "./../../assets/nav/voucher.svg";
import { ReactComponent as accountsSettings } from "./../../assets/nav/accountsSettings.svg";
import { ReactComponent as actionLog } from "./../../assets/nav/actionLog.svg";
import { ReactComponent as vehicleIcon } from "./../../assets/nav/vehicle.svg";
import { ReactComponent as Garage } from "./../../assets/nav/garage-icon.svg";
import { ReactComponent as EXDB } from "./../../assets/nav/exDB.svg";
import { ReactComponent as B2C } from "./../../assets/nav/b2c.svg";
import { ReactComponent as Speaker } from "./../../assets/nav/loud-speaker.svg";
import { ReactComponent as ScheduleIcon } from "./../../assets/nav/schedule.svg";
import { ReactComponent as SimahIcon } from "./../../assets/nav/simah.svg";
import { ReactComponent as TriggersIcon } from "./../../assets/nav/trigger.svg";

import "./index.scss";
import Scrollbar from "react-scrollbars-custom";
const drawerWidth = 195;

const useStyles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "transparent",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: "none",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // transition: '2.3s ease'
  },
  menuButton: {
    // marginRight: 36
    // marginLeft: -25
  },
  hide: {
    display: "none",
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpen: {
    backgroundColor: "#fff",
    width: drawerWidth,
    top: 47,
    bottom: 0,
    height: "auto",
    transition: "0.3s ease",
  },
  drawerClose: {
    top: 47,
    bottom: 0,
    height: "auto",
    overflowX: "hidden",
    width: 50,
    transition: "0.3s ease",
    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.up('sm')]: {
    //   width: theme.spacing(9) + 1
    // }
  },

  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    backgroundColor: "transparent",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

class MainSideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      navigation: {
        ex_dashboard: {
          checked: checkPrivileges("excetiveDashboard") ,
          href: "/ex_dashboard",
          icon: EXDB,
          title: this.props.translate("ex_dashboard"),
        },
        maintenance_dashboard: {
          checked: checkPrivileges("maintenanceDashboard"),
          href: "/maintenance_dashboard",
          icon: DashboardIcon,
          title: this.props.translate("dashboard"),
        },
        accounts: {
          checked:
            checkPrivileges("account") &&
            props.userInfo.userType !== 3 &&
            props.userInfo.roleId !== 57 &&
            props.userInfo.roleId !== 58 &&
            props.userInfo.userType !== 2,
          href: "/accounts",
          icon: accountsIcon,
          title: "accounts",
        },
        users: {
          checked: checkPrivileges("user"),
          href: "/users",
          icon: usersIcon,
          title: "users",
        },
        b2c: {
          checked: checkPrivileges("accountCustomerProfile"),
          href: "/b2c",
          icon: B2C,
          title: "customerProfile",
        },
        contractManagment: {
          checked: checkPrivileges("expensetype"),
          href: "/contractManagement",
          icon: contractMgmtIcon,
          title: "contractManagement",
        },
        invoice: {
          checked: checkPrivileges("invoice"),
          href: "/invoice",
          icon: invoiceIcon,
          title: "invoices",
        },
        deliveryNote: {
          checked: checkPrivileges("deliverynote"),
          href: "/delivery-note",
          icon: DeliveryIcon,
          title: "deliveryNote",
        },
        simah: {
          checked: checkPrivileges("creditbureauSimah"),
          href: "/simah",
          icon: SimahIcon,
          title: "creditBureau",
        },
        scheduleReportsAndNotifications: {
          checked: checkPrivileges("deliverynote"),
          href: "/schedule-Reports-And-Notifications",
          icon: ScheduleIcon,
          title: "scheduleReportsAndNotifications",
        },
        missingItems : {
          checked: checkPrivileges("outOfContract"),
          href: "/missing-items",
          icon: OutOfContract,
          title: "missingItems",
        },
        missedOpportunities : {
          checked: checkPrivileges("aiPms"),
          href: "/missed-opportunities",
          icon: MissedOpportunity,
          title: "missedOpportunities",
        },
        payment: {
          checked: checkPrivileges("payment"),
          href: "/new_payment",
          icon: groupsIcon,
          title: "payments",
        },
        vehicle: {
          checked: checkPrivileges("vehicle"),
          href: "/vehicle",
          icon: vehicleIcon,
          title: "fleet",
        },
        workshop: {
          checked: checkPrivileges("garage"),
          href: "/workshops",
          icon: Garage,
          title: "workshops",
        },
        commutator_module: {
          checked: checkPrivileges("broadcastCreate"),
          href: "/commutator",
          icon: Speaker,
          title: "campaign",
        },
        offers: {
          checked: checkPrivileges("role"),
          href: "/offers",
          icon: couponIcon,
          title: "offers",
        },
        credit_debit_notes: {
          checked: checkPrivileges("creditdebitnote"),
          href: "/credit_debit_notes",
          icon: tempGenIcon,
          title: "credit-debit notes",
        },
        reports: {
          checked: checkPrivileges("report"),
          href: "/reports",
          icon: couponIcon,
          title: "reports",
        },
        triggers: {
          checked: checkPrivileges("invoiceTrigger"),
          href: "/triggers",
          icon: TriggersIcon,
          title: "monthlyInvs&Dn",
        },
        // quotation: {
        //   checked: checkPrivileges("role"),
        //   href: "/quotation",
        //   icon: groupsIcon,
        //   title: "quotations",
        // },
        units: {
          checked: checkPrivileges("device") && props.userInfo.userType !== 6,
          href: "/units",
          icon: unitsIcon,
          title: "units",
        },
        maicintegration: {
          checked: checkPrivileges("device") && props.userInfo.userType === -1,
          href: "/maic-integration",
          icon: unitsIcon,
          title: "MAIC Integration",
        },
        // groups: {
        //   checked: checkPrivileges("group") && props.userInfo.userType !== 6,
        //   href: "/groups",
        //   icon: groupsIcon,
        //   title: "resources",
        // },

        serverSettings: {
          checked: checkPrivileges("account") && props.userInfo.userType === -1,
          href: "/serverSettings",
          icon: serverSettingsIcon,
          title: "serverSettings",
        },
        systemLogs: {
          checked: props.userInfo.userType === -1 && 
          props.userInfo.roleId !== 57 &&
          props.userInfo.roleId !== 58 ,
          href: "/systemLogs",
          icon: sysLogsIcon,
          title: "systemLogs",
        },
        actionLogs: {
          checked: props.userInfo.roleId !== 57 &&
                    props.userInfo.roleId !== 58,
          href: "/actionLogs",
          icon: actionLog,
          title: "actionLogs",
        },
        roleManagement: {
          // checked: checkPrivileges('role') && props.userInfo.userType === -1,
          checked: checkPrivileges("role"),
          href: "/roleManagement",
          icon: roleManageIcon,
          title: "roleManagement",
        },

        templateGenerator: {
          checked: checkPrivileges("rptmpl") && props.userInfo.userType !== 6,
          href: "/templateGenerator",
          icon: tempGenIcon,
          title: "templateGenerator",
        },
        trash: {
          checked:  props.userInfo.roleId !== 57 &&
                  props.userInfo.roleId !== 58  ,
          href: "/trash",
          icon: trashIcon,
          title: "trash",
        },
        coupon: {
          checked:  props.userInfo.roleId !== 57 &&
                     props.userInfo.roleId !== 58 &&   props.userInfo.userType === -1,
          href: "/coupon",
          icon: couponIcon,
          title: "coupon",
        },
        accountsSettings: {
          checked: checkPrivileges("account") && props.userInfo.userType === -1,
          href: "/accountSettings",
          icon: accountsSettings,
          title: "accountSettings",
        },
        webHooks: {
          checked: props.userInfo.roleId !== 57 &&
                   props.userInfo.roleId !== 58 &&  props.userInfo.userType === -1,
          href: "/webHooks",
          icon: serverSettingsIcon,
          title: "webHooks",
        },
      },
    };
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root + " main-navigation"}>
        {checkPrivileges("accountShowbar")?
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx({
              [classes.drawerOpen]: this.props.sidebarToggle,
              [classes.drawerClose]: !this.props.sidebarToggle,
            }),
          }}
        >
          <Scrollbar>
            <List>
              {Object.keys(this.state.navigation).map((keyName, i) => {
                if (
                  this.state &&
                  this.state.navigation &&
                  this.state.navigation[keyName] &&
                  this.state.navigation[keyName].checked
                ) {
                  let item = this.state.navigation[keyName];
                  const { icon: ItemIcon } = item;

                  return (
                    <Tooltip
                      key={i}
                      title={this.props.translate(
                        this.state.navigation[keyName].title
                      )}
                    >
                      <li key={i}>
                        <ListItem
                          button
                          to={this.state.navigation[keyName].href}
                          component={NavLink}
                          key={keyName}
                          className="listItem"
                        >
                          <ListItemIcon style={{ Width: 35, color: "inherit" }}>
                            <ItemIcon
                              fill="currentColor"
                              width={20}
                              height={20}
                              style={{ marginLeft: -2 }}
                            />
                            <ListItemText
                              style={{
                                marginTop: 0,
                                marginBottom: 0,
                                paddingLeft: 18,
                              }}
                              primary={this.props.translate(
                                this.state.navigation[keyName].title
                              )}
                            />
                          </ListItemIcon>
                        </ListItem>
                      </li>
                    </Tooltip>
                  );
                }
              })}
            </List>
          </Scrollbar>
        </Drawer>         
         :null   } 
      </div>
    );
  }
}
export default withLocalize(withStyles(useStyles)(MainSideBar));
