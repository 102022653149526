import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import "./index.scss";
import { Tooltip, Icon } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkPrivileges, setCookie } from "../../Helpers";

import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import axios from "axios";

const emptyLink = "#";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseMenu: true,
      navigation: {
        accounts: {
          checked: checkPrivileges("account") && props.userInfo.userType !== 2,
          href: "/accounts",
          title: "accounts",
        },

        users: {
          checked: checkPrivileges("user"),
          href: "/users",
          title: "users",
        },
        units: {
          checked: checkPrivileges("device") && props.userInfo.userType !== 5,
          href: "/units",
          title: "units",
        },
        groups: {
          checked: checkPrivileges("group") && props.userInfo.userType !== 5,
          href: "/groups",
          title: "Resources",
        },
        plans: {
          checked: false,
          href: "/plans",
          title: "plans",
        },
        serverSettings: {
          checked: checkPrivileges("account") && props.userInfo.userType === -1,
          href: "/serverSettings",
          title: "serverSettings",
        },
        systemLogs: {
          checked: props.userInfo.userType === -1,
          href: "/systemLogs",
          title: "systemLogs",
        },
        roleManagement: {
          checked:
            checkPrivileges("role") &&
            (props.userInfo.userType === -1 || props.userInfo.userType === 4),
          href: "/roleManagement",
          title: "roleManagement",
        },
        contractManagment: {
          checked: checkPrivileges("expensetypeCreate"),
          href: "/contractManagement",
          title: "contractManagement",
        },
        templateGenerator: {
          checked: checkPrivileges("rptmpl") && props.userInfo.userType !== 5,
          href: "/templateGenerator",
          title: "templateGenerator",
        },
        trash: {
          checked: this.props.logInUser,
          href: "/trash",
          title: "Trash",
        },
        coupon: {
          checked: props.userInfo.userType === -1,
          href: "/coupon",
          title: "coupon",
        },
      },
      rightNav: {
        search: {
          checked: false,
          href: "search",
          title: "Search Items on map",
        },
      },
    };

    this.collapseMenu = this.collapseMenu.bind(this);
  }

  handleUserSetting = () => {
    this.props.history.push("/accountManagements");
  };
  refreshCache = () => {
    axios
      .get(`api/server/reload/cache`)
      .then((res) => {
        if (res && res.data && res.data.status === "success")
          window.location.reload();
      })
      .catch((err) => {
        console.log("err ===", err);
      });
  };

  collapseMenu() {
    //
    let collapseMenu = this.state.collapseMenu;
    this.setState({ collapseMenu: !collapseMenu });
  }

  VisibleLeftMenuItem(items) {
    return Object.keys(items).map((userData, i) => {
      if (items[userData].checked) {
        return (
          <Tooltip key={i} title={this.props.translate(items[userData].title)}>
            <li>
              <NavLink
                activeClassName="active"
                to={items[userData].href}
                id={items[userData].title}
              >
                <span className="hover-title">
                  {this.props.translate(items[userData].title)}
                </span>
              </NavLink>
            </li>
          </Tooltip>
        );
      }
      return null;
    });
  }

  VisibleRightMenuItem(items) {
    return Object.keys(items).map((userData, i) => {
      if (items[userData].checked) {
        return (
          <li key={i}>
            <a href={items[userData].href} title={items[userData].title}>
              <FontAwesomeIcon icon={items[userData].icon} />
            </a>
          </li>
        );
      }

      return null;
    });
  }

  AllMenuItem(items) {
    return Object.keys(items).map((userData, i) => {
      return (
        <li key={i}>
          <label href={items[userData].href} title={items[userData].title}>
            <input
              type="checkbox"
              name={userData}
              onChange={this.showHideMenuItems(userData)}
              checked
            />
            {items[userData].title}
          </label>
        </li>
      );
    });
  }
  checkUserType = (Utype) => {
    let type = "";
    if (Utype === -1) {
      type = "Owner";
    } else if (Utype === 1) {
      type = "User";
    } else if (Utype === 2) {
      type = "Admin";
    } else if (Utype === 3) {
      type = "Service-Provider";
    } else if (Utype === 4) {
      type = "Dealer";
    } else {
      type = "";
    }
    return type;
  };
  checkUserNameLength = (name) => {
    let newName = name;
    if (name && name.length > 30) {
      newName = name.substring(0, 30) + "..";
    }
    return newName;
  };
  render() {
    let serverAtt = "";
    const { attributes } = this.props.logInUser;
    if (
      this.props &&
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes
    ) {
      serverAtt = this.props.ServerSetting.attributes;
    }

    return (
      <header className="fms-header">
        <nav className="nav-container">
          <div className="logo-section">
            {/* { this.props.userInfo.roleId === 57 || 
                    this.props.userInfo.roleId === 58 ? null : */}
            {checkPrivileges("accountShowbar")?
              <IconButton
                className="icon"
                onClick={this.props.handleDrawer}
                style={{
                  borderRight: `1px solid rgba(0, 0, 0, 0.12)`,
                  borderRadius: 0,
                  minWidth: 51,
                  minHeight: 46,
                  color: "inherit",
                }}
              >
                <MenuIcon />
              </IconButton>
               : null   } 
           {/* } */}
            <NavLink to="/accounts">
              <img
                className="logo"
                id="header-logo"
                src={serverAtt && serverAtt.logo ? serverAtt.logo : ""}
                alt=""
              />
            </NavLink>
            <Icon className="menuIcon pull-right" onClick={this.collapseMenu}>
              menu
            </Icon>
          </div>
          <div
            className={
              "nav-collapse " + (this.state.collapseMenu ? "collapse" : "")
            }
          >
            <div className="right-nav">
              <ul className="nav">
                <li className="has-menu">
                  <a
                    href="https://leasing.ntsc.app/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {" "}
                    Leasing App{" "}
                  </a>
                </li>
                {this.VisibleRightMenuItem(this.state.rightNav)}
                {this.props.userInfo && (
                  <li className="has-menu">
                    <a
                      href={emptyLink}
                      title={this.checkUserType(this.props.userInfo.userType)}
                    >
                      <span>
                        {this.checkUserNameLength(this.props.userInfo.name)}
                      </span>
                    </a>
                    <ul className="sub-menu right-menu">
                      {checkPrivileges("user") && (
                        <li>
                          <a href={emptyLink} onClick={this.handleUserSetting}>
                            {this.props.translate("usersSettings")}
                          </a>
                        </li>
                      )}
                      {this.props.logInUser &&
                        this.props.logInUser.userType === -1 && (
                          <li>
                            <a href={emptyLink} onClick={this.refreshCache}>
                              {this.props.translate("refreshCache")}
                            </a>
                          </li>
                        )}
                      {((attributes !== null && attributes.privacyPolicyLink) ||
                        (serverAtt && serverAtt.privacyPolicyLink)) && (
                        <li>
                          <a
                            href={
                              attributes.privacyPolicyLink ||
                              serverAtt.privacyPolicyLink
                            }
                          >
                            {this.props.translate("privacyPolicy")}
                          </a>
                        </li>
                      )}
                      {((attributes !== null && attributes.termsLink) ||
                        (serverAtt && serverAtt.termsLink)) && (
                        <li>
                          <a href={attributes.termsLink || serverAtt.termsLink}>
                            {this.props.translate("termsAndConditions")}
                          </a>
                        </li>
                      )}
                      <li className="divider"></li>
                      <li>
                        <a
                          href={emptyLink}
                          onClick={() => this.props.history.push("/logout")}
                        >
                          {this.props.translate("logOut")}
                        </a>
                      </li>
                    </ul>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}
export default withLocalize(Header);
