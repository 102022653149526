import React, { Component, Fragment } from "react";
import SimpleModalWrapped from "../common/Modal";
import { withStyles } from "@material-ui/core/styles";
import SingleSelect from "../common/SingleSelect";

import { Grid, Checkbox } from "@material-ui/core";
import { withLocalize } from "react-localize-redux";
import { timeZonesTypes } from "../Attributes/timezone";
import Button from "../common/Button";
import TextField from "../common/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
  button: {
    margin: theme.spacing(1),
  },
});
const GarageTypes = [
  {
    key: "PE",
    name: "PE",
  },
  {
    key: "PCVC",
    name: "PCVC",
  },
  {
    key: "PAC",
    name: "PAC",
  },
  {
    key:"DEX",
    name: "DEX",
  },
  {
    key: "TRISTAR",
    name: "TRISTAR",
  },
  {
    key: "JV",
    name: "JV",
  },
];
class addWorkShops extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    // const {form}=this.props.data;

    // const defaultZones = []
    // timeZonesTypes.map((item, i) => {
    //   if (i <= 9) {
    //     defaultZones.push({
    //       id: item.key,
    //       key: item.key,
    //       label: item.key,
    //       value: item.key
    //     })
    //   }
    // })

    return (
      <Fragment>
        <SimpleModalWrapped
          {...this.state}
          {...this.props}
          visable
          isButtonVisable
          title={this.props.title}
          isNotShowAttributesBtn
          formSubmit={this.props.formSubmit}
          // addAttributes={this.addAttributes}
          modalControle={this.props.modalControle}
          btnclosetext={this.props.translate("sharedBack")}
          buttonText={this.props.buttonText}
          // isVisableBtn={true}
          isVisableBtn={this.props.isVisableAddBtn}

          notVisableBackbtn={false}
          // showPortList={this.props.showPortList}
          // showPort={this.props.showPort}
          content={
            <>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="name"
                    required
                    margin="dense"
                    label={this.props.translate("sharedName")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.name}
                    onChange={this.props.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="description"
                    margin="dense"
                    label={this.props.translate("sharedDescription")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.description}
                    onChange={this.props.handleChange("description")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="garageNumber"
                    type="number"
                    margin="dense"
                    required
                    label={this.props.translate("garageNumber")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.garageNumber}
                    onChange={this.props.handleChange("garageNumber")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} style={{zIndex:99}}>
                  {/* {console.log("this.props.data?.garageType====", this.props.data?.garageType)} */}
                <SingleSelect
                    array={GarageTypes || []}
                    async
                    selectName="garageType"
                    // isClearable
                    label={this.props.translate("garageType")}
                    value={
                      this.props.data && this.props.data?.garageType
                        ? {
                            id: this.props.data?.garageType.key || this.props.data?.garageType,
                            value: this.props.data?.garageType.name || this.props.data?.garageType,
                            label: this.props.data?.garageType.name || this.props.translate(this.props.data?.garageType),
                          }
                        : ""
                    }
                    required
                    // value={this.props.form.reportNumber || ""}
                    handleChange={this.props.handleChange1}
                    canAssign={true}
                    menuPlacement="auto"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                  {/* <TextField
                    id="garageType"
                    margin="dense"
                    label={this.props.translate("garageType")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.garageType}
                    onChange={this.props.handleChange("garageType")}
                  /> */}
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="garageArea"
                    margin="dense"
                    label={this.props.translate("garageArea")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.garageArea}
                    onChange={this.props.handleChange("garageArea")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="garageCity"
                    margin="dense"
                    label={this.props.translate("garageCity")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.garageCity}
                    onChange={this.props.handleChange("garageCity")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="garageManager"
                    margin="dense"
                    label={this.props.translate("garageManager")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.garageManager}
                    onChange={this.props.handleChange("garageManager")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="email"
                    margin="dense"
                    label={this.props.translate("userEmail")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.email}
                    onChange={this.props.handleChange("email")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="phone"
                    margin="dense"
                    label={this.props.translate("sharedPhone")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.phone}
                    onChange={this.props.handleChange("phone")}
                  />
                </Grid>
               
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="address"
                    margin="dense"
                    label={this.props.translate("address")}
                    variant="outlined"
                    fullWidth
                    value={this.props.data?.address}
                    onChange={this.props.handleChange("address")}
                  />
                </Grid>
             
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="latitude"
                    type="number"
                    margin="dense"
                    label={this.props.translate("positionLatitude")}
                    placeholder=""
                    variant="outlined"
                    fullWidth
                    value={
                      this.props.data?.latitude ? this.props.data?.latitude : ""
                    }
                    onChange={this.props.handleChange("latitude")}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    id="longitude"
                    type="number"
                    margin="dense"
                    label={this.props.translate("positionLongitude")}
                    placeholder=""
                    variant="outlined"
                    fullWidth
                    value={
                      this.props.data?.longitude
                        ? this.props.data?.longitude
                        : ""
                    }
                    onChange={this.props.handleChange("longitude")}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  {/* <Tooltip title={translate('addLocation')}>
              <IconButton onClick={()=>handleClickShowPostion()}>
                {<EditIcon style={{ color:"#ffffff"}} />}
              </IconButton>
            </Tooltip> */}
                </Grid>
                {/* <Tooltip title={this.props.translate('addLocation')}>
              <IconButton onClick={this.handleClickShowPostion}>
                {<EditIcon />}
              </IconButton>
            </Tooltip> */}
                {/* <Grid item xs={12} sm={6} md={4} lg={3}>
           <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                format="dd/MM/yyyy"
                margin='dense'
                label={translate('created')}
                variant='outlined'
                fullWidth
                value={form.created? new Date( `${form.created}` ).toString() : null}
                onChange={handleChangeLicenseExpDate('created')}
                InputProps={{
                  classes: {
                    root: 'theme-cssOutlinedInput',
                    input: 'theme-input',
                    focused: 'theme-cssFocused',
                    notchedOutline: 'theme-notchedOutline'
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root: 'theme-label',
                    focused: 'theme-label-focused',
                    shrink: 'theme-label-shrink'
                  }
                }}
              />
          </MuiPickersUtilsProvider>
        </Grid> */}

                {/* <Grid item xs={12}>
            <Checkbox
              checked={form.attributes.enable}
              onChange={e => handleChangeAttributes('archive', e.target.checked)}
              color='primary'
              label={translate('archive')}
              name='enable'
            />
          </Grid> */}
              </Grid>
            </>
          }
        />
      </Fragment>
    );
  }
}

const AddWorkShopsModal = withStyles(styles);
export default AddWorkShopsModal(withLocalize(addWorkShops));
