import React, { Component } from "react"
import { Translate } from 'react-localize-redux'
import Notifications from 'react-notification-system-redux'
import axios from 'axios'
import parseHtml from 'react-html-parser'
export const validEmailPattern = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,30}(?:\.[a-z]{2})?)$/i
export const validNamePattern = /^[a-zA-Z0-9]{2,50}$/;

export function checkPrivileges (type) {
  let privileges = localStorage.getItem('privileges')
  let userInfo = localStorage.getItem('userInfo')
  if (privileges && userInfo) {
    let userPrivileges = JSON.parse(privileges)
    const info = JSON.parse(userInfo)
    if (userPrivileges && info && info.id) {
      return Object.values(userPrivileges).indexOf(type) > -1
    } else {
      window.location.assign('/logout')
      return false
    }
  } else {
    window.location.assign('/logout')
  }
}

export function displayErrorMessages (err) {
  
  console.log('err =', err)
  let errormgs = ''
  if (err.includes('Admin')) {
    errormgs = 'youRequiredAdministrationAccess'
  } else if (err.includes('Unauthorized')) {
    errormgs = 'yourEmailOrPasswordIsWrong'
  } else if (err.includes('Account has expired')) {
    errormgs = 'yourAccountHasExpired'
  } else if (err.includes('Account is disabled')) {
    errormgs = 'accountIsDisabled'
  } else if (err.includes('Invalid Current Password')) {
    errormgs = 'wrongCurrentPassword'
  } else if (err.includes('Invalid User. Please try again with other user')) {
    errormgs = 'invalidUserPleaseTryAgainWithOtherUser'
  } else if (err.includes('UnAuthorize User')) {
    errormgs = 'UnAuthorizeUserPleaseTryAgainWithOtherUser'
  } else if (err.includes('Not enough privileges')) {
    errormgs = 'somthingWentWrongMessage1'
  }else if (err.includes('bad request (HINT: Sorry! Your account has been temporarily disabled. Please contact NTSC Operations Team.)')) {
    errormgs = 'Sorry! Your account has been temporarily disabled. Please contact NTSC Operations Team.'
  } 
  else {
    errormgs = 'serverIsDown'
  }
  return errormgs
}

export function isOwnerOrDealer (type) {
  if (type === -1 || type === 5) {
    return true
  } else {
    return false
  }
}

export function isAuthenticated (props) {
  const info = JSON.parse(localStorage.getItem('userInfo'))
  if (info && info.id) {
    return info.id
  } else {
    if (props && props.history) {
      props.history.push('/logout')
    }
  }
}

export function prepareUsers (users) {
  const userArray = []
  if (users) {
    users.map(item => {
      let singleUser = {}
      const parent = {
        parentId: item.parent.id || '',
        parentName: item.parent.name || '',
        accountName: item.extraInfo.accountName || ''
      }
      const extraInfo = { ...item.extraInfo }
      singleUser = { ...item.entity, ...parent, extraInfo }
      const keys = Object.keys(singleUser)
      const final = {}
      keys.map(key => {
        if (typeof singleUser[key] === 'string') {
          final[key] = parseHtml(singleUser[key])[0]
        } else final[key] = singleUser[key]
      })

      userArray.push(final)
    })
  }
  return userArray
}
export function prepareUserForLogin (users) {
  if (users) {
    users.map(item => {
      item.logInAsUserState = ''
    })
  }
  return users
}

export function checkUserRoles (users, roles) {
  if (users && roles) {
    users.map(value => {
      for (let item of roles) {
        if (value.roleId === item.id) {
          value['roleName'] = item.name
        }
      }
    })
  }
  return users
}
export function checkUserAcount (users, account) {
  if (users && account) {
    users.map(value => {
      for (let item of account) {
        if (value.accountId === item.id) {
          value['accountName'] = item.name || item.label
        }
      }
    })
  }
  return users
}

export function prepareUnits (units) {
  let unitsArray = [],
    singleUnit = {}
  if (units) {
    units.map(item => {
      let parent = {
        parentId: item.parent.id,
        parentName: item.parent.name
      }
      singleUnit = { ...item.entity, ...parent }
      unitsArray.push(singleUnit)
    })
  }
  return unitsArray
}

export function checkRolesOfParent (units, users, roles) {
  if (units && users && roles) {
    units.map(value => {
      for (let item of users) {
        if (value.parentId === item.id) {
          for (let role of roles) {
            if (role.id === item.roleId) {
              value['ownerRole'] = role.name
            }
          }
        }
      }
    })
  }
  return units
}

export function checkUsersOfUnits (unitUser, users) {
  let usersArray = [],
    singleUser = {}
  if (users && unitUser) {
    users.map(value => {
      singleUser = JSON.parse(JSON.stringify(value))
      singleUser['check'] = false
      singleUser['indeterminate'] = false
      singleUser['readonly'] = false
      for (let item of unitUser) {
        if (value.id === item.userId) {
          //   singleUser.check=true
          if (item.parent) {
            item.check = true
            singleUser.check = true
            item.readonly = true
            singleUser.readonly = true
          } else {
            if (item.directAccess) {
              item.readonly = false
              singleUser.readonly = false
              item.check = true
              singleUser.check = true
            } else {
              item.readonly = false
              singleUser.readonly = false
              item.check = false
              singleUser.check = false
              singleUser.indeterminate = true
            }
          }
        }
      }
      usersArray.push(singleUser)
    })
  }
  return usersArray
}

export function checkUsersOfUnits1 (users) {
  if (users) {
    users.map(item => {
      item['check'] = false
      item['indeterminate'] = false
      item['readonly'] = false
      if (item.directAccess) {
        item.check = true
        item.readonly = true
        item.readonly = true
      } else {
        if (item.directAccess === false) {
          item.readonly = true
          item.check = false
          item.indeterminate = true
        } else {
          item.readonly = true
          item.check = false
          item.indeterminate = false
        }
      }
    })
  }
  return users
}

export const PaginationConfig = {
  page: 1,
  pageSize: 20,
  itemSearch: ''
}

export function getValue(o, k) {
  return k.split('.').reduce((r, e) => {
    if (!r) return r;
    else return r[e] || undefined;
  }, o);
}

export function getCookie (cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
export function setCookie (cname, exdays) {
  var d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  var expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '= ;' + expires + ';path=/'
}

export function checkedDevelopmentType () {
  let result = `&host=${window.location.host}`
  let serverType = window.location.host.includes('localhost')
  if (serverType) {
    result = ''
  }
  return result
}

export const selectStyles = themecolors => ({
  option: base => ({
    ...base,
    padding: '10px 14px',
    color: themecolors['themeInverse']
  }),
  noOptionsMessage: base => ({
    ...base,
    padding: '10px 14px'
  }),
  multiValue: base => ({
    ...base,
    margin: '2px',
    background: themecolors[600],
    color: themecolors['themeInverse']
  }),
  multiValueLabel: base => ({
    ...base,
    color: themecolors['themeInverse']
  }),
  placeholder: base => ({
    ...base,
    color: themecolors['themeInverse'],
    opacity: 0.5
  }),
  dropdownIndicator: base => ({
    ...base,
    color: themecolors['themeInverse'],
    padding: 4
  }),
  clearIndicator: base => ({
    ...base,
    color: themecolors['themeInverse'],
    padding: 4
  }),
  indicatorsContainer: base => ({
    ...base,
    color: themecolors['themeInverse']
  }),
  input: base => ({
    ...base,
    color: themecolors['themeInverse']
  }),
  container: base => ({
    ...base,
    margin: '8px 0 4px'
  }),
  control: base => ({
    ...base,
    boxShadow:
      '0px 2px 4px 0px rgba(20, 20, 20, 0.15), 0px 2px 4px 0px rgba(20, 20, 20, 0.15) inset',
    background: 'none',
    borderColor: themecolors['600'],
    minHeight: 26,
    '&:hover': {
      borderColor: themecolors['600']
    }
  }),
  menuList: base => ({
    ...base,
    background: themecolors['500'],
    color: themecolors['themeInverse']
  }),
  singleValue: base => ({
    ...base,
    color: themecolors['themeInverse']
  })
})

export const errorHandler = (error, dispatch, callback) => {
  console.log('err in helper =', error)
  if(error?.message?.includes("HINT")){
    let message = error.message.split(":")[1]
    if(message.includes("Duplicate entry")){
      dispatch(
        Notifications.error({
          message: <Translate id={'Duplicate entry'}/>,
          autoDismiss: 5,
        })
      )
    }else{
      dispatch(
        Notifications.error({
          message: <Translate id={message.replace(')','')}/>,
          autoDismiss: 5,
        })
      )
    }
}else if(error?.statusCode === '440'){
  dispatch(
    Notifications.error({
      message: <Translate id={error?.message}/>,
      autoDismiss: 5,
    })
  )
  window.location.replace('/login')
}
else {
  if(!axios.isCancel(error)){
    dispatch(
      Notifications.error({
        message: <Translate id={'somthingWentWrongMessage'}/>,
        autoDismiss: 5,
      })
    )
  }
}
  callback && typeof callback === 'function' && callback(error)
}

export const replaceSpecialCh = (input)=>{
  return input?.replace(/amp;/g, '')
}
export const Api = 'http://185.230.210.44:3003/'

export const  nFormatter =(num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup.slice().reverse().find(function(item) {
    return num >= item.value;
  });
  return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
}

export const getTooltipMessage = (fieldValue, fieldName) => {
  if(fieldName === "c_schemeId")
  switch (fieldValue) {
    case "TIN":
      return "TIN is one";
    case "CRN":
      return "CRN is two";
    case "MOM":
      return "MOM is three";
    case "MLS":
      return "MLS is four";
    case "700":
      return "700 is five";
    case "SAG":
      return "SAG is six";
    case "NAT":
      return "NAT is seven";
    case "GCC":
      return "GCC is eight";
    case "IQA":
      return "IQA is nine";
    case "OTH":
      return "OTH is ten";
    default:
      return ""; // Return empty string for unknown values
  }
  else if(fieldName === "c_idIssuer"){
    switch (fieldValue) {
      case "CM":
        return "Capital Market Authority";
      case "CU":
        return "Saudi Customs";
      case "CW":
        return "Ministry of Social Affairs for Charity & Welfare organizations";
      case "EN":
        return "Saudi Council of Engineers";
      case "FI":
        return "Foreign Investment Commission (for Foreign Investors)";
      case "GI":
        return "Saudi Arabian General Investment Authority ‘SAGIA’ for Joint Ventures with Foreign Ownership";
      case "IN":
        return "Internal (Syndicated Loans)";
      case "MA":
        return "Ministry of Agriculture";
      case "MC":
        return "Ministry of Commerce and Investment";
      case "MD":
        return "Ministry of Defence and Aviation for Foreign Airlines";
      case "ME":
        return "Ministry of Education";
      case "MF":
        return "Ministry of Foreign Affair (Diplomatic)";
      case "MH":
        return "Ministry of Health";
      case "MI":
        return "Ministry of Information";
      case "MJ":
        return "Ministry of Hajj and Umrah";
      case "ML":
        return "Ministry of Labour for Cooperative Societies";
      case "MN":
        return "Ministry of Interior";
      case "MU":
        return "Municipality";
      case "MX":
        return "Ministry of Commerce Commercial Licences";
      case "RD":
        return "Royal Decree";
        case "PP":
        return "Foreign Passports";
      case "SA":
        return "SAMA for Money Exchange and Insurance";
      case "TV":
        return "Technical and Vocational Training Corporation";
      case "UN":
        return "Unknown";
      default:
        return ""; // Return empty string for unknown values
    }
  }
  else if(fieldName === "c_productType"){
    switch (fieldValue) {
      case "ADFL":
        return "Agricultural Loan";
      case "AMTG":
        return "Additional Mortgage";
      case "AQAR":
        return "Government Mortgage Real Estate Fund";
      case "CDC":
        return "Consumer Durables Card";
      case "CDL":
        return "Consumer Durables Loan";
      case "CHC":
        return "Charge Card";
      case "CHCK":
        return "Bounced Cheques";
      case "COM":
        return "Commercial Enquiry";
      case "CRC":
        return "Credit Card";
      case "EDUF":
        return "Education Finance";
      case "GFC":
        return "Official Financial Claims";
      case "IAC":
        return "Accident & related services";
      case "IEN":
        return "Engineering & related";
      case "ILB":
        return "Liability & related";
      case "IMD":
        return "Medical & related products";
      case "IMR":
        return "Marine & related";
      case "IMTG":
        return "Istisna Construction Financing";
      case "IMV":
        return "Motor Vehicle & related products";
      case "IPR":
        return "Property & related";
      case "IPS":
        return "Protection & Savings Insurance";
      case "LEGL":
        return "Legal Enquiry";
      case "LND":
        return "Landline Phone";
      case "MBL":
        return "Mobile Phone";
      case "MGLD":
        return "Margin Lending";
      case "MIS":
        return "Miscellaneous";
      case "MSKN":
        return "Muskun (Government product as mortgage)";
      case "MTG":
        return "Mortgage";
      case "NET":
        return "Internet service";
      case "PE":
        return "Public";
      case "PLN":
        return "Personal Finance";
      case "POD":
        return "Authorized Over Draft";
      case "RCSR":
        return "Recruiting Service";
      case "RERA":
        return "Real Estate Rental Agreement";
      case "RMSKN":
        return "Restructured Muskun";
      case "RMTG":
        return "Restructured Mortgage";
      case "RPLN":
        return "Restructured Loan";
      case "RSMEI":
        return "Restructured Small Medium Enterprise Leasing";
      case "RSMEL":
        return "Restructured Small Medium Enterprise Instalment";
      case "RVIN":
        return "Restructured Car Instalment Agreement";
      case "RVLS":
        return "Restructured Car Lease Agreement";
      case "SFB":
        return "Secured Finance Program";
      case "SME":
        return "Small Medium Enterprise";
      case "SMEI":
        return "Small Medium Enterprise Leasing";
      case "SMEL":
        return "Small Medium Enterprise Installment";
      case "SMS":
        return "SMS Enquiry";
      case "STFM":
        return "Stock Finance Murabaha";
      case "TMTG":
        return "Top-up Mortgage";
      case "TPLN":
        return "Top-up Loan";
      case "TOD":
        return "Unauthorized Over Draft";
      case "UNFS":
        return "Unsettled Finacical Sservices";
      case "VCLM":
        return "Vehicle Claim";
      case "VEHE":
        return "Heavy Equipment";
      case "VESP":
        return "Spare Parts";
      case "VIN":
        return "Car Instalment Agreement";
      case "VLS":
        return "Car Lease Agreement";
      case "VRA":
        return "Car Rentals Agreemen";
      case "WAT":
        return "Water bills";
      default:
        return ""; // Return empty string for unknown values
    }
  }
  else if(fieldName === "c_productStatus"){
    switch (fieldValue) {
      case "A":
        return "Active";
      case "S":
        return "Suspended";
      case "C":
        return "Closed";
      case "W":
        return "Default";
      case "X":
        return "Default only. May only be used for Credit Instruments created with the ‘Standalone Default Upload’ Service";
      case "L":
        return "Lost / Stolen";
      default:
        return ""; // Return empty string for unknown values
    }
  }
  else if(fieldName === "c_defaultStatus"){
    switch (fieldValue) {
      case "FS":
        return "Fully Paid";
      case "NS":
        return "Negotiated Settlement";
      case "OS":
        return "Outstanding";
      case "PP":
        return "Partially Paid";
      case "RG":
        return "Registered";
      default:
        return ""; // Return empty string for unknown values
    }
  }
  else if(fieldName === "c_goodsServices"){
    switch (fieldValue) {
      case "ALOTH":
        return "All Other";
      case "APPLN":
        return "Appliances";
      case "MCHNR":
        return "Machinery";
      case "CARS":
        return "Cars";
      case "BLDMT":
        return "Building Material";
      case "TRCTH":
        return "Tractors";
      case "OTHFD":
        return "Other Food";
      case "LMEAT":
        return "Lean Meat";
      case "SUGTC":
        return "Sugar Products";
      case "FRVEG":
        return "Fresh Vegetables";
      // Add more cases as needed
      default:
        return ""; // Return empty string for unknown values
    }
  }
};