import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import Layout from "../../wrapper";
import { logInUserInfo } from "../../Actions/Users";
import Notifications from "react-notification-system-redux";
import axios from "axios";
import { ItemsList } from "../../Components/MissedOpportunities";

let source;
class MissedOpportunities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      to: "",
      from: "",
      selectedUser: "",
      status:"",
      page: 1,
      pageSize: 20,
      searchItem:"",
      value: "maintenance",
      invoiceData: "",
      searchInvoiceText: "",
      selectedAccount: "",
      selectSingleInvoice: false,
      itemsData: "",
      leasing: false,
      showPayments: false,
      recievingPartyAccounts: [],
      contractType:1,
      loader1:false,
      loader2:false,
      selectSingleAccount:false,
      itemsData:[],
      checkUser:true,
      tab:"missedOpportunity"

    };
  }
  componentWillMount() {
      this.getMissingItems()
      // this.getContractsData();

  }

  // componentWillReceiveProps (n) {
  //   if(this.props.logInUser && this.state.checkUser){
  //       this.getContractsData();
  //     }
  // }
  

  searchInvoiceText = (e) => {
    source.cancel();
    this.setState(
      {
        searchInvoiceText: e.target.value,
      },
      () => {
        // this.getContractsData();
      }
    );
  };
 

  setPagination = () => {
    let nAcc;
    // if (this.state.selectSingleAccount) {
      nAcc = { ...this.state.itemsData }; // for   Missing Items 
    // } else {
    //   nAcc = { ...this.state.invoiceData }; // for contract All accounts
    // }
    let lastPage = nAcc.total / this.state.pageSize;
    let IsFloate = this.checkFloteNumber(lastPage);
    delete nAcc.data;
    nAcc.pageSize = this.state.pageSize;
    nAcc.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
    return nAcc;
  };

  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  handleChangePage = (value) => {
    // if (this.state.selectSingleAccount) {
      this.setState({   
        to: this.state.to,
        from: this.state.from,
        selectedUser: this.state.selectedUser,
        status:this.state.status,
         page: value
         }, () =>
        this.getMissingItems()
      );
    // } else {
    //   this.setState({ page: value }, () => this.getContractsData()); // for contract All accounts
    // }
  };

  handleChangeRowsPerPage = (value) => {
    // if (this.state.selectSingleAccount) {
      this.setState({     
          to: this.state.to,
          from: this.state.from,
          selectedUser: this.state.selectedUser,
          status:this.state.status, 
          pageSize: value ,
         page: 1

        }, () =>
          this.getMissingItems()
      );
    // } else {
    //   this.setState({ pageSize: value }, () => this.getContractsData()); // for contract All accounts
    // }
  };


  
  handleChangeTabs = (e) => {
    if (e === "leasing") {
      this.setState(
        {
          value: e,
          leasing: true,
          contractType:2
        },
        () => {
          this.getContractsData();
        }
      );
    } else if (e === "maintenance") {
      this.setState(
        {
          value: e,
          leasing: false,
          contractType:1
        },
        () => {
          this.getContractsData();
        }
      );
    }
  };



    getContractsData = () => {
    let list = {};
    let usersId = {};
   
    let apiUrl = `/api/accounts/list?all=true&contractType=1&userId=${this.props.logInUser.id}&limit=-1&search=${this.state.searchInvoiceText}`;
    source = axios.CancelToken.source();
            this.setState({
        accounts: [],
        loader1:false,
      }, () => {
    axios
      .get(apiUrl, {
        cancelToken: source.token,
      })
      .then((response) => {
        let dataRes = response?.data?.data?.data || [];
        dataRes &&
          dataRes.map(
            (d) => ((list[d.contractNumber] = true), (usersId[d.userId] = true))
          );
        this.setState({
          accounts: response?.data?.data || {},
          makingAccounts: list,
          makingUsers: usersId,
          loader1: false,
          checkUser: false
        // loaderR:true,

        });
      })
      .catch((e) => {
        console.log("e ===", e);
      });
    })
  };



  // getContractsData = async () => {
  //   while (!this.props.logInUser.id) {
  //     await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for 100 milliseconds before checking again
  //   }
  
  //   let list = {};
  //   let usersId = {};
  
  //   let apiUrl = `/api/accounts/list?all=true&contractType=1&userId=${this.props.logInUser.id}&limit=-1&search=${this.state.searchInvoiceText}`;
  
  //   source = axios.CancelToken.source();
  //   this.setState({
  //     accounts: [],
  //     loader1: false,
  //   }, async () => {
  //     try {
  //       const response = await axios.get(apiUrl, {
  //         cancelToken: source.token,
  //       });
  
  //       let dataRes = response?.data?.data?.data || [];
  //       dataRes &&
  //         dataRes.map(
  //           (d) => ((list[d.contractNumber] = true), (usersId[d.userId] = true))
  //         );
  //       this.setState({
  //         accounts: response?.data?.data || {},
  //         makingAccounts: list,
  //         makingUsers: usersId,
  //         loader1: false,
  //       });
  //     } catch (e) {
  //       console.log("e ===", e);
  //     }
  //   });
  // };
  invoiceSelection = (item) => {
    this.setState(
      {
        selectedAccount:item,
        selectSingleAccount: true,
      },
      () => {
        this.getMissingItems(item.contractNumber)
      }
    );
  };

  goBack = () => {
    this.setState(
      {
        selectSingleAccount: false,
      },
      () => {
        this.getContractsData();
      }
    );
  };

  getItems = (userId, to, from, status, type) => {
    this.setState(
      {
        to: to,
        from: from,
        selectedUser: userId,
        status:status,
        page:1
      }, () => {
        if(type==="downloadExcel") { 
        
          this.downloadCreditDebit(type)
        }else{
          this.getMissingItems()
        }
      
      })

  }

  downloadCreditDebit = (type) => {
    let accept;
    let dateto = this.state.to || "";
    let datefrom =  this.state.from || "";
    let userId =  this.state.selectedUser || "";
    // let status =  this.state.status || 0;

    let status =  this.state.tab ==="missedOpportunity" ? 1 : this.state.tab ==="potentialOpportunity" ? 2 : 0;

    if (type === "downloadPdf") {
      accept = "application/pdf";
    } else {
      accept =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }
    let header;
    header = {
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: accept,
      }),
      method: "GET",
    };

    let apiUrl;
    apiUrl = `/api/predictions/export?status=${status}`;

    if (dateto) apiUrl += `&to=${dateto}`;
    if (datefrom) apiUrl += `&from=${datefrom}`;
    if (userId) apiUrl += `${userId}`;

    fetch(
      apiUrl,
      { ...header }
    )
      .then((response1) => {
        if (response1.status === 200) {
          if (type === "downloadPdf") {
            response1.blob().then((response) => {
              this.setState(
                {
                  selectedAreaId: "",
                  selectedvehicleId: "",
                  page:this.state.page,
                  pageSize:this.state.pageSize,
                  to: this.state.to,
                  from: this.state.from,
                  selectedUser : this.state.selectedUser,
                  loader: false,
                },
                () => {
                  this.saveData(response, "CreditDebit.pdf");
                }
              );
            });
          } else {
            response1.blob().then((response) => {
              this.setState(
                {
                  selectedAreaId: "",
                  selectedvehicleId: "",
                  page:this.state.page,
                  pageSize:this.state.pageSize,
                  to: this.state.to,
                  from: this.state.from,
                  selectedUser : this.state.selectedUser,
                  loader: false,
                },
                () => {
                  this.saveData(response, "Missed_Opportunity.xlsx");
                }
              );
            });
          }
        } else {
          throw response1;
        }
      })

      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };

  saveData = (blob, filename) => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement("a");
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  };


  getMissingItems=()=>{
    let dateto = this.state.to || "";
    let datefrom =  this.state.from || "";
    let userId =  this.state.selectedUser || "";

    let status =  this.state.tab ==="missedOpportunity" ? 1 : this.state.tab ==="potentialOpportunity" ? 2 : 0;
    let { page, pageSize, searchItem } = this.state;
    let apiUrl;
    // api/predictions/get?search=
    apiUrl = `/api/predictions/get?page=${page}&limit=${pageSize}&status=${status}`;
    // apiUrl = `/api/deliverynotes/items/notincontract?page=${page}&limit=${pageSize}&search=${searchItem}`;
    
    // Add the parameters to the apiUrl only if they have values
    if (dateto) apiUrl += `&to=${dateto}`;
    if (datefrom) apiUrl += `&from=${datefrom}`;
    if (userId) apiUrl += `${userId}`;
    // if (status) apiUrl += `&status=${status}`;

    source = axios.CancelToken.source();
    this.setState(
      {
        loader2: true,
      },
      () => {
        axios
          .get(apiUrl, {
            cancelToken: source.token,
          })
          .then((response) => {
            // console.log("res====", response?.data?.data);
            // let allItems =  [];
            // const { packages, services, items } = response?.data?.data || [];
            // allItems.push(...packages, ...services, ...items);

            // const filteredItems = response?.data?.data?.data?.filter(item => {
            //   const nextExecutionDate = new Date(item.nextExecutionDate);
            //   const today = new Date();
            //   const timeDifference = nextExecutionDate - today;
            //   const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
              
            //   return daysDifference < 30;
            // });

            //    // Create a new response object with filteredItems
            //     const newResponse = {
            //       ...response?.data.data,
            //       data: filteredItems || [],
            //     };


     
              
  
            this.setState({
              loader2: false,
              itemsData:response?.data?.data|| {},
              page:this.state.page,
              pageSize:this.state.pageSize,
              to: this.state.to,
              from: this.state.from,
              selectedUser : this.state.selectedUser,

            });
          })
          .catch((e) => {
            // console.log("e ===", e);
          });
      }
    );
  }
  searchItems = (e) => {
    source.cancel();
    // console.log("e====", e,   this.state.selectedUser,);
    this.setState(
      {
        to: this.state.to,
        from: this.state.from,
        selectedUser: this.state.selectedUser,
        status:this.state.status,
        searchItem:e.target.value,
      }, () => {this.getMissingItems()})

  }

  handleChangeTabs = (e) => {
    // let contractType;
    // contractType =   e ==="maintenance" ? 1:e ==="leasing" ? 2:""
    this.setState({ tab: e , page:1,to : "", from:"", selectedUser:""}, () => { 
      this.getMissingItems();
      // this.props.setContractType(contractType);
    });
  };

    render() {
    if (this.props.logInUser) {
      const { value } = this.state;

      return (
        <Layout
          {...this.state}
          {...this.props}
          getMissingItems={this.getItems}
          // noSidebar={
          //   this.state.selectSingleAccount
          //     ? ""
          //     : "noSidebar"
          // }
        >
             <ItemsList 
              {...this.state}
              handleChangeTabs={this.handleChangeTabs}
              getMissingItems={this.getMissingItems}
              {...this.props}
              searchItems={this.searchItems}
              itemsData={this.state.itemsData.data || [] }
              setPagination={this.setPagination}
              handleChangeRowsPerPage={this.handleChangeRowsPerPage}
              handleChangePage={this.handleChangePage}
              goBack={this.goBack}
             />
            {/* :""} */}
        </Layout>
      );
    } else {
      fetch("/api/session", {
        headers: { Accept: "application/json; odata=verbose" },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then(res => {
              if(res.status ==='success'){  
                let userInfo = res.data
                this.props.setActiveLanguage(userInfo?.attributes?.lang || 'en')
                this.props.dispatch(logInUserInfo(userInfo))
              }
              else if(res?.statusCode === '440'){
                window.location.replace('/login')
              }
              else if(res.statusCode){
                var err = res?.message.split(':')
                err[1] =err[1].replace(')', "")
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location
                  })
                )
                this.props.history.push('/login')
              }
            })
          } else {
            this.props.history.push('/login')
            throw response
          }
        })
        .catch((e) => {
          this.props.history.push('/login')
          this.props.dispatch(
            Notifications.error({
              message: "somethingWentWrong",
              autoDismiss: 5,
            })
          );
        });
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)(withLocalize(MissedOpportunities));
