import React, { Component } from 'react'
import Layout from './../../wrapper'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'
import Notifications from 'react-notification-system-redux'
import { DeviceList } from '../../Components/Devices/deviceModalForSuperAdmin'
import { logInUserInfo } from '../../Actions/Users'

import { checkPrivileges } from '../../Helpers'

class Devices extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isVisable: true,
      sessionCallTrack: true,
      unitsApiCall: true
    }
  }
  componentWillMount () {
    this.props.dispatch(Notifications.removeAll())
  }
  controleSession = () => {
    if (localStorage.getItem('userInfo')) {
      fetch('/api/session', {
        method: 'DELETE'
      }).then(res => {
        let response = res.data
        if (response.ok) {
          this.props.history.push('/logout')
        }else{
          throw response
        }
      }).catch(e => {})
    }
  }
  render () {
    if (this.props.logInUser) {
    if (checkPrivileges('device')) {
    
        if (this.props.logInUser.userType === 6) {
          this.props.history.push('/*')
        } else {
          return (
            <Layout {...this.props}>
              <DeviceList {...this.props} />
            </Layout>
          )
        }
      
    } else if (
      !checkPrivileges('account') &&
      !checkPrivileges('user') &&
      !checkPrivileges('group')
    ) {
      this.controleSession()
      return null
    } else {
      return defaultPages.map(page => {
        if (checkPrivileges(page)) {
          this.props.history.push(`/${page}s`)
        }
        return null
      })
    }
  }
    else {
      if (this.state.sessionCallTrack) {
        this.setState(
          {
            sessionCallTrack: false
          },
          () => {
            fetch('/api/session', {
              headers: { Accept: 'application/json; odata=verbose' }
            }).then(response => {
              if (response.ok) {
                response.json().then(res => {
                  if(res.status ==='success'){ 
                    let userInfo = res.data
                    this.props.setActiveLanguage(
                      userInfo?.attributes?.lang || 'en'
                    )
                    //API privilige call
                    if(res.status === 'success'){
                      fetch(`/api/privileges?roleId=${userInfo.roleId}`, {
                        method: 'Get'
                      })
                        .then(response => {
                          if (response.ok) {
                            response.json().then(res2 => {
                              if(res2.status ==='success'){ 
                                let privileges = res2.data
                                let privilegeKeys = []
                                privileges.map(item => {
                                  privilegeKeys.push(item.key)
                                })
                                localStorage.setItem(
                                  'privileges',
                                  JSON.stringify({ ...privilegeKeys })
                                )
                                if (privileges.length) {
                                  localStorage.setItem('userToken', '')
                                  this.props.history.push('/units')
                                } else {
                                  this.setState({
                                    loading: false,
                                    loginErrorMessage: 'lackOfPrivileges'
                                  })
                                }
                              }
                              else if(res?.statusCode === '440'){
                                window.location.replace('/login')
                              }
                              else if(res.statusCode){
                                var err = res?.message.split(':')
                                err[1] =err[1].replace(')', "")
                                this.props.dispatch(
                                  Notifications.error({
                                    message: this.props.translate(err[1]),
                                    autoDismiss: 5,
                                    location: this.props.location
                                  })
                                )
                              }
                            })
                            .catch(e => {
                               console.log('e =',e)
                              // this.props.dispatch(Notifications.error({
                              //   message: 'somethingWentWrong',
                              //   autoDismiss: 5
                              // }))
                            })
                          } 
                        })
                        .catch(e=>{
                          this.props.dispatch(
                            Notifications.error({
                              message: this.props.translate('somethingWentWrong'),
                              autoDismiss: 5,
                              location: this.props.location
                            })
                          )
                        })
                    }
                     else if(res.statusCode){
                      var err = res.message.split(':')
                      err[1] =err[1].replace(')', "")
                      this.props.dispatch(
                        Notifications.error({
                          message: this.props.translate(err[1]),
                          autoDismiss: 5,
                          location: this.props.location
                        })
                      )
                      this.props.history.push('/logout')
                    }
                      

                    //Privilige call ends

                    this.props.dispatch(logInUserInfo(userInfo))

                  }
                  else if(res?.statusCode === '440'){
                    window.location.replace('/login')
                  }
                  else if(res.statusCode){
                    var err = res?.message.split(':')
                    err[1] =err[1].replace(')', "")
                    this.props.dispatch(
                      Notifications.error({
                        message: this.props.translate(err[1]),
                        autoDismiss: 5,
                        location: this.props.location
                      })
                    )
                    this.props.history.push('/logout')
                  }
                })
              } else {
                this.props.history.push('/logout')
                  throw response
                }
              })  .catch(e => {
                this.props.dispatch(Notifications.error({
                  message: 'somethingWentWrong',
                  autoDismiss: 5
                }))
              })
          }
        )
      }
      return null
    }
  }
}
const mapStateToProps = state => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
  users: state.users,
  roles: state.roles,
  devices: state.devices
})

export default connect(mapStateToProps)(withLocalize(Devices))

const defaultPages = ['accounts', 'users', 'group']
