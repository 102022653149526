import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Paper from '@material-ui/core/Paper'
import Draggable from 'react-draggable'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withLocalize } from 'react-localize-redux'

const mapStateToProps = (state, ownProps) => {
  return {
    themecolors: state.themecolors
  }
}

const style = theme => ({
  root: {
    top: 40
  },
  paper: {
    margin: 0
  }
})
const draggableId = new Date().getTime()

class PaperComponent extends Component {
  render () {
    return (
      <Draggable bounds='parent' handle={'.dragHandle' + draggableId}>
        <Paper {...this.props} />
      </Draggable>
    )
  }
}

function PaperDefault (props) {
  return <Paper {...props} />
}

class CustomDialog extends Component {
  state = {
    open: true
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
    this.props.onClose()
  }

  handleSubmit = () => {
    this.setState({ open: false })
    this.props.handleSubmit()
  }
  onStart = e => {
    //console.log(e)
  }

  onStop = e => {
    //console.log(e)
  }

  render () {
    const { classes, translate } = this.props
    const ThemeIconButton = styled(IconButton)`
      position: absolute !important;
      top: 0;
      right: 0;
      z-index: 9;
      border-radius: 0 !important;
      background-color: ${props => this.props.themecolors['500']}!important;
      color: ${props => this.props.themecolors['themeInverse']}!important;
      &:hover {
        background-color: ${props => this.props.themecolors['600']};
      }
    `

    const DialogActionsStyled = styled(DialogActions)`
      background-color: ${props => this.props.themecolors['lightGrey']};
    `

    const DragIcon = styled.span`
      position: absolute;
      width: 44px;
      height: 44px;
      text-align: center;
      font-size: 32px;
      cursor: move;
      z-index: 9;
      left: 0;
      top: 0;
      color: ${props => this.props.themecolors['themeInverse']};
    `

    let BackdropProps = {}
    if (this.props.invisible === true) {
      BackdropProps = { invisible: true }
    } else {
      BackdropProps = {
        style: { backgroundColor: 'rgba(255,255,255,0.7)' },
        classes: { root: classes.root }
      }
    }
    return (
      <Dialog
        style={{ overflow: 'auto' }}
        // classes={classes}
          classes={{
          paperFullScreen: 'dialog-full-screen',
          scrollPaper: 'scroll-auto',
          paperScrollPaper:
            'scroll-' + (this.props.overflow ? this.props.overflow : 'auto')
        }}
        open={this.state.open}
        onClose={this.handleClose}
        maxWidth={this.props.maxWidth || 'sm'}
        disableBackdropClick={true}
        PaperComponent={this.props.draggable ? PaperComponent : PaperDefault}
        BackdropProps={BackdropProps}
        PaperProps={{ style: { overflow: 'visible' , width:this.props.viewUserDetailWidth} }}
        fullWidth= {this.props.fullWidth}
      >
        <ThemeIconButton
          aria-label='onClose'
          onClick={this.handleClose}
          color='inherit'
        >
          <CloseIcon fontSize='small' />
        </ThemeIconButton>
        {this.props.title && (
          <DialogTitle
            disableTypography={true}
            style={{
              backgroundColor: this.props.themecolors['500'],
              fontSize: 18,
              fontWeight: 500,
              minHeight: 44,
              display: 'flex',
              alignItems: 'center',
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: this.props.draggable ? '44px' : 'def',
              color: this.props.themecolors['white']
            }}
          >
            {this.props.title}{' '}
            {this.props.selectedUser ? this.props.selectedUser : null}
          </DialogTitle>
        )}
        <DialogContent
          style={{
            padding: this.props.bodyPadding ? this.props.bodyPadding : 0,
            overflow: 'visible',
            background:this.props.background ? this.props.background : null,
            height:this.props.viewUserDetailWidth? this.props.viewUserDetailWidth: ''
          }}
        >
          {!this.props.hideDragIcon && (
            <DragIcon className={'dragHandle' + draggableId}>
              <DragIndicatorIcon color='inherit' />
            </DragIcon>
          )}
          {this.props.children}
        </DialogContent>
        <DialogActionsStyled style={{ margin: 0, padding: 10 }}>
          {this.props.showSubmitbtn && (
            <Button disabled={this.props.isImportBtnDisabled} onClick={this.handleSubmit} style={{background: this.props.isImportBtnDisabled ? "#ebebeb" : "#00c179", color:this.props.isImportBtnDisabled ? "#acacac" : "#ffff"}} variant="contained" color="success" size='small'>
                {this.props.translate('Submit')}
              </Button>
          )}
          <Button onClick={this.handleClose} variant='outlined' size='small'>
            {this.props.translate('Cancel')}
          </Button>
          {this.props.headerActions}
        </DialogActionsStyled>
      </Dialog>
    )
  }
}

CustomDialog.propTypes = {
  classes: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(
  withLocalize(withStyles(style)(CustomDialog))
)
