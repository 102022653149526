import React, { Component } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import Layout from "../../wrapper";
import { ShowCoupon } from "../../Components/Coupon";
import { logInUserInfo } from "../../Actions/Users";
import Notifications from "react-notification-system-redux";
import { checkPrivileges, errorHandler, isOwnerOrDealer } from "../../Helpers";
import { OffersList } from "../../Components/Offers/index";
import axios from "axios";
import instance from "../../axios";

const formDefault = {
  id: "",
  title: "",
  description: "",
  category: "",
  type: "",
  imgUrl: "",
  contractNumber: "",
  attributes: {},
  expenseTypeId: "",
  expenseSubTypeId: ""
};

// "id": 0,
// "title": "mytest2",
// "description": "Popular Four mytest2",
// "category": "banner",
// "type": "Engine",
// "imgUrl": "https://storage.googleapis.com/fms-static/1696578761755",
// "contractNumber": "1",
// "attributes":{}
const makeGrageData = (data) => ({
  ...data,
  category: data.category.id || data.category,
});


let source;
class Offers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: {},
      userFetch: false,
      addOption: false,
      editOption: false,
      garagesData: "",
      form: { ...formDefault },
      isVisableAddBtn: false,
      selectedWorkShop: "",
      onDeleteConfirmation: false,
      page: 1,
      pageSize: 20,
      searchGarage: "",
      loader1: false,
      garageCity:"",
      garageType:"",
      selectedImage: null, // Track the selected image,
      expensetypedata: [],
      expensesubtypedata: []
    };
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
  }


  handleImageChange = (e) => {
    const file = e.target.files[0];
    // this.setState({ selectedImage: file });
    const imageUrl = URL.createObjectURL(file);
    const updatedImageUrl = `https://storage.googleapis.com/fms-static/${imageUrl.split('/').pop()}`;

    this.setState(
      {selectedImage: file,
        form: {
          ...this.state.form,
          imgUrl: updatedImageUrl,
        },
      },
      () => this.checkRequiredFields()
    );

    // You can also preview the image here if needed
  };

  
  modalControle = () => {
    this.setState({
      addOption: false,
      editOption: false,
      form: "",
    });
  };

  addGarages = () => {
    this.setState({
      addOption: true,
      form: "",

    });
  };

  handleChange1 = (name, event) => {
    // if(name==="garageType"){
    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: event,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  handleChange = (name) => (event) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: event.target.value,
        },
      },
      () => this.checkRequiredFields()
    );
  };

  checkRequiredFields() {
    let { title, imgUrl , category, expenseTypeId, expenseSubTypeId, type} = this.state.form;
    let { selectedImage} = this.state;

    if (title  && category && imgUrl && expenseTypeId && expenseSubTypeId) {
      this.setState({
        isVisableAddBtn: true,
      });
    } else {
      this.setState({
        isVisableAddBtn: false,
      });
    }
  }


  uploadImage= event => {
    let files = event.target.files
    let imgData = ''
    let reader = new FileReader()
    if (files && files[0]) {
      reader.readAsDataURL(files[0])
      reader.onload = e => {
        imgData = e.target.result

        if (imgData.split(':')[1].split('/')[0] === 'image') {
          let image = new Image();
          image.src = imgData;

          image.onload = () => {
            const width = image.width;
            const height = image.height;
            
            if (width >= 320 && height >= 190 && width <= 640 && height <= 380) {
              let obj = {
                name: `${this.state.form.title + new Date().getTime()}`,
                contentType: `${files[0].type}`,
                oldUrl: `${this.state.form.imgUrl}`,
                data: `${imgData.split('base64')[1].substring(1)}`
              }

              this.setState(
                {
                  selectedImage: obj,

                  form: {
                    ...this.state.form,
                    imgUrl: imgData
                  }
                },
                () => {
                      this.checkRequiredFields()
                }
              )
            } else {
              this.props.dispatch(
                Notifications.error({
                  message: this.props.translate('Image dimensions are out of range. Upload image between 320 x 190px and 640 x 380px'),
                  autoDismiss: 10
                })
              )
              return;
            }
          }
        } else {
          //this.props.enqueueSnackbar(this.props.translate('onlyAcceptImage'));
          this.props.dispatch(
            Notifications.error({
              message: this.props.translate('onlyAcceptImage'),
              autoDismiss: 10
            })
          )
        }
      }
    } else {
      this.setState(
        {
          selectedImage: '',
          form: {
            ...this.state.form,
            imgUrl:''
          }
        },
        () => {
          this.setState({
            isVisibleBtn: this.state.isAttChanged
          })
        }
      )
    }
  }


  imageDataPrepare = () => {
    let { selectedImage } = this.state
    if (selectedImage) {
      // fetch('/api/uploads', {
      //   method: 'POST',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...selectedImage
      //   })
      // })
      instance({
        url: '/api/uploads/labeling',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data:{
          ...selectedImage
        }
        })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
          .then(publicUrl => {
            this.setState(
              {
            isVisableAddBtn: false,

                form: {
                  ...this.state.form,
                  imgUrl: publicUrl.publicUrl
                }
              },
              () => {
                if (this.state.editOption) {
                  this.updateWorkshops()
                } else {
                  this.handleSubmit()
                }
              }
            )
        //   })
        // } else {
        //   //this.props.enqueueSnackbar(this.props.translate('somthingWentWrongMessage'));
        //   this.props.dispatch(
        //     Notifications.error({
        //       message: this.props.translate('somthingWentWrongMessage'),
        //       autoDismiss: 10
        //     })
        //   )
        //     throw response
        //   }
        }).catch(error => {errorHandler(error, this.props.dispatch)
          // this.props.dispatch(
          //   Notifications.error({
          //     message: this.props.translate('somthingWentWrongMessage'),
          //     autoDismiss: 10
          //   })
          // )
        })
    } else {
        if (this.state.editOption) {
          this.setState(
            {
              isVisableAddBtn: false,
            }, ()=> this.updateWorkshops())
          } else {
            this.setState(
              {
                isVisableAddBtn: false,
              }, ()=>
            this.handleSubmit()
            )
          }
      }
  }
  handleSubmit = async () => {
       let { form } = this.state;
       let result;

        const obj = {
            ...form , 
           contractNumber : this.props?.logInUser?.id,
          };

        const offerObj = makeGrageData(obj);
        // const imageUrl = URL.createObjectURL(this.state.selectedImage);
    this.setState({
       addOption: false,
      editOption: false,
      isVisableAddBtn:false, }, async () => {
       result = await axios.post(`/api/offers`, offerObj);
       if (result.data.status === "success") {
        this.setState({
          searchGarage: "",
          // editOption: false,
          form:""
        });
        this.props.dispatch(
          Notifications.success({
            message: this.props.translate("offerCreated"),
            autoDismiss: 5,
            location: this.props.location,
          })
        );
        this.getWorkshops();
      } else {
        this.setState({
          searchGarage: "",
          // editOption: false,
          form:""
        }, this.getWorkshops());
        this.props.dispatch(
          Notifications.error({
            message: this.props.translate(result.data.message),
            autoDismiss: 5,
            location: this.props.location,
          })
        );
      }
    });
    };


  searchContractText =  (e, name) => {
    source.cancel();
    if (name === "garageCity" || name === "garageType") {
      this.setState({ [name]: e.target.value }, () => {
      });
    }
    else{
      this.setState(
        {
          searchGarage: e.target.value,
        },
        () => {
          this.getWorkshops();
        }
      );
    }
  };

  getWorkshops = () => {
    let { page, pageSize, searchGarage, garageCity, garageType } = this.state;
    // api/offers/get?category=exclusive&page=1&limit=1&search=
    let apiUrl = `/api/offers/get?page=${page}&limit=${pageSize}&search=${searchGarage}`;

    source = axios.CancelToken.source();
    this.setState(
      {
        loader1: true,
      },
      () => {
        axios
          .get(apiUrl, {
            cancelToken: source.token,
          })
          .then((response) => {
            this.setState({
              loader1: false,
              garagesData: response?.data.data || [],
            });
          })
          .catch((e) => {
            // console.log("e ===", e);
          });
      }
    );
  };
  getExpenseType = () => {
    fetch('/api/expensetypes/')
    .then(response => response.json())
    .then(data => {
      this.setState({ expensetypedata: data.data });
    }).catch((error) => {console.error('API failed to fetch : ', error)});
  }
  handleChange2 = (event) => {
    const selectedKey = event.target.value; // key is the value attribute of the selected option
    this.getExpenseSubType(selectedKey);
  }
  getExpenseSubType = (selectedKey) => {
    fetch(`/api/expensetypes/subtype?expenseTypeId=${selectedKey}`)
    .then(response => response.json())
    .then(data => {
      this.setState({ expensesubtypedata: data.data });
    }).catch((error) => {console.error('API failed to fetch : ', error)});
  }
  componentDidMount() {
    this.getWorkshops();
  }

  workShopSelection = (items) => {
    this.getExpenseType();
    let item = items[0];

    if (item) {
      this.setState({
        // defaultDeviceList: false,
        selectedWorkShop: item,
        editOption: true,
        // form: { ...item},
        form: { ...item, garageType: item.garageType },
      });
      this.getExpenseSubType(item.expenseTypeId)
    }
  };

  updateWorkshops = async () => {
    const { selectedWorkShop, form } = this.state;

     const obj = {...form};
     const offerObj = makeGrageData(obj);


    let result = await axios.put(`/api/offers/${selectedWorkShop.id}`, offerObj);
    if (result.data.status === "success") {
      this.setState({
        addOption: false,
        editOption: false,
        isVisableAddBtn:false,
        form: "",
      });
      this.props.dispatch(
        Notifications.success({
          message: this.props.translate("offerUpdated"),
          autoDismiss: 5,
          location: this.props.location,
        })
      );
      this.getWorkshops();
    } else {
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate(result.data.message),
          autoDismiss: 5,
          location: this.props.location,
        })
      );
    }
  };

  deleteWorkShop = async () => {
    const { selectedWorkShop } = this.state;
    let result = await axios.delete(`/api/offers/${selectedWorkShop.id}`);
  

    if (result.data.status === "success") {
      // this.getFilteredPAyments(this.state.to,this.state.from,this.state.account,this.state.vehcile,this.state.area)
      this.props.dispatch(
        Notifications.success({
          message: this.props.translate("offerDeleted"),
          autoDismiss: 5,
          location: this.props.location,
        })
      );
        this.setState({
          onDeleteConfirmation:false,
          searchGarage:"",
        }, ()=>this.getWorkshops())
      
    } else {
      this.props.dispatch(
        Notifications.error({
          message: this.props.translate(result.data.message),
          autoDismiss: 5,
          location: this.props.location,
        })
      );
    }
  };

  removeEnable = async (selected) => {
   
    if (selected[0]) {
      await this.setState({
        selectedWorkShop: selected[0],
        onDeleteConfirmation: true,
        form: "",
      });
    } else {
      this.setState({
        onDeleteConfirmation: true,
        form: "",
      });
    }
  };
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };

  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }

  setPagination = (items) => {
    let nItem = { ...items };
    let lastPage = nItem.total / this.state.pageSize;
    let IsFloate = this.checkFloteNumber(lastPage);
    delete nItem.data;
    nItem.pageSize = this.state.pageSize;
    nItem.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
    return nItem;
  };

  //   getFilteredPAyments
  // getPaymentList
  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({page:1, pageSize: rowsPerPage }, () => {
      this.getWorkshops();
    });
  };

  handleChangePage = (value) => {
    this.setState({ page: value }, () => {
      this.getWorkshops();
    });
  };

//   getGarages = () => {
//     this.getWorkshops();
   
//  };
  render() {
    if (this.props.logInUser) {
      return (
        <Layout {...this.props} noSidebar>
          {/* Workshops */}
          <OffersList
            {...this.props}
            {...this.state}
            getGarages={this.getWorkshops}
            searchContractText={this.searchContractText}
            addOption={this.state.addOption}
            modalControle={this.modalControle}
            addGarages={this.addGarages}
            getExpenseType={this.getExpenseType}
            garagesData={this.state.garagesData}
            data={this.state.form}
            handleChange={this.handleChange}
            handleChange1={this.handleChange1}
            handleChange2={this.handleChange2}
            isVisableAddBtn={this.state.isVisableAddBtn}
            checkRequiredFields={this.checkRequiredFields}
            handleSubmit={this.imageDataPrepare}
            editOption={this.state.editOption}
            workShopSelection={this.workShopSelection}
            selectedWorkShop={this.state.selectedWorkShop}
            updateWorkshops={this.imageDataPrepare}
            deleteWorkShop={this.deleteWorkShop}
            removeEnable={this.removeEnable}
            onCancel={this.onCancel}
            setPagination={this.setPagination}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            handleImageChange={this.uploadImage}
          />
        </Layout>
      );
    } else {
      fetch("/api/session", {
        headers: { Accept: "application/json; odata=verbose" },
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((res) => {
              if (res.status === "success") {
               
                let userInfo = res.data;
                this.props.setActiveLanguage(
                  userInfo?.attributes?.lang || "en"
                );
                this.props.dispatch(logInUserInfo(userInfo));
              } else if (res?.statusCode === "440") {
                window.location.replace("/login");
              } else if (res.statusCode) {
                var err = res?.message.split(":");
                err[1] = err[1].replace(")", "");
                this.props.dispatch(
                  Notifications.error({
                    message: this.props.translate(err[1]),
                    autoDismiss: 5,
                    location: this.props.location,
                  })
                );
                this.props.history.push("/login");
              }
            });
          } else {
            this.props.history.push("/login");
            throw response;
          }
        })
        .catch((e) => {
          this.props.dispatch(
            Notifications.error({
              message: "somethingWentWrong",
              autoDismiss: 5,
            })
          );
        });
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  ServerSetting: state.ServerSetting,
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)(withLocalize(Offers));
